import React, { Fragment } from "react";

import Table from "components/MeTable/Table";
import TableCell from "components/MeTable/TableCell";

function TableData({ header, rows }) {
  return (
    <Fragment>
      <Table>
        <thead className="sticky top-0 leading-6">
          <tr className="bg-amber-100 text-sm text-center font-normal text-stone-700">
            {header.map((field, index) => (
              <th key={index} className="py-2 px-2">
                {field}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-center ">
          {rows.map((row, index) => (
            <tr key={`row-${index}`} className="odd:bg-white even:bg-zinc-100">
              {row.map((field, index2) => (
                <TableCell key={`cell-${index2}`}>{field}</TableCell>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Fragment>
  );
}

export default TableData;

{
  /* <td
  key={`cell-${index2}`}
  className="py-2 pl-4 pr-3 text-sm text-slate-700 sm:pl-6 break-words"
>
  {field}
</td> */
}
