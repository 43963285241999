import { ChartBarIcon } from "@heroicons/react/20/solid";
import { CurrencyDollarIcon } from "@heroicons/react/24/solid";
import React from "react";

const Card = ({ title, amount, number, unit }) => (
  <div className="bg-white shadow-md shadow-line-100 hover:scale-110 traanform overflow-hidden transition-all duration-200 rounded-md ">
    <h2 className="flex justify-between text-lg font-semibold py-2 px-4">
      <span> {title}</span>
      <ChartBarIcon className="w-6 text-gray-600" />
    </h2>
    <div className="flex justify-between items-center  p-4">
      <div className="text-left flex flex-col">
        <span className="text-gray-600 font-medium">Total</span>
        <span className="text-2xl font-bold flex ">
          <CurrencyDollarIcon className="w-6" />
          <span>
            {amount} {unit}
          </span>
        </span>
      </div>
      <div className="text-right">
        <p className="text-gray-600"># ventas</p>
        <span className="text-2xl font-bold ">
          <span>{number}</span>
        </span>
      </div>
    </div>
  </div>
);

const CardsGroupTotal = ({ data = {} }) => {
  const variables = [
    "current_sales",
    "current_amount",
    "previous_sales",
    "previous_amount",
    "pending_sales",
    "pending_amount",
  ];
  let formated_data = {};

  variables.forEach((key) => {
    formated_data[key] = Number(data ? data[key] : 0).toLocaleString("es");
  });

  return (
    <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1">
      <Card
        title="Actual"
        amount={formated_data.current_amount}
        number={formated_data.current_sales}
        unit="COP"
      />
      <Card
        title="Mes Anterior"
        amount={formated_data.previous_amount}
        number={formated_data.previous_sales}
        unit="COP"
      />
      <Card
        title="Pendientes por confirmar"
        amount={formated_data.pending_amount}
        number={formated_data.pending_sales}
        unit="COP"
      />
    </div>
  );
};

export default CardsGroupTotal;
