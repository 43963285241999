import funcs from "tools/functions";

async function getStatements(value) {
  const terminal = funcs.getCookie();

  let dom = [];
  if (terminal) {
    dom = [
      ["sale_device", "=", Number(terminal.device_id)],
      ["state", "=", "draft"],
    ];
    if (value) {
      dom.push(["rec_name", "ilike", `%${value}%`]);
    }
  }
  return dom;
}

const getView = () => {
  let DictCtxView = {
    model: "hotel.booking.add_payment",
    form_action: [],
    table_action: [],
    webfields: {
      statement: {
        type: "many2one",
        model: "account.statement",
        readOnly: false,
        required: true,
        recSearch: getStatements,
      },
      amount: {
        type: "numeric",
        readOnly: false,
        required: true,
      },
      voucher: {
        type: "char",
        readOnly: false,
      },
      pay: {
        type: "button",
        color: "green",
        iconRight: "fi fi-rr-dollar",
      },
    },
    webform: [
      { name: "statement" },
      { name: "amount" },
      { name: "voucher" },

      {
        id: "paid",
        grid: [{ name: "pay" }],
        size: [1, 3],
        span: "col-span-2",
      },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
