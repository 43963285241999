import React from "react";

const colors = {
  blue: "bg-cyan-300",
  rose: "bg-rose-200",
  yellow: "bg-amber-200",
  green: "bg-lime-200",
};

function CardBasic({ onClick, children, color }) {
  const _color = colors[color];
  return (
    <div
      className={`${_color} w-64 my-4 mx-4 rounded-lg p-4 shadow-lg cursor-pointer`}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default CardBasic;
