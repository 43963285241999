// Guard model

const getView = () => {
  let ctxView = {
    model: "company.employee",
    selectable: "one",
    activeSearch: true,
    filters: null,
    table_action: [],
    webfields: {
      code: { type: "char", readOnly: true },
      party: { type: "many2one", model: "party.party", searchable: true },
    },
    webtree: [
      { name: "code", width: "10%" },
      { name: "party", width: "40%" },
    ],
    webform: [],
  };
  return ctxView;
};

export default { ctxView: getView };
