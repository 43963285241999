import React from "react";
import { useQuery } from "@tanstack/react-query";

import store from "store";
import OnScreenKeyboard from "./Components/OnScreenKeyboard";
import proxy from "api/proxy";
import tools from "tools/dates";
import SectionNoData from "components/Tools/SectionNoData";

const today = tools.dateToday();
const dom = [["date_ticket", ">=", today]];

const RegisterTicketSystem = () => {
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
  const queryTurnActive = useQuery(
    ["ticket_system.ticket_control", dom],
    async () => {
      let turns = [];
      if (dom) {
        const { data: res, error } = await proxy.search(
          "ticket_system.ticket_control",
          dom,
          [],
        );
        turns = res;
      }

      return turns;
    },
    {
      staleTime: 5000,
    },
  );

  return (
    <div className="screen h-screen bg-white p-4 rounded-md  overflow-hidden justify-center ">
      <div className="absolute top-8 left-8">
        {/* <img
          src="https://www.laboratoriobolivar.com/images/logo.png"
          className="max-w-[280px] mb-5"
        /> */}
      </div>
      {queryTurnActive?.data && queryTurnActive?.data.length != 0 ? (
        <div className="bg-gray-100 h-full w-full p-4 flex items-center ">
          <OnScreenKeyboard
            numbers={numbers}
            idControl={queryTurnActive?.data[0]}
          />
        </div>
      ) : (
        <SectionNoData text={"TURNADOR NO HA INICIADO"} />
      )}
      <div className="absolute right-10 bottom-8 text-gray-400">
        <span>By Presik S.A.S 2024</span>
      </div>
    </div>
  );
};

export default RegisterTicketSystem;
