import React, { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import store from "store";

import Board from "components/Meta/Board";
import Screen from "components/Meta/Screen";
import SectionSidePanel from "components/Panel/SectionSidePanel";
import HeaderPanel from "components/Panel/HeaderPanel";
import PanelRight from "components/Panel/PanelRight";
import { classNames } from "tools/ui";
import saleOrder from "./ModelSale";
import proxy from "api/proxy";
import DashBoardSeller from "./Components/DashBoardSeller";
import useMainStore from "store/mainStore";

const SaleOrder = ({ config }) => {
  const [showPanelRight, setShowPanelRight] = useState(false);
  const { record } = useMainStore();

  const { data: agent } = useQuery(["agent"], async () => {
    const session = store.get("ctxSession");
    let agent = session.agent;
    if (!agent) {
      const { data: agents } = await proxy.search(
        "commission.agent",
        [["user", "=", session.user]],
        ["rec_name"],
      );
      if (agents) {
        agent = agents[0];
        let session_ = { ...session };
        session_.agent = agent;
        await store.set("ctxVirtualSession", session_);
      }
      console.log(agent, "validate agent");
      return agent;
    }
  });

  const { data: modules_activated } = useQuery(
    ["modules_activated"],
    async () => {
      const { data: modules } = await proxy.search(
        "ir.module",
        [
          ["name", "in", ["sale_discount_flex", "sale_packing_unit"]],
          ["state", "=", "activated"],
        ],
        ["name"],
      );

      return modules?.map((item) => {
        return item.name;
      });
    },
  );

  const config_ = { ...config, modules_activated: modules_activated };
  const ctxView = saleOrder.ctxView(config_);

  function handleDisplay() {
    setShowPanelRight(!showPanelRight);
  }

  let _displayStyle = "";
  if (showPanelRight) {
    _displayStyle =
      "bg-gray-100 px-2 custom-transition duration-500 sticky w-full";
  }

  const _style = showPanelRight ? "justify-between" : "";

  const dataInfo = {};

  return (
    <Screen>
      <div className={classNames("flex w-full", _style)}>
        {!showPanelRight && <Board ctxView={ctxView} />}
        {!record.id && (
          <PanelRight
            title="Indicador Mes Actual"
            status={showPanelRight}
            handleDisplay={handleDisplay}
            displayOpen={showPanelRight}
            style={_displayStyle}
          >
            <HeaderPanel
              title="crm.opportunity.panel.status_info"
              dataInfo={dataInfo}
              name="status_info"
            />
            <DashBoardSeller agent={agent} />
          </PanelRight>
        )}
      </div>
    </Screen>
  );
};

export default SaleOrder;
