import React from "react";

// const StaffSchedule = () => {
//   return (
//     <div>StaffSchedule</div>
//   )
// }

const getView = () => {
  let DictCtxView = {
    model: "hotel.booking",
    form_action: ["edit", "add"], // options: ['save', 'delete']
    table_action: ["edit", "add"], // options: ['open', 'delete', 'edit', 'add', "info"]
    activeSearch: true,
    filters: getFilters,
    orderBy: [["number", "DESC"]],
    limit: 30,
    selectable: null, // Options for table rows: null, multi, one
    // domain: [["lines.arrival_date", "=", "2023-06-27"]], // Options: null or valid domain
    // domain: [],
    // pagination: [],
    reports: [
      {
        name: "hotel.booking",
        description: "Detalle de Reserva confirmada",
        send_print: true,
        send_email: "send_email",
      },
      {
        name: "hotel.booking_statement",
        description: "Estado de cuenta de cliente",
        send_print: true,
        send_email: "send_email",
      },
    ],
    tags: {
      state: stateColors,
    },
    sources: {
      image: iconOTA,
    },
    webfields: {
      number: {
        type: "char",
        readOnly: true,
        searchable: true,
      },
      contact: {
        type: "char",
        searchable: true,
        required: true,
      },
      channel: {
        type: "many2one",
        model: "hotel.channel",
        searchable: true,
        getIcon: getImageChannel,
        attrs: ["code"],
        dependents: ["ota_booking_code"],
      },
      agent: {
        type: "many2one",
        model: "commission.agent",
        searchable: true,
        // attrs: ["code"],
      },
      ota_booking_code: {
        type: "char",
        searchable: true,
        required: getOtaRequired,
        visible: getOtaVisible,
      },
      responsible_payment: {
        type: "selection",
        options: RESPONSIBLE,
        default: { id: "holder", name: "holder" },
        translate: true,
        required: true,
      },
      party: {
        type: "many2one",
        model: "party.party",
        searchable: true,
        ctxView: party.ctxView(),
        attrs: ["account_receivable"],
      },
      lines: {
        type: "one2many",
        model: "hotel.folio",
        ctxView: ModelFolios.ctxView(),
      },
      space: {
        type: "many2one",
        model: "analytic_account.space",
      },
      company: {
        type: "many2one",
        model: "company.rec_name",
      },
      booking_date: {
        type: "datetime",
        readOnly: true,
        default: () => dates.getNow(),
      },
      total_amount: {
        type: "numeric",
        readOnly: true,
      },
      tax_amount: {
        type: "numeric",
        readOnly: true,
      },
      total_advances: {
        type: "numeric",
        readOnly: true,
      },
      pending_to_pay: {
        type: "numeric",
        readOnly: true,
      },
      media: {
        type: "selection",
        options: MEDIA,
        translate: true,
      },
      group: {
        type: "boolean",
        translate: true,
      },
      breakfast_included: {
        type: "boolean",
        translate: true,
      },
      corporative: {
        type: "boolean",
        translate: true,
      },
      plan: {
        type: "selection",
        options: PLAN,
        default: { id: "bed_breakfast", name: "bed_breakfast" },
        translate: true,
      },
      state: {
        type: "char",
        translate: true,
        default: "offer",
        readOnly: true,
        tags: stateColors,
      },
      complimentary: {
        type: "boolean",
        translate: true,
        readOnly: true,
      },
      notes: {
        type: "text",
      },
      channel_payment_method: {
        type: "selection",
        translate: true,
        options: CHANNEL_PAYMENT_METHOD,
      },
      payment_term: {
        type: "many2one",
        model: "account.invoice.payment_term",
        // required: true,
        // default: 1,
      },
      payments: {
        type: "one2Many",
        model: "account.statement.line",
        ctxView: StatementLine.ctxView(),
        // required: true,
        // default: 1,
      },
      price_list: {
        type: "many2one",
        recSearch: () => [],
        model: "product.price_list",
      },
      rate_plan: {
        type: "many2one",
        model: "hotel.rate_plan",
        required: true,
      },
      lead_origin: {
        type: "many2one",
        recSearch: () => [],
        model: "crm.lead_origin",
      },
      confirm: {
        type: "button",
        button_method: "confirm",
        visible: visibleConfirm,
        onSuccessMsg: "Confirmacion exitosa!",
        color: "blue",
      },
      add_rooms: {
        type: "button_wizard",
        Component: WizardAddRoom,
        color: "sky",
        icon: "fi fi-rr-add",
      },
      add_payment: {
        type: "button_wizard",
        Component: WizardPayment,
        ctxView: modelWizardPayment.ctxView(),
        color: "lime",
        icon: "fi fi-rr-add",
        visible: visibleAddPayment,
      },
      bill: {
        type: "button_wizard",
        Component: WizardBill,
        visible: visibleBill,
        color: "amber",
        icon: "fi fi-rs-receipt",
      },
    },
    webtree: [
      { name: "number", width: "7%" },
      {
        name: "channel",
        width: "7%",
        widget: "image",
      },
      {
        name: "ota_booking_code",
        width: "7%",
        widget: "image",
      },
      { name: "party", width: "12%" },
      { name: "contact", width: "8%" },
      { name: "agent", width: "5%" },
      { name: "pending_to_pay", width: "7%" },
      { name: "total_amount", width: "7%" },
      { name: "booking_date", width: "7%", formatString: "YYYY-MM-DD" },
      { name: "group", width: "7%" },
      {
        name: "state",
        width: "10%",
        widget: "badge",
      },
    ],
    webform: [
      { name: "party", widget: "search" },
      {
        id: "infoDate",
        grid: [{ name: "number" }, { name: "booking_date" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "contact",
        grid: [{ name: "contact" }, { name: "rate_plan" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "info_payments",
        grid: [{ name: "responsible_payment" }, { name: "payment_term" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "info_commercial",
        grid: [{ name: "channel" }, { name: "plan" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "info_kind",
        grid: [
          { name: "ota_booking_code" },
          { name: "add_rooms" },
          { name: "add_payment" },
        ],
        size: [1, 3],
        span: "col-span-1",
      },
      { name: "lines" },
      {
        id: "payment",
        grid: [{ name: "total_advances" }, { name: "pending_to_pay" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "state",
        grid: [{ name: "total_amount" }, { name: "state" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "marketing",
        grid: [{ name: "lead_origin" }, { name: "media" }],
        size: [1, 2],
        span: "col-span-1",
        collapse: true,
      },
      {
        id: "segmentation",
        grid: [{ name: "group" }, { name: "corporative" }],
        size: [1, 2],
        span: "col-span-1",
        collapse: true,
      },
      {
        id: "crm",
        grid: [{ name: "agent" }, { name: "notes" }],
        size: [1, 2],
        span: "col-span-1",
        collapse: true,
      },
      {
        id: "buttons",
        grid: [{ name: "confirm" }, { name: "bill" }],
        size: [1, 2],
        span: "col-span-1",
        collapse: true,
      },
      {
        id: "tabs",
        tabs: [
          { name: "payments" },
          { name: "invoices" },
          { name: "notifications" },
        ],
        size: [1, 3],
        span: "col-span-2",
      },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
