import React from "react";

import Board from "components/Meta/Board";
import puchaseView from "./ModelPurchase";

const PurchaseOrder = (props) => {
  const { config } = props;
  const ctxView = puchaseView.ctxView(config);

  return <Board ctxView={ctxView} />;
};

export default PurchaseOrder;
