// Surveillance Location model

const getView = () => {
  let ctxView = {
    model: "surveillance.location",
    selectable: "multi", // options: multi - one - false
    activeSearch: true,
    filters: null,
    table_action: [],
    webfields: {
      name: { type: "char", readOnly: true, searchable: true },
      customer: { type: "many2one", model: "party.party", searchable: true },
      city: { type: "many2one", model: "party.city_code", searchable: true },
      code: { type: "char" },
      positions: { type: "one2many", model: "surveillance.location.position" },
    },
    webtree: [
      { name: "code", width: "10%" },
      { name: "name", width: "40%" },
      { name: "customer", width: "30%" },
      { name: "city", width: "20%" },
    ],
    webform: [],
  };
  return ctxView;
};

export default { ctxView: getView };
