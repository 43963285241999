import React, { useState } from "react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/20/solid";

const SearchProduct = ({ onSearch }) => {
  const [value, setValue] = useState("");

  const onChange = (e) => setValue(e.target.value);
  const clearSearch = () => {
    setValue("");
    onSearch([]);
  };

  const handleSearchProduct = (e) => {
    if ((e && e.keyCode === 13) || e === undefined) {
      if (value !== "" || value !== null) {
        let domain = [];
        const target_words = value.split(" ");
        target_words.forEach((tw) => {
          if (tw.length <= 1) {
            return;
          }
          const clause = [
            "OR",
            ["template.name", "ilike", `%${tw}%`],
            ["description", "ilike", `%${tw}%`],
            ["code", "ilike", `%${tw}%`],
            ["barcode", "=", `%${tw}%`],
          ];
          domain.push(clause);
        });
        onSearch(domain);
      }
    }
  };

  return (
    <div className="mx-auto bg-white border border-gray-300 shadow-md py-2 px-4 max-w-4xl h-12 rounded-3xl flex justify-between text-2xl">
      <MagnifyingGlassIcon
        className="h-full w-5 fill-slate-500 cursor-pointer hover:scale-125"
        onClick={() => handleSearchProduct()}
      />
      <input
        type="text"
        id="searchProduct"
        value={value}
        onChange={onChange}
        onKeyDown={handleSearchProduct}
        placeholder="¿Qué producto estas buscando?"
        className="bg-transparent w-full outline-none px-2"
      />
      <XMarkIcon
        className="h-full w-5 fill-slate-500 cursor-pointer hover:scale-125"
        onClick={clearSearch}
      />
    </div>
  );
};

export default SearchProduct;
