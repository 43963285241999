import React from "react";
import { FormattedMessage as FM } from "react-intl";

import FormWizard from "./FormWizard";
import StatementReportModel from "./StatementReportModel";
import CashUpReportModel from "./CashUpReportModel";
import MenuReport from "components/Report/MenuReport";

const base = "flex flex-wrap bg-stone-100 py-4 pl-4 h-24 items-center divide-x";
const nameReports = "account.statement.reports";
const iconOpen = "fi fi-rr-browser";
const iconClose = "fi fi-rr-checkbox";

function Toolbar() {
  function actionMenu(name, icon) {
    const model = "account.statement";
    const ctxView = {};

    return (
      <FormWizard
        key={name}
        name={name}
        icon={icon}
        model={model}
        ctxView={ctxView}
        resertWizardModal={true}
      />
    );
  }

  const reports = [
    {
      name: "account_statement_co.advanced",
      description: "account_statement_co.advanced.description",
      ctxView: StatementReportModel.ctxView(),
    },
    {
      name: "account_statement_co.cash_up",
      description: "account_statement_co.cash_up.description",
      ctxView: CashUpReportModel.ctxView(),
    },
  ];

  return (
    <div className={base}>
      {actionMenu("open", iconOpen)}
      {actionMenu("close", iconClose)}
      <div className="flex grow ml-8">
        <MenuReport reports={reports} />
      </div>
    </div>
  );
}

export default Toolbar;
