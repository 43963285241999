// Folio Charge model

const getUnitPrice = (record) => {
  if (record.product) {
    return record.product.sale_price_taxed;
  }
};

const onChangeProduct = (record, store) => {
  if (record.product) {
    const product = record.product;
    // let unit = product["sale_uom."];
    let unit_price = product.sale_price_taxed;
    unit_price = parseFloat(unit_price.toString());
    store.unit_price = unit_price;
    record.unit_price = unit_price;
    record.unit_price_w_tax = record.product.sale_price_taxed;
    record.amount = unit_price * record.quantity;
    // unit: unit, FIXME
  }
};

const getAmount = (record) => {
  if (record.unit_price_w_tax && record.quantity) {
    const amount = (record.unit_price_w_tax * record.quantity).toFixed(2);
    return amount;
  }
};

const getView = () => {
  let DictCtxView = {
    model: "hotel.folio.charge",
    form_action: ["add", "save"], // options: ['save', 'delete']
    table_action: ["delete"], // options: ['open', 'delete', 'edit', 'add']
    domain: [], // Options: null or valid domain
    orderBy: [["sale_date", "ASC"]],
    limit: 100,
    selectable: null, // Options for table rows: null, multi, one,
    target: "folio",
    webfields: {
      sale_date: {
        type: "date",
        readOnly: false,
        default: new Date(),
        required: true,
      },
      kind: {
        type: "selection",
        readOnly: true,
        required: true,
        default: { id: "product", name: "product" },
        translate: true,
        options: [
          { id: "product", name: "product" },
          { id: "accommodation", name: "accommodation" },
        ],
      },
      amount: { type: "numeric", readOnly: true, function: getAmount },
      status: { type: "char", readOnly: true, translate: true },
      folio: {
        type: "many2one",
        model: "hotel.folio",
        readOnly: true,
      },
      invoice_line: {
        type: "many2one",
        model: "account.invoice.line",
        readOnly: true,
      },
      product: {
        type: "many2one",
        model: "product.product",
        required: true,
        recSearch: () => [
          ["account_category", "!=", null],
          ["salable", "=", true],
        ],
        withChange: onChangeProduct,
        attrs: [
          "id",
          "list_price",
          "name",
          "sale_price_taxed",
          "sale_uom.rec_name",
        ],
      },
      quantity: {
        type: "numeric",
        readOnly: false,
        // default: 1,
        required: true,
      },
      order: {
        type: "char",
        readOnly: true,
        // default: 1,
      },
      unit_price_w_tax: {
        type: "numeric",
        function: getUnitPrice,
        // required: true,
      },
      "origin.time_start": { type: "char", readOnly: true },
      "origin.time_end": { type: "char", readOnly: true },
      origin: { type: "many2one", readOnly: true },
    },
    webtree: [
      { name: "sale_date", width: "10%" },
      { name: "product", width: "25%" },
      { name: "origin.time_start", width: "23%" },
      { name: "origin.time_end", width: "23%" },
      { name: "quantity", width: "20%" },
      { name: "unit_price_w_tax", width: "20%" },
      { name: "amount", width: "25%" },
      { name: "status", width: "25%" },
    ],
    webform: [
      { name: "product" },
      {
        id: "info_main",
        grid: [{ name: "sale_date" }, { name: "folio" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "info_qty",
        grid: [{ name: "quantity" }, { name: "unit_price_w_tax" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "info_amounts",
        grid: [{ name: "amount" }, { name: "kind" }],
        size: [1, 2],
        span: "col-span-1",
      },
      { name: "order" },
      {
        id: "info_status",
        grid: [{ name: "status" }, { name: "invoice_line" }],
        size: [1, 2],
        span: "col-span-1",
      },
      // { name: "add_product" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
