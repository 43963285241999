import React, { useState, useEffect } from "react";
// import GridCards from "components/Card/GridCards";
import FullTable from "components/MeTable/FullTable";
import proxy from "api/proxy";

const ModalWizzardActivityInformation = ({ ctxView, idSchedule }) => {
  let [records, setRecords] = useState(null);
  const _ctxView = { ...ctxView };

  _ctxView.webtree = _ctxView.webtree.filter(function (item) {
    return item.name !== "quantity" && item.name !== "add";
  });

  _ctxView.webtree.push({
    name: "sale",
    width: "30%",
  });
  _ctxView.webfields.sale = {
    type: "integer",
  };

  useEffect(() => {
    fillActivities();
  }, [idSchedule]);

  function updateRecords(field, value, recId) {
    let _records = new Map(records);
    let category = _records.get(recId);
    category[field] = value;
    setRecords(_records);
  }

  const fillActivities = async () => {
    const fields = [
      "quota",
      "time_start",
      "time_end",
      "available",
      "id",
      "state",
      "schedule",
      "schedule.date_activity",
      "schedule.kind",
      "schedule.kind.name",
      "schedule.kind.product.name",
      "schedule.kind.product.sale_price_taxed",
      "schedule.kind.product.list_price",
      "schedule.kind.product.sale_uom.name",
      "schedule.kind.product.sale_price_w_tax",
    ];
    let dom = [["schedule.id", "=", idSchedule]];
    const { data: res, error } = await proxy.search(
      "sale_activity.activity",
      dom,
      fields,
    );
    console.log(error);
    res.sort(orderArray);
    const mapRecs = new Map();
    res.forEach((act) => {
      if (
        act.state == "scheduled"
        // act.time_start > formattedTime &&
        // act.available > 0
      ) {
        // act.id = act.id;
        act.sale = act.quota - act.available;
        (act.quantity = parseInt(1)), mapRecs.set(act.id, act);
      }
    });
    console.log(res);
    if (res) {
      setRecords(mapRecs);
    }
  };

  const orderArray = (a, b) => {
    const hour1 = a.time_start;
    const hour2 = b.time_start;
    if (hour1 < hour2) {
      return -1;
    }
    if (hour1 > hour2) {
      return 1;
    }
    return 0;
  };

  return (
    <div id="hotel-booking-wizard-rooms " className="block">
      <div className="-z-auto flex p-4 mb-4 ml-auto my-auto bg-blue-presik text-white rounded-t-md ">
        <h2 className="!my-auto font-bold leading-6 text-white mb-2 text-2xl">
          Programación para estas actividades
        </h2>
      </div>
      <div className="p-6">
        <FullTable
          records={records}
          isLoading={false}
          limit={30}
          updateRecords={updateRecords}
          onChangeView={() => console.log("cambio de vista")}
          ctxView={_ctxView}
        />
      </div>
    </div>
  );
};

export default ModalWizzardActivityInformation;
