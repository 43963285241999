// Purchase model
import purchase_line from "./ModelPurchaseLine";
import tools from "tools/common";
// asa
let dateTo = tools.dateToday();
let dateFrom = tools.dateAdd(new Date(), "days", 30);
import store from "store";

const stateColors = {
  // draft: 'black',
  quotation: "slate",
  approved: "sky",
  confirmed: "pink",
  processing: "emerald",
  done: "lime",
  // cancelled: "bg-indigo-500",
};

const getFilters = (session) => {
  return {
    all: [
      ["purchase_date", "<=", `${dateTo}`],
      ["purchase_date", ">=", `${dateFrom}`],
      [
        "state",
        "in",
        [
          "quotation",
          "confirmed",
          "processing",
          "approved",
          "canceled",
          "done",
        ],
      ],
      [
        "OR",
        [
          ["confirm_user.user", "=", session.user],
          [
            "state",
            "in",
            [
              "quotation",
              "confirmed",
              "processing",
              "approved",
              "canceled",
              "done",
            ],
          ],
        ],
        [
          ["approval_user.user", "=", session.user],
          [
            "state",
            "in",
            [
              "quotation",
              "confirmed",
              "processing",
              "approved",
              "canceled",
              "done",
            ],
          ],
        ],
      ],
    ],
    pending: [
      [
        "OR",
        [
          ["confirm_user.user", "=", session.user],
          ["state", "=", "quotation"],
          ["approval_user", "=", null],
        ],
        [
          ["state", "=", "quotation"],
          ["approval_user.user", "=", session.user],
        ],
        [
          ["confirm_user.user", "=", session.user],
          ["state", "=", "approved"],
        ],
        // [
        //   ["confirm_user.user", "=", session.user],
        //   ["state", "=", "quotation"],
        // ],
      ],
    ],
  };
};

const getVisible = (button, rec) => {
  const session = store.get("ctxSession");
  if (button === "approval") {
    if (
      rec &&
      rec.state === "quotation" &&
      rec["approval_user"] &&
      rec["approval_user"].user === session.user
    ) {
      return true;
    }
  } else if (
    rec &&
    rec["confirm_user"] &&
    rec["confirm_user"].user === session.user
  ) {
    if (
      rec.state === "approved" ||
      (rec.state === "quotation" && !rec["approval_user"])
    ) {
      return true;
    }
  }
  return false;
};

const getView = (config) => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    limit: 150,
    activeSearch: true,
    form_action: ["attachment"], // options: ['save', 'delete']
    table_action: ["open"], // options: ['open', 'delete', 'edit', 'add']
    title: { field: "number", model: true },
    filters: getFilters,
    validate_state: true, // ??? What is this?
    model: "purchase.purchase",
    tags: {
      state: stateColors,
    },
    webfields: {
      purchase_date: {
        type: "date",
        readOnly: true,
        searchable: true,
      },
      create_uid: {
        type: "many2one",
        model: "res.user",
        readOnly: true,
      },
      company: {
        type: "many2one",
        model: "company.company",
      },
      party: {
        type: "many2one",
        model: "party.party",
        readOnly: true,
        searchable: true,
      },
      warehouse: {
        type: "many2one",
        model: "stock.location",
        readOnly: true,
      },
      invoice_address: {
        type: "many2one",
        model: "party.address",
        readOnly: false,
      },
      payment_term: {
        type: "many2one",
        model: "account.invoice.payment_term",
        readOnly: true,
      },
      approval_user: {
        type: "many2one",
        model: "purchase.configuration.user_authorized",
        readOnly: true,
        fieldsNames: ["user"],
      },
      confirm_user: {
        type: "many2one",
        model: "purchase.configuration.user_authorized",
        readOnly: true,
        fieldsNames: ["user"],
      },
      description: { type: "char", readOnly: true },
      reference: { type: "char", readOnly: true },
      number: { type: "char", readOnly: true, searchable: true },
      confirmation_signature: { type: "image", readOnly: true, bytes: true },
      approval_signature: { type: "image", readOnly: true, bytes: true },
      project: {
        type: "many2one",
        model: "project.work",
        readOnly: true,
        searchable: true,
      },
      lines: {
        type: "one2many",
        model: "purchase.line",
        ctxView: purchase_line.ctxView(config),
      },
      untaxed_amount: { type: "numeric", readOnly: true },
      tax_amount: { type: "numeric", readOnly: true },
      total_amount: { type: "numeric", readOnly: true },
      comment: {
        type: "text",
        readOnly: {
          state: ["done", "confirmed", "cancelled", "processing"],
        },
      },
      approval: {
        type: "button",
        button_method: "approval",
        visible: getVisible,
        onSuccessMsg: "ORDEN DE COMPRA APROBADA!",
        color: "blue",
      },
      confirm: {
        type: "button",
        button_method: "confirm",
        visible: getVisible,
        onSuccessMsg: "ORDEN DE COMPRA CONFIRMADA!",
        color: "pink",
      },
      state: {
        type: "char",
        readOnly: true,
        translate: true,
      },
      priority: {
        type: "char",
        translate: true,
      },
    },
    webtree: [
      { name: "number", width: "15%" },
      { name: "purchase_date", width: "12%" },
      { name: "party", width: "35%" },
      { name: "priority", width: "12%" },
      { name: "total_amount", width: "21%" },
      { name: "state", width: "10%", widget: "circle" },
    ],
    webform: [
      { name: "party" },
      // { name: "number" },
      { name: "purchase_date" },
      { name: "create_uid" },
      { name: "description" },
      // { name: "project" },
      { name: "payment_term" },
      { name: "reference" },
      { name: "approval_user" },
      { name: "confirm_user" },
      { name: "state" },
      { name: "lines" },
      { name: "untaxed_amount" },
      { name: "tax_amount" },
      { name: "total_amount" },
      { name: "comment" },
      { name: "approval_signature" },
      { name: "confirmation_signature" },
      { name: "approval" },
      { name: "confirm" },
    ],
  };

  if (config && config.project) {
    DictCtxView["webform"].insert(5, { name: "project" });
  }

  return DictCtxView;
};

export default { ctxView: getView };
