import React, { Fragment, useState } from "react";
import { signal } from "@preact/signals-react";

import { EyeIcon, PlusCircleIcon, UserIcon } from "@heroicons/react/20/solid";
import { BedIcon } from "../icons";
import { FormattedMessage as FM } from "react-intl";
import GalleryImagesRoom from "../../../components/Modals/GalleryImagesPopup";
import GalleryItem from "ext-apps/WebBookingHotel/Components/GalleryItem";
import IntegerField from "./IntegerField";
import SelectPricesRadio from "./SelectPriceRadio";
import { MoonIcon } from "@heroicons/react/24/solid";

const imagesGalleryRoom = signal([]);

const ItemClassification = ({
  dataRooms,
  handleSelectClassification,
  imgDefault,
  colors,
  data,
}) => {
  const [roomValues, setRoomValues] = useState({});

  const handleInputChange = (name, value, max) => {
    const [type, categoryId] = name.split("-");
    const otherType = type === "adults" ? "children" : "adults";
    const otherValue = roomValues[categoryId]?.[otherType] || 0;

    if (value + otherValue <= max) {
      setRoomValues((prevState) => ({
        ...prevState,
        [categoryId]: {
          ...prevState[categoryId],
          [type]: value,
        },
      }));
    }
  };

  if (!dataRooms || dataRooms.length === 0) {
    return null;
  }
  return (
    <Fragment>
      <div className="md:mb-20 space-y-5 pt-10 md:pt-0">
        {dataRooms.map((category) => {
          let max_accommodation =
            category.max_adults + category.max_children ?? 0;
          return (
            <div
              key={category.id}
              className=" border border-gray-100 shadow-sm rounded-md bg-white relative cursor-pointer overflow-hidden"
            >
              <div className="md:flex">
                <div
                  className="md:w-[36%] w-full relative group "
                  onClick={() => (imagesGalleryRoom.value = category?.images)}
                >
                  <div className=" absolute w-full h-full  md:h-72 md:top-3 top-0 md:left-3 group-hover:flex transform transition-all duration-500  z-20">
                    <EyeIcon className="w-10 h-10 text-white" />
                  </div>
                  <img
                    src={category?.images[0] || imgDefault}
                    className="h-62 md:h-72 object-cover w-full object-center"
                  />
                </div>
                <div className=" flex flex-col px-5 pt-5 items-stretch  md:w-[65%] w-full relative">
                  <h2 className="font-medium mb-4">{category.name}</h2>
                  <div className="flex md:flex-col md:block md:justify-around">
                    <div className="md:flex space-y-3 md:space-y-0 md:divide-x md:divide-gray-200 md:space-x-4 justify-between text-gray-600  md:w-full">
                      <span className="flex flex-col ">
                        <b className="min-w-max">
                          <FM id="web.booking.occupation" />
                        </b>
                        <span className="flex space-x-2">
                          <UserIcon className="w-4" />{" "}
                          <span>{max_accommodation}</span>
                        </span>
                      </span>
                      <span className="md:pl-3 flex flex-col">
                        <b className="min-w-max">
                          <FM id="web.booking.minimum_stay" />
                        </b>
                        <span className="flex space-x-2 items-center">
                          <MoonIcon className="w-4" />
                          <span>
                            1 <FM id="app.booking.night" />
                          </span>
                        </span>
                      </span>
                      <span className="md:pl-3 flex flex-col">
                        <b className="min-w-max">
                          <FM id="web.booking.avalaible" />
                        </b>
                        <span className="flex space-x-2 items-center">
                          <BedIcon size="13" />
                          <span>{category.rooms.length || 0} </span>
                        </span>
                      </span>

                      <div className="flex justify-between w-full space-x-3 md:max-w-[290px] relative md:pl-4">
                        <IntegerField
                          field={{
                            name: `adults-${category.id}`,
                            readOnly: false,
                            color: "white",
                            label: "hotel.folio.num_adults",
                          }}
                          data={{ value: roomValues[category.id]?.adults || 0 }}
                          attrs={{ level: "wizard" }}
                          onChange={handleInputChange}
                          max={max_accommodation}
                        />
                        <IntegerField
                          field={{
                            name: `children-${category.id}`,
                            readOnly: false,
                            color: "white",
                            label: "hotel.folio.num_children",
                          }}
                          data={{
                            value: roomValues[category.id]?.children || 0,
                          }}
                          attrs={{ level: "wizard" }}
                          onChange={handleInputChange}
                          max={max_accommodation}
                        />
                      </div>
                    </div>
                  </div>
                  {data?.children_policy && (
                    <div className="text-gray-600 mt-3 font-normal text-sm">
                      {data?.children_policy}
                    </div>
                  )}

                  <GalleryItem
                    data={category.amenities}
                    key={category?.id}
                    colors={colors}
                  />
                </div>
              </div>

              <SelectPricesRadio
                ratePlan={category.price_lists}
                category={category}
                roomValues={roomValues}
                currentNumPeople={roomValues[category.id]}
                handleSelectClassification={handleSelectClassification}
                colors={colors}
              />
            </div>
          );
        })}
      </div>
      <GalleryImagesRoom
        show={imagesGalleryRoom.value.length !== 0}
        dataImages={imagesGalleryRoom.value}
        onClose={() => (imagesGalleryRoom.value = [])}
      />
    </Fragment>
  );
};

export default ItemClassification;
