import React from "react";
import { useQuery } from "@tanstack/react-query";
import mainStore from "store/mainStore";
import childStore from "store/childStore";

import proxy from "api/proxy";
import { Fragment } from "react";

function ProductDiscount({ onClose }) {
  // const [selected, setSelected] = useState([]);
  const { store, record, updateStore, updateRecord } = mainStore();
  const {
    record: recordChild,
    updateStore: updateStoreChild,
    updateRecord: updateRecordChild,
  } = childStore();
  const shopId = record?.shop?.id;
  const queryProductDiscount = useQuery(
    ["productDiscount", shopId],
    async () => {
      let discounts = [];
      if (shopId) {
        const { data } = await proxy.search(
          "sale.shop",
          [["id", "=", shopId]],
          ["discounts.name", "discounts.type_discount", "discounts.discount"],
        );
        discounts = data;
      }

      return discounts;
    },
  );

  const handleSelect = async (discount) => {
    const list_price = recordChild.product.list_price;
    const sale_price_taxed = recordChild.amount;
    let activeRecord = { ...record };
    let lines = activeRecord.lines;
    let record_ = lines.get(recordChild.id);

    let storeRecord = { ...store };
    let to_create = storeRecord.lines.get("create").get(recordChild.id);
    if (discount.type_discount === "percentage") {
      const dsc = discount.discount / 100;
      record_["unit_price"] = Number(list_price - dsc * list_price)
        .toFixed(2)
        .toString();
      record_["amount"] = Number(sale_price_taxed - dsc * sale_price_taxed)
        .toFixed(2)
        .toString();
      record_["total_amount"] = Number(
        (sale_price_taxed - dsc * sale_price_taxed) * recordChild.quantity,
      )
        .toFixed(2)
        .toString();

      to_create.unit_price = record_.unit_price;
    } else {
      const price_taxed = sale_price_taxed - discount.discount;
      const args = {
        product: recordChild.product.id,
        sale_price_taxed: price_taxed,
      };
      const { data: result } = await proxy.method({
        model: "sale.sale",
        method: "dash_get_reverse_sale_price_taxed",
        args: [args],
      });
      record_["unit_price"] = Number(result["unit_price"])
        .toFixed(2)
        .toString();
      record_["amount"] = Number(price_taxed).toFixed(2).toString();
      record_["total_amount"] = Number(price_taxed * recordChild.quantity)
        .toFixed(2)
        .toString();
      to_create.unit_price = record_.unit_price;
    }
    updateRecordChild(record_);
    updateStoreChild(to_create);
    updateRecord(activeRecord);
    updateStore(storeRecord);
    onClose();
  };

  return (
    <Fragment>
      <div className="px-4 sm:px-6 lg:px-8 py-2 md:col-span-2">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 pt-2">
          {queryProductDiscount.data?.[0]["discounts."].map(
            (discount, index) => {
              return (
                <div
                  className={
                    "rounded-md hover:opacity-50 text-center py-8 bg-orange-100"
                  }
                  key={discount.id}
                  onClick={() => {
                    handleSelect(discount);
                  }}
                  name={index}
                >
                  <p
                    key={discount.id}
                    className="pointer-events-none flex-wrap p-2"
                  >
                    {discount.name}
                  </p>
                </div>
              );
            },
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default ProductDiscount;
