import React, { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { FormattedMessage as FM } from "react-intl";

import Button from "components/Buttons/StdButton";

function ModalInfo({ onClose, open, msg }) {
  if (!open) return;

  const buttons = [
    <Button key="close" color="rose" onClick={() => onClose("modalInfo")}>
      CERRAR
    </Button>,
  ];

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => onClose("modalInfo")}
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <div className="flex flex-col items-center p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <div className="flex items-center justify-center w-12 h-12 bg-red-100 rounded-full">
              <ExclamationCircleIcon
                className="w-6 h-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-xl font-medium leading-6 text-gray-900"
              >
                <FM id={msg} />
              </Dialog.Title>
            </div>

            <div className="mt-5 sm:mt-6">
              <div className="flex justify-center">{buttons}</div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ModalInfo;
