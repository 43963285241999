import React, { Fragment, useState } from "react";

import mainStore from "store/mainStore";
import { classNames } from "tools/ui";

const base =
  "flex mx-2 my-1 h-14 cursor-pointer rounded-full border-8 active:scale-95 active:bg-stone-100";

function TableWidget({ record, onClose }) {
  const { store: _store, record: _record } = mainStore();

  function onSelect(e) {
    e.preventDefault();
    _record.table_assigned = record;
    _store.table_assigned = record.id;
    onClose();
  }

  let colorTable = "border-stone-400 active:border-sky-700";
  let colorChair = "bg-stone-300";
  let hover = "hover:bg-stone-200";
  // let textColor = "text-stone-400";
  let textColor = "text-sky-800";

  // if (record.sale) {
  //   colorTable = "border-sky-500";
  //   colorChair = "bg-sky-200";
  //   hover = "hover:bg-sky-100 hover:border-sky-600";
  //   textColor = "text-sky-800";
  // }
  const chair = (
    <div className={classNames("h-2 lg:mx-2 rounded-full", colorChair)} />
  );
  const rowChairs = (
    <div className="grid grid-cols-2 gap-x-2 mx-2 sm:mx-4 md:mx-6">
      {chair}
      {chair}
    </div>
  );

  return (
    <div className="grid my-5">
      {rowChairs}
      <div className={classNames(base, hover, colorTable)} onClick={onSelect}>
        <p
          className={classNames(
            "text-md md:text-sm text-left mx-auto my-auto font-medium",
            textColor,
          )}
        >
          {record.rec_name}
        </p>
      </div>
      {rowChairs}
    </div>
  );
}

export default TableWidget;
