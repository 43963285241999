import React from "react";
import { FormattedMessage as FM } from "react-intl";

import CardBasic from "components/Card/CardBasic";
import salesIcon from "assets/apps/charts-sales.png";
import purchasesIcon from "assets/apps/charts-purchases.png";
import hotelIcon from "assets/apps/charts-hotel.png";
import crmIcon from "assets/apps/charts-crm.png";

const CATEGORIES = {
  sales: { color: "blue", icon: salesIcon },
  hotel: { color: "yellow", icon: hotelIcon },
  purchases: { color: "green", icon: purchasesIcon },
  crm: { color: "rose", icon: crmIcon },
};

function CardGroupCharts({ category, onSelected }) {
  const color = CATEGORIES[category].color;

  function onClick() {
    onSelected(category);
  }

  return (
    <div className="flex">
      <CardBasic color={color} onClick={onClick}>
        <img src={CATEGORIES[category].icon} width={110} alt="chart-icon" />
        <p className="text-5xl text-black text-opacity-30 font-bold my-4 text-center">
          <FM id={category} />
        </p>
      </CardBasic>
    </div>
  );
}

export default CardGroupCharts;
