import { useRef, useEffect } from "react";

const BookingMap = ({ getAddress, cod_country, api }) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: cod_country || "co" },
    fields: [
      "address_components",
      "geometry",
      "icon",
      "name",
      "formatted_address",
    ],
    types: ["geocode"],
  };

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${api}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = initializeAutoComplete;
      document.body.appendChild(script);
    };

    const initializeAutoComplete = () => {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options,
      );
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        let formattedAddressParts = place.formatted_address;
        let suburb = "";
        let state = "";
        let city = "";
        let postcode = "";

        if (place.address_components) {
          place.address_components.forEach(function (component) {
            const types = component.types;

            if (types.includes("locality")) {
              suburb = component.long_name;
            }
            if (types.includes("administrative_area_level_1")) {
              state = component.short_name;
            }
            if (types.includes("postal_code")) {
              postcode = component.long_name;
            }
            if (types.includes("administrative_area_level_2")) {
              city = component.long_name;
            }
          });

          const address_ = {
            suburb,
            state,
            city,
            postcode,
            fullAddress: formattedAddressParts,
          };

          getAddress(address_);
        }
      });
    };

    if (!window.google) {
      loadGoogleMapsScript();
    } else {
      initializeAutoComplete();
    }
  }, []);

  return (
    <div>
      <label className="flex text-sm mt-3 mb-0.5 ml-1 font-bold text-zinc-600 max-h-5 capitalize">
        enter address <p className="text-red-500">&nbsp; *</p>
      </label>
      <input
        ref={inputRef}
        className="bg-white h-10 rounded-md w-full py-2 px-2 text-gray-700 border border-slate-300 focus:outline-none focus:border focus:border-cyan-500"
      />
    </div>
  );
};

export default BookingMap;
