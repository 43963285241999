import React from "react";

import { channelsImg } from "./constants";

function PopupHeader(props) {
  let { room, guest_name, channel_code, kind } = props.record;
  let imgChannel = channelsImg["house"];
  if (channel_code) {
    imgChannel = channelsImg[channel_code];
  }
  if (kind === "accommodation") {
    return (
      <div className="flex grid-cols-2">
        <div className="w-3/4">
          <p className="text-3xl font-bold text-zinc-500">{room}</p>
          <p className="text-lg text-zinc-500">{guest_name}</p>
        </div>
        <div className="w-auto ml-auto">
          <img src={imgChannel} width={50} className="" />
        </div>
      </div>
    );
  } else {
    return (
      <div className="text-lg">
        <p className="text-3xl font-bold text-zinc-600">{room}</p>
      </div>
    );
  }
}

export default PopupHeader;
