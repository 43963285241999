import store from "store";

import proxy from "api/proxy";
import fetchLocalAPI from "printing/printing";

const onPrint = async (record) => {
  const model = "sale.sale";
  const session = store.get("ctxSession");
  const args = { user_id: session.user };
  const { data: ctxPrinting } = await proxy.methodCall({
    model: model,
    method: "get_printing_context",
    args: [args],
  });
  const apiPrint = ctxPrinting.local_printer;
  // console.log(ctxPrinting.local_printer, "validate passs");
  const argSale = {
    type_doc: "invoice",
    sale_id: record.id,
  };
  const { data: sale } = await proxy.methodCall({
    model: model,
    method: "get_data",
    args: [argSale],
  });
  const obj = { record: sale, context: ctxPrinting };
  const printer = new fetchLocalAPI(apiPrint);
  const { data, error } = await printer.post("/print_invoice", obj);
  console.log(data);
  if (error) {
    window.alert(error);
  }
};

const getVisibleBilling = (name, rec) => {
  if (rec?.state.includes("draft", "quotation")) return true;
  return false;
};

const getVisiblePrint = (name, rec) => {
  if (rec?.state === "processing") return true;
  return false;
};

export default {
  row_selectable: true,
  selectable: "multi",
  activeSearch: true,
  model: "sale.sale",
  form_action: [],
  table_action: [],
  pagination: [],
  limit: 50,
  searchable: true,

  webfields: {
    number: { type: "integer", readonly: true, searchable: true },
    party: { type: "many2one", readonly: true },
    sale_date: { type: "date", readonly: true },
    shipment_date: { type: "date", readonly: true },
    delivery_time: { type: "char", readonly: true },
    shipment_address: { type: "many2one", readonly: true },
    consumer: { type: "many2one", readonly: true },
    channel: { type: "many2one", readonly: true },
    total_amount: { type: "numeric", readonly: true },
    order_status: { type: "char", readonly: true, translate: true },
    waiting_time: { type: "numeric", readonly: true },
    payment_method: { type: "char", readonly: true, translate: true },
    comment: { type: "text", readonly: true },
    state: { type: "char", readonly: true, translate: true },
    billing: {
      type: "button",
      button_method: "dash_faster_process",
      visible: getVisibleBilling,
      color: "blue",
    },
    print: {
      type: "button",
      method: onPrint,
      visible: getVisiblePrint,
      color: "amber",
    },
  },
  webtree: [
    { name: "number", width: "8%" },
    { name: "sale_date", width: "10%" },
    { name: "shipment_date", width: "10%" },
    { name: "delivery_time", width: "10%" },
    { name: "consumer", width: "30%" },
    { name: "waiting_time", width: "5%" },
    { name: "payment_method", width: "7%" },
    { name: "order_status", width: "10%" },
    { name: "total_amount", width: "10%" },
    { name: "billing", width: "8%" },
    { name: "print", width: "8%" },
    { name: "state", width: "7%" },
  ],
};

// {name: 'print', width: '8%'},
