// Money model
const onChangeQuantity = async (store) => {
  if (store.quantity && store.bill) {
    store.amount = store.quantity * store.bill;
  }
};

const getView = () => {
  let DictCtxView = {
    selectable: false, // options: multi - one - false
    domain: [
      // ["agent.user", "=", session.user],
    ],
    model: "sale_pos.money_count",
    webfields: {
      bill: { type: "numeric", readOnly: true },
      quantity: { type: "integer", withChange: onChangeQuantity },
      amount: { type: "numeric", readOnly: true },
    },
    webtree: [
      { name: "bill", width: "30%" },
      { name: "quantity", width: "20%", editable: true },
      { name: "amount", width: "30%" },
    ],
    webform: [],
  };

  return DictCtxView;
};

export default { ctxView: getView };
