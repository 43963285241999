import React, { Fragment, useState } from "react";
import { FormattedMessage as FM } from "react-intl";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";

import BasicModal from "components/Modals/BasicModal";
import StdButton from "components/Buttons/StdButton";
import TextField from "components/MeForm/TextField";
import SelectionField from "components/MeForm/SelectionField";
import NumericField from "components/MeForm/NumericField";

import imgOpenpay from "../../../assets/img/openpay_color.png";
import imgVisa from "../../../assets/img/visa.png";
import imgMastercard from "../../../assets/img/masterCard.png";
import imgAmerican from "../../../assets/img/americanExpress.png";
import imgAvVillas from "../../../assets/img/bc_av_villas.png";
import imgBancolombia from "../../../assets/img/bc_bancolombia.png";
import imgCajaSocial from "../../../assets/img/bc_caja_social.png";
import imgDavivienda from "../../../assets/img/bc_davivienda.png";
import imgItau from "../../../assets/img/bc_itau.png";
import imgFallabela from "../../../assets/img/bc_falabella.png";
import { useCardStore } from "../store";
import openPay from "tools/openPay";
import ModalValidation from "./ModalValidation";

// import puchaseModel from "./ModelPurchase";

const Card = ({ open, onClose }) => {
  const { cardStore, upCardStore } = useCardStore();
  const [isCorrect, setIsCorrect] = useState(true);
  const [validations, setValidations] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const validateCard = async () => {
    const OpenPay = window.OpenPay;
    if (!OpenPay.getId()) {
      openPay.initOpenPay();
    }

    const testNumber = OpenPay.card.validateCardNumber(cardStore.card_number);
    const testCVC = OpenPay.card.validateCVC(cardStore.secret_code);
    const testCVCType = OpenPay.card.validateCVC(
      String(cardStore.secret_code),
      String(cardStore.card_number),
    );
    const testExpiry = OpenPay.card.validateExpiry(
      String(cardStore.month),
      String(cardStore.year),
    );
    const testType = OpenPay.card.cardType(String(cardStore.card_number));

    const validation = {
      testNumber,
      testCVCType,
      testCVC,
      testExpiry,
      testType,
    };
    const toValidate = Object.values(validation).slice(0, -1);
    console.log(toValidate, "this is to validate");
    const resultValidate = toValidate.every((element) => element === true);
    setValidations(validation);
    setIsCorrect(resultValidate);
    setOpenModal(true);
  };

  const handleCloseModal = (confirm) => {
    if (confirm) {
      saveCard();
    }
    console.log("pasa por esta");
    setOpenModal(false);
  };

  const saveCard = async () => {
    console.log("pass");
  };

  const handleChange = (name, value) => {
    upCardStore(name, value);
  };

  const MONTHS = [
    { id: "01", name: "01" },
    { id: "02", name: "02" },
    { id: "03", name: "03" },
    { id: "04", name: "04" },
    { id: "05", name: "05" },
    { id: "06", name: "06" },
    { id: "07", name: "07" },
    { id: "08", name: "08" },
    { id: "09", name: "09" },
    { id: "10", name: "10" },
    { id: "11", name: "11" },
    { id: "12", name: "12" },
  ];

  const button = (
    <StdButton
      name={"button_save"}
      color={"lime"}
      noFill={true}
      content={"button_add"}
      onClick={validateCard}
    />
  );
  console.log(open, "modal card is open??");
  return (
    <Fragment>
      <BasicModal
        width="w-5/6 sm:w-2/4"
        title="portal_customer.add_card"
        open={open}
        buttons={[button]}
        onClose={onClose}
      >
        <div className="flex flex-row divide-x-2 space-x-6">
          <div>
            <h6 className="font-semibold text-slate-600">
              Tarjetas de Crédito
            </h6>
            <div className="flex flex-row space-x-4 space-y-1">
              <img
                src={imgVisa}
                className="w-10 relative animate-fade-right animate-ease-linear transform "
              />
              <img
                src={imgMastercard}
                className="w-10 relative animate-fade-right animate-ease-linear transform "
              />
              <img
                src={imgAmerican}
                className="w-10 relative animate-fade-right animate-ease-linear transform "
              />
            </div>
          </div>
          <div className="pl-6">
            <h6 className="font-semibold text-slate-600">Tarjetas de débito</h6>
            <div className="flex flex-row space-x-4 space-y-1">
              <img
                src={imgAvVillas}
                className="w-10 relative animate-fade-right animate-ease-linear transform "
              />
              <img
                src={imgBancolombia}
                className="w-10 relative animate-fade-right animate-ease-linear transform "
              />
              <img
                src={imgCajaSocial}
                className="w-10 relative animate-fade-right animate-ease-linear transform "
              />
            </div>
            <div className="flex flex-row space-x-4 space-y-1">
              <img
                src={imgDavivienda}
                className="w-10 relative animate-fade-right animate-ease-linear transform "
              />
              <img
                src={imgFallabela}
                className="w-10 relative animate-fade-right animate-ease-linear transform "
              />
              <img
                src={imgItau}
                className="w-10 relative animate-fade-right animate-ease-linear transform "
              />
            </div>
          </div>
        </div>
        <form className="grid grid-cols-2 gap-4 py-8">
          <TextField
            type={"char"}
            label={"portal_customer.holder_name"}
            name={"holder_name"}
            placeholder={"Como aparece en la tarjeta"}
            onChange={handleChange}
            value={cardStore.holder_name}
            required={true}
            specialCharacters={true}
          />
          <NumericField
            label={"portal_customer.card_number"}
            name={"card_number"}
            onChange={handleChange}
            value={cardStore.card_number}
            required={true}
          />
          <div className="">
            <label className="flex text-sm mt-3 mb-0.5 ml-1 font-bold text-zinc-600 max-h-5">
              <FM id={"portal_customer.expiration_date"} />
              <span className="text-red-500">&nbsp; *</span>
            </label>
            <div className="flex space-x-10">
              {/* <NumericField
                name={"month"}
                placeholder={"mes mm"}
                onChange={handleChange}
                value={cardStore.month}
              /> */}
              <SelectionField
                // label={"portal_customer.holder_name"}
                name={"month"}
                placeholder={"mes mm"}
                onChange={handleChange}
                value={cardStore.month}
                required={true}
                options={MONTHS}
              />
              <NumericField
                name={"year"}
                placeholder={"año yy"}
                onChange={handleChange}
                value={cardStore.year}
              />
            </div>
          </div>
          <NumericField
            label={"portal_customer.secret_code"}
            name={"secret_code"}
            placeholder={"3 dígitos"}
            onChange={handleChange}
            value={cardStore.secret_code}
            required={true}
          />
        </form>
        <div className="flex flex-row-reverse space-x-4">
          <div className="flex flex-row divide-x-2 w-full sm:w-3/4">
            <div className="w-96 pr-3">
              <span>Transacciones realizadas vía:</span>
              <img
                src={imgOpenpay}
                className=" w-40 relative animate-fade-right animate-ease-linear transform "
              />
            </div>
            <div className="flex flex-row pl-3">
              <span>
                <ShieldCheckIcon className="w-8 fill-green-700" />
              </span>
              <span className="flex-wrap">
                Tus pagos se realizaran de forma segura con encriptación de 256
                bits:
              </span>
            </div>
          </div>
        </div>
      </BasicModal>
      {openModal && (
        <ModalValidation
          open={openModal}
          onClose={handleCloseModal}
          validations={validations}
          isCorrect={isCorrect}
        />
      )}
    </Fragment>
  );
};

export default Card;
