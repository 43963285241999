// src/components/DataTable.js
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/solid";
import React, { useState } from "react";

const STYLE_BUTTON =
  " flex space-x-2 justify-center items-center ml-3 bg-blue-presik cursor-pointer hover:bg-yellow-500 text-white font-bold py-2 hover:scale-95 transform duration-100 transition-all hover:text-blue-presik px-4 rounded focus:outline-none focus:shadow-outline";
const ChartTableProduct = (props) => {
  let data = [];
  if (props.data) {
    data = props.data;
  }
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10; // Tamaño de página

  // Función para obtener los datos de la página actual
  const getCurrentPageData = () => {
    if (!data) return [];
    const startIndex = currentPage * pageSize;
    const endIndex = startIndex + pageSize;
    return data.slice(startIndex, endIndex);
  };

  // Función para manejar el cambio de página
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Función para manejar la ordenación
  const handleSort = () => {
    // Implementa la lógica de ordenación aquí
    // Puedes mantener un estado para la columna y dirección de ordenación
    // Ejemplo básico de ordenación:
    // setSortedData([...data].sort((a, b) => (a[column] > b[column]) ? 1 : -1));
  };

  // Obtiene los datos de la página actual
  const currentPageData = getCurrentPageData();

  return (
    <div className=" p-4 col-span-3 bg-white rounded-md relative shadow-md rounded-tl-md overflow-hidden">
      <h2 className="text-center font-semibold text-[24px] text-[#666666] mb-4">
        Productos vendidos
      </h2>
      <div className="rounded-md overflow-hidden border border-blue-presik">
        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white rounded-lg shadow overflow-y-auto">
          <thead className="sticky top-0 !bg-blue-presik text-white ">
            <tr className="text-left ">
              <th className="py-2 px-3  border-b border-gray-200 font-bold">
                Código
              </th>
              <th className="py-2 px-3  border-b border-gray-200 font-bold">
                Nombre
              </th>
              <th className="py-2 px-3  border-b border-gray-200 font-bold">
                UOM
              </th>
              <th className="py-2 px-3  border-b border-gray-200 font-bold">
                Cantidad
              </th>
              <th className="py-2 px-3  border-b border-gray-200 font-bold">
                Monto sin Impuesto
              </th>
              <th className="py-2 px-3  border-b border-gray-200 font-bold">
                % del Total
              </th>
            </tr>
          </thead>
          <tbody>
            {currentPageData.map((row, index) => (
              <tr
                key={index}
                className={`border-t border-gray-200 ${index % 2 == 0 ? "bg-gray-100" : ""} `}
              >
                <td className="py-2 px-3">{row.product_code}</td>
                <td className="py-2 px-3">{row.product_name}</td>
                <td className="py-2 px-3">{row.uom}</td>
                <td className="py-2 px-3 text-right">{row.quantity}</td>
                <td className="py-2 px-3 text-right">
                  {Number(row.untaxed_amount).toLocaleString("es")}
                </td>
                <td className="py-2 px-3 text-right">
                  {Number(row.percent * 100).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Controles de paginación */}
      <div className="flex justify-between items-center mt-4 w-full">
        <div className="flex">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 0}
            className={STYLE_BUTTON}
          >
            <ArrowLeftCircleIcon className="w-5" />
            <span>Anterior</span>
          </button>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === Math.ceil(data.length / pageSize) - 1}
            className={STYLE_BUTTON}
          >
            <span>Siguiente</span>
            <ArrowRightCircleIcon className="w-5" />
          </button>
        </div>
        <div className="text-sm text-gray-700">
          Página <span className="font-medium">{currentPage + 1}</span> de{" "}
          <span className="font-medium">
            {Math.ceil(data.length / pageSize)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChartTableProduct;
