import React, { useState } from "react";
import imgDefault from "../../../assets/img/default-image.jpg";

const GalleryProduct = ({ images }) => {
  const [imgSelected, setImgSelected] = useState(
    images ? images[0] : imgDefault,
  );

  return (
    <div className="px-6 pt-10 md:w-1/2 lg:px-14 xl:px-16">
      <img
        src={imgSelected}
        decoding="async"
        className="border-8 border-gray-150 rounded-md shadow-sm w-full object-cover object-center p-2"
      />
      <div className="grid grid-cols-4 gap-2 bg-white p-2 mt-4 rounded-md shadow-xs">
        {images &&
          images.map((item, key) => (
            <img
              key={key}
              src={item}
              className="w-full object-cover h-16 rounded-md cursor-pointer border-2 border-gray-150 shadow-sm"
              onClick={() => setImgSelected(item)}
            />
          ))}
      </div>
    </div>
  );
};

export default GalleryProduct;
