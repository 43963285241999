// Sale model line

const getView = () => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    form_action_add: "modal",
    model: "sale.line",
    row_selectable: false,
    table_action: ["edit"],
    form_action: ["add", "delete"],
    otherActions: [],
    selectable: true,
    webfields: {
      product: {
        type: "many2one",
        model: "product.product",
        attrs: [
          "id",
          "list_price",
          "name",
          "sale_price_taxed",
          "sale_uom.rec_name",
          "categories",
          "products_mix",
        ],
        required: true,
      },
      unit: {
        type: "many2one",
        model: "product.uom",
        readOnly: true,
        attrs: ["id", "name", "category", "factor"],
      },
      quantity: {
        type: "numeric",
        required: true,
        default: 1,
      },
      unit_price: { type: "numeric", readOnly: true },
      amount: { type: "numeric", readOnly: true },
    },
    webtree: [
      { name: "product", width: "40%" },
      { name: "quantity", width: "10%" },
      { name: "amount", width: "23%" },
    ],
    webform: [{ name: "product" }, { name: "quantity" }, { name: "amount" }],
  };
  return DictCtxView;
};

export default { ctxView: getView };
