import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { TagIcon, IdentificationIcon } from "@heroicons/react/20/solid";
import Say from "react-say/lib/Say";

const TicketTurn = ({ dataTurn = [] }) => {
  return (
    <div className="h-full w-[30vw] 2xl:w-[27vw] bg-gray-200 2xl:p-10 p-4 rounded-md shadow-md">
      <h3 className="text-2xl 2xl:text-9xl text-blue-presik font-semibold mb-5">
        <FM id="ticket_system.ticket.currents_turn" />
      </h3>
      <div className="space-y-4">
        {dataTurn &&
          dataTurn.map((item) => {
            const hiddenChars = item.number_id.length - 4;
            let textVoice = (item["party."] && item["party."].name) || "";
            const maskedString =
              hiddenChars > 0
                ? "*".repeat(hiddenChars) + item.number_id.slice(-4)
                : item.number_id;

            return (
              <div
                key={item.id}
                className="bg-slate-100 py-10 px-10 rounded-md flex space-x-4 2xl:space-x-8  justify-start items-center"
              >
                <div className="text-8xl 2xl:text-6xl items-center text-blue-presik  font-semibold flex">
                  {/* <TagIcon className="w-20 2xl:w-28" /> */}
                  <span className="2xl:text-[150px]  relative">
                    {/* <span className="absolute text-6xl -left-8">#</span> */}
                    {item.turn}
                  </span>
                </div>
                <div className="">
                  <span className="text-2xl  2xl:text-8xl font-bold text-blue-presik ">
                    {(item.position && item?.["position."].number) || ""}
                  </span>
                  {item["party."] && item["party."].name && (
                    <span className="text-2xl 2xl:text-5xl block truncate max-w-[680px]">
                      {item["party."].name}
                    </span>
                  )}
                  <Say
                    text={`turno ${item.turn} ha ${item?.["position."].number} ${textVoice}`}
                    lang="es-ES"
                    pitch={1.2}
                    rate={0.8}
                  />

                  <span className="text-2xl 2xl:text-8xl flex space-x-2">
                    <IdentificationIcon className="w-10 2xl:w-16" />
                    <span>{maskedString}</span>
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TicketTurn;
