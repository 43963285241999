import React from "react";
import { FormattedMessage as FM } from "react-intl";

/**
 * Define cell for row
 * @param {Boolean} selectable - optional selectable
 * @param {Boolean} editable - optional default true
 * @param {Function} onClick - callback function on click cell
 * @param {ChildNode} Component - Component to render as child
 * @param {String} textAling - optional align text for cell [left, right, center] default center
 * @param {String} color - define color for cell
 * @param {Array} style - define style for cell
 */

function HeaderCell({
  index,
  field,
  handleSort,
  // orderField,
  // orderDirection,
  ctxView,
}) {
  const _className =
    "sticky top-0 z-10 whitespace-nowrap py-2 px-2 text-sm text-center font-normal text-stone-700";
  const { model } = ctxView;

  return (
    <th
      key={index}
      scope="col"
      onClick={() => handleSort(field.name)}
      className={_className}
    >
      <FM id={`${model}.${field.name}`} />
    </th>
  );
}

export default HeaderCell;
