import React from "react";
import useLangStore from "store/langStore";
import { FormattedMessage as FM } from "react-intl";

const ChangeLang = (colors) => {
  const { changeLang, locale } = useLangStore();

  const handleLanguageChange = (event) => {
    const newLocale = event.target.value;
    changeLang(newLocale);
  };

  return (
    <div className="border border-white flex  rounded-md min-w-[70px] pr-2">
      <select
        onChange={handleLanguageChange}
        value={locale}
        className={`${
          colors.colors || "bg-blue-presik"
        } text-white py-1 rounded-md outline-none w-full px-2 select-custom `}
      >
        <option value="en" className="bg-blue-presik">
          <FM
            id="app.global.english"
            className={`${colors.colors || "bg-blue-presik"}  text-white`}
          />
        </option>
        <option
          value="es"
          className={`${colors.colors || "bg-blue-presik"}  text-white`}
        >
          <FM id="app.global.spanish" />
        </option>
      </select>
    </div>
  );
};

export default ChangeLang;
