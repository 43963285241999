// Sale model
import store from "store";
import date from "date-and-time";

import saleLine from "./SaleLine";
import MenuRest from "./MenuRest";
import TableDisplay from "./TableDisplay";
import dates from "tools/dates";
import proxy from "api/proxy";
// import mainStore from "store/mainStore";

const stateColors = {
  draft: "amber",
  quotation: "sky",
  processing: "lime",
  cancelled: "rose",
  // cancelled: "indigo",
};

const defaultParty = async () => {
  const session = store.get("ctxSession");
  const dom = [["id", "=", session.shop]];
  const fields = ["id", "rec_name", "party"];
  const { data } = await proxy.search("sale.shop", dom, fields, 1);
  return data[0].party;
};

const defaultAgent = async () => {
  const session = store.get("ctxSession");
  const dom = [["user", "=", session.user]];
  const fields = ["id", "rec_name"];
  const { data } = await proxy.search("commission.agent", dom, fields, 1);
  return data[0];
};

const getFilters = (session) => {
  let last14 = date.addHours(new Date(), -14);
  last14 = dates.fmtDatetime2Tryton(last14);
  return {
    today: [
      ["create_uid", "=", session.user],
      ["create_date", ">=", last14],
    ],
    draft: [
      ["state", "=", "draft"],
      ["create_date", ">=", last14],
    ],
  };
};

const getTable = (value) => {
  const session = store.get("ctxSession");
  let dom = [
    ["shop", "=", session.shop],
    // ["state", "=", "available"],
  ];
  if (value) {
    const addDom = ["rec_name", "ilike", `%${value}%`];
    dom.push(addDom);
  }
  return dom;
};

function setReadOnly(rec) {
  return rec.number ? true : false;
}

function readOnlyLines(rec) {
  let res = false;
  if (rec.state === "quotation") {
    res = true;
  }
  return res;
}

function isVisibleSend(name, store) {
  let res = false;
  if (store.lines) {
    for (const line of store.lines.values()) {
      if (line.id <= 0 || line.status_order === "draft") {
        res = true;
      }
    }
  }
  if (store?.id < 0 && !store.table_assigned) {
    res = false;
  }
  return res;
}

const getTableScreen = ({ attrs, field, record }) => {
  return TableDisplay({ attrs, field, record });
};

const getTotalAmount = (record) => {
  const lines = record?.lines?.values();
  if (lines) {
    const totalAmount = Array.from(lines).reduce((total, item) => {
      const sale_price = item.product.sale_price_taxed ?? item.sale_price_taxed;
      return total + item.quantity * sale_price;
    }, 0);
    // return func.fmtMoney(totalAmount);
    return totalAmount;
  }
};

const getView = (config) => {
  const session = store.get("ctxSession");

  let DictCtxView = {
    selectable: false, // options: multi - one - false
    activeSearch: true,
    filters: getFilters,
    access: { edit: () => false },
    form_action: ["edit"],
    table_action: ["edit", "add"],
    model: "sale.sale",
    preSave: "send_order",
    title: { field: "number" },
    tags: {
      state: stateColors,
    },
    webfields: {
      number: { type: "char", readOnly: true },
      party: {
        type: "many2one",
        model: "party.party",
        default: defaultParty,
      },
      table_assigned: {
        type: "many2one",
        model: "sale.shop.table",
        recSearch: getTable,
        required: true,
        component: getTableScreen,
      },
      shop: {
        type: "many2one",
        model: "sale.shop",
        default: session.shop,
      },
      agent: {
        type: "many2one",
        model: "commission.agent",
        recSearch: () => [["user", "=", session.user]],
        default: defaultAgent,
        readOnly: setReadOnly,
      },
      sale_date: {
        type: "date",
        readOnly: true,
      },
      persons: {
        type: "integer",
        default: 2,
        // readOnly: true,
      },
      price_list: {
        type: "many2one",
        model: "product.price_list",
        recSearch: () => "[]",
        readOnly: { state: ["quotation"] },
      },
      state: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "draft",
        tags: stateColors,
      },
      kind: {
        type: "char",
        readOnly: true,
        default: "to_table",
      },
      lines: {
        type: "one2many",
        model: "sale.line",
        ctxView: saleLine.ctxView(config),
        required: true,
        readOnly: readOnlyLines,
        // default: [],
      },
      total_amount: {
        type: "numeric",
        readOnly: true,
        function: getTotalAmount,
      },
      comment: { type: "text", readOnly: { state: ["quotation"] } },
      send_order: {
        type: "button",
        method: "send_order",
        color: "green",
        visible: isVisibleSend,
      },
      // change_table: {
      //   type: "button",
      //   method: "send_order",
      //   color: "amber",
      //   visible: isVisibleChangeTable,
      // },
      see_menu: {
        type: "button_custom_modal",
        Component: MenuRest,
        icon: "fi fi-rr-edit",
        color: "amber",
      },
    },
    webtree: [
      { name: "number", width: "15%" },
      { name: "table_assigned", width: "30%" },
      { name: "agent" },
      // { name: "total_amount", width: "10%" },
      { name: "sale_date", width: "15%" },
      {
        name: "state",
        width: "15%",
        widget: "badge",
      },
    ],
    webform: [
      { name: "agent" },
      { name: "number" },
      {
        id: "info_order",
        grid: [
          { name: "table_assigned", widget: "custom" },
          { name: "persons" },
        ],
        size: [1, 2],
        span: "col-span-1",
      },
      { name: "see_menu" },
      { name: "lines" },
      { name: "total_amount" },
      { name: "send_order" },
      // { name: "change_table" },
      { name: "state" },
      { name: "comment" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
