import React, { Fragment } from "react";

const LogoTerra = () => {
  return (
    <Fragment>
      <img
        src="https://res.cloudinary.com/dl4sdiukx/image/upload/v1697643382/bgg7gduizrwervhaw2jp.png"
        alt="Terra Biohotel Medellin"
        title=""
        className="w-[100px]"
      />
    </Fragment>
  );
};

export default LogoTerra;
