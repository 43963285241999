import React from "react";

const style =
  "px-3 py-0 grid gap-x-4 md:grid-cols-2 lg:px-3 lg:py-3 lg:grid-cols-2 xl:grid-cols-2";

function FormGrid(props) {
  return (
    <div id="form-grid" className="bg-slate-50">
      <div className={style}>{props.children}</div>
    </div>
  );
}

export default FormGrid;
