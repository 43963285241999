import React, { useState } from "react";
import date from "date-and-time";
import store from "store";
import { signal } from "@preact/signals-react";

import CalendarTable from "./calendar/CalendarTable";
import ModalForm from "components/Modals/ModalForm";
import GroupTags from "./calendar/GroupTags";
import DateField from "components/MeForm/DateField";
import StdButton from "components/Buttons/StdButton";
import ModelFolio from "./ModelFolio";
import ModelMaintenance from "./ModelMaintenance";
import { useWizardStore } from "store/wizardStore";
import "./calendar/style.scss";

const fmtDate = "YYYY-MM-DD";
const selectedDate = signal(new Date());

function Calendar(props) {
  const { rooms } = props;
  let [openModal, setOpenModal] = useState(false);
  let [ctxView, setCtxView] = useState(null);
  let [reload, setReload] = useState(false);
  const session = store.get("ctxSession");
  let _groups = session.groups.filter(
    (group) => group.name === "Hotel Booking Viewer",
  );
  let viewerUser = _groups.length > 0 ? true : false;

  function onClickAdd(e, name) {
    setCtxView(ModelFolio.ctxView());
    setOpenModal(true);
  }

  function onClickAddMaint(e, name) {
    setCtxView(ModelMaintenance.ctxView());
    setOpenModal(true);
  }

  async function onCloseModal() {
    const { reset, trigger } = useWizardStore.getState();
    setOpenModal(false);
    await trigger(); // This is for reload table
    await reset();
  }

  function onChangeDate(field, value) {
    let value_ = date.parse(value, fmtDate);
    selectedDate.value = value_;
  }

  const field = {
    name: "planner_date",
    labeled: false,
    onChange: onChangeDate,
  };

  const attrsDate = {
    level: "wizard",
  };

  return (
    <div className="w-full">
      <div className="flex h-20 space-x-12 w-full">
        <p className="my-auto text-4xl font-bold ml-6 text-sky-800">
          ROOMS RACK
        </p>
        <div className="my-auto">
          <DateField attrs={attrsDate} data={selectedDate} field={field} />
        </div>
        <GroupTags />
        <div className="flex my-auto gap-x-3 ">
          {!viewerUser && (
            <StdButton
              onClick={onClickAdd}
              name="add_booking"
              content="hotel.calendar.add_booking"
              iconRight="fi fi-bs-add"
            />
          )}
          {!viewerUser && (
            <StdButton
              onClick={onClickAddMaint}
              name="add_maintenance"
              content="hotel.calendar.add_maintenance"
              iconRight="fi fi-bs-add"
              color="slate"
            />
          )}
          <ModalForm
            level="wizard"
            open={openModal}
            ctxView={ctxView}
            mode="create"
            nameField={"booking"}
            onClose={onCloseModal}
          />
        </div>
      </div>
      {rooms.size > 0 && (
        <CalendarTable
          viewerUser={viewerUser}
          selectedDate={selectedDate}
          rooms={rooms}
          reload={reload}
        />
      )}
    </div>
  );
}

export default Calendar;
