import tools from "tools/dates";
const today = tools.dateToday();

const stateColors = {
  waiting: "amber",
  done: "lime",
  busy: "sky",
  cancelled: "rose",
};

const getView = () => {
  let DictCtxView = {
    model: "ticket_system.ticket",
    form_action: [],
    table_action: [],
    title: { field: "ticket", model: true },
    tags: {
      status: stateColors,
    },
    domain: [["ticket_control.date_ticket", "=", today]],
    webfields: {
      id: { type: "char" },
      turn: { type: "char" },
      "position.number": { type: "char" },
      position: {
        type: "many2one",
        model: "ticket_system.attention_position",
        readOnly: true,
        // attrs: ["date_ticket", "next_ticket"],
      },
      state: { type: "char", tags: stateColors },
      number_id: { type: "char" },
      "ticket_control.date_ticket": { type: "date" },
      "ticket_control.next_ticket": { type: "char" },
      ticket_control: {
        type: "many2one",
        model: "ticket_system.ticket_control",
        readOnly: true,
        // attrs: ["date_ticket", "next_ticket"],
      },

      user: {
        type: "many2one",
        model: "res.user",
        readOnly: true,
      },
    },
    webtree: [
      { name: "turn", width: "30%" },
      { name: "number_id", width: "30%" },
      { name: "position.number", width: "30%" },
      { name: "state", width: "30%", widget: "badge" },
    ],
    webform: [],
  };

  return DictCtxView;
};

export default { ctxView: getView };
