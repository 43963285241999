import React from "react";

import Board from "components/Meta/Board";
import saleOrder from "./ModelSale";

const SaleOrder = (props) => {
  const { config } = props;
  const ctxView = saleOrder.ctxView(config);

  return <Board ctxView={ctxView} />;
};

export default SaleOrder;
