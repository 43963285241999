import React, { useState } from "react";
import { FormattedMessage as FM } from "react-intl";

import BasicModal from "components/Modals/BasicModal";
import StdButton from "components/Buttons/StdButton";
import { XMarkIcon } from "@heroicons/react/24/solid";

const MESSAGES = {
  testNumber: "invalid_card_number",
  testCVCType: "invalid_cvc_card_number",
  testCVC: "invalid_cvc",
  testExpiry: "invalid_expire_date",
  testType: "card_validated",
};
// import puchaseModel from "./ModelPurchase";

const ModalValidation = ({ open, onClose, isCorrect, validations }) => {
  const handleClose = (confirm) => {
    console.log("validate access close");
    onClose(confirm);
  };
  const handleCloses = () => {
    console.log("validate access close ....");
  };
  let title = "validation_card_openpay";
  const buttonConfim = (
    <StdButton
      name={"button_confirm"}
      color={"blue"}
      noFill={true}
      content={"button_confirm"}
      onClick={() => handleClose(true)}
    />
  );

  const buttonCancel = (
    <StdButton
      name={"button_cancel"}
      color={"red"}
      noFill={true}
      content={"button_cancel"}
      onClick={handleClose}
    />
  );
  const buttonAccept = (
    <StdButton
      name={"button_accept"}
      color={"blue"}
      noFill={true}
      content={"button_accept"}
      onClick={handleClose}
    />
  );

  let buttons = [buttonAccept];
  let messages = Object.entries(validations)
    .filter(([key, value]) => {
      return value === false;
    })
    .map(([key, value]) => key);

  if (isCorrect) {
    title = "confirm_card_openpay";
    buttons = [buttonCancel, buttonConfim];
    messages = Object.keys(MESSAGES).slice(-1);
  }

  return (
    <BasicModal
      width="w-3/6 sm:w-1/4"
      title={title}
      open={open}
      buttons={buttons}
      onClose={() => {}}
    >
      <ul className="px-4 text-base font-light text-slate-700 transition-shadow">
        {messages.map((message) => {
          return (
            <li key={message}>
              <span className="flex">
                <XMarkIcon className="stroke-2 stroke-rose-300 w-5" />
                <FM id={MESSAGES[message]} />
              </span>
            </li>
          );
        })}
      </ul>
    </BasicModal>
  );
};

export default ModalValidation;
