import React from "react";
// import { useRecoilState, useRecoilValue } from "recoil";

import pizzaImg from "./assets/pizza_white.png";

const ButtonProduct = (props) => {
  function onClicked(e) {
    e.preventDefault();
    props.onClick(props);
  }
  const unit_price = props.sale_price_w_tax.toLocaleString();
  let img_src =
    props["images."].length > 0 ? props["images."][0].image_url : null;
  if (!img_src) {
    img_src = pizzaImg;
  }

  return (
    <div
      key={props.id}
      id="self-service-button-product"
      onClick={onClicked}
      className="border rounded-lg shadow-lg active:scale-95 active:bg-amber-100 pb-4 text-center"
    >
      <img
        alt={props.name}
        src={img_src}
        className="pb-4 w-full h-4/5 object-cover"
      />
      <p className="text-base px-2">{props.name}</p>
      <p className="px-2 text-lg font-bold">{unit_price}</p>
    </div>
  );
};

export default ButtonProduct;
