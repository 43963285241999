import React, { useEffect, useState } from "react";
import Button from "components/Buttons/StdButton";
import { FormattedMessage as FM } from "react-intl";
import {
  ArrowSmallLeftIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import store from "store";
import date from "date-and-time";

import ctxViewSale from "./ModelViewSale";
import QuickTable from "components/Meta/QuickTable";
import QuickForm from "components/Meta/QuickForm";
import TimeLine from "apps/SaleCallCenter/TimeLine";

function ListSaleHistory() {
  const session = store.get("ctxSession");
  const [viewType, setViewType] = useState("list");
  const [activeRecord, setActiveRecord] = useState({});
  const [storeRec, setStoreRec] = useState({});
  const [action, setAction] = useState(null);
  const today = date.format(
    date.addHours(new Date(), -12, true),
    "YYYY-MM-DD HH:mm:ss",
  );

  // const today_ = tools.fmtDate2Tryton(today);
  const domain = [
    ["create_date", ">=", today],
    [
      "order_status",
      "in",
      ["draft", "commanded", "in_preparation", "dispatched", "requested"],
    ],
    ["shop", "=", session.shop],
  ];

  useEffect(() => {}, [today]);

  async function onChangeView(event, action, rec) {
    let viewType_ = viewType;
    if (["edit", "add"].includes(action)) {
      viewType_ = "form";
    } else if (["editModal", "addModal"].includes(action)) {
      viewType_ = "modalForm";
    } else {
      viewType_ = "list";
    }
    if (!rec) {
      setActiveRecord({});
      setStoreRec({});
    } else {
      setActiveRecord(rec);
      setStoreRec({ id: rec.id });
    }

    setViewType(viewType_);
  }

  function onClickAction(event, action) {
    setAction(action);
    setTimeout(() => {
      setAction(null);
    }, 3000);
  }

  return (
    <div className="mt-4">
      {viewType === "list" ? (
        <QuickTable
          onChangeView={onChangeView}
          ctxView={ctxViewSale}
          domain={domain}
        />
      ) : (
        <>
          <div
            id="board-header"
            className="grid-cols-12 flex mx-4 pb-2 text-slate-600"
          >
            <div className="flex col-span-12 md:col-span-8 w-4/5">
              <h1 className="truncate text-3xl font-bold">
                {<FM id={ctxViewSale.model} key={ctxViewSale.model} />}{" "}
                {activeRecord.number}
              </h1>
            </div>
            <div className="flex col-span-12 md:col-span-4 ml-auto gap-x-4">
              <Button onClick={(e) => onChangeView(e, "return")} color="green">
                <ArrowSmallLeftIcon
                  className="h-6 w-6 mx-auto stroke-2 "
                  aria-hidden="true"
                />
              </Button>
              <Button
                onClick={(e) => onClickAction(e, "save")}
                color="blue"
                size="w-40"
              >
                <ArrowDownTrayIcon
                  className="h-6 w-6 mx-auto stroke-2"
                  aria-hidden="true"
                />
              </Button>
            </div>
          </div>

          <TimeLine
            activeRecord={activeRecord}
            storeRecord={storeRec}
            ctxView={ctxViewSale}
            action={action}
          />
          <QuickForm
            activeRecord={activeRecord}
            storeRecord={storeRec}
            ctxView={ctxViewSale}
            action={action}
          />
        </>
      )}
    </div>
  );
}

export default ListSaleHistory;
