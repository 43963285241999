import React from "react";

import { classNames, colors } from "tools/ui";
import upStore from "store/upStore";
// import mainStore from "store/mainStore";
// import useMainStore from "store/mainStore";

function BooleanCell(props) {
  // let parentStore = useMainStore.getState();
  // const { save: saveParent, reload: reloadParent } = parentStore;
  // const { store, updateStore } = mainStore();
  const { field, attrs, data } = props;
  const { name, color, editable } = field;
  const { record, parentRec, level, readOnly } = attrs;
  // const level = "child";

  const onChange = async (event) => {
    let _value = event.target.value;
    data.value = !data.value;
    upStore(level, {
      fieldName: name,
      value: _value,
      field: field,
      recId: record.id,
      parentRec,
    });

    // TODO: review this for save in upStore
    // if (parentRec) {
    //   const _store = store;
    //   const field_ = field.name;
    //   _store[parentRec.fieldName] = [
    //     [
    //       "write",
    //       [record.id],
    //       {
    //         [field_]: data.value,
    //       },
    //     ],
    //   ];
    //   updateStore({ ..._store });
    //   await saveParent();
    //   await reloadParent();
    // }
  };
  let _color = readOnly ? colors.readOnly : color ?? "bg-white";

  return (
    <input
      id={name}
      name={name}
      className={classNames("h-5 w-5 rounded-full", _color)}
      type="checkbox"
      inputMode="numeric"
      value={data.value}
      checked={data.value ? true : false}
      onChange={onChange}
      disabled={readOnly || !editable}
    />
  );
}

export default BooleanCell;
