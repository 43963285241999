import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  BarController,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-annotation";

import ChartCard from "components/Chart/ChartCard";
// import BarChart from "components/Chart/BarLineChart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  BarController,
  Tooltip,
  Legend,
);

var options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: "Ventas Diarias y Acumuladas",
      font: {
        size: 24,
      },
    },
  },
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  scales: {
    y: {
      type: "linear",
      position: "left",
      title: {
        display: true,
        text: "Ventas por día",
      },
      min: 0,
    },
    y1: {
      type: "linear",
      position: "right",
      title: {
        display: true,
        text: "Ventas acumuladas",
      },
      min: 0,
      grid: {
        drawOnChartArea: false, // Solo dibuja la cuadrícula para el eje Y principal
      },
    },
  },
};

function BarChart({ name, data, getReport }) {
  const dataObj = {
    labels: data.labels,
    datasets: [
      {
        type: "line",
        label: "Ventas Acumuladas",
        tension: 0.1,
        data: data.sales_cumulative,
        fill: false,
        backgroundColor: "#d9f99d",
        borderColor: "black",
        borderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 6,
        yAxisID: "y1",
      },
      {
        type: "bar",
        label: "Ventas por día",
        tension: 0.1,
        data: data.sales,
        fill: false,
        backgroundColor: "#bae6fd",
        BorderColor: "#84cc16",
        pointRadius: 4,
        pointHoverRadius: 6,
        borderWidth: 2,
        yAxisID: "y",
        // barThickness: 16,
      },
    ],
  };

  if (data.max_value) {
    options.scales = {
      y: {
        min: data.min_value,
        max: data.max_value,
      },
    };
  }
  return (
    <ChartCard
      header={name}
      header_meta={data.header_meta}
      desc={data.desc}
      desc_meta={data.desc_meta}
      getReport={getReport}
      style={"col-span-3"}
    >
      <div className="">
        <Bar data={dataObj} height={350} options={options} />
      </div>
    </ChartCard>
  );
}

function ChartDailyAmount(props) {
  const [data, setData] = useState({});

  function getReport() {}

  function getData() {
    if (props.data) {
      const labels = props.data.map((item) => item.date);
      const sales = props.data.map((item) => item.untaxed_amount);
      const sales_cumulative = props.data.map((item) => item.cumulative);
      const data_ = {
        labels: labels,
        sales: sales,
        sales_cumulative: sales_cumulative,
      };
      setData(data_);
    }
  }

  useEffect(() => {
    getData();
  }, [props.data]);

  if (!props.data) return;
  return <BarChart data={data} getReport={getReport} style={"col-span-3"} />;
}
export default ChartDailyAmount;
