const getProduct = (value) => {
  const search = [
    "OR",
    [
      ["template.name", "ilike", `%${value}%`],
      ["template.salable", "=", true],
    ],
    [
      ["code", "ilike", `${value}%`],
      ["template.salable", "=", true],
    ],
  ];
  return search;
};

const getDiference = (rec) => {
  const value = rec.quantity - rec.expected_quantity;
  if (Number.isNaN(value)) return;
  return value;
};

const getColumns = (isAdmin) => {
  const admin = isAdmin();
  let columns = [];

  if (admin) {
    columns = [
      { name: "product.code", width: "15%" },
      { name: "product", width: "25%" },
      { name: "product.description", width: "25%" },
      { name: "expected_quantity", width: "15%" },
      { name: "quantity", width: "15%", editable: true },
      { name: "diference", width: "5%" },
    ];
  } else {
    columns = [
      { name: "product.code", width: "15%" },
      { name: "product", width: "25%" },
      { name: "product.description", width: "25%" },
      { name: "quantity", width: "15%", editable: true },
    ];
  }

  return columns;
};

function accDelete(rec, parentRec) {
  console.log(rec, parentRec, ".-.-.-.-");
  return rec.state === "draft" ?? false;
}

const getView = (config, isAdmin) => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    selectable: "one",
    form_action: ["add"],
    table_action: ["delete"],
    access: {
      delete: accDelete,
    },
    model: "stock.inventory.line",
    target: "inventory",
    webfields: {
      product: {
        type: "many2one",
        recName: "name",
        model: "product.product",
        recSearch: getProduct,
        readOnly: ["checkup", "pre_count"],
      },
      "product.description": {
        type: "char",
        readOnly: true,
      },
      "product.code": {
        type: "char",
        readOnly: true,
      },
      uom: {
        type: "many2one",
        model: "product.uom",
        readOnly: true,
      },
      expected_quantity: {
        type: "numeric",
        readOnly: true,
      },
      quantity: {
        type: "numeric",
        // readOnly: { state: ["draft", "confirm"] },
      },
      diference: {
        type: "numeric",
        readOnly: true,
        function: getDiference,
      },
    },
    webtree: getColumns(isAdmin),

    webform: [
      { name: "product" },
      { name: "expected_quantity" },
      { name: "quantity" },
    ],
  };
  return DictCtxView;
};

export default { ctxView: getView };
