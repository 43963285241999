import React from "react";
import "chartjs-plugin-annotation";
import { FormattedMessage as FM } from "react-intl";

import { classNames } from "tools/ui";
import dataFake from "components/Chart/dataFake";

const cellStyle =
  "whitespace-nowrap py-3 pl-4 pr-3 text-sm text-gray-700 sm:pl-3";

let USDollar = new Intl.NumberFormat();

function TableChart({ style, data }) {
  const typeCols = dataFake.cols.map((col) => col.type);
  const widthCols = dataFake.cols.map((col) => col.width);
  console.log("data table....", data);

  function getChar(value, idx) {
    const align = "text-left";
    const width = widthCols[idx];
    console.log("width....", width);
    return (
      <td key={idx} className={classNames(cellStyle, align, width)}>
        {value}
      </td>
    );
  }

  function getNumber(value, idx) {
    const addStyle = "text-right";
    return (
      <td key={idx} className={classNames(cellStyle, addStyle)}>
        {USDollar.format(value)}
      </td>
    );
  }

  function getInteger(value, idx) {
    const addStyle = "text-center";
    return (
      <td key={idx} className={classNames(cellStyle, addStyle)}>
        {value}
      </td>
    );
  }

  // function getFloat(value, idx) {
  //   const addStyle = "text-center";
  //   return (
  //     <td key={idx} className={classNames(cellStyle, addStyle)}>
  //       {value}
  //     </td>
  //   );
  // }

  function getCell(value, idx) {
    const type_ = typeCols[idx];
    let valueFmt = value;
    if (type_ === "char") {
      valueFmt = getChar(value, idx);
    } else if (type_ === "number") {
      valueFmt = getNumber(value, idx);
    } else if (type_ === "integer") {
      valueFmt = getInteger(value, idx);
    } else if (type_ === "float") {
      valueFmt = getInteger(value, idx);
    }
    return valueFmt;
  }

  return (
    <div className={classNames("px-2 sm:px-2", style)}>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {dataFake.title}
          </h1>
          <p className="mt-2 text-sm text-gray-700">{dataFake.description}</p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-sky-500 bg-sky-100">
              <thead>
                <tr>
                  {dataFake.cols.map((val, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-center text-sm text-sky-700 sm:pl-3"
                    >
                      <FM id={val.name} />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
                {dataFake.rows.map((data, index) => (
                  <tr key={index} className="even:bg-yellow-50">
                    {data.map((value, idx) => getCell(value, idx))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableChart;
