import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { ChartPieIcon } from "@heroicons/react/24/outline";

import MenuItemReport from "components/MeForm/MenuItemReport";

function MenuButtonReport(props) {
  return (
    <Popover className="relative flex h-full px-3">
      <Popover.Button className="inline-flex my-auto items-center gap-x-1 text-md leading-6 text-stone-700">
        <ChartPieIcon
          className="h-7 w-7 mr-3 text-gray-600"
          aria-hidden="true"
        />
        <span>Reports</span>
        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="w-80 absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 translate-y-8 px-4">
          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-2xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="m-4">
              {props.options.map((item, idx) => (
                <MenuItemReport
                  item={item}
                  key={idx}
                  activeRecord={props.activeRecord}
                  dialogMesssage={props.dialogMesssage}
                />
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default MenuButtonReport;
