import React, { Fragment } from "react";
import { useRecords } from "hooks/records";
import FullTable from "components/MeTable/FullTable";

import funcs from "tools/functions";
import { classNames } from "tools/ui";
import {
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  BanknotesIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/20/solid";

const ctxViewLine = {
  model: "account.invoice.line",
  selectable: false,
  webfields: {
    product: {
      type: "many2one",
      model: "product.product",
      required: true,
      recName: "name",
    },
    unit: {
      type: "many2one",
      model: "product.uom",
      readOnly: true,
    },
    quantity: {
      type: "numeric",
      required: true,
      default: 1,
    },
    unit_price: { type: "numeric", readOnly: true },
    amount: { type: "numeric", readOnly: true },
  },
  webtree: [
    { name: "product", width: "20%" },
    { name: "unit", width: "20%" },
    { name: "quantity", width: "20%" },
    { name: "unit_price", width: "35%" },
    { name: "amount", width: "35%" },
  ],
};

const ctxView = {
  model: "account.invoice",
  selectable: false, // options: multi - one - false
  form_action: ["open"],
  table_action: ["open"],
  title: { field: "number", model: true },
  limit: 50,
  webfields: {
    number: { type: "char", readOnly: true },
    party: {
      type: "many2one",
      model: "party.party",
      required: true,
      readOnly: true,
    },
    invoice_date: {
      type: "date",
      readOnly: true,
      depends: ["number"],
    },
    state: {
      type: "char",
      readOnly: true,
      translate: true,
    },
    invoice_state: {
      type: "char",
      readOnly: true,
      translate: true,
    },
    lines: {
      type: "one2many",
      model: "account.invoice.line",
      ctxView: ctxViewLine,
      readOnly: true,
    },
    untaxed_amount: {
      type: "numeric",
      readOnly: true,
    },
    total_amount: {
      type: "numeric",
      readOnly: true,
    },
    amount_to_pay: {
      type: "numeric",
      readOnly: true,
    },
    description: { type: "char", readOnly: true },
  },
  webform: [
    {
      id: "infoInvoice",
      grid: [{ name: "invoice_date" }, { name: "state" }],
      size: [1, 2],
      span: "col-span-1",
    },
    { name: "party" },
    { name: "description" },
    { name: "lines" },
    { name: "untaxed_amount" },
    { name: "total_amount" },
    { name: "amount_to_pay" },
  ],
  webtree: [
    { name: "number", width: "20%" },
    { name: "invoice_date", width: "20%" },
    { name: "state", width: "20%" },
    { name: "party", width: "35%" },
    { name: "description", width: "35%" },
    { name: "untaxed_amount", width: "10%" },
    { name: "total_amount", width: "10%" },
    { name: "amount_to_pay", width: "10%" },
  ],
};

const style =
  "flex flex-col flex-auto p-4 space-y-4 rounded-md divide-y-2 items-center h-24 bg-white shadow-md border-2 border-slate-200";
const formatter = Intl.NumberFormat("en-US");

function Invoice({ record }) {
  const domain = [["id", "in", record.invoices ?? []]];
  let fields_names = funcs.getViewFields(ctxView, "list");
  fields_names = fields_names.concat([
    "lines.product.cost_price",
    "lines.amount",
    "lines.quantity",
  ]);

  console.log(record, "access record");

  let { data, isLoading } = useRecords(ctxView.model, domain, fields_names);

  let totalUntaxedAmount = 0;
  let totalAmount = 0;
  let amountToPaySum = 0;
  let basePrice = 0;
  let baseCostPrice = 0;
  for (const invoice of data ?? []) {
    if (invoice.state === "cancelled") continue;
    totalUntaxedAmount += invoice.untaxed_amount;
    totalAmount += invoice.total_amount;
    amountToPaySum += invoice.amount_to_pay;
    for (const line of invoice["lines."]) {
      basePrice += line.amount;
      baseCostPrice += line.quantity * line["product."]["cost_price"];
    }
  }

  let profit = 0;
  let amountProfit = 0;
  if (basePrice > 0 && baseCostPrice > 0) {
    amountProfit = Number(basePrice - baseCostPrice);
    profit = Number((amountProfit / basePrice) * 100).toFixed(2);
    amountProfit = formatter.format(amountProfit.toFixed(2));
  }
  const colorProfit = profit > 0 ? "text-green-500" : "text-rose-500";

  const percentageInvoiced = Number(
    (totalUntaxedAmount * 100) / record?.untaxed_amount,
  ).toFixed(2);
  const colorPercentInv =
    percentageInvoiced > 90 ? "text-green-500" : "text-rose-500";

  const percentagePaid = totalAmount
    ? Number((amountToPaySum * 100) / totalAmount).toFixed(2)
    : 0;
  const colorPercentPaid =
    percentageInvoiced <= 0 ? "text-green-500" : "text-rose-500";

  totalUntaxedAmount = formatter.format(Number(totalUntaxedAmount));
  totalAmount = formatter.format(Number(totalAmount));
  amountToPaySum = formatter.format(Number(amountToPaySum));

  return (
    <Fragment>
      <div className="grid grid-cols-4 m-4 space-x-6 items-stretch">
        <div className="relative flex flex-col shadow-md px-4 pt-10 pb-4 rounded-md">
          <div className="flex flex-col">
            <span className="text-indigo-950 font-semibold text-xl">
              {formatter.format(record?.untaxed_amount)}
            </span>
            <span className="text-slate-400 font-medium">Vendido </span>
          </div>
        </div>
        <div className="relative flex flex-col shadow-md px-4 pt-10 pb-4 rounded-md">
          <div className="flex flex-col">
            <span className="text-indigo-950 font-semibold text-xl">
              {totalUntaxedAmount}
            </span>
            <span className="text-slate-400 font-medium">Facturado</span>
          </div>
          <BanknotesIcon
            className={classNames(
              "h5 w-5 absolute inset-y-2 left-4",
              colorPercentInv,
            )}
          />
          <span
            className={classNames(
              colorPercentInv,
              "font-semibold absolute inset-y-2 right-4",
            )}
          >
            {formatter.format(percentageInvoiced)}%
          </span>
        </div>
        <div className="relative flex flex-col shadow-md px-4 pt-10 pb-4 rounded-md">
          <div className="flex flex-col">
            <span className="text-indigo-950 font-semibold text-xl">
              {amountProfit}
            </span>
            <span className="text-slate-400 font-medium">Utilidad</span>
          </div>
          {profit <= 0 ? (
            <ArrowTrendingDownIcon
              className={classNames(
                "h5 w-5 absolute inset-y-2 left-4",
                colorProfit,
              )}
            />
          ) : (
            <ArrowTrendingUpIcon
              className={classNames(
                "h5 w-5 absolute inset-y-2 left-4",
                colorProfit,
              )}
            />
          )}
          <span
            className={classNames(
              "font-semibold absolute inset-y-2 right-4",
              colorProfit,
            )}
          >
            {profit}%
          </span>
        </div>
        <div className="relative flex flex-col shadow-md px-4 pt-10 pb-4 rounded-md">
          <div className="flex flex-col">
            <span className="text-indigo-950 font-semibold text-xl">
              {amountToPaySum}
            </span>
            <span className="text-slate-400 font-medium">Pend. a Pagar </span>
          </div>
          <CurrencyDollarIcon
            className={classNames(
              "h5 w-5 absolute inset-y-2 left-4",
              colorPercentPaid,
            )}
          />
          <span
            className={classNames(
              colorPercentPaid,
              "font-semibold absolute inset-y-2 right-4",
            )}
          >
            {formatter.format(percentagePaid)}%
          </span>
        </div>
      </div>
      <FullTable
        records={data ? data : []}
        model={ctxView.model}
        ctxView={ctxView}
      />
    </Fragment>
  );
}

export default Invoice;
