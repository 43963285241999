import React, { useState, useEffect, useRef } from "react";

import HeaderStep from "./HeaderStep";
import { classNames } from "tools/ui";
import datafono from "./assets/datafono.png";
import printOrder from "./printOrder";
import proxy from "api/proxy";
import funcs from "tools/functions";
import { useWizardStore } from "store/wizardStore";
const test = false;

function StepPayment(props) {
  const { record: storeCart, updateWizard } = useWizardStore();
  let [colorMsg, setColorMsg] = useState("text-lime-600");
  const [showImg, setShowImg] = useState(false);
  const isMounted = useRef(false);

  const _msg = "PROCESANDO TU COMPRA!!";
  let [msg, setMsg] = useState(_msg);

  async function activateTerminal(saleId) {
    setMsg("Sigue las instrucciones del datafono ...");
    setShowImg(true);
    const { data: res, error } = await proxy.methodCall({
      model: "sale.sale",
      method: "get_pay_from_card",
      args: [{ id: saleId, test }],
    });
    console.log(error);
    let response_pay;
    if (res["status"] === "ok") {
      for (let n = 1; n < 18; n++) {
        if (storeCart.id !== saleId) break;
        const time_ = n === 1 ? 20000 : 10000;
        await new Promise((r) => setTimeout(r, time_));
        if (storeCart.id !== saleId) break;
        response_pay = await getResponsePay(res["terminal"], saleId, n);

        if (!isMounted.current || response_pay.status === "ok") {
          break;
        }
      }
    }
    setShowImg(false);
    return response_pay;
  }

  async function getResponsePay(terminal, sale, intent) {
    const { data } = await proxy.methodCall({
      model: "sale.sale",
      method: "get_response_pay_from_card",
      args: [{ terminal, sale, intent, test }],
    });
    return data;
  }

  async function createSale() {
    let _sale = JSON.parse(JSON.stringify(storeCart));

    _sale["lines"] = funcs.cloneMap(storeCart.lines);
    for (const key of ["payment_amount", "qr_code", "total_amount"]) {
      delete _sale[key];
    }
    const _lines = Array.from(_sale.lines.values()).map((val) => {
      val.product = val.product.id;
      return val;
    });
    _sale["lines"] = [["create", _lines]];
    const args = { model: "sale.sale", method: "create_sale", args: [_sale] };
    const res = await proxy.methodCall(args);
    return res;
  }

  async function getPaymentTerminal() {
    const { data } = await createSale();
    const saleId = data?.record?.id;
    if (saleId) {
      setMsg("Compra procesada!!");
      storeCart.id = saleId;
      updateWizard(storeCart);
      const response = await activateTerminal(saleId);

      if (response?.status !== "ok" || response?.code !== "0") {
        const _msg = response.msg;
        setMsg(_msg);
        setColorMsg("text-rose-700");
        await new Promise((r) => setTimeout(r, 10000));
        props.onChangeView(null, "cancel", true, data.record);
      } else {
        storeCart.pay = response.pay;
        updateWizard(storeCart);
        await printOrder(response); //printTicket
        props.onChangeView(null, "ticket", null, data.record);
      }
    }
  }

  useEffect(() => {
    getPaymentTerminal();
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  let style = "text-5xl font-bold";
  return (
    <div>
      <HeaderStep label="PASO 2. REALIZA EL PAGO" />
      <div className="flex flex-col mt-60">
        <p className={classNames(style, colorMsg)}>{msg}</p>
        <p className="pt-3">
          {showImg && <img src={datafono} alt="datafono" />}
        </p>
      </div>
    </div>
  );
}

export default StepPayment;
