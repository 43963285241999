import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-annotation";

import ChartCard from "components/Chart/ChartCard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
};

function BarChart({ name, data, getReport, style }) {
  const dataObj = {
    labels: data.labels,
    datasets: [
      {
        label: "Valor",
        data: data.values,
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(141, 193, 75, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(255, 110, 134, 0.6)",
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
        ],
      },
    ],
  };

  let height = 300;
  if (window.innerWidth < 400) {
    height = 220;
  }
  return (
    <ChartCard
      header={name}
      header_meta={data.header_meta}
      desc={data.desc}
      desc_meta={data.desc_meta}
      getReport={getReport}
      style={style}
    >
      <div className="">
        <Bar data={dataObj} height={height} options={options} />
      </div>
    </ChartCard>
  );
}

export default BarChart;
