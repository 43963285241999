import React from "react";
import { FormattedMessage as FM } from "react-intl";

import { classNames } from "tools/ui";
// import { ICON_COLORS } from "./Constants/constants";

const COLORS = {
  slate: ["bg-slate-300", "border-slate-500", "text-slate-700"],
  blue: ["bg-blue-300", "border-cyan-500", "text-cyan-700"],
  sky: ["bg-sky-300", "border-sky-500", "text-sky-700"],
  yellow: ["bg-orange-300", "border-orange-500", "text-orange-700"],
  amber: ["bg-amber-200", "text-amber-600", "text-amber-700"],
  lime: ["bg-lime-200", "border-lime-500", "text-lime-700"],
  indigo: ["bg-violet-300", "border-violet-500", "text-violet-700"],
  pink: ["bg-pink-300", "border-pink-500", "text-pink-700"],
  rose: ["bg-rose-300", "border-rose-500", "text-rose-700"],
  gray: ["bg-gray-300", "border-gray-500", "text-gray-700"],
  stone: ["bg-stone-300", "border-stone-500", "text-stone-700"],
};

const base = "w-5 h-6 w-full p-1 mx-auto text-xs rounded-md";

function Badge({ color, value, model }) {
  let bgColor = "bg-slate-300";
  let borderColor = "border-slate-500";
  let textColor = "bg-slate-800";
  if (COLORS[color]) {
    [bgColor, borderColor, textColor] = COLORS[color];
  }
  const _value = `${model}.${value}`;
  return (
    <div
      id="badge"
      className={classNames(base, bgColor, borderColor, textColor)}
    >
      <FM id={_value} />
    </div>
  );
}

export default Badge;
