// Customer Service Model

const ctxView = () => {
  return {
    selectable: null, // options: multi - one - false (default is false)
    model: "crm.customer_service",
    form_action: null, // options: ['open', 'delete']
    table_action: ["open"], // options: ['open', 'delete', 'create']
    webfields: {
      number: { type: "char", readOnly: true },
      state: { type: "char", translate: true, readOnly: true },
      cs_date: {
        type: "datetime",
        readOnly: true,
        formatString: "YYYY-MM-DD HH:mm",
      },
      effective_date: {
        type: "datetime",
        readOnly: true,
        formatString: "YYYY-MM-DD HH:mm",
      },
      party: {
        type: "many2one",
        model: "party.party",
        readOnly: true,
      },
      email: { type: "char", readOnly: true },
      description: {
        type: "text-area",
        readOnly: true,
      },
      response: {
        type: "text-area",
        readOnly: true,
      },
      case: {
        type: "many2one",
        model: "crm.case",
        readOnly: true,
      },
      health_provider: {
        type: "many2one",
        model: "crm.health_provider",
        readOnly: true,
      },
    },
    webtree: [
      { name: "number", width: "10%" },
      { name: "state", width: "15%" },
      { name: "party", width: "20%" },
      { name: "cs_date", width: "15%" },
      { name: "description", width: "20%" },
      { name: "response", width: "20%" },
    ],
    webform: [
      { name: "party" },
      { name: "number" },
      { name: "cs_date" },
      { name: "case" },
      { name: "description" },
      { name: "response" },
      { name: "health_provider" },
      { name: "email" },
      { name: "effective_date" },
      { name: "state" },
    ],
  };
};

export { ctxView };
