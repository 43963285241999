import React, { useState } from "react";
import { FormattedMessage as FM } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";
import store from "store";
import { EyeIcon } from "@heroicons/react/20/solid";

import Footer from "pages/Footer";
import TextField from "components/MeForm/TextField";
import StdButton from "components/Buttons/StdButton";
import proxy from "api/proxy";
import presikLogo from "assets/img/presik-login.png";
import futureImg from "assets/img/future.jpg";

function LoginCustomer(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const database = queryParams.get("db");

  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState(null);
  const [passwd, setPasswd] = useState(null);
  const [formMessage, setFormMessage] = useState(null);

  const handleSession = async () => {
    setLoading(true);
    const formData = { database, username, passwd };
    let { data } = await proxy.loginCustomer(formData);
    if (data && data.user && database) {
      data.db = database;
      data.web_user = true;
      await store.set("ctxSession", data);
      navigate("/dash/portal_customer", { replace: true });
      props.handleSession(data);
    } else {
      setFormMessage("login.invalid_user_password");
      setLoading(false);
    }
  };

  const handleChange = (field, value) => {
    switch (field) {
      case "username":
        setUsername(value);
        break;
      case "passwd":
        setPasswd(value);
        break;
      default:
        break;
    }
    const res = database && username && passwd;
    setBtnDisabled(!res);
    setFormMessage(null);
  };

  const eyeIcon = (
    <div
      className="absolute hover:cursor-pointer inset-y-0 right-0 flex items-center pr-3"
      onClick={() => setShowPassword(!showPassword)}
    >
      <EyeIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
    </div>
  );

  return (
    <div className="container flex w-full mx-auto py-2 sm:py-20 max-h-max">
      <div className="flex container mt-0 rounded-xl overflow-hidden lg:border mx-auto xl:w-8/12 lg:w-12/12 w-full lg:shadow-xl md:shadow-xl sm:shadow-xl">
        <div className="px-8 py-0 lg:w-6/12 lg:px-10 xl:px-14 md:w-8/12 sm:w-8/12 mx-auto">
          <img
            src={presikLogo}
            alt="PRESIK LOGO"
            width="170"
            className="my-12 mx-auto"
          />
          <p className="my-4 text-center text-4xl text-gray-600 font-bold">
            Log In
          </p>
          <p className="font-roboto font-extralight opacity-70 pt-2 text-[16px] text-gray-500">
            Ingrese sus credenciales para iniciar sesión. Tres intentos de
            contraseña incorrecta bloquearán su usuario.
          </p>
          <form className="py-3" title="login.welcome" method="POST">
            <TextField
              color="gray"
              label="user"
              name="username"
              value={username}
              placeholder={"email"}
              type="text"
              onChange={handleChange}
            />
            <TextField
              label="password"
              name="passwd"
              color="gray"
              value={passwd}
              onChange={handleChange}
              type={showPassword ? "text" : "password"}
              icon={eyeIcon}
            />
            <div className="my-8" />
            {formMessage && (
              <div className="text-red-400 my-3">
                <span>
                  <FM id="login.invalid_user_password" />
                </span>
              </div>
            )}
            <StdButton
              onClick={handleSession}
              size="w-full"
              loading={loading}
              disabled={btnDisabled}
              color="blue"
              content="enter"
            />
          </form>
          <Footer />
        </div>
        <div className="hidden w-0 xl:w-1/2 lg:w-1/2 overflow-hidden lg:flex invisible lg:visible xl:visible ">
          <div className="backdrop-blur-sm bg-white/30 w-1/4 my-40 h-[260px] absolute overflow-hidden rounded-r-2xl">
            <div className="absolute mx-20 h-1 mt-20 sm:mx-8" />
            <p className="font-roboto font-extralight absolute py-12 px-20 sm:px-8 text-5xl text-white">
              Ready <br /> for <br /> Future...
            </p>
          </div>
          <img
            src={futureImg}
            alt="future"
            loading="lazy"
            className="my-0 mx-0 h-full object-cover overflow-hidden"
          />
        </div>
      </div>
    </div>
  );
}
//
export default LoginCustomer;
