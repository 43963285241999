// import useGrandStore from "store/grandStore";
import useChildStore from "store/childStore";
import { validateForm } from "tools/form";
import storeCtx from "store/storeCtx";
import editTable from "store/editTable";
import funcs from "tools/functions";

function validate(record, store, webfields) {
  const requiredFields = funcs.getRequired(webfields, record);
  let action = record.id > 0 ? "update" : "create";
  const res = validateForm(action, record, requiredFields);
  if (res !== "ok") {
    return { msg: res };
  } else {
    store.id = record.id;
    return { msg: res };
  }
}

async function saveStore({
  level,
  webfields,
  parentRec,
  fieldName,
  ctxView,
  actionType,
  records,
}) {
  // This method save store in API
  // must return a response about error, data, msg and close
  const { record, store, save, reload, newRecord } = storeCtx(level);
  let res = {
    error: null,
    data: null,
    msg: null,
    close: false,
  };

  let resValidate;
  const autoSave = ctxView.autoSave;
  let action = actionType;
  if (action === "delete") {
    editTable({ level, action, fieldName, records, autoSave });
    res.close = true;
    return res;
  } else if (record && actionType !== "write" && actionType !== "create") {
    action = record.id > 0 ? "write" : "create";
  }

  // We need check if there is data for to save else must return
  if (store && Object.values(store).length <= 1) {
    res.close = true;
    return res;
  }

  if (action === "create") {
    res.close = true;
    resValidate = validate(record, store, webfields);
    if (resValidate.msg !== "ok") {
      res.msg = resValidate.msg;
      return res;
    }
    if (["child", "grand"].includes(level)) {
      editTable({ level, action, fieldName, autoSave });
      if (parentRec) {
        if (parentRec.record.id <= 0) {
          if (actionType === "add") {
            newRecord(ctxView, parentRec);
            res.close = false;
          }
          return res;
        }
        store[parentRec.target] = parentRec.record.id;
      }
      if (level === "grand") {
        // We need reload parent record for to see the new line in table
        const { reload } = useChildStore.getState();
        reload();
      }
      return res;
    } else {
      // save();
      console.log("No esta haciendo nada.....", record);
    }
  } else if (action === "write") {
    if (fieldName) {
      editTable({ level, action, fieldName, autoSave });
      return; // ???? is neccesary
    }
  } else {
    resValidate = validate(record, store, webfields);
    if (resValidate.msg !== "ok") {
      res.msg = resValidate.msg;
      return res;
    }
    console.log("Accion desconocida en saveStore....", action);
  }

  const { data, error } = await save();
  if (!error && reload && level != "wizard") {
    // Because we dont need reload wizards
    await reload();
  }
  return { ...res, data, error };
}

export default saveStore;
