import React, { useState, useEffect } from "react";
import { PhoneArrowUpRightIcon } from "@heroicons/react/20/solid";

import iconWhatsapp from "assets/apps/whatsapp_icon.png";

const CallAction = ({ value = "", type = "call" }) => {
  // Estado para almacenar el número de teléfono formateado
  const [formattedValue, setFormattedValue] = useState(value);

  // Función para formatear el número de teléfono
  const formatPhoneNumber = (phoneNumber) => {
    // Elimina caracteres no numéricos y espacios
    const formattedNumber = phoneNumber.replace(/[^0-9]/g, "");
    return formattedNumber;
  };

  // Use useEffect para formatear el número de teléfono cuando cambien las props
  useEffect(() => {
    setFormattedValue(formatPhoneNumber(value));
  }, [value]);

  // Función para realizar la llamada
  const makeCall = () => {
    // Aquí puedes implementar la lógica para realizar la llamada
    // Por ejemplo, abrir una URL tel: en el navegador
    window.location.href = `tel:${formattedValue}`;
  };

  if (type === "call") {
    return (
      <div className="flex justify-center space-x-3 items-center align-middle">
        <span
          className="flex group cursor-pointer rounded-md overflow-hidden max-w-max justify-between bg-gray-100 "
          onClick={makeCall}
        >
          <span className="px-3 py-1  min-w-[100px]">{formattedValue}</span>
          <PhoneArrowUpRightIcon className="w-6  bg-blue-presik  text-white p-0.5 px-1 group-hover:text-black group-hover:bg-lime-500" />
        </span>
        <span className="cursor-pointer">
          <img src={iconWhatsapp} className="w-5" />
        </span>
      </div>
    );
  }

  return <span>{formattedValue}</span>;
};

export default CallAction;
