// English translation

const en = {
  "account.invoice": "Invoice",
  "account.invoice.invoice_date": "Invoice Date",
  "account.invoice.total_amount": "Total Amount",
  "account.invoice.number": "Number",
  "account.invoice.reference": "Reference",
  "account.invoice.party": "Party",
  "account.invoice.invoice_type": "Invoice Type",
  "account.invoice.payment_term": "Payment Term",
  "account.invoice.untaxed_amount": "Untaxed Amount",
  "account.invoice.tax_amount": "Taxed Amount",
  "account.invoice.line.unit": "Unit",
  "account.invoice.line.product": "Product",
  "account.invoice.line.unit_price": "Unit Price",
  "account.invoice.line.quantity": "Quantity",
  "account.invoice.line.description": "Description",
  "account.invoice.line.amount": "Amount",
};

export default en;
