import React, { Fragment } from "react";
import date from "date-and-time";

import { classNames } from "tools/ui";
import helper from "./helper";

const fmtDate = "YYYY-MM-DD";
const style = "min-w-20 text-zinc-600 hover:text-zinc-900 hover:bg-zinc-200";

function CalendarHeader({ dates }) {
  function splitMonths() {
    // currentMonth;
  }

  // Render table head of calendar
  let cellsHeader = [];
  let cellsMonths = [];
  let monthsActive = {};
  let lenMonth = 0;
  let currentMonth;
  for (const rawDate of dates) {
    const today = date.format(new Date(), fmtDate);
    const fdate = date.format(rawDate, fmtDate);
    const dayweek = date.format(rawDate, "ddd");
    const isToday = today === fdate ? "-today" : "";
    let bgColor = "bg-white";
    if (isToday) {
      bgColor =
        "hover:!bg-amber-400 hover:!text-amber-900 bg-cyan-700 !text-white";
    }
    const month = helper.getMonthName(rawDate);
    lenMonth += 1;
    if (!currentMonth || currentMonth === month) {
      currentMonth = month;
      monthsActive[month] = lenMonth;
    } else {
      lenMonth = 1;
      currentMonth = month;
      monthsActive[month] = 1;
    }

    cellsHeader.push(
      <th className={classNames(style, bgColor)} key={fdate}>
        <div className="text-sm text-right pr-2">
          <p>{dayweek}</p>
          <p> {rawDate.getDate()}</p>
        </div>
      </th>,
    );
  }
  for (const [month, length] of Object.entries(monthsActive)) {
    cellsMonths.push(
      <th
        colSpan={length}
        className={classNames(style, "bg-amber-100")}
        key={month}
      >
        <div className="text-sm text-zinc-600 font-bold border-x-[1px]">
          {month}
        </div>
      </th>,
    );
  }

  return (
    <Fragment>
      <tr className="h-8">
        <th key="rooms" className="bg-cyan-700" />
        {cellsMonths}
      </tr>
      <tr className="h-12">
        <th
          key="rooms"
          className="bg-cyan-700 text-cyan-100 !font-bold text-xl min-w-[8rem]"
        >
          ROOMS
        </th>
        {cellsHeader}
      </tr>
    </Fragment>
  );
}

export default CalendarHeader;
