import React from "react";
import { FormattedMessage as FM } from "react-intl";

// import Loading from "components/Tools/Loading";

function MsgInfo(props) {
  return (
    <div className="text-gray-600 text-xl pt-6 pl-4">
      <FM id={props.msg} key={props.msg} />
    </div>
  );
}

export default MsgInfo;
