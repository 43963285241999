import React, { Fragment, useEffect, useState } from "react";

import proxy from "api/proxy";
import TableData from "./TableData";

function History(props) {
  const [rows, setRows] = useState([]);
  const [header, setHeader] = useState([]);

  async function searchContent() {
    let rows = [];
    let header = [];
    const product_id = props.record["product."]["id"];
    const { data } = await proxy.methodCall({
      model: "dash.app.purchase_requisition",
      method: "get_history",
      args: [{ product: product_id, option: props.option }],
    });
    if (data) {
      rows = data.records;
      header = data.header;
    }

    setRows(rows);
    setHeader(header);
  }

  useEffect(() => {
    searchContent();
  }, []);

  return (
    <Fragment>
      <TableData header={header} rows={rows} />
    </Fragment>
  );
}

export default History;
