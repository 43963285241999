import React from "react";
import { Carousel } from "react-responsive-carousel";

const SliderHotel = ({ data }) => {
  const renderCategories = () => {
    let itemsPerSlide;

    if (window.innerWidth >= 1000) {
      itemsPerSlide = 3;
    } else if (window.innerWidth >= 500) {
      itemsPerSlide = 4;
    } else {
      itemsPerSlide = 1;
    }

    const totalSlides = Math.ceil(data.length / itemsPerSlide);
    const slides = [];

    for (let i = 0; i < totalSlides; i++) {
      const start = i * itemsPerSlide;
      const end = start + itemsPerSlide;
      const slideCategories = data.slice(start, end);

      slides.push(
        <div
          key={i}
          className="grid mt-10 grid-cols-1 md:grid-cols-3 gap-x-14  bg-cover pt-10 pb-10"
        >
          {slideCategories.map((testimonial, index) => (
            <div
              key={index}
              className={`bg-cover flex items-end overflow-hidden  relative h-[500px] rounded-xl  ${
                index % 2 == 1 ? "scale-110 z-20 " : ""
              }`}
              style={{ backgroundImage: `url(${testimonial.image})` }}
            >
              <div className="   bg-gradient-to-t from-black via-black/90 to-transparent px-8  pt-14  pb-6 w-full text-white relative z-10">
                <h2 className="text-base text-left font-semibold w-max melhous-menu-title uppercase melhous-menu-title text-white">
                  {testimonial.title}
                  <hr className="border-[0,5] border-gray-700 mb-2 mt-1 w-[70%]" />
                </h2>

                <div className="text-[13px]  font-thin text-left text-base melhous-menu-description text-white">
                  {testimonial.text}
                </div>
              </div>
              <img
                src={testimonial.photo}
                className="w-full h-full object-cover absolute"
              />
            </div>
          ))}
        </div>,
      );
    }

    return slides;
  };
  return (
    <div className="md:w-screen">
      <div className="md:w-[120vw] relative md:-left-[18vw] mb-10 ">
        {/* <TitleSection text="RESEÑAS" align="center" /> */}
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={true}
          emulateTouch={true}
          showArrows={false}
          autoPlay={true} // Hace que el carousel sea automático
          interval={5000} // Establece el tiempo de transición en milisegundos (5 segundos en este caso)
          infiniteLoop={true} // Hace que el carousel sea infinito
          renderIndicator={(clickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  className="custom-dot active"
                  aria-label={`Slide ${label} - Current Slide`}
                />
              );
            }
            return (
              <li
                className="custom-dot"
                onClick={clickHandler}
                onKeyDown={clickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label}`}
                aria-label={`Slide ${label}`}
              />
            );
          }}
        >
          {renderCategories()}
        </Carousel>
      </div>
    </div>
  );
};

export default SliderHotel;
