import React, { useState, useEffect } from "react";
import proxy from "api/proxy";

import CardItemProduct from "./Components/CardItemProduct";
import CardproductCategory from "./Components/CardIProductCategory";
import NavCategoriesProducts from "./Components/NavCategoriesProducts";
import ItemProductContentModal from "./Components/ItemProductContentModal";
import Breadcrumb from "./Components/CategoryBreadCrumb";
import BasicModal from "components/Modals/BasicModal";
import Banner from "./Components/BannerCategory";
import ProductAttributeFilter from "./Components/ProductAttributeFilter";
import SearchProduct from "./Components/SearchProduct";
import Pagination from "./Components/PaginationProduct";

const Catalogue = (props) => {
  const { config } = props;
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [product, setProduct] = useState(null);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [category, setCategory] = useState(null);
  const [domainProduct, setDomainProduct] = useState([]);
  const [countProducts, setCountProducts] = useState(null);
  const [offset, setOffset] = useState(null);
  const limit = 20;

  useEffect(() => {
    getCategories();
    getAttributes();
    console.log("ingresa esta ");
    getProducts();
  }, [category, selectedAttributes, domainProduct, offset]);

  async function getProducts() {
    let cat_ids = [];

    function filterCategory(item) {
      if (item.children.length === 0) {
        cat_ids.push(item.id);
      } else {
        item.children.map((_item) => {
          filterCategory(_item);
        });
      }
    }

    let domain = [["web_shops", "=", config.web_shop]];

    if (domainProduct) {
      domain = domain.concat(domainProduct);
    }

    if (category) {
      filterCategory(category);
      domain.push(["categories", "in", cat_ids]);
    }

    if (selectedAttributes) {
      const transformedData = ["OR"];
      for (const key in selectedAttributes) {
        const values = selectedAttributes[key];
        if (values.length > 0) {
          transformedData.push(["attributes." + key, "in", values]);
        }
      }

      const filteredData = transformedData.length > 1 ? [transformedData] : [];
      domain = domain.concat(filteredData);
    }
    if (domain.length <= 1) return;

    const model = "dash.app.catalog";
    const query = {
      domain: domain,
      limit: limit,
      offset: offset,
      order: null,
    };
    const args = {
      model: model,
      method: "get_products",
      instance: config.id,
      args: [],
      kwargs: { query },
    };
    const { data } = await proxy.methodInstance(args);
    const products = data[0];
    const countProducts = data[1];
    setProducts(products);
    setCountProducts(countProducts);
  }

  async function getAttributes() {
    console.log("es");
    if (attributes?.length > 0) return;
    const model = "dash.app.catalog";
    const args = {
      model: model,
      method: "get_attributes",
      instance: config.id,
      args: [],
    };
    const { data: attributes_ } = await proxy.methodInstance(args);
    console.log(attributes_, "validate attributes");
    setAttributes(attributes_);
  }

  async function getCategories() {
    if (categories.length > 0) return;
    const model = "dash.app.catalog";
    const args = {
      model: model,
      method: "get_categories",
      instance: config.id,
      args: [],
    };
    const { data: categories_ } = await proxy.methodInstance(args);
    setCategories(categories_);
  }

  const onChangePage = (offset) => setOffset(offset);

  const handleCategory = (category) => setCategory(category);

  const handleProduct = (product) => setProduct(product);

  const onSelectAttribute = (attributes) => setSelectedAttributes(attributes);

  return (
    <div className="w-full">
      {category ? (
        <Banner category={category} />
      ) : (
        <CardproductCategory
          data={categories}
          handleCategory={handleCategory}
        />
      )}
      <div className="m-3 lg:mx-4 ">
        <Breadcrumb
          categories={categories}
          handleCategory={handleCategory}
          category={category}
        />
        <div className="flex space-x-8 items-start">
          <div className="flex flex-col z-10 w-[20%] space-y-4">
            <NavCategoriesProducts
              data={categories}
              handleCategory={handleCategory}
              category={category}
            />
            <ProductAttributeFilter
              attributes={attributes}
              selectedAttributes={selectedAttributes}
              onSelectAttribute={onSelectAttribute}
            />
          </div>
          <div className="grid grid-cols-4 gap-5 w-[80%]">
            <div className="col-span-4">
              <SearchProduct onSearch={(domain) => setDomainProduct(domain)} />
            </div>
            {Array.isArray(products) &&
              products.map((item, key) => (
                <CardItemProduct
                  data={item}
                  mode="catalogue"
                  key={key}
                  handleProduct={handleProduct}
                />
              ))}
          </div>
        </div>
        <Pagination
          lenRecords={products.length}
          limit={limit}
          offset={offset}
          onChangePage={onChangePage}
          countTotal={countProducts}
        />
      </div>
      {product && (
        <BasicModal
          open={true}
          onClose={() => setProduct(null)}
          width="w-4/6"
          height="h-3/4"
        >
          <ItemProductContentModal
            product={product}
            onClose={() => setProduct(null)}
          />
        </BasicModal>
      )}
    </div>
  );
};

export default Catalogue;
