import React, { useState, useEffect } from "react";
import { FormattedMessage as FM } from "react-intl";
import GalleryItem from "components/Tools/GalleryItem";
import { ClockIcon } from "@heroicons/react/24/solid";

const BodyTurn = ({ config }) => {
  const { text_info, phone_info, email_info, attention_schedule } = config;
  const _attention_schedule = attention_schedule?.split("|");

  let content = config?.["content."] || [];
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (time) => {
    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const Clock = () => {
    return (
      <div className="w-auto flex space-x-1 rounded-md text-8xl font-medium bg-blue-presik h-max px-4 py-3 shadow-md text-white">
        <ClockIcon className="w-20" />
        <span>{formatTime(currentTime)}</span>
      </div>
    );
  };

  const GalleryItemRender = ({ data: content }) => {
    const { video, image, title } = content;
    return (
      <div className="w-full -mt-8">
        {video ? (
          <video autoPlay className="w-full h-[70vh]" controls={false}>
            <source src={video} type="video/mp4" />
            Tu navegador no soporta la etiqueta de video.
          </video>
        ) : (
          <img
            src={image}
            className="w-full h-[70vh] object-cover  object-top"
            alt={title}
          />
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full h-full text-center">
      <div className="relative justify-between mb-5 2xl:mb-10 items-center w-full flex top-1  pt-4 ">
        <img
          src="https://res.cloudinary.com/dfxrayjv0/image/upload/v1721405890/logo_2_mg5bb2.png"
          className="max-w-[270px] 2xl:max-w-[480px] 2xl "
          alt="Logo"
        />
        <Clock />
      </div>
      <div className="flex flex-col items-stretch ">
        <GalleryItem
          renderItemComponent={<GalleryItemRender />}
          data={config["content."] ?? []}
          numberItem={{ desk: 1, mobile: 1 }}
          style="w-full "
          indicator={false}
        />
        <div
          className={`grid grid-cols-3 gap-x-3 px-10  text-center  ${content.length == 0 ? "mt-52" : "-mt-20  h-[10vh]"}  items-start  text-3xl 2xl:text-6xl bg-gray-100 z-10 pt-10`}
        >
          <div className="flex flex-col items-center text-center space-y-1 ">
            <span className="font-semibold">Teléfono</span>
            <span>{phone_info}</span>
          </div>
          <div className="flex flex-col items-center text-center space-y-1 ">
            <span className="font-semibold">Correo</span>
            <span>{email_info}</span>
          </div>
          <div className="flex flex-col items-center text-center space-y-1 ">
            <span className="font-semibold">Horarios</span>
            {_attention_schedule &&
              _attention_schedule.map((schedule, index) => (
                <span key={index}>{schedule}</span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyTurn;
