import React, { useState } from "react";

import CategoryCard from "./CategoryCard";
import HeaderStep from "./HeaderStep";
import ModalProducts from "./ModalProducts";
import { useWizardStore } from "store/wizardStore";
import { useRecords } from "hooks/records";
// import { useIdSeq } from "store/idSeq";
// import cats from "./test_data";
const fields = [
  "product_categories.name",
  "product_categories.products.sale_price_w_tax",
  "product_categories.products.salable",
  "product_categories.products.name",
  "product_categories.products.quantity",
  "product_categories.products.images.image_url",
  "product_categories.products.id",
  "product_categories.products.list_price",
  "product_categories.images.image",
];
const model = "sale.shop";

function StepOrder() {
  const { record, updateWizard } = useWizardStore();
  let [productsCategory, setProductsCat] = useState(null);
  const domain = record.shop ? [["id", "=", record?.shop]] : null;
  let { data: categories, isLoading } = useRecords(model, domain, fields);
  const cats = categories ? categories[0]["product_categories."] : [];

  function closeModal() {
    setProductsCat(null);
  }

  function onClickedCategory(products) {
    if (record.lines.size >= 3) {
      return;
    }
    setProductsCat(products);
  }

  function onClickedProduct(value) {
    const timeStamp = new Date();
    const rec_id = -timeStamp.getTime();
    if (record.lines.size >= 3) {
      return;
    }
    const product = {
      id: rec_id,
      product: { id: value.id, name: value.name },
      unit_price: value.list_price,
      unit_price_w_tax: value.sale_price_w_tax,
    };
    record.lines.set(rec_id, product);
    updateWizard(record);
  }

  return (
    <div>
      <HeaderStep label="PASO 1. ESCOJE TUS PRODUCTOS" />
      <div className="block">
        {cats.map((value) => {
          return (
            <CategoryCard
              onClicked={onClickedCategory}
              key={value.id}
              {...value}
            />
          );
        })}
      </div>
      {productsCategory && (
        <ModalProducts
          open={true}
          onClicked={onClickedProduct}
          products={productsCategory}
          buttons={["close"]}
          onClose={closeModal}
        />
      )}
    </div>
  );
}

export default StepOrder;
