import NumericCell from "./NumericCell";
import SelectionCell from "./SelectionCell";
import TextCell from "./CharCell";
import IntegerCell from "./IntegerCell";
import FloatCell from "./FloatCell";
import BooleanCell from "./BooleanCell";
import DropdownCell from "./DropdownCell";
import DatetimeCell from "./DatetimeCell";
import CharCell from "./CharCell";
import DateCell from "./DateCell";
import ButtonTable from "components/Buttons/ButtonTable";
import TButtonCustomModal from "./ButtonCustomModal";

const poolCell = {
  char: CharCell,
  numeric: NumericCell,
  selection: SelectionCell,
  text: TextCell,
  date: DateCell,
  datetime: DatetimeCell,
  integer: IntegerCell,
  float: FloatCell,
  boolean: BooleanCell,
  many2one: DropdownCell,
  button_custom_modal: TButtonCustomModal,
  button: ButtonTable,
};

export default poolCell;
