import { create } from "zustand";
import proxy from "api/proxy";

const initialState = {
  holder_name: "",
  month: "",
  year: "",
  secret_code: "",
  card_number: "",
};

export const useCardStore = create((set, get) => ({
  cardStore: { ...initialState },
  upCardStore: (field, value) => {
    let store = {};
    store[field] = value;
    set((state) => ({ cardStore: { ...state.cardStore, ...store } }));
  },
  saveRecord: async (model) => {
    const store = get().storeRecord;
    console.log("aqui ======", model, store);
    const { data } = await proxy.saveQuery({
      model: model,
      storeRec: store,
    });
    return data;
  },
  resetRecord: () => set({ cardStore: { ...initialState } }),
}));
