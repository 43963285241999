const fields = [
  "company.rec_name",
  "party.name",
  "party.email",
  "party.phone",
  "number",
  "adults_num",
  "contact",
  "channel.name",
  "booking_date",
  "state",
  "total_amount",
  "party.name",
  "lines.room.name",
  "lines.room.cleaning_type",
  "lines.room.state",
  "lines.room.last_check_in",
  "lines.room.last_check_out",
  "lines.product.template.name",
  "lines.room.type_.name",
  "lines.arrival_date",
  "lines.departure_date",
  "lines.nights_quantity",
  "lines.guests",
  "lines.registration_state",
  "lines.unit_price_w_tax",
];

export { fields };
