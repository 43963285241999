import React from "react";
import { FormattedMessage as FM } from "react-intl";

import IconButton from "components/Buttons/IconButton";

/**
 * create component BoardHeader
 * @param {Object} props
 * @param {Array} props.ctxView
 * @param {Function} props.onChangeView
 * @param {Array} props.buttons
 * @param {Object} props.activeRecord
 * @param {String} props.viewType
 * @return component BoardHeader
 */
const BoardHeader = (props) => {
  const { ctxView, onChangeView, buttons, submodel, viewType } = props;
  const title = ctxView.model && <FM id={ctxView.model} />;

  const getButtons = () => {
    if (viewType === "list") {
      return ctxView.table_action && ctxView.table_action.includes("add") ? (
        <IconButton
          onClick={(e) => onChangeView(e, "add")}
          color="blue"
          name="fi fi-rr-add"
          tooltip="board.button_add"
        />
      ) : null;
    } else if (viewType === "form") {
      return;
      // return (
      //   <Fragment>
      //     <IconButton
      //       onClick={(e) => onChangeView(e, "return")}
      //       color="lime"
      //       name="fi fi-rr-arrow-small-left"
      //       tooltip="board.button_back"
      //     />
      //     {ctxView.form_action.includes("attachment") && (
      //       <IconButton
      //         onClick={() => setViewAttach(true)}
      //         color="gray"
      //         name="fi fi-rr-clip"
      //         tooltip="board.button_attachment"
      //       />
      //     )}
      //     {ctxView.form_action.includes("save") && (
      //       <IconButton
      //         onClick={(e) => onClickAction(e, "save")}
      //         color="gray"
      //         name="fi fi-rr-disk"
      //         tooltip="board.button_save"
      //       />
      //     )}
      //   </Fragment>
      // );
    } else if (viewType === "attachment") {
      return (
        <IconButton
          onClick={(e) => onChangeView(e, "return")}
          color="green"
          size="w-40"
        />
      );
    } else {
      return buttons ?? null;
    }
  };

  if (viewType === "form") return;

  return (
    <div id="board-header" className="grid-cols-12 flex px-0 py-1">
      <div className="flex col-span-12 md:col-span-8 w-4/5 text-zinc-500">
        {submodel ? (
          <h2 className="truncate text-xl">{title}</h2>
        ) : (
          <h1 className="truncate text-4xl font-light">{title}</h1>
        )}
      </div>
      <div className="flex col-span-12 md:col-span-4 ml-auto gap-x-4">
        {getButtons()}
      </div>
    </div>
  );
};

export default BoardHeader;
