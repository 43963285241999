import React, { useState, useEffect, Fragment } from "react";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";

import ModelFolioWizard from "../models/ModelFolioWizard";
import FullTable from "components/MeTable/FullTable";
import StdButton from "components/Buttons/StdButton";
import ModelSale from "../models/ModelSale";
import proxy from "api/proxy";
import mainStore from "store/mainStore";

export const ModalWizzardFolios = ({
  // ctxView,
  // handleStatusShedule,
  onClose,
}) => {
  const ctxViewFolio = ModelFolioWizard.ctxView();
  const ctxViewSale = ModelSale.ctxView();
  const {
    updateRecord,
    updateStore,
    reload,
    store,
    record: activeRecord,
  } = mainStore();
  let [folios, setFolios] = useState(null);
  let [selectFolio, setSelectedFolio] = useState(null);
  const getFolios = async () => {
    const { data: dataFolio, error: errorFolios } = await proxy.methodCall({
      model: "hotel.folio",
      method: "get_current_folios",
      args: [],
    });
    dataFolio;
    const mapRecs = new Map();
    console.log(errorFolios);
    if (dataFolio) {
      dataFolio.forEach((folio) => {
        folio["room."] = {
          code: folio["room."].name,
          id: folio["room."].id,
        };
        mapRecs.set(folio.id, folio);
      });
      setFolios(mapRecs);
    }
  };

  const addFolio = async (record) => {
    const _activeRecord = { ...activeRecord };
    const _storeRecord = { ...store };
    _activeRecord.party = record["main_guest."];
    _activeRecord.id_folio = record.id;
    _storeRecord.party = record["main_guest."];
    updateRecord(_activeRecord);
    updateStore(_storeRecord);
    setSelectedFolio(record);
  };

  ctxViewFolio.webfields.add.method = addFolio;

  const createSaleRecord = async () => {
    let _storeRecord = { ...store };
    _storeRecord.status = "transferred";
    _storeRecord.state = "transferred";
    // const _data = func.recToTryton(_storeRecord);

    if (_storeRecord.id > 0) {
      const { data: resTransfer, error: errorTransfer } =
        await proxy.methodCall({
          model: "sale.sale",
          method: "transfer_to_folio",
          args: [_storeRecord.id, selectFolio.id],
        });
      if (resTransfer) {
        reload();
        onClose();
        return false;
      } else {
        console.log(errorTransfer);
      }
    }
  };

  const cancelledFolio = () => {
    let _activeRecord = { ...record };
    let _storeRecord = { ...store };
    _activeRecord.party = null;
    _storeRecord.party = null;
    updateRecord(_activeRecord);
    updateStore(_storeRecord);
    onClose();
  };

  useEffect(() => {
    getFolios();
  }, []);

  return (
    <Fragment>
      {selectFolio ? (
        <div className="px-10">
          <ArrowLeftIcon
            className="w-10 absolute right-10 top-2 cursor-pointer flex text-2xl my-auto fi fi fi-rr-arrow-small-left text-lime-700 bg-lime-200 h-10  rounded-xl py-1 px-2 items-center text-center hover:opacity-90 disabled:opacity-50 justify-center gap-2 active:scale-95"
            onClick={() => setSelectedFolio(null)}
          />
          <div className="mt-10">
            <div className="font-medium text-xl mb-3">Datos de habitacion</div>
            <div>Habitacion: {selectFolio["room."].code}</div>
            <div>Reserva: {selectFolio["booking."].number}</div>
            <div>Huesped: {selectFolio["main_guest."].name}</div>
            <div>Numero ID: {selectFolio["main_guest."].id_number}</div>
            <div>Telefono: {selectFolio["main_guest."].mobile}</div>
          </div>
          <div className="flex mx-auto space-x-3 justify-center mt-10">
            <StdButton
              content="Cargar a la habitación"
              color="bluePresik"
              style="uppercase w-[200px]"
              onClick={() => createSaleRecord()}
            />
            <StdButton
              content="Cancelar"
              color="rose"
              style="uppercase w-[200px] "
              onClick={() => cancelledFolio()}
            />
          </div>
        </div>
      ) : (
        <FullTable
          records={folios}
          isLoading={false}
          limit={30}
          // updateRecords={updateRecords}
          onChangeView={() => console.log("cambio de vista")}
          ctxView={ctxViewFolio}
        />
      )}
    </Fragment>
  );
};
