import modelGuest from "./Guest";
import modelCharge from "./Charge";

const getView = () => {
  let DictCtxView = {
    model: "hotel.folio",
    row_selectable: false,
    form_action: ["add"], // options: ['save', 'delete']
    table_action: ["add", "edit"], // options: ['open', 'delete', 'edit', 'add']
    card_action: ["delete"], // open (open modal form)
    title: { field: "booking", component: "title" },
    webfields: {
      main_guest: {
        type: "many2one",
        model: "party.party",
        // readOnly: {
        //   registration_state: ["check_in", "check_out"],
        // },
      },
      // booking: {
      //   type: "many2one",
      //   model: "hotel.booking",
      //   readOnly: {
      //     registration_state: ["check_in", "check_out"],
      //   },
      // },
      arrival_date: {
        type: "date",
        // required: true,
        readOnly: true,
      },
      departure_date: {
        type: "date",
        readOnly: true,
      },
      unit_price: {
        type: "numeric",
        required: true,
        readOnly: {
          registration_state: ["check_out", "check_in"],
        },
        editable: true,
      },
      nights_quantity: {
        type: "integer",
        readOnly: {
          registration_state: ["check_in", "check_out"],
        },
      },
      room: {
        type: "many2one",
        model: "hotel.room",
        required: true,
        readOnly: {
          registration_state: ["check_out", "check_in"],
        },
      },
      product: {
        type: "many2one",
        model: "product.product",
        recSearch: () => [],
        // recSearch: searchProduct,
        readOnly: {
          registration_state: ["check_in", "check_out"],
        },
      },
      channel: {
        type: "many2one",
        model: "hotel.channel",
        readOnly: {
          registration_state: ["check_in", "check_out"],
        },
        images: { targetField: "code", source: {} },
      },
      registration_state: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "pending",
      },
      unit_price_w_tax: {
        type: "numeric",
        readOnly: true,
      },
      guests: {
        type: "one2many",
        model: "hotel.folio.guest",
        ctxView: modelGuest.ctxView(),
        readOnly: {
          registration_state: ["check_in", "check_out"],
        },
      },
      charges: {
        type: "one2many",
        model: "hotel.folio.charge",
        ctxView: modelCharge.ctxView(),
        readOnly: {
          registration_state: ["check_in", "check_out"],
        },
      },
      total_amount: { type: "numeric", readOnly: true },
      notes: {
        type: "text",
        readOnly: false,
      },
      vehicle_plate: {
        type: "char",
        readOnly: ["check_out"],
      },
      group: {
        type: "boolean",
        readOnly: {
          registration_state: ["check_out", "check_in"],
        },
        translate: true,
      },
      payment_status: {
        type: "char",
        readOnly: true,
        translate: true,
      },
      check_in: {
        type: "button",
        button_method: "check_in",
        visible: { registration_state: ["pending"] },
        onSuccessMsg: "Check In exitoso!",
        color: "blue",
      },
      check_out: {
        type: "button",
        button_method: "check_out",
        visible: [{ registration_state: ["check_in"] }],
        onSuccessMsg: "Check Out exitoso!",
        color: "blue",
      },
    },
    webcards: {
      head: [
        {
          col: [
            { name: "room", style: "font-bold text-lg" },
            { name: "product" },
          ],
          width: "w-full",
        },
        // {
        //   col: [{ name: "product" }],
        // },
      ],
      content: [
        // { row: [{ name: "product" }] },
        {
          row: [
            { name: "nights_quantity", width: "w-3/5", label: true },
            { name: "unit_price_w_tax", label: true },
          ],
        },
        { row: [{ name: "guests", width: "w-2/5" }] },
      ],
    },
    webtree: [
      { name: "registration_card", width: "15%" },
      { name: "room", width: "15%" },
      { name: "main_guest", width: "15%" },
      { name: "product", width: "15%" },
      { name: "arrival_date", width: "10%" },
      { name: "departure_date", width: "10%" },
      { name: "nights_quantity", width: "10%" },
      { name: "registration_state", width: "10%" },
    ],
    webform: [
      { name: "product" },
      {
        id: "booking",
        grid: [{ name: "booking" }, { name: "registration_card" }],
        size: [1, 2],
      },

      { name: "room" },
      { name: "main_guest" },
      {
        id: "dates",
        grid: [
          { name: "arrival_date" },
          { name: "departure_date" },
          { name: "nights_quantity" },
        ],
        size: [1, 3],
        // border: "visible",
        span: "md:col-span-2",
      },
      {
        id: "amounts",
        grid: [
          { name: "unit_price" },
          { name: "unit_price_w_tax" },
          { name: "total_amount" },
        ],
        span: "md:col-span-2",
        size: [1, 3],
      },
      // {
      //   id: "sales",
      //   grid: [{ name: "channel" }, { name: "plan" }, { name: "group" }],
      //   size: [1, 3],
      //   span: "md:col-span-2",
      // },
      { name: "registration_state" },
      { name: "notes" },
      // { name: "pending_total" },
      // { name: "payment_status" },
      // {
      //   name: "more_info",
      //   component: "group",
      //   children: [
      //     {
      //       name: "guests",
      //       component: "button_modal",
      //       color: "sky",
      //       icon: "fi fi-rr-users",
      //     },
      //     {
      //       name: "charges",
      //       component: "button_modal",
      //       color: "amber",
      //       icon: "fi fi-rr-file-invoice",
      //     },
      //   ],
      // },
      // { name: "check_out" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
