import React, { memo } from "react";

/**
 * Define cell for row FIXME
 * @param {Boolean} readOnly - optional selectable
 * @param {Boolean} selectable - optional default true
 * @param {Function} children - callback function on click cell
 * @param {ChildNode} Component - Component to render as child
 * @param {String} textAling - optional align text for cell [left, right, center] default center
 * @param {String} color - define color for cell
 * @param {Array} style - define style for cell
 */

const TableRow = memo(function TableRow({
  readOnly,
  record,
  selectable,
  idx,
  children,
  onClick,
  onDoubleClick,
  selectedRows,
}) {
  let _style = "odd:bg-white even:bg-zinc-100";
  if (selectable && selectedRows?.get(record.id)) {
    _style = "bg-lime-200";
  }

  if (selectable) {
    return (
      <tr
        key={idx}
        disabled={readOnly}
        className={_style}
        onClick={() => onClick(record)}
        onDoubleClick={() => onDoubleClick(record)}
      >
        {children}
      </tr>
    );
  } else {
    return (
      <tr
        key={idx}
        disabled={readOnly}
        className={_style}
        onClick={() => onClick(record)}
      >
        {children}
      </tr>
    );
  }
});

export default TableRow;
