// Guard model
const getView = () => {
  let DictCtxView = {
    row_selectable: false,
    form_action: [],
    activeSearch: true,
    filters: null,
    model: "account.period",
    webfields: {
      name: { type: "char", readOnly: true, searchable: true },
    },
    webtree: [{ name: "name", width: "40%" }],
    webform: [],
  };

  return DictCtxView;
};

export default { ctxView: getView };
