import React from "react";
import { FormattedMessage as FM } from "react-intl";

import StdButton from "components/Buttons/StdButton";
import PureModal from "components/Modals/PureModal";
import Form from "components/MeForm/Form";
import proxy from "api/proxy";
import funcs from "tools/functions";
import { useWizardStore } from "store/wizardStore";

function ModalReport({ ctxView, parentRec, onClose, open }) {
  const { webform, reportName } = ctxView;
  const { store, record } = useWizardStore();

  const onPrintReport = async () => {
    let storeStd = funcs.recToTryton(store);
    const { reportUrl, nameFile } = await proxy.report({
      report: reportName,
      data: storeStd,
      records: [],
    });
    funcs.createReportLink(reportUrl, nameFile);
    onClose();
  };

  return (
    <PureModal
      open={open}
      width="w-2/3 sm:w-4/5 md:w-3/5 lg:w-2/3 xl:w-5/12"
      btnCloseActive={true}
      onClose={onClose}
      title={
        <h1 className="font-bold text-3xl text-stone-600">
          <FM id={reportName} />
        </h1>
      }
    >
      <div className="grid grid-cols-2 p-5">
        <Form
          ctxView={ctxView}
          webform={webform}
          parentRec={parentRec}
          level="wizard"
        />
        <div className="flex mt-8 mb-3 col-span-2">
          <StdButton
            key="print-report"
            color="blue"
            onClick={() => onPrintReport()}
            content="button.print"
            style="ml-auto"
          />
        </div>
      </div>
    </PureModal>
  );
}

export default ModalReport;
