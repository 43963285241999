import React, { Fragment } from "react";
import { FormattedMessage as FM } from "react-intl";
import { Dialog, Transition } from "@headlessui/react";

import StdButton from "components/Buttons/StdButton";
import { classNames } from "tools/ui";

// const ICON = {
//   ask: { name: "check", color: "green" },
//   success: { name: "check", color: "green" },
//   info: { name: "info", color: "blue" },
//   warning: { name: "warning circle", color: "orange" },
//   error: { name: "cancel", color: "red" },
// };

/**
 *
 * @param {object} props - component props
 * @param {Function} props.onClose - function for close modal
 * @param {boolean} props.open - true or false
 * @param {string} props.type - options [success, info, warning, error]
 * @param {string} props.msg - text to show
 * @return {Component} - Modal component message success or error
 */

const TYPES = {
  ask: "modal_msg.record_ask",
  error: "modal_msg.record_error",
  success: "modal_msg.record_successful",
  warning: "modal_msg.record_warning",
};

const ICONS = {
  ask: (
    <i
      className="fi fi-rr-comment-question text-5xl m-auto text-sky-700"
      aria-hidden="true"
    />
  ),
  success: (
    <i
      className="fi fi-bs-check text-5xl m-auto text-lime-500"
      aria-hidden="true"
    />
  ),
  error: (
    <i
      className="fi fi-rr-cross-circle text-5xl m-auto text-rose-600"
      aria-hidden="true"
    />
  ),
  warning: (
    <i
      className="fi fi-rr-triangle-warning text-5xl m-auto text-orange-700"
      aria-hidden="true"
    />
  ),
};

const backIcon = "mx-auto flex my-2 h-16 w-16";

const ModalMsg = (props) => {
  const onClose = () => {
    props.onClose(props.origin, props.reset);
  };

  const buttons = {
    cancel: (
      <StdButton
        key="cancel"
        color="rose"
        size="w-full"
        onClick={onClose}
        content="board.dialog.button_cancel"
      />
    ),
    ok: (
      <StdButton
        key="ok"
        color="blue"
        size="w-full"
        onClick={props.onOK}
        content="board.dialog.button_ok"
      />
    ),
    discard: (
      <StdButton
        key="discard"
        color="amber"
        size="w-full"
        onClick={props.onDiscard}
        content="board.dialog.button_discard"
      />
    ),
    close: (
      <StdButton
        key="close"
        color="blue"
        size="w-full"
        onClick={onClose}
        content="board.dialog.button_close"
      />
    ),
  };

  const dialogButtons = props?.buttons?.map((name) => {
    return buttons[name];
  });

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bbackIcong-gray-500 bg-opacity-75 transition-opacity bg-black backdrop-blur-sm" />
        </Transition.Child>
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex pt-36 justify-center px-4 text-center sm:items-center sm:p-12 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/3 p-4 sm:my-8 sm:p-6 text-left shadow-xl transition-all rounded-lg bg-white">
                <div>
                  <div className={classNames(backIcon)}>
                    {ICONS[props.type ?? "success"]}
                  </div>
                  <div className="text-center sm:mt-3">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-medium leading-6 text-gray-900 mb-2 mt-6"
                    >
                      <FM id={TYPES[props.type ?? "success"]} />
                    </Dialog.Title>
                    <div className="mt-1">
                      <p className="text-md text-gray-500 pb-4">{props.msg}</p>
                    </div>
                  </div>
                </div>
                <div className="flex mt-2 sm:mt-4 gap-2">{dialogButtons}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalMsg;
