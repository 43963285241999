import React, { Fragment, useEffect } from "react";
// import TimeLine from 'apps/SaleCallCenter/TimeLine'
import proxy from "api/proxy";

const SaleTracker = (props) => {
  const { config } = props;
  console.log(config);
  const get_data = async () => {
    const { data } = await proxy.methodCall({
      model: "dash.app.sale_tracker",
      method: "get_shipment",
      args: [{ sale_number: "000001" }],
    });
    console.log(data);
  };
  useEffect(() => {
    get_data();
  }, []);
  return (
    <Fragment>
      <div>SaleTracker</div>
      {/* <TimeLine></TimeLine> */}
    </Fragment>
  );
};

export default SaleTracker;
