import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { Carousel } from "react-responsive-carousel";
import { XMarkIcon } from "@heroicons/react/20/solid";

const GalleryImagesRoom = ({ show, dataImages, onClose }) => {
  const renderCategories = () => {
    let itemsPerSlide;

    if (window.innerWidth >= 1000) {
      itemsPerSlide = 1;
    } else if (window.innerWidth >= 500) {
      itemsPerSlide = 1;
    } else {
      itemsPerSlide = 1;
    }

    const totalSlides = Math.ceil(dataImages.length / itemsPerSlide);
    const slides = [];

    for (let i = 0; i < totalSlides; i++) {
      const start = i * itemsPerSlide;
      const end = start + itemsPerSlide;
      const slideCategories = dataImages.slice(start, end);

      slides.push(
        <div key={i} className="">
          {slideCategories.map((imageRoom, key) => (
            <img
              src={imageRoom}
              key={key}
              className="mx-auto max-w-4xl max-h-[80vh] object-cover"
            />
          ))}
        </div>,
      );
    }

    return slides;
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Transition.Child
        // show={show}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        as={Fragment}
      >
        <div className="fixed w-screen h-screen bg-black/30 backdrop-blur-md left-0 top-0 z-[100] flex justify-center items-center align-middle">
          <XMarkIcon
            className="w-10 absolute md:right-16 md:top-10 right-3 top-3 bg-red-400  text-red-800 rounded-full p-2 cursor-pointer"
            onClick={onClose}
          />
          <div>
            <Carousel
              showThumbs={false}
              showStatus={false}
              showIndicators={true}
              emulateTouch={true}
              showArrows={false}
              renderIndicator={(clickHandler, isSelected, index, label) => {
                if (isSelected) {
                  return (
                    <li
                      className="custom-dot active"
                      aria-label={`Slide ${label} - Current Slide`}
                    />
                  );
                }
                return (
                  <li
                    className="custom-dot"
                    onClick={clickHandler}
                    onKeyDown={clickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    title={`${label}`}
                    aria-label={`Slide ${label}`}
                  />
                );
              }}
            >
              {renderCategories()}
            </Carousel>
          </div>
        </div>
      </Transition.Child>
    </Transition.Root>
  );
};

export default GalleryImagesRoom;
