import React, { useState, useEffect } from "react";
import proxy from "api/proxy";
import tools from "tools/dates";
import funcs from "tools/functions";
import SectionNoData from "components/Tools/SectionNoData";
import ScreenSystenControl from "./ScreenSystenControl";
import ModelTickets from "./ModelSystemControl";
import store from "store";

const today = tools.dateToday();
const dom = [["date_ticket", "=", today]];

const TicketSystem = (props) => {
  const { config } = props;
  const session = store.get("ctxSession");
  const ctxView = ModelTickets.ctxView(config);
  const [control, setControl] = useState(null);
  const [position, setPosition] = useState(null);
  const getTicketSystemControl = async () => {
    const fields = [
      "date_ticket",
      "tickets",
      "tickets.position",
      "tickets.number_id",
      "tickets.turn",
    ];
    const { data: res, error } = await proxy.search(
      "ticket_system.ticket_control",
      dom,
      fields,
    );
    if (res) {
      // tickets.value = res;
      let control_ = res[0] && res[0].length != 0 ? res[0] : false;

      setControl(control_);
    } else {
      console.log(error);
    }
  };

  const getPosition = async () => {
    let domPosition = [["user", "=", session.user]];
    const fields = ["id", "number"];
    const { data } = await proxy.search(
      "ticket_system.attention_position",
      domPosition,
      fields,
    );

    setPosition(data[0]);
  };

  useEffect(() => {
    getTicketSystemControl();
    getPosition();
  }, []);

  const createTicketControl = async () => {
    const record = {
      date_ticket: today,
    };
    let _record = funcs.recToTryton(record);
    const model = "ticket_system.ticket_control";
    const fields_names = ["date_ticket"];
    const { data } = await proxy.create(model, _record, fields_names);
    if (data) {
      let control_ = data[0];
      setControl(control_);
    } else {
      console.log("error", error);
    }
  };

  return (
    <div className="w-full p-4">
      {control ? (
        <ScreenSystenControl
          today={today}
          ctxView={ctxView}
          {...props}
          control={control}
          position={position}
        />
      ) : (
        <div>
          <SectionNoData text={"Debes iniciar el turnador hoy"}>
            <button
              className="bg-yellow-500 text-blue-presik py-1.5 px-4 rounded-lg mt-1"
              onClick={() => createTicketControl()}
            >
              INICIAR AHORA
            </button>
          </SectionNoData>
        </div>
      )}
    </div>
  );
};

export default TicketSystem;
