const getView = () => {
  let DictCtxView = {
    model: "sale.sale",
    domain: [["state", "=", "draft"]],
    form_action: [], // options: ['save', 'delete']
    table_action: [], // options: ['open', 'delete', 'edit', 'add']
    activeSearch: true,
    pagination: [],
    limit: 20,
    selectable: null, // Options for table rows: null, multi, one
    title: { field: "number", model: true },
    // tags: {
    //   order_status: stateColors,
    // },
    webfields: {
      number: { type: "char", readOnly: true, searchable: true },
      party: {
        type: "many2one",
        model: "party.party",
        // recSearch: searchParty,
        searchable: true,
        readOnly: true,
      },
      sale_date: {
        type: "date",
        readOnly: true,
        default: () => new Date(),
        required: true,
      },
      state: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "draft",
      },

      description: { type: "char", readOnly: true, searchable: true },
      add: {
        type: "button",
        color: "blue",
        icon: "fi fi-rr-add",
      },
    },
    webtree: [
      { name: "number", width: "20%" },
      { name: "sale_date", width: "25%" },
      { name: "party", width: "25%" },
      { name: "description", width: "25%" },
      { name: "add", width: "25%" },
    ],
    webform: [],
  };

  return DictCtxView;
};

export default { ctxView: getView };
