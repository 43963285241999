import React, { useState } from "react";

// import ModalReport from "components/Modals/ModalReport";
import MenuButtonReport from "components/Buttons/MenuButtonReport";
import ModalMsg from "components/Modals/ModalMsg";

function MenuReport({ ctxView, activeRecord }) {
  const { reports } = ctxView;
  let [modalMsg, setModalMsg] = useState(null);
  let [openModalMsg, setOpenModalMsg] = useState(false);
  // let [openReport, setOpenReport] = useState(false);

  function dialogMesssage(msg) {
    setModalMsg(msg);
    setOpenModalMsg(true);
  }

  const closeModalMsg = () => {
    setOpenModalMsg(false);
  };

  // const fields = [
  //   {
  //     label: "surveillance.schedule.period",
  //     name: "period",
  //     type: "many2one",
  //     model: "account.period",
  //     required: true,
  //     ctxView: ModelPeriod.ctxView(),
  //   },
  //   {
  //     label: "surveillance.schedule.location",
  //     name: "location",
  //     type: "search",
  //     model: "surveillance.location",
  //     required: true,
  //     ctxView: ModelLocation.ctxView(),
  //   },
  // ];

  // <ModalReport onClose={onClose} open={openReport} ctxView={reportView} />

  return (
    <div className="w-72">
      <MenuButtonReport
        id="form-toolbar-report"
        name="REPORTES"
        className="icon"
        activeRecord={activeRecord}
        options={reports}
        dialogMesssage={dialogMesssage}
      />
      {openModalMsg && (
        <ModalMsg
          open={openModalMsg}
          buttons={["close"]}
          msg={modalMsg}
          onClose={closeModalMsg}
        />
      )}
    </div>
  );
}

export default MenuReport;
