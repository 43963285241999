import React from "react";

import Board from "components/Meta/Board";
import puchaseModel from "./ModelPurchase";

const PurchaseApproval = (props) => {
  const { config } = props;
  const ctxView = puchaseModel.ctxView(config);

  return <Board ctxView={ctxView} />;
};

export default PurchaseApproval;
