// Sale model line

const getProduct = (value) => {
  const search = [
    "OR",
    [
      ["template.name", "ilike", `%${value}%`],
      ["template.salable", "=", true],
    ],
    [
      ["code", "ilike", `${value}%`],
      ["template.salable", "=", true],
    ],
  ];
  return search;
};

const onChangeProduct = async (record, store) => {
  if (record.product) {
    const product = record.product;
    let unit = product["sale_uom."];
    let unit_price;
    console.log("product.....", product);
    if (product["sale_uom."] !== product["sale_uom."]) {
      // FIXME get price according to sale uom
      unit_price = product.list_price;
    } else {
      unit_price = product.list_price;
    }
    unit_price = parseFloat(unit_price.toString());
    store.unit_price = unit_price;
    store.unit = unit.id;
    store.product = product.id;
    record.unit_price = unit_price;
    record.unit = unit;
    record.amount = unit_price * record.quantity;
    record.sale_price_taxed = record.product.sale_price_taxed * record.quantity;
  }
};

const onChangeQty = (record) => {
  const amount = (record.unit_price || 0) * record.quantity || 0;
  record.amount = amount;
};

const getUnitPriceTaxed = (record) => {
  let res = 0;
  if (record) {
    res = record.sale_price_taxed ?? record.product?.sale_price_taxed;
  }
  return res;
};

const searchUnit = (value, record) => {
  let dom = [["category", "=", record?.unit?.category]];
  if (value) {
    dom.push(["name", "ilike", `%${value}%`]);
  }
  return dom;
};

const getTotalAmount = (record) => {
  let res = 0;
  if (record.sale_price_taxed && record.quantity) {
    res = record.sale_price_taxed * record.quantity;
  }
  return res;
};

const recReadOnly = (record) => {
  let res = false;
  if (record.status_order !== "draft") {
    res = true;
  }
  return res;
};

const getDisabled = (record) => {
  let res = true;
  if (record && record.status_order === "draft") {
    res = false;
  }
  return res;
};

const getAmount = (record) => {
  let res = 0;
  if (record.unit_price && record.quantity) {
    res = record.unit_price * record.quantity;
  }
  return res;
};

function accDelete(rec) {
  return rec.status_order === "draft" ?? false;
}

function accEdit(rec) {
  let res = false;
  console.log("rec .....", rec);
  // if (rec && rec.status_order === "draft") {
  //   res = false;
  // }
  return res;
}

const getView = () => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    model: "sale.line",
    table_action: ["edit"],
    form_action: ["delete", "add", "edit"],
    access: {
      delete: accDelete,
      edit: accEdit,
    },
    target: "sale",
    readOnly: recReadOnly,
    webfields: {
      product: {
        type: "many2one",
        recName: "name",
        model: "product.product",
        recSearch: getProduct,
        withChange: onChangeProduct,
        attrs: [
          "id",
          "list_price",
          "name",
          "sale_price_taxed",
          "sale_uom.rec_name",
          "default_uom.rec_name",
        ],
        required: true,
        readOnly: getDisabled,
      },
      unit: {
        type: "many2one",
        model: "product.uom",
        recSearch: searchUnit,
        readOnly: true,
        attrs: ["id", "name", "category", "factor"],
        // withChange: onChangeUnit,
      },
      quantity: {
        type: "integer",
        withChange: onChangeQty,
        required: true,
        default: 1,
        readOnly: getDisabled,
      },
      // unit_price: { type: "numeric", readOnly: true },
      sale_price_taxed: {
        type: "numeric",
        readOnly: true,
        function: getUnitPriceTaxed,
      },
      status_order: {
        type: "char",
        readOnly: true,
        default: "draft",
        translate: true,
      },
      amount: { type: "numeric", readOnly: true, function: getAmount },
      total_amount: {
        type: "numeric",
        readOnly: true,
        function: getTotalAmount,
      },
      note: { type: "text", readOnly: getDisabled },
    },
    webtree: [
      { name: "product", width: "40%" },
      { name: "quantity", width: "10%" },
      { name: "sale_price_taxed", width: "20%" },
      // { name: "total_amount", width: "20%" },
      { name: "status_order", width: "10%" },
    ],
    webform: [
      { name: "product" },
      { name: "quantity" },
      { name: "note" },
      { name: "sale_price_taxed" },
      { name: "total_amount" },
      { name: "status_order" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
