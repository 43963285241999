import React from "react";
import { FormattedMessage as FM } from "react-intl";
import store from "store";
// import { PaperClipIcon } from "@heroicons/react/24/outline";

import { classNames } from "tools/ui";
import proxy from "api/proxy";
import funcs from "tools/functions";

const styleIcon = "pr-3 flex text-2xl my-auto active:scale-95";
const iconDiv =
  "flex p-3 w-12 rounded-md mx-2 bg-stone-100 text-stone-500 hover:bg-white hover:text-sky-700 active:bg-sky-800 active:text-white active:scale-95";

function MenuItemReport({ item, activeRecord, active, dialogMesssage }) {
  const session = store.get("ctxSession");
  if (active && active === false) return null;
  const storeStd = {
    company: session.company,
  };

  async function printDirectReport(report) {
    const { reportUrl, nameFile } = await proxy.report({
      report: report.name,
      data: storeStd,
      records: [activeRecord.id],
    });
    funcs.createReportLink(reportUrl, nameFile);
  }

  async function sendEmail(report) {
    const { data, error } = await proxy.methodCall({
      model: report.name,
      method: report.send_email,
      args: [activeRecord.id],
    });
    console.log(".............", data, error);
    if (!error) {
      console.log("sendEmail/.............");
      // setOpenModalMsg(true);
      dialogMesssage("Correo enviado a exitosamente!");
    }
  }

  return (
    <div
      key={item.name}
      className="flex px-3 py-2 relative gap-x-6 rounded-lg hover:bg-stone-100 hover:text-sky-700 hover:cursor-pointer"
      onClick={item.onClick}
    >
      <div className="grid">
        <p className="mb-2">
          <FM id={item.name} />
        </p>
        <p className="text-stone-400 mt-auto">{item.description}</p>
      </div>
      <div className="flex ml-auto justify-items-end">
        {item.send_print && (
          <div
            key="div-report-icon-print"
            className={iconDiv}
            onClick={() => printDirectReport(item)}
          >
            <i
              key={item.name}
              className={classNames(styleIcon, "fi fi-rr-print")}
            />
          </div>
        )}
        {item.send_email && (
          <div
            key="div-report-icon-email"
            className={iconDiv}
            onClick={() => sendEmail(item)}
          >
            <i
              key={item.name}
              className={classNames(styleIcon, "fi fi-rr-envelope")}
            />
          </div>
        )}
        {item.print_modal && (
          <div
            key="div-report-icon-email"
            className={iconDiv}
            onClick={item.send_email}
          >
            <i
              key={item.name}
              className={classNames(styleIcon, "fi fi-rr-envelope")}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default MenuItemReport;
