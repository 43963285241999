import React, { useState, useEffect } from "react";

import Screen from "components/Meta/Screen";
import Board from "components/Meta/Board";
import PanelRight from "components/Panel/PanelRight";
import HeaderPanel from "components/Panel/HeaderPanel";
import SectionSidePanel from "components/Panel/SectionSidePanel";
import opportunityModel from "./ModelOpportunity";
import { classNames } from "tools/ui";
import { leads, activities, winRate } from "./fakeData";

const fmt = "YYYY-MM-DD";

function Opportunity(props) {
  const { config } = props;
  const ctxView = opportunityModel.ctxView(config);
  const [displayMode, setDisplayMode] = useState(true);
  const [winRateInfo, setWinRateInfo] = useState({});
  const [leadsInfo, setLeadsInfo] = useState([]);
  const [activitiesInfo, setActivitiesInfo] = useState([]);

  function handleDisplay(mode) {
    setDisplayMode(mode);
  }

  function getWinRate() {
    setWinRateInfo({
      title: "crm.opportunity.panel.win_rate",
      elements: winRate,
    });
  }

  function getData() {
    setLeadsInfo(leads);
    setActivitiesInfo(activities);
  }

  useEffect(() => {
    getData();
    getWinRate();
    // getLastReservations();
  }, []);

  let _displayStyle = "";
  if (displayMode) {
    _displayStyle = "md:w-[65vw] xl:w-[70vw] custom-transition duration-500";
  }

  const _style = displayMode ? "flex-row justify-between " : "flex-col";

  return (
    <Screen>
      <div className={classNames("flex", _style)}>
        <Board ctxView={ctxView} />
        <PanelRight
          title="SMART CRM"
          status={displayMode}
          handleDisplay={handleDisplay}
        >
          <HeaderPanel
            title="crm.opportunity.panel.status_info"
            dataInfo={winRateInfo}
            name="status_info"
          />
          <SectionSidePanel key="leads" elements={leadsInfo} />
          <SectionSidePanel key="activities" elements={activitiesInfo} />
        </PanelRight>
      </div>
    </Screen>
  );
}

export default Opportunity;
