import React, { useEffect, useState } from "react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { FormattedMessage as FM } from "react-intl";

import tools from "tools/common";
import proxy from "api/proxy";
import dates from "tools/dates";
import { classNames } from "tools/ui";
import mainStore from "store/mainStore";

const styleStatus =
  "z-20 flex border-2 shadow-xl w-5 h-5 rounded-full p-1 relative border-gray-300";

const labelHour = (hour) => {
  return (
    <div className="absolute leading-none -top-[40px] left-0.5 text-gray-900 font-[300] text-xs p-2 bg-white rounded-md">
      {tools.fmtHour(hour)}
      <div className="absolute w-2 h-2.5 left-0.5  -bottom-[3px] bg-white  right-0 rotateFull"></div>
    </div>
  );
};

function TimeLine() {
  const activeRecord = mainStore.getState().record;
  const status = activeRecord?.order_status;
  const sale = activeRecord?.id;

  const [data, setData] = useState(null);

  useEffect(() => {
    const statusCustomer = async () => {
      const { data: statusTime } = await proxy.search(
        "sale.order_status.time",
        [["sale", "=", sale]],
        ["requested", "commanded", "dispatched", "delivered", "created"],
      );
      const data = [
        {
          name: "created",
          hour: dates.getTrytonDateTime2Js(statusTime[0]?.created, true),
          bg: "bg-rose-500",
        },
        {
          name: "requested",
          hour: dates.getTrytonDateTime2Js(statusTime[0]?.requested, true),
          bg: "bg-slate-500",
        },
        {
          name: "commanded",
          hour: dates.getTrytonDateTime2Js(statusTime[0]?.commanded, true),
          bg: "bg-lime-500",
        },
        {
          name: "dispatched",
          hour: dates.getTrytonDateTime2Js(statusTime[0]?.dispatched, true),
          bg: "bg-blue-500",
        },
        {
          name: "delivered",
          hour: dates.getTrytonDateTime2Js(statusTime[0]?.delivered, true),
          bg: "bg-green-500",
        },
      ];

      setData(data);
    };
    if (sale) statusCustomer();
  }, [sale]);

  if (!data) {
    return null; // Aquí puedes devolver cualquier cosa mientras esperas a que se cargue la información
  }

  return (
    <div className={"relative mt-10 mb-14 mx-20"}>
      <div className="grid grid-flow-col inset-y-7 mt-3 w-full justify-items-stretch relative">
        {data.map(({ name, hour, bg }) => (
          <div
            key={name}
            className={
              status === name
                ? "relative font-[500] uppercase text-zinc-600"
                : "relative uppercase text-zinc-600"
            }
          >
            <div
              key={name}
              className={
                hour
                  ? classNames(styleStatus, bg)
                  : classNames(styleStatus, "bg-white")
              }
            ></div>
            {hour ? labelHour(hour) : ""}
            <div className="mt-4">
              <FM id={"sale.sale." + name} />
            </div>
          </div>
        ))}
      </div>
      <div
        className={
          "border-2 border-opacity-20 bg-gray-200 w-[99%] absolute left-0 inset-y-9 h-1 mx-3"
        }
      ></div>
      <CheckIcon
        className="w-6 rounded-full p-1 bg-gray-300 text-white absolute -right-0 top-6"
        color="white"
      ></CheckIcon>
    </div>
  );
}

export default TimeLine;
