import React, { useState, useEffect } from "react";
import { Route, Routes, useParams } from "react-router-dom";

import LogoCompany from "pages/Apps/LogoCompany";
import Loading from "components/Tools/Loading";
import BookingPage from "./BookingPage";
import AddGuestPage from "./AddGuestPage";
import SuccessPage from "./SuccessPage";
import mainStore from "store/mainStore";
import proxy from "api/proxy";
import func from "tools/functions";
import fields from "./fields";

function WebCheckIn(props) {
  const { id } = useParams();
  let [booking, setBooking] = useState(null);
  const { reset } = mainStore();

  function onAddGuest() {
    getBooking();
  }

  async function getBooking() {
    if (!id) {
      return;
    }
    let dom = [["id", "=", id]];
    const { data } = await proxy.search("hotel.booking", dom, fields);

    if (data) {
      const record = func.prepareRecord(data[0]);
      setBooking(record);
    }
  }

  useEffect(() => {
    getBooking();
    reset();
  }, [props]);

  if (!booking) {
    return <Loading />;
  }

  return (
    <div className="bg-slate-50 px-8 py-4 w-full mx-auto sm:w-4/5 md:w-2/3 lg:w-1/2">
      <div className="my-2">
        <LogoCompany size="w-40" />
        <h1 className="pt-8 text-3xl font-bold text-gray-600 text-center">
          Web Check-In
        </h1>
      </div>
      <Routes>
        <Route path="/" element={<BookingPage booking={booking} />} />
        <Route path="folio" element={<AddGuestPage onAdd={onAddGuest} />} />
        <Route path="success" element={<SuccessPage booking={booking} />} />
      </Routes>
    </div>
  );
}

export default WebCheckIn;
