const stateColors = {
  available: "amber",
  maintenance: "lime",
  not_available: "sky",
  stolen: "rose",
};
const getType = () => {
  const options = [
    { id: "equipment", name: "Equipment" },
    { id: "instrument", name: "Instrument" },
    { id: "vehicle", name: "Vehicle" },
  ];

  return options;
};
const getView = (config) => {
  let DictCtxView = {
    model: "maintenance.equipment",
    form_action: ["edit"],
    table_action: ["add", "edit"],
    activeSearch: true,
    // title: { field: "schedule", model: true },
    domain: [],
    tags: {
      status: stateColors,
    },
    webfields: {
      model_num: { type: "char" },
      vin: { type: "char" },
      code: { type: "char" },
      gps_serial: { type: "char" },
      gps_link: { type: "char" },
      gps_passwd: { type: "char" },
      active: { type: "boolean" },
      code: { type: "char" },
      serial: { type: "char" },
      issues: { type: "text" },
      date_return: { type: "date" },
      type_device: { type: "selection", options: getType() },
      status: { type: "char", tags: stateColors, readOnly: true },
      product: {
        type: "many2one",
        required: true,
        model: "product.template",
        readOnly: true,
      },
      brand: {
        type: "many2one",
        required: true,
        model: "maintenance.equipment.brand",
        readOnly: true,
      },
    },
    webtree: [
      { name: "model_num" },
      { name: "product" },
      { name: "vin" },
      { name: "gps_serial" },
      { name: "serial" },
      { name: "active" },
      { name: "status", widget: "badge" },
    ],
    webform: [
      {
        id: "data",
        grid: [
          { name: "product" },
          { name: "code" },
          { name: "vin" },
          { name: "active" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      {
        id: "gps",
        grid: [
          { name: "serial" },
          { name: "gps_serial" },
          { name: "gps_link" },
          { name: "gps_passwd" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      {
        id: "brand",
        grid: [
          { name: "brand" },
          { name: "model_num" },
          { name: "type_device" },
          { name: "date_return" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      { name: "issues" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
