import React, { useState, useEffect } from "react";
import proxy from "api/proxy";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { FormattedMessage as FM } from "react-intl";

const InformationPaymentBooking = ({ invoiceId }) => {
  let [dataInvoice, setDataInvoice] = useState(null);
  const getData = async () => {
    const { data, error } = await proxy.search(
      "electronic.payment",
      [["charge_id", "=", invoiceId]],
      [
        "state",
        "amount",
        "company.currency",
        "openpay_customer",
        "openpay_status",
        "customer_name",
        "customer_last_name",
        "customer_phone",
        "customer_email",
        "description",
        "date",
        "openpay_checkout_id",
      ],
    );

    if (data) {
      setDataInvoice(data[0]);
      const { data: dataAddPay, error } = await proxy.methodCall({
        model: "electronic.payment",
        method: "apply_payment_from_charge_id",
        args: [invoiceId],
      });
      console.log(error);

      if (data[0]) {
        console.log(dataAddPay);
      }
    } else {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [invoiceId]);

  return (
    <div className="bg-blue-presik w-full flex  flex-col justify-center items-center h-[450px] rounded-md text-white space-y-3 max-w-4xl mx-auto mt-20">
      <CheckCircleIcon className="w-20" />
      <h2 className="text-yellow-500 font-semibold text-xl uppercase">
        {dataInvoice?.state}
      </h2>
      <div>
        <span className="font-semibold uppercase mr-2">
          <FM id="crm.customer_service.party" />:
        </span>
        {`${dataInvoice?.customer_name} ${dataInvoice?.customer_last_name} `}
      </div>
      <div>
        <span className="font-semibold uppercase mr-2">
          <FM id="crm.customer_service.email" />:
        </span>
        {`${dataInvoice?.customer_email}  `}
      </div>
      <div>
        <span className="font-semibold uppercase mr-2">
          <FM id="crm.customer_service.phone" />:
        </span>
        {`${dataInvoice?.customer_phone}  `}
      </div>
      <div>
        <span className="font-semibold uppercase mr-2">
          <FM id="hotel.folio.booking" />:
        </span>
        {dataInvoice?.description}
      </div>

      <div>
        <span className="font-semibold uppercase mr-2">
          <FM id="crm.opportunity.amount" />:
        </span>{" "}
        {dataInvoice?.amount.toLocaleString("es-ES")}
      </div>
      <div>
        <span className="font-semibold uppercase mr-2">
          <FM id="app.booking.date_payment" />:
        </span>{" "}
        {dataInvoice?.date}
      </div>
      <div className="text-xs text-gray-500">
        {dataInvoice?.openpay_checkout_id}
      </div>
    </div>
  );
};

export default InformationPaymentBooking;
