import React from "react";
import date from "date-and-time";

import { classNames } from "tools/ui";

const baseCard =
  "group shadow-sm rounded-md px-7 cursor-pointer pt-0 pb-2 flex flex-col justify-center w-20 items-center -space-y-2 active:scale-95 active:shadow-x";
const noSeletedCard = "bg-white hover:bg-blue-presik hover:text-white";
const selectedCard = "bg-sky-200 hover:bg-blue-presik hover:text-white";
const bgTodayCard = "bg-blue-presik text-white";
const styleMonth =
  "text-xs font-semibold uppercase group-hover:text-yellow-300 pb-2";
const styleDay = "text-xs group-hover:text-white";

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const DateRangeCard = ({ date_, selected, onClick }) => {
  const currentDate = date.format(date_, "DD-MM-YYYY");
  const todayDate = date.format(new Date(), "DD-MM-YYYY");
  const isToday = currentDate === todayDate;
  let isSelected = false;
  if (selected) {
    let _selected = date.format(selected, "DD-MM-YYYY");
    if (_selected === currentDate) {
      isSelected = true;
    }
  }

  const handleClick = () => {
    onClick(date_);
  };

  let colorMonth = isToday ? "text-yellow-300" : "text-cyan-700";
  let colorWeekday = isToday ? "text-white" : "text-bluePresik";

  let colorBg = noSeletedCard;
  if (isSelected) {
    colorBg = selectedCard;
  }
  if (isToday) {
    colorBg = bgTodayCard;
  }

  return (
    <li
      data={date_.toDateString()}
      onClick={handleClick}
      className={classNames(baseCard, colorBg)}
    >
      <span className="text-[28px] font-semibold">{date_.getDate()}</span>
      <span className={classNames(styleMonth, colorMonth)}>
        {MONTHS[date_.getMonth()]}
      </span>
      <span className={classNames(styleDay, colorWeekday)}>
        {daysOfWeek[date_.getDay()]}
      </span>
    </li>
  );
};

export default DateRangeCard;
