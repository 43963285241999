import React from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import GalleryProduct from "./GalleryProduct";

const style_btn_categories =
  "mb-2 whitespace-nowrap rounded border border-border-200 bg-transparent py-1 px-2.5 text-sm lowercase tracking-wider text-heading transition-colors hover:border-accent hover:text-accent focus:bg-opacity-100 focus:outline-0 ltr:mr-2 rtl:ml-2";

const ItemProductContentModal = ({ product, onClose }) => {
  let attributes = [];
  product?.attributes &&
    Object.keys(product?.attributes).forEach((key) => {
      attributes.push({
        key: key.toUpperCase(),
        value: product.attributes[key].join(","),
      });
    });

  return (
    <article className="rounded-lg bg-light relative">
      <div className="absolute right-4 top-4">
        <XMarkIcon
          className="w-10 bg-red-300 rounded-full text-red-800 p-1 cursor-pointer"
          onClick={onClose}
        />
      </div>
      <div className="flex flex-col md:flex-row">
        <GalleryProduct images={product?.images} />
        <div className="flex flex-col items-start md:w-1/2 px-5 pt-10 lg:pt-14 xl:pt-16 xl:pl-2 lg:pl-2">
          <div className="w-full">
            <h1 className="text-lg font-semibold mb-3 tracking-tight text-heading md:text-xl xl:text-2xl cursor-pointer transition-colors hover:text-accent">
              {product?.name}
            </h1>
            {/* metadata */}
            <div className="my-3 text-sm leading-7 text-body md:mt-4">
              {product?.description}
            </div>
            <div className="grid grid-cols-3">
              <span className="block text-sm font-normal text-body col-span-2">
                <span className="font-semibold">REFERENCIA:</span>{" "}
                {product?.reference}
              </span>
              <span className="block text-sm font-normal text-body">
                <span className="font-semibold">CODE:</span> {product?.code}
              </span>
              <span className="block text-sm font-normal text-body">
                <span className="font-semibold">MARCA:</span> {product?.brand}
              </span>
            </div>

            <div className="mt-4 flex space-x-3 w-full flex-col items-start border-t border-border-200 border-opacity-60 pt-4 md:mt-6 md:pt-6">
              <span className="py-1 text-sm font-semibold capitalize text-heading">
                Categorias:
              </span>
              <div className="flex flex-row flex-wrap">
                {product?.categories?.map((item, key) => {
                  const parts = item.rec_name.split("/");
                  const contentName = parts.slice(1).join("/");

                  return (
                    <span className={style_btn_categories} key={key}>
                      {contentName}
                    </span>
                  );
                })}
              </div>
              <span className="py-1 text-sm font-semibold capitalize text-heading">
                Atributos:
              </span>
              <div className="flex flex-row flex-wrap">
                {attributes.map((item) => {
                  return (
                    <span key={item.key}>
                      {item.key}: {item.value}
                    </span>
                  );
                })}
                {/* {product?.attributes?.map((item, key) => {
                  const parts = item.rec_name.split("/");
                  const contentName = parts.slice(1).join("/");

                  return (
                    <span>item</span>
                  );
                })} */}
              </div>
            </div>
            {/* <div className="grid grid-cols-3 mt-3">
              <span className="block text-sm font-normal text-body">
                <span className="font-semibold">ID:</span> {product?.id}
              </span>
              <span className="block text-sm font-normal text-body">
                <span className="font-semibold">UOM:</span> {product?.uom?.name}
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </article>
  );
};

export default ItemProductContentModal;
