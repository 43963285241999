import React from "react";
import { FormattedMessage as FM } from "react-intl";

import PureModal from "components/Modals/PureModal";
import StdButton from "components/Buttons/StdButton";
import RestCardProduct from "./RestCardProduct";

const OptionsModal = ({ open, options, onClose, updateMsg }) => {
  if (!options) return;

  return (
    <PureModal open={open} onClose={() => onClose()}>
      <div className="flex p-4 mx-auto text-4xl text-stone-600 font-bold">
        <FM id="rest_order.options" key="rest_order.options" />
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 w-full my-3 sm:px-2">
        {options.map((pdt, idx) => (
          <RestCardProduct
            key={idx}
            product={pdt}
            updateMsg={updateMsg}
            optional={true}
          />
        ))}
      </div>
      <div className="flex p-4 mx-auto">
        <StdButton
          name="close"
          content="basic_modal.button_close"
          onClick={onClose}
          color="rose"
        />
      </div>
    </PureModal>
  );
};

export default OptionsModal;
