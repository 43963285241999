class PritingAPI {
  constructor(uri) {
    /*
    uri :: String > https://url:port
    */
    this.uri = uri;
    this.printAPI = this.printAPI.bind(this);
  }

  post(route, body) {
    let options = {};
    options.method = "POST";
    options.body = body;
    return this.printAPI(route, options);
  }

  async printAPI(route, opts) {
    /*
      route :: /my_endpoint
      opts :: {uri, method, body}
      response is Object returned with one attribute called data
    */

    let args = {
      method: opts.method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    if (opts.token) {
      args["headers"]["authorization"] = `Bearer ${opts.token}`;
    }

    if (opts.body) {
      args["body"] = JSON.stringify(opts.body, (k, v) =>
        v === undefined ? null : v,
      );
    } else {
      opts.body = {};
    }

    const url = `${this.uri}${route}`;
    // console.log("Request in fecthAPI to : ", url);
    let data, error, response;
    try {
      response = await fetch(url, args);
      data = JSON.parse(await response.text());
      if (response.status !== 200) {
        // return { res, error };
        error = data.detail;
        data = null;
      }
    } catch (errorMsg) {
      // For testing purposes
      console.log("API Error please check this: ", errorMsg);
      error = errorMsg;
    }
    return { data, error };
  }
}

export default PritingAPI;
