// Constants for components

export const ICON_COLORS = {
  slate: ["bg-slate-400", "text-slate-700"],
  blue: ["bg-white", "text-cyan-700"],
  yellow: ["bg-orange-200", "text-orange-700"],
  amber: ["bg-amber-200", "text-amber-700"],
  lime: ["bg-lime-200", "text-lime-700"],
  indigo: ["bg-violet-200", "text-violet-700"],
  pink: ["bg-pink-200", "text-pink-700"],
  rose: ["bg-white", "text-rose-600"],
  gray: ["bg-gray-200", "text-gray-700"],
  stone: ["bg-stone-200", "text-stone-700"],
};

export const BUTTON_COLORS = {
  slate: ["bg-slate-400", "text-slate-700"],
  blue: ["bg-blue-200", "text-blue-700"],
  sky: ["bg-sky-100", "text-sky-800"],
  yellow: ["bg-orange-200", "text-orange-700"],
  amber: ["bg-amber-200", "text-gray-700"],
  lime: ["bg-lime-200", "text-lime-700"],
  indigo: ["bg-violet-200", "text-violet-700"],
  pink: ["bg-pink-200", "text-pink-700"],
  rose: ["bg-rose-200", "text-rose-700"],
  gray: ["bg-gray-200", "text-gray-700"],
  stone: ["bg-stone-200", "text-stone-700"],
};

export const BUTTON_COLORS_DARK = {
  slate: ["bg-slate-400", "text-slate-700"],
  blue: ["bg-blue-500", "text-sky-100", "border-sky-400"],
  sky: ["bg-sky-500", "text-sky-100", "border-sky-700"],
  yellow: ["bg-orange-200", "text-orange-700"],
  amber: ["bg-amber-300", "text-amber-800"],
  lime: ["bg-lime-200", "text-lime-700"],
  indigo: ["bg-violet-200", "text-violet-700"],
  pink: ["bg-pink-200", "text-pink-700"],
  rose: ["bg-rose-200", "text-rose-700"],
  gray: ["bg-gray-200", "text-gray-700"],
  stone: ["bg-stone-200", "text-stone-700"],
};
