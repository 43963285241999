import React from "react";

import { classNames, colors } from "tools/ui";

const style =
  "h-9 rounded-md shadow-sm w-full text-right py-1 px-3 text-gray-700 border border-slate-300 focus:outline-none focus:border focus:border-cyan-500";

const PATTERN = /^-?\d*\.?\d{0,6}$/;
const formatter = Intl.NumberFormat("en-US");

function FloatCell(props) {
  const { data } = props;
  let percent;
  if (props.widget && props.widget === "percent") {
    percent = true;
  }
  let initValue = "";
  if (data.value) {
    if (percent) {
      initValue = data.value * 100;
    } else {
      initValue = formatter.format(data.value);
    }
  }
  if (!props.editable) {
    return initValue;
  }

  function onChange(event) {
    let _value = event.target.value;
    _value = _value.replaceAll(",", "");
    const validNumber = PATTERN.test(_value);
    if (!validNumber) {
      return;
    }
    data.value = event.target.value;
  }

  const onBlur = (event) => {
    let _value = event.target.value;
    if (data.value !== "") {
      _value = _value.replaceAll(",", "");
      let valueFloat = parseFloat(_value).toFixed(props.decimalPlaces || 0);
      if (percent) {
        valueFloat = valueFloat / 100;
      }
      props.onChange(props.name, Number(valueFloat), props.recordid);
    }
  };

  let _color = props.readOnly ? colors.readOnly : props.color ?? "bg-white";

  let moreTools;
  if (props.widget && props.widget === "percent") {
    moreTools = <p className="pl-2 my-auto text-stone-700 text-sm">%</p>;
  }

  return (
    <div className="flex">
      <input
        id={props.name}
        name={props.name}
        className={classNames(style, _color)}
        type="text"
        value={data}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={props.readOnly}
        placeholder={props.placeholder ?? ""}
      />
      {props.icon || null}
      {moreTools}
    </div>
  );
}

export default FloatCell;
