// Account Statement Model
import funcs from "tools/functions";

const stateColors = {
  draft: "amber",
  validated: "lime",
  posted: "sky",
};

const getFilters = () => {
  const terminal = funcs.getCookie();
  console.log("here is a cookie...", terminal);
  return {
    draft: [
      ["state", "=", "draft"],
      ["sale_device.code", "=", terminal.device_code],
      // ["shop", "=", session.shop],
    ],
  };
};

const getView = () => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    model: "account.statement",
    table_action: [],
    form_action: [],
    filters: getFilters,
    activeSearch: true,
    tags: {
      state: stateColors,
    },
    webfields: {
      name: { type: "char", readOnly: true },
      state: { type: "char", readOnly: true, tags: stateColors },
      turn: { type: "char", readOnly: true },
      journal: {
        type: "many2one",
        model: "account.journal",
        readonly: true,
      },
      company: {
        type: "many2one",
        model: "company.company",
      },
      sale_device: {
        type: "many2one",
        model: "sale.device",
      },
      end_balance: {
        type: "numeric",
        readonly: true,
      },
      start_balance: {
        type: "numeric",
        readonly: false,
      },
    },
    webtree: [
      { name: "name", width: "40%" },
      { name: "journal", width: "10%" },
      { name: "sale_device", width: "10%" },
      { name: "end_balance", width: "20%" },
      { name: "turn", width: "10%" },
      { name: "state", width: "10%", widget: "badge" },
    ],
    webform: [
      { name: "name" },
      { name: "journal" },
      { name: "sale_device" },
      { name: "end_balance" },
      { name: "state" },
      // { name: "unit_price_w_tax" },
      // { name: "amount_w_tax" },
    ],
  };

  // if (config && config.allow_discount) {
  //   DictCtxView["webfields"]["discount"] = {
  //     type: "numeric",
  //     withChange: onChangeDiscount,
  //   };
  //   DictCtxView["webtree"].insert(3, { name: "discount", width: "4%" });
  //   DictCtxView["webform"].insert(3, { name: "discount" });
  // }
  // if (config && config.allow_manual_pricing) {
  //   DictCtxView["webfields"]["unit_price"] = {
  //     type: "numeric",
  //     required: true,
  //     withChange: onChangeUnitPrice,
  //   };
  // }
  return DictCtxView;
};

export default { ctxView: getView };
