import React, { useState, useEffect } from "react";

import store from "store";
import proxy from "api/proxy";
import tools from "tools/common";
import dates from "tools/dates";

import DropdownField from "components/MeForm/DropdownField";
import BoardHeader from "components/Meta/BoardHeader";
import Button from "components/Buttons/StdButton";
import QuickTable from "components/Meta/QuickTable";

import ModalPayment from "./ModalPayment";
import ModalInfo from "./ModalInfo";
import ViewModel from "./Model";
import CardTotal from "./CardTotal";
import { signal } from "@preact/signals-react";
import { useWizardStore } from "store/wizardStore";

function DeliveryPanel() {
  const { record, reset, updateWizard } = useWizardStore();
  const { action, selectedRows } = record;
  const delivery = signal(record.delivery);
  const [modalInfo, setModalInfo] = useState(false);
  const [msg, setMsg] = useState("");
  const [model, setModel] = useState(ViewModel);
  const [environment, setEnvironment] = useState(null);
  const [modalPayment, setModalPayment] = useState(false);
  const [domain, setDomain] = useState(["create_date", "=", null]);
  const session = store.get("ctxSession");
  const yesterday = tools.dateAdd(new Date(), "days", -1);

  async function getConfigShop() {
    const fieldsRetail = ["shipment_date", "delivery_time"];
    const { data } = await proxy.search(
      "sale.device",
      ["shop", "=", session.shop],
      ["environment"],
    );

    if (data) {
      let _webtree = model.webtree.filter(function (field) {
        return !fieldsRetail.includes(field.name);
      });
      if (data[0].environment === "restaurant") {
        model.webtree = _webtree;
      }
      setEnvironment(data[0].environment);
    }
  }

  function handleAction(e, name) {
    if (name === "dispached" && !delivery.value) {
      setModalInfo(true);
      setMsg("missing_delivery");
      return;
    }
    let dom = [];
    if (environment !== "restaurant") {
      dom = [
        ["kind", "=", "delivery"],
        ["shipment_date", ">=", `${yesterday}`],
        ["shop", "=", session.shop],
      ];
    } else {
      dom = [
        ["kind", "=", "delivery"],
        ["sale_date", ">=", `${yesterday}`],
        ["shop", "=", session.shop],
      ];
    }
    if (name === "dispached") {
      if (environment !== "restaurant") {
        dom = dom.concat([
          ["state", "in", ["confirmed", "processing", "done"]],
          ["order_status", "in", ["commanded", "dispatched"]],
          // ["residual_amount", "==", "0"],
          ["delivery_party", "=", delivery.value.id],
        ]);
      } else {
        dom = dom.concat([
          ["state", "in", ["confirmed", "processing"]],
          ["order_status", "in", ["commanded", "dispatched"]],
          ["payment_method", "!=", "all_paid"],
          ["delivery_party", "=", delivery.value.id],
        ]);
      }
    } else {
      if (environment == "restaurant") {
        dom = dom.concat([
          ["state", "in", ["draft", "quotation", "confirmed", "processing"]],
          ["delivery_party", "=", null],
          ["order_status", "=", "commanded"],
          // ["invoice_type", "=", "P"],
        ]);
      } else {
        dom = dom.concat([
          [
            "state",
            "in",
            ["draft", "quotation", "confirmed", "processing", "done"],
          ],
          ["delivery_party", "=", null],
          ["order_status", "=", "commanded"],
          // ["invoice_type", "=", "P"],
        ]);
      }
    }
    const beforeAction = record.action;
    if (beforeAction !== name) {
      updateWizard({ selectedRows: new Map(), action: name });
      setDomain(dom);
    }
  }

  // function updateRecords() {
  //   setDomain([...domain]);
  // }

  function showModalInfo(msg) {
    setModalInfo(true);
    setMsg(msg);
  }

  function ordersByArrive() {
    if (record.selectedRows.size <= 0)
      return showModalInfo("no_invoices_selected");
    setModalPayment(true);
  }

  function clearOptions() {
    reset();
  }

  async function dispatchOrders() {
    if (!delivery.value) return showModalInfo("missing_delivery");
    if (selectedRows.size <= 0) return showModalInfo("no_invoices_selected");
    const invoicesDraft = Array.from(selectedRows.values()).filter(
      (inv) => inv.state === "draft",
    );
    if (invoicesDraft.length > 0) return showModalInfo("must_bill");

    const args = {
      sales: Array.from(selectedRows.keys()),
      dispatched: dates.fmtDatetime2Tryton(dates.getNow()),
      delivery_party: delivery.value.id,
    };
    const { data, error } = await proxy.methodCall({
      model: "sale.sale",
      method: "dispatch_delivery",
      args: [args],
    });
    clearOptions();
    console.log(data, error);
    // await setOrderStatus("dispatched");
  }

  function onClose(option) {
    if (option === "modalInfo") {
      setModalInfo(false);
      setMsg("");
    } else if (option === "modalPayment") {
      setModalPayment(false);
      clearOptions();
    }
  }

  async function searchDelivery(value) {
    const { data } = await proxy.search(
      "sale.shop",
      [["id", "=", session.shop]],
      ["id", "delivery_man"],
    );
    let dom = [["id", "in", data[0].delivery_man]];
    if (value && value !== "") {
      dom.push(["rec_name", "ilike", `%${value}%`]);
    }
    return dom;
  }

  const fieldDelivery = {
    name: "delivery",
    model: "sale.delivery_party",
    recSearch: searchDelivery,
    label: "delivery.panel.delivery",
  };

  useEffect(() => {
    getConfigShop();
  }, []);

  return (
    <div className="m-4 md:m-6 w-full">
      <BoardHeader ctxView={{ model: "delivery.panel" }} />
      <div className="flex flex-col md:flex-row gap-4 lg:gap-80 mb-6">
        <div className="w-full lg:w-2/4">
          <DropdownField
            field={fieldDelivery}
            attrs={{ level: "wizard" }}
            data={delivery}
          />
        </div>
        <div className="w-full lg:w-2/4">
          <CardTotal />
        </div>
      </div>
      <div className="flex flex-row gap-10">
        <Button
          size="w-60"
          style={"h-14"}
          content="delivery.panel.by_dispached"
          name="byDispached"
          color="amber"
          onClick={(event, name) => handleAction(event, name)}
        />
        <Button
          size="w-60"
          style={"h-14"}
          content="delivery.panel.dispached"
          color="blue"
          name={"dispached"}
          onClick={(event, name) => handleAction(event, name)}
        />
        {action === "byDispached" ? (
          <Button
            size="w-60"
            style={"h-14"}
            content="delivery.panel.send"
            color="green"
            onClick={dispatchOrders}
          />
        ) : action === "dispached" && environment === "restaurant" ? (
          <Button
            size="w-60"
            style={"h-14"}
            content="delivery.panel.confirm_send"
            color="green"
            onClick={ordersByArrive}
          />
        ) : (
          <></>
        )}
        <Button
          size="w-60"
          style={"h-14"}
          content="delivery.panel.close"
          color="slate"
          onClick={clearOptions}
        />
      </div>
      <div className={"my-6"}>
        {action && <QuickTable ctxView={model} domain={domain} />}
        <ModalInfo open={modalInfo} onClose={onClose} msg={msg} />
        {modalPayment && <ModalPayment open={modalPayment} onClose={onClose} />}
      </div>
    </div>
  );
}

export default DeliveryPanel;
