import React, { Fragment, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { signal } from "@preact/signals-react";
import tools from "tools/dates";

import proxy from "api/proxy";
import CharField from "components/MeForm/CharField";
import SelectionField from "components/MeForm/SelectionField";
import StdButton from "components/Buttons/StdButton";
import DatetimeField from "components/MeForm/DatetimeField";
import ModalMsg from "components/Modals/ModalMsg";
import IntegerField from "components/MeForm/IntegerField";
import SignatureCanvas from "react-signature-canvas";
import TextFieldGeocoding from "components/MeForm/TextFieldGeocoding";
import func from "tools/functions";
import { checkRequiredForm } from "tools/form";
import { TYPE_DOC, SUBDIVISION } from "./constants";
import { useWizardStore } from "store/wizardStore";
import BookingMap from "./BookingMap";
import { GOOGLE_MAP_API_KEY } from "env";

const STYLE_LABEL_ADDRESS = "bg-gray-100 rounded-md shadow-md  px-3 py-1";
const level = "wizard";
const first_name = signal("");
const last_name = signal("");
const type_document = signal("");
const doc_number = signal("");
const email = signal("");
const mobile = signal("");
const start_date = signal(false);
const lapse_time = signal(1);
const end_date = signal("");
const subdivision = signal("");
const isLoading = signal(false);
const btnDisabled = signal(false);
const post_code = signal("");
const city = signal("");
const suburb = signal("");
const address = signal("");
const openModal = signal(null);
const error = signal("");
const errorField = signal("");

const ATTRS = {
  level: level,
};

const storeRequired = [
  "first_name",
  "last_name",
  "doc_number",
  "email",
  "mobile",
  "type_document",
  "subdivision",
  "city",
  "suburb",
  // "address",
  "post_code",
  "start_date",
];

function FormAddRentalBooking(props) {
  const navigate = useNavigate();
  const signatureCanvasRef = useRef();

  const changeDate = (record, store) => {
    const { updateWizard, resetWizard } = useWizardStore.getState();

    let date = record.start_date;
    let value = record.lapse_time || 1;
    let _end_date = new Date(date);
    _end_date.setDate(_end_date.getDate() + value * 7);
    end_date.value = tools.fmtDate2Tryton(_end_date);
    const date_ = { end_date: _end_date };
    updateWizard({ ...date_ });
  };

  const fields = {
    firstNameField: {
      name: "first_name",
      label: "rental.booking.first_name",
      specialCharacters: true,
      type: "char",
      required: true,
    },
    lastNameField: {
      name: "last_name",
      label: "rental.booking.last_name",
      specialCharacters: true,
      type: "char",
      required: true,
    },
    required: true,
    docNumberField: {
      name: "doc_number",
      label: "rental.booking.doc_number",
      required: true,
    },
    typeNumberField: {
      name: "type_document",
      label: "rental.booking.type_document",
      options: TYPE_DOC,
      required: true,
    },

    emailField: {
      name: "email",
      label: "rental.booking.email",
      required: true,
    },
    mobileField: {
      name: "mobile",
      label: "rental.booking.mobile",
      required: true,
    },
    addressField: {
      name: "address",
      label: "rental.booking.address",
      required: true,
    },
    startDateField: {
      name: "start_date",
      label: "rental.booking.start_date",
      withChange: changeDate,
      required: true,
    },
    endDateField: {
      name: "end_date",
      label: "rental.booking.end_date",
      readOnly: true,
    },
    lapseTimeField: {
      name: "lapse_time",
      label: "rental.booking.lapse_time",
      readOnly: end_date.value ? false : true,
      withChange: changeDate,
    },
    subdivisionField: {
      name: "subdivision",
      label: "rental.booking.subdivision",
      options: SUBDIVISION,
      required: true,
    },
    cityField: {
      name: "city",
      label: "rental.booking.city",
      required: true,
      // readOnly: true,
    },
    suburbField: {
      name: "suburb",
      label: "rental.booking.suburb",
      required: true,
      // readOnly: true,
    },
    addressField: {
      name: "address",
      label: "rental.booking.address",
      required: true,
    },
    postField: {
      name: "post_code",
      label: "rental.booking.post_code",
      required: true,
      // readOnly: true,
    },
  };

  const cleanField = () => {
    first_name.value = "";
    last_name.value = "";
    type_document.value = "";
    doc_number.value = "";
    email.value = "";
    mobile.value = "";
    start_date.value = "";
    lapse_time.value = 1;
    end_date.value = "";
    subdivision.value = "";
    isLoading.value = false;
    btnDisabled.value = false;
    post_code.value = "";
    city.value = "";
    suburb.value = "";
    address.value = "";
    errorField.value = "";
    error.value = "";
  };
  async function acceptCreate() {
    const { store, resetWizard } = useWizardStore.getState();
    isLoading.value = true;
    var emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    var mobileRegex = /^[0-9]{10}$/;

    if (!emailRegex.test(email.value)) {
      error.value = "The email is not valid.";
      isLoading.value = false;
      return false;
    }

    if (!mobileRegex.test(mobile.value)) {
      error.value = "Mobile phone number is not valid.";
      isLoading.value = false;
      return false;
    }
    isLoading.value = true;
    if (Object.keys(store).length > 1) {
      const _store = func.recToTryton(store);
      const fieldsNames = ["id"];
      _store.state = "draft";
      _store.address = address.value;
      _store.subdivision = subdivision.value.id;
      _store.city = city.value;
      _store.suburb = suburb.value;
      _store.post_code = post_code.value;
      const validation = checkRequiredForm(_store, storeRequired);
      if (!validation) {
        errorField.value = "You need some fields to fill out.";
        isLoading.value = false;
        return false;
      }
      const { data: res, error } = await proxy.create(
        "rental.booking",
        _store,
        fieldsNames,
      );

      if (res) {
        errorField.value = "";
        const { data, error } = await proxy.methodInstance({
          model: "rental.booking",
          method: "send_email_confirmation",
          instance: res[0],
        });
        isLoading.value = false;
        // error.value = null;
        openModal.value = {
          msg: "Reservation Created!",
        };
        resetWizard();
        cleanField();
      } else {
        isLoading.value = false;
        openModal.value = {
          msg: "Error al crear reserva",
        };
      }
    }
  }

  function onCloseModal() {
    openModal.value = null;
    navigate(1);
  }
  const onChangeAddress = (field, value) => {
    post_code.value = value.post_code;
    city.value = value.city;
    suburb.value = value.suburb;
    address.value = value.address;
  };

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
  };

  const saveSignature = () => {
    const signatureDataURL = signatureCanvasRef.current.toDataURL();
    console.log(signatureDataURL);
  };

  const getAddress = (objAddress) => {
    console.log(objAddress, "asdasd");
    if (objAddress) {
      console.log("paso por aca", objAddress);
      post_code.value = objAddress.postcode;
      city.value = objAddress.city;
      suburb.value = objAddress.suburb;
      address.value = objAddress.fullAddress;
      subdivision.value = SUBDIVISION.find(
        (subdivision) => subdivision.cod === objAddress.state,
      );
    }
  };

  return (
    <form className="grid grid-cols-2 gap-5 w-full mt-20">
      {!props.id ? (
        <Fragment>
          <CharField
            field={fields.firstNameField}
            attrs={ATTRS}
            data={first_name}
          />
          <CharField
            field={fields.lastNameField}
            attrs={ATTRS}
            data={last_name}
          />
          <SelectionField
            field={fields.typeNumberField}
            attrs={ATTRS}
            data={type_document}
          />
          <CharField
            field={fields.docNumberField}
            attrs={ATTRS}
            data={doc_number}
          />
          <CharField field={fields.mobileField} attrs={ATTRS} data={mobile} />
          <CharField field={fields.emailField} attrs={ATTRS} data={email} />
          <p className="w-full col-span-2 text-gray-500">
            Now choose the number of weeks you want to rent and select the start
            day
          </p>
          <div className="col-span-2 md:flex gap-5 p-3 bg-gray-100 rounded-md">
            <DatetimeField
              field={fields.startDateField}
              attrs={ATTRS}
              data={start_date}
            />
            <div className="w-2/4">
              <IntegerField
                field={fields.lapseTimeField}
                attrs={ATTRS}
                data={lapse_time}
              />
            </div>
            <DatetimeField
              field={fields.endDateField}
              attrs={ATTRS}
              data={end_date}
            />
          </div>
          <div className="flex col-span-2 space-x-4">
            {subdivision.value && (
              <span className={STYLE_LABEL_ADDRESS}>
                <b>State: </b>
                {subdivision.value.cod}
              </span>
            )}
            {city.value && (
              <span className={STYLE_LABEL_ADDRESS}>
                <b>City: </b>
                {city.value}
              </span>
            )}
            {suburb.value && (
              <span className={STYLE_LABEL_ADDRESS}>
                <b>Suburb: </b>
                {suburb.value}
              </span>
            )}

            {post_code.value && (
              <span className={STYLE_LABEL_ADDRESS}>
                <b>Post code: </b>
                {post_code.value}
              </span>
            )}
            {address.value && (
              <span className={STYLE_LABEL_ADDRESS}>
                <b>Address: </b>
                {address.value}
              </span>
            )}
          </div>
          <div></div>
          <div className="grid grid-cols-3 col-span-2  gap-5 p-3 bg-gray-100 rounded-md z-0 relative">
            <p className="col-span-3 mt-2 text-gray-500">
              Add your location data
            </p>
            <SelectionField
              field={fields.subdivisionField}
              attrs={ATTRS}
              data={subdivision}
            />
            <div className="col-span-2">
              <BookingMap
                getAddress={getAddress}
                cod_country={"au"}
                api={GOOGLE_MAP_API_KEY}
              />
              {/* <CharField
                field={fields.addressField}
                attrs={ATTRS}
                data={address}
                className="col-span-3"
              /> */}
            </div>
            <CharField field={fields.cityField} attrs={ATTRS} data={city} />
            <CharField field={fields.suburbField} attrs={ATTRS} data={suburb} />
            <CharField
              field={fields.postField}
              attrs={ATTRS}
              data={post_code}
            />
          </div>
          <div className="w-full col-span-2">
            <label
              htmlFor="name"
              className=" text-gray-400 mb-2 flex justify-end"
            >
              <span>I authorize the processing of data</span>
              <input type="checkbox" className="ml-3" onChange={() => {}} />
            </label>
          </div>
          {error.value && !errorField.value && (
            <span className="grid py-2 gap-3 justify-center col-span-2 bg-red-300 w-full rounded-md px-20 text-red-800">
              {error.value}
            </span>
          )}
          {errorField.value && (
            <span className="grid py-2 gap-3 justify-center col-span-2 bg-red-300 w-full rounded-md px-20 text-red-800">
              {errorField.value}
            </span>
          )}
          <div className="grid py-5 gap-3 justify-center col-span-2">
            <StdButton
              key="add"
              color="blue"
              disabled={btnDisabled.value}
              loading={isLoading.value}
              onClick={acceptCreate}
              size="w-full"
              content="rental.service.appbooking"
            />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          {" "}
          <div className="border border-gray-400 p-3 col-span-2 mx-20">
            <SignatureCanvas
              ref={signatureCanvasRef}
              penColor="black"
              canvasProps={{
                width: 500,
                height: 100,
                className: "signature-canvas",
              }}
            />
            <button onClick={clearSignature} className="mr-5">
              Clean
            </button>
            <button onClick={saveSignature}>Add</button>
          </div>
          <div className="grid py-5 gap-3 justify-center col-span-2">
            <StdButton
              key="add"
              color="blue"
              disabled={btnDisabled}
              loading={isLoading}
              onClick={acceptCreate}
              size="w-full"
              content="rental.service.appbooking"
            />
          </div>
        </Fragment>
      )}

      {openModal.value && (
        <ModalMsg
          open={true}
          onClose={onCloseModal}
          buttons={["close"]}
          type="success"
          msg={openModal.value.msg}
        />
      )}
    </form>
  );
}

export default FormAddRentalBooking;
