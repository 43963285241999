import React, { useState } from "react";

import RestCardProduct from "./RestCardProduct";
import OptionsModal from "./OptionsModal";
import { useStoreRest } from "./storeRest";

function RestProducts({ category, updateMsg }) {
  const { products } = useStoreRest();
  const [openModal, setOpenModal] = useState(false);
  const [options, setOptions] = useState(null);

  if (!products[category.id]) return null;

  function closeModal() {
    setOptions(null);
    setOpenModal(false);
  }

  function handleModal(opts) {
    setOpenModal(true);
    setOptions(opts);
  }

  const _products = Array.from(products[category.id].values());
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 w-full my-3 sm:px-2">
      {_products.map((pdt, idx) => (
        <RestCardProduct
          key={idx}
          product={pdt}
          updateMsg={updateMsg}
          handleModal={handleModal}
          closeModal={closeModal}
        />
      ))}
      {options && (
        <OptionsModal
          open={openModal}
          options={options}
          onClose={closeModal}
          updateMsg={updateMsg}
        />
      )}
    </div>
  );
}

export default RestProducts;
