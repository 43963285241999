import React, { Fragment, useEffect } from "react";
import date from "date-and-time";
import { signal } from "@preact/signals-react";

import store from "store";
import SectionNoData from "components/Tools/SectionNoData";
import CartBooking from "./Components/CartBooking";
import ItemClassification from "./Components/ItemClassification";
import Header from "./Components/layout/Header";
import Footer from "./Components/layout/Footer";
import FilterBooking from "./Components/FilterBooking";
import Banner from "./Components/Banner";
import FormCustomer from "./Components/FormCustomer";
import { FormattedMessage as FM } from "react-intl";
import InformationPaymentBooking from "./InformationPaymentBooking";
import { Alert } from "components/Alerts/Alert";
import imgDefault from "../../assets/img/default-image.jpg";
import proxy from "api/proxy";
import dates from "tools/dates";
import { useWizardStore } from "store/wizardStore";
import hotels from "./infoHotels";
import "./Assets/style.css";
import LoadingIcon from "components/Tools/LoadingIcon";
// import PageExperience from "ext-apps/WebMelhous/pages/PageExperience";

const nights = signal(0);
const dataCompany = signal(null);
const dataChannel = signal(null);
const ratePlan = signal(null);
const msgData = signal("app.booking.msg_nodate");
const invoiceId = signal(null);
const view = signal("selectRoom");
const dataRooms = signal([]);
const selectClassification = signal([]);
const isLoading = signal(false);
const textAlert = signal("");
const showAlert = signal(false);

const WebBooking = (props) => {
  const ctxSession = store.get("ctxVirtualSession");
  const infoColors = hotels[ctxSession?.db];
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const dateStart = params.get("dateStart");
  const dateEnd = params.get("dateEnd");
  const invoice_id = params.get("id");
  const dateUrl = {
    arrival_date: dateStart,
    departure_date: dateEnd,
    invoice_id: invoice_id,
  };
  if (invoice_id) {
    invoiceId.value = invoice_id;
  }

  
  const onChange = (name, value) => {
    const { store } = useWizardStore.getState();
    let _wizStore = { ...store };
    _wizStore[name] = value;
    let _nights;
    if (_wizStore.arrival_date && _wizStore.departure_date) {
    
      if (_wizStore.arrival_date >= _wizStore.departure_date) {
        alert(true, "app.booking.alert_filter_date", "");
        return false;
      }
      showAlert.value = false;
      _nights = getNights(_wizStore);
      fillCategories(_wizStore);
    } else {
      _nights = 0;
    }
    nights.value = _nights;
  };

  const fillCategories = async (store) => {
    isLoading.value = true;
    const { data } = await proxy.methodCall({
      model: "hotel.room",
      method: "available_for_web",
      args: [store.arrival_date, store.departure_date, null],
      kwargs: {},
    });

    if (data?.length == 0) {
      msgData.value = "app.booking.msg_nodata";
      return false;
    }

    dataRooms.value = data;
    const mapRecs = new Map();
    isLoading.value = false;
    data.forEach((cat) => {
      mapRecs.set(cat.id, cat);
    });
  };

  const getNights = (wizStore) => {
    const arrival_date = dates.getTrytonDate2Js(wizStore.arrival_date);
    const departure_date = dates.getTrytonDate2Js(wizStore.departure_date);
    const res = date.subtract(departure_date, arrival_date).toDays();
    return res;
  };
  const handleSelectClassification = (item) => {
    const _item = { ...item };
    if (Array.isArray(selectClassification.value)) {
      selectClassification.value = [...selectClassification.value, _item];
    } else {
      selectClassification.value = [_item];
    }
  };

  const handleRemoveItem = (itemToRemove) => {
    const { store, updateWizard } = useWizardStore.getState();

    const updatedClassification = selectClassification.value.filter(
      (item) => item !== itemToRemove,
    );

    selectClassification.value = updatedClassification;
    if (updatedClassification.length == 0) {
      const _store = { ...store };
      delete _store.ratePlan;
      updateWizard({ ratePlan: null });
    }
  };

  const handleRemoveAllItem = () => {
    selectClassification.value = [];
    dataRooms.value = [];
  };

  const handlePaymentView = () => {
    if (selectClassification.value.length == 0) {
      alert(true, "app.booking.alert_select_room", "");
    } else {
      view.value = "formCustomer";
    }
  };

  const dataInfoCompany = async () => {
    const { data } = await proxy.search(
      "hotel.configuration",
      ["id", "=", ctxSession.company],
      [
        "price_list.meal_plan",
        "payment_term",
        "company.logo",
        "company.logo_link",
        "children_policy",
      ],
    );
    dataCompany.value = { ...data[0], ...infoColors };
  };

  const getChannelWeb = async () => {
    const { store, updateWizard } = useWizardStore.getState();
    const dom = [["kind", "=", "web"]];
    const fields = ["id"];
    const { data: dataRate } = await proxy.search(
      "hotel.rate_plan",
      dom,
      fields,
      1,
    );
    let _ratePlan;
    if (dataRate) {
      _ratePlan = dataRate[0];
    }
    const { data } = await proxy.search(
      "hotel.channel",
      ["kind", "=", "web"],
      ["name", "rate_plan"],
    );
    dataChannel.value = data[0];
    // updateWizard({ ...store, currentRatePlan: _ratePlan });
  };

  const alert = (show, text) => {
    showAlert.value = show;
    textAlert.value = text;
  };

  if (dateStart && dateEnd) {
    fillCategories(dateUrl);
    const _nights = getNights(dateUrl);
    nights.value = _nights;
  }
  useEffect(() => {
    dataInfoCompany();
    getChannelWeb();
  }, []);

  return (
    <div className="min-h-screen relative max-w-full">
      <Header data={dataCompany} />
      {!invoiceId.value ? (
        <Fragment>
          <Banner colors={infoColors}>
            <FilterBooking
              onChange={onChange}
              nights={nights}
              mode="fullWidth"
              dateUrl={dateUrl}
              dates={{ dateStart: dateStart, dateEnd: dateEnd }}
              selectClassification={selectClassification}
            />
          </Banner>
          <div className="flex md:flex-row flex-col-reverse py-10  items-start  relative  container mx-auto md:mt-20">
            <div className="md:px-14 md:w-[75%] w-full ">
              <Alert
                color={`${infoColors?.colorPrimary} ${
                  infoColors?.colorTextPrimary || "text-white"
                }`}
                text={
                  "Un momento estamos buscando el mejor precio para tu acomodación. "
                }
                show={isLoading.value}
                onClose={() => null}
              >
                <LoadingIcon />
              </Alert>
              {view == "selectRoom" && (
                <Fragment>
                  <Alert
                    color="bg-red-300 text-red-900"
                    text={textAlert.value}
                    show={showAlert.value}
                    onClose={() => (showAlert.value = false)}
                  />
                  {dataRooms.value.length == 0 && isLoading.value == false && (
                    <SectionNoData
                      text={<FM id={msgData.value} />}
                      colors={infoColors}
                    />
                  )}

                  <ItemClassification
                    dataRooms={dataRooms.value}
                    handleSelectClassification={handleSelectClassification}
                    handlePaymentView={handlePaymentView}
                    imgDefault={imgDefault}
                    colors={infoColors}
                    data={dataCompany.value}
                  />
                </Fragment>
              )}

              {view == "formCustomer" && (
                <FormCustomer
                  classification={selectClassification.value}
                  reset={handleRemoveAllItem}
                  data={dataCompany.value}
                  dataChannel={dataChannel.value}
                  nights={nights.value}
                  handleView={() => (view.value = "selectRoom")}
                  colors={infoColors}
                />
              )}
            </div>
            <CartBooking
              selectClassification={selectClassification.value}
              onRemoveItem={handleRemoveItem}
              onRemoveAllItem={handleRemoveAllItem}
              nights={nights.value}
              handlePaymentView={handlePaymentView}
              colors={infoColors}
            />
          </div>
        </Fragment>
      ) : (
        <InformationPaymentBooking invoiceId={invoiceId} />
      )}

      {/* <div className="container mx-auto pb-5 px-4">
        <PageExperience />
      </div> */}

      <div className="h-12 md:hidden" />
      <Footer colors={infoColors} />
    </div>
  );
};

// <Footer />
export default WebBooking;
