import date from "date-and-time";

import dates from "tools/dates";
import modelGuest from "./Guest";
import modelCharge from "./Charge";
import modelOccupancy from "./Occupancy";

const getName = (record) => {
  return record.booking;
};

function visibleCheck(fieldName, record) {
  let res = false;
  const { registration_state } = record;
  if (fieldName === "check_in" && registration_state === "pending") {
    res = true;
  } else if (fieldName === "check_out" && registration_state === "check_in") {
    res = true;
  }
  return res;
}

function accessDelete(rec) {
  if (["check_in", "check_out"].includes(rec.registration_state)) {
    return false;
  }
  return true;
}

function getNights(arrival, departure) {
  let arrival_date = arrival;
  let departure_date = departure;
  if (typeof arrival === "string") {
    arrival_date = dates.getTrytonDate2Js(arrival);
  }
  if (typeof departure_date === "string") {
    departure_date = dates.getTrytonDate2Js(departure);
  }
  return date.subtract(departure_date, arrival_date).toDays();
}

const withChangeProduct = (record, store) => {
  if (record.product) {
    const product = record.product;
    let uom = product["sale_uom."];
    let unit_price = product.sale_price_taxed;
    unit_price = parseFloat(unit_price.toString());
    store.unit_price = unit_price;
    store.uom = uom.id;
    record.unit_price = unit_price;
    record.unit_price_w_tax = record.product.sale_price_taxed;
    record.uom = uom;
  }
};

const withChangeDate = (record, store) => {
  const { arrival_date, departure_date, unit_price_w_tax } = record;
  if (arrival_date && departure_date) {
    const nights = getNights(arrival_date, departure_date);
    record.nights_quantity = nights;
    store.nights_quantity = nights;
    if (unit_price_w_tax) {
      record.total_amount = (nights * unit_price_w_tax).toFixed(2);
    }
  }
};

const getView = () => {
  let DictCtxView = {
    model: "hotel.folio",
    row_selectable: false,
    form_action_add: "modal",
    form_action: ["add", "edit", "delete"], // options: ['save', 'delete']
    table_action: ["add", "edit"], // options: ['open', 'delete', 'edit', 'add']
    activeSearch: true,
    form_rec_name: getName,
    target: "booking",
    autoSave: true,
    access: {
      delete: accessDelete,
    },
    title: { field: "booking", component: "title" },
    reports: [
      {
        name: "hotel.folio.registration_card",
        description: "Tarjeta de Registro",
        send_print: true,
        // send_email: "send_email",
      },
    ],
    webfields: {
      registration_card: { type: "char", readOnly: true },
      main_guest: {
        type: "many2one",
        model: "party.party",
        readOnly: true,
      },
      booking: {
        type: "many2one",
        model: "hotel.booking",
        readOnly: true,
      },
      arrival_date: {
        type: "date",
        required: true,
        withChange: withChangeDate,
        readOnly: {
          registration_state: ["check_in", "check_out"],
        },
      },
      departure_date: {
        type: "date",
        readOnly: {
          registration_state: ["check_in", "check_out"],
        },
        required: true,
        withChange: withChangeDate,
      },
      unit_price: {
        type: "numeric",
        required: true,
        decimalPlaces: 2,
        readOnly: {
          registration_state: ["check_out", "check_in"],
        },
      },
      pending_total: { type: "numeric", readOnly: true },
      nights_quantity: { type: "numeric", readOnly: true },
      room: {
        type: "many2one",
        model: "hotel.room",
        required: true,
        recSearch: () => [],
        readOnly: {
          registration_state: ["check_out", "check_in"],
        },
      },
      product: {
        type: "many2one",
        model: "product.product",
        recSearch: () => [["kind", "=", "accommodation"]],
        required: true,
        readOnly: {
          registration_state: ["check_in", "check_out"],
        },
        withChange: withChangeProduct,
        attrs: [
          "id",
          "list_price",
          "name",
          "sale_price_taxed",
          "sale_uom.rec_name",
        ],
      },
      channel: {
        type: "many2one",
        model: "hotel.channel",
        readOnly: true,
        images: { targetField: "code", source: {} },
      },
      registration_state: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "pending",
      },
      number: {
        type: "char",
        readOnly: true,
      },
      meal_plan: {
        type: "many2one",
        readOnly: true,
        translate: true,
      },
      unit_price_w_tax: {
        type: "numeric",
        readOnly: true,
        decimalPlaces: 0,
      },
      guests: {
        type: "one2many",
        model: "hotel.folio.guest",
        ctxView: modelGuest.ctxView(),
        readOnly: {
          registration_state: ["check_in", "check_out"],
        },
      },
      charges: {
        type: "one2many",
        model: "hotel.folio.charge",
        ctxView: modelCharge.ctxView(),
        readOnly: {
          registration_state: ["check_in", "check_out"],
        },
      },
      occupancy: {
        type: "one2many",
        model: "hotel.folio.occupancy",
        ctxView: modelOccupancy.ctxView(),
        readOnly: {
          registration_state: ["check_out"],
        },
      },
      total_amount: { type: "numeric", readOnly: true },
      notes: {
        type: "text",
        readOnly: false,
      },
      num_adults: {
        type: "integer",
        readOnly: ["check_in", "check_out"],
        default: 0,
      },
      num_children: {
        type: "integer",
        readOnly: ["check_in", "check_out"],
        default: 0,
      },
      pax: {
        type: "integer",
        readOnly: true,
        default: 0,
      },
      group: {
        type: "boolean",
        readOnly: true,
        translate: true,
      },
      payment_status: {
        type: "char",
        readOnly: true,
        translate: true,
        depends: ["charges"],
      },
      check_in: {
        type: "button",
        button_method: "check_in",
        visible: visibleCheck,
        onSuccessMsg: "Check In exitoso!",
        color: "blue",
      },
      check_out: {
        type: "button",
        button_method: "check_out",
        visible: visibleCheck,
        onSuccessMsg: "Check Out exitoso!",
        color: "blue",
      },
    },
    webtree: [
      { name: "room", width: "15%" },
      { name: "product", width: "15%" },
      { name: "arrival_date", width: "10%" },
      { name: "departure_date", width: "10%" },
      { name: "unit_price", width: "10%" },
      { name: "unit_price_w_tax", width: "10%" },
      { name: "nights_quantity", width: "10%" },
      { name: "registration_state", width: "10%" },
    ],
    webform: [
      { name: "product" },
      {
        id: "booking",
        grid: [{ name: "booking" }, { name: "number" }],
        size: [1, 2],
      },

      { name: "room" },
      { name: "main_guest" },
      {
        id: "dates",
        grid: [{ name: "arrival_date" }, { name: "departure_date" }],
        size: [1, 2],
      },
      {
        id: "quantities",
        grid: [{ name: "nights_quantity" }, { name: "pax" }],
        size: [1, 2],
      },
      { name: "channel" },
      {
        id: "pax",
        grid: [{ name: "num_adults" }, { name: "num_children" }],
        size: [1, 2],
      },
      {
        id: "prices",
        grid: [{ name: "unit_price" }, { name: "unit_price_w_tax" }],
        size: [1, 2],
      },
      {
        id: "amounts",
        grid: [{ name: "total_amount" }, { name: "pending_total" }],
        size: [1, 2],
      },
      { name: "meal_plan" },
      {
        id: "states",
        grid: [{ name: "registration_state" }, { name: "payment_status" }],
        size: [1, 2],
      },
      { name: "registration_card" },
      { name: "notes" },
      {
        id: "guests_charges",
        grid: [
          {
            name: "guests",
            widget: "button_modal",
            color: "sky",
            icon: "fi fi-rr-users",
          },
          {
            name: "charges",
            widget: "button_modal",
            color: "amber",
            icon: "fi fi-rr-ticket",
          },
          {
            name: "occupancy",
            widget: "button_modal",
            color: "lime",
            icon: "fi fi-rr-file-invoice",
          },
        ],
        size: [1, 3],
        span: "md:col-span-1",
      },
      {
        id: "buttons",
        grid: [{ name: "check_in" }, { name: "check_out" }],
        size: [1, 2],
      },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
