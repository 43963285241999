import React from "react";
import { FormattedMessage as FM } from "react-intl";

function Line() {
  return (
    <div className="w-full md:col-span-2 border-t border-stone-200 my-2" />
  );
}

/**
 * Component Divider.
 *
 * @param {object} props - component props
 * @param {string} props.text - text show in line
 * @return {Component} - Divider component
 */
function Divider(props) {
  return props.field && props.field.text ? (
    <div className="w-full md:col-span-2">
      <div className="flex justify-between items-center align-middle">
        <h3 className="font-semibold text-slate-500 text-lg">
          <FM id={props.field.text} />
        </h3>
        {props.rightButtons}
      </div>
      <Line />
    </div>
  ) : (
    <Line />
  );
}

export default Divider;
