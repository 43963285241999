import React from "react";

import { classNames } from "tools/ui";

function PopupContentSection({ label1, value1, label2, value2 }) {
  let span = "";
  if (!label2) {
    span = "col-span-2";
  }
  return (
    <div className="grid grid-cols-2 pt-2">
      <div className={classNames("text-left px-2", span)}>
        <p className="text-base text-sky-600">{label1}</p>
        <p className="text-base font-bold text-zinc-600">{value1}</p>
      </div>
      {label2 && (
        <div className="text-left px-2">
          <p className="text-base text-sky-600">{label2}</p>
          <p className="text-base font-bold text-zinc-600">{value2}</p>
        </div>
      )}
    </div>
  );
}

export default PopupContentSection;
