import React from "react";

import Menu from "./Menu";
const widthScreen = window.innerWidth;
console.log("tamaño", widthScreen);

function Sidebar({ open, routes, handleSidebar }) {
  const style =
    "duration-500 bg-gray-800 min-h-screen transform duration-200 z-10";

  return (
    <div
      className={`${
        open
          ? "w-16 hidden lg:block z-10"
          : // : "w-full h-screen sm:w-40 lg:w-48 fixed sm:static z-10  flex items-center sm:block "
            "w-screen h-screen sm:w-40 lg:w-48 fixed sm:static z-10  flex items-start md:items-center sm:block overflow-y-auto md:overflow-y-visible pb-20 md:pb-0"
      } ${style}`}
    >
      <Menu
        dashMenu={routes}
        open={open}
        handleSidebar={handleSidebar}
        widthScreen={widthScreen}
      />
    </div>
  );
}

export default Sidebar;
