import React from "react";

import PureModal from "components/Modals/PureModal";
import QuickForm from "components/Meta/QuickForm";

/**
 *
 * @param {object} props - component props
 * @param {object} props.ctxView - object for render record
 * @param {object} props.onClose - object record to show
 * @param {boolean} props.open - true or false
 * @param {boolean} props.onSave - true or false
 * @return {Component} - Modal Form for show form record
 */
function ModalForm({
  ctxView,
  onClose,
  parentRec,
  open,
  level,
  mode,
  fieldName,
}) {
  return (
    <PureModal id="modal-form" open={open} onClose={onClose}>
      <QuickForm
        kind="modal"
        level={level}
        mode={mode}
        ctxView={ctxView}
        onClose={onClose}
        parentRec={parentRec}
        fieldName={fieldName}
      />
    </PureModal>
  );
}

export default ModalForm;
