import React, { useState } from "react";

import Table from "components/MeTable/Table";
import BodyTable from "components/MeTable/BodyTable";
import TableHeader from "components/MeTable/TableHeader";
import Loading from "components/Tools/Loading";
import Pagination from "./Pagination";
import ModalForm from "components/Modals/ModalForm";
import mainStore from "store/mainStore";
import { useWizardStore } from "store/wizardStore";

/**
 * Component for create table with body and header
 *
 * @param {object} props - component props
 * @param {boolean} props.sortable - boolean define if column is sortable
 * @param {Objetc} props.ctxView - define model view
 * @param {Array} props.records - Array of records to fill in
 * @param {function} props.updateRecords - Function for update records parent
 * @param {function} props.onClickRow - Function for handle event onClickRow
 * @param {function} props.onClickCell - Function for handle event onClickCell
 * @param {boolean} props.paginate - Define if table is paginate
 * @return {Component} - table component
 */
function FullTable(props) {
  const { ctxView, records, limit, isLoading, reloadRecords, offset, level } =
    props;
  const { model } = ctxView;
  const [openModalForm, setOpenModalForm] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const main = mainStore();
  const { record: recordWizard, updateWizard } = useWizardStore();
  const selectable = ctxView.selectable || props.selectable;
  const { record, setRecord } = main;

  function selectRows(selectAll) {
    let selectedRows_ = recordWizard?.selectedRows ?? new Map();

    if (records) {
      if (selectAll) {
        if (selectable === "multi") {
          if (Array.isArray(records)) {
            records.map((item) => {
              selectedRows_.set(item.id, item);
            });
          } else {
            selectedRows_ = new Map(records);
          }
        }
      } else {
        selectedRows_ = new Map();
      }

      updateWizard({ selectedRows: selectedRows_ });
    }
  }

  function handleSort() {
    // Add sort function
  }

  function onChangePage(arrow, page) {
    let _activePage = activePage;
    if (page) {
      _activePage = page;
    } else if (arrow === "next") {
      _activePage = activePage + 1;
    } else {
      _activePage = activePage - 1;
    }
    props.onChangePage(_activePage);
    setActivePage(_activePage);
  }

  async function onActionButton(event, action, _record) {
    if (action !== "delete") {
      await setRecord(_record.id, model, ctxView);
    }
    if (ctxView.table_action.includes("open")) {
      await setRecord(_record.id, model, ctxView);
      setOpenModalForm(true);
    } else {
      props.onChangeView(event, action, _record);
    }
  }

  if (isLoading) return <Loading />;

  let mode;
  if (record && record.id > 0) {
    mode = "edit";
  }

  return (
    <div
      id="fulltable"
      className="w-full overflow-x-scroll xl:overflow-visible max-h-fit block ring-1 ring-black ring-opacity-5 md:rounded-lg"
    >
      <Table>
        <TableHeader
          ctxView={ctxView}
          handleSort={handleSort}
          level={level}
          selectRows={selectRows}
          selectable={selectable}
        />
        <BodyTable
          {...props}
          level={level}
          ctxView={ctxView}
          selectable={selectable}
          records={records}
          onActionButton={onActionButton}
          reloadRecords={reloadRecords}
        />
      </Table>
      {ctxView.pagination && (
        <Pagination
          lenRecords={records?.length}
          limit={limit}
          offset={offset}
          ctxView={ctxView}
          onChangePage={onChangePage}
        />
      )}
      {openModalForm && record && (
        <ModalForm
          ctxView={ctxView}
          onClose={() => setOpenModalForm(false)}
          open={openModalForm}
          level="main"
          mode={mode}
        />
      )}
    </div>
  );
}

export default FullTable;
