import React, { useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import FilterTable from "components/MeTable/FilterTable";
import InputSearchTable from "components/MeTable/InputSearchTable";
import SectionRowTags from "components/Tools/SectionRowTags";
import common from "tools/common";

function SectionHeader(props) {
  const { ctxView, countRecords, searchables, activeSearch } = props;
  const [value, setValue] = useState("");

  function makeDomain(textMap) {
    let arrText = textMap.split(" ");
    let domain = [];
    for (const tw of arrText) {
      let clause = ["OR"];
      for (const field of searchables) {
        let field_name = field.name;
        if (field.type === "many2one") {
          field_name = field_name + ".rec_name";
        }
        if (field.type === "date") {
          const n = common.getTrytonDate2Js(tw);
          if (!isNaN(n)) {
            clause.push([field_name, "=", tw]);
          }
        } else {
          clause.push([field_name, "ilike", `%${tw}%`]);
        }
      }
      domain.push(clause);
    }
    return domain;
  }

  function onClickSearch(filterTag) {
    let dom = [];
    if (filterTag) {
      dom = filterTag;
    } else if (searchables && searchables.length > 0 && value !== "") {
      dom = makeDomain(value);
    }
    if (props.domain) {
      dom.push(props.domain[0]);
    }

    if (dom.length > 0) {
      props.handleFilter(dom);
    }
  }

  function searchClicked(event) {
    const _event = event.nativeEvent;
    // keyCode = 13 is Enter
    if (event.keyCode === 13 || (_event && _event.type === "click")) {
      onClickSearch();
    }
  }
  return (
    <div className="grid md:flex py-3">
      <div className="flex w-full md:w-1/4 my-2">
        {ctxView.filters && (
          <FilterTable
            {...props}
            ctxView={ctxView}
            onClickFilter={onClickSearch}
            countRecords={countRecords}
          />
        )}
        <div className="flex my-auto">
          <div className="bg-stone-700 my-auto mx-3 rounded-full flex items-center justify-center text-white text-sm font-semibold h-9 w-9">
            {countRecords}
          </div>
        </div>
      </div>
      {activeSearch && (
        <div className="flex w-full md:w-1/4 my-auto relative col-span-6 md:col-span-2">
          <InputSearchTable
            value={value}
            handleValue={setValue}
            searchClicked={searchClicked}
          />
          <button
            onClick={searchClicked}
            className="flex bg-stone-700 hover:shadow-md rounded-r-lg px-3 my-1 text-xl items-center text-center hover:opacity-90 disabled:opacity-50 w-12 h-10 absolute right-0"
          >
            <MagnifyingGlassIcon className="h-6 w-6 text-white" />
          </button>
        </div>
      )}

      {ctxView.tags && (
        <div className="flex w-full md:w-1/2">
          <SectionRowTags ctxView={ctxView} />
        </div>
      )}
    </div>
  );
}

export default SectionHeader;
