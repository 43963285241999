// Surveillance Schedule

const getView = () => {
  let ctxView = {
    model: "surveillance.schedule",
    selectable: "one", // options: multi - one - false
    activeSearch: true,
    filters: null,
    table_action: [],
    webfields: {
      location: {
        type: "many2one",
        model: "surveillance.location",
        domain: [["state", "=", "rosa"]],
        readOnly: true,
        required: true,
        searchable: true,
      },
      period: {
        type: "many2one",
        model: "account.period",
        readOnly: true,
        required: true,
      },
      state: {
        type: "char",
        readOnly: true,
        translate: true,
      },
    },
    webtree: [
      { name: "location", width: "40%" },
      { name: "period", width: "40%" },
      { name: "state", width: "20%" },
    ],
    webform: [{ name: "period" }, { name: "location" }, { name: "state" }],
  };
  return ctxView;
};

export default { ctxView: getView };
