import env from "../env";

const api = env.api;

function getDatabase() {
  const [, , db] = location.pathname.split("/");
  return db;
}

async function fetchAPI(opts, database) {
  /*
    api :: https://url:port/api
    opts :: {uri, method, body}
    response is Object returned with one attribute called data
  */

  let response, route;
  let args = {
    method: opts.method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };

  const session = opts?.body?.context?.session;
  if (session) {
    args["headers"]["Authorization"] = `Bearer ${session}`;
  }

  if (opts.body) {
    args["body"] = JSON.stringify(opts.body, (k, v) =>
      v === undefined ? null : v,
    );
  }
  if (!database) {
    database = opts?.body?.context?.db ?? getDatabase();
  }
  route = `${api}${database}${opts.uri}`;
  // console.log("route.......", route); // For testing purposes

  let data = null;
  let error = null;
  try {
    response = await fetch(route, args);
    data = JSON.parse(await response.text());
    if (response.status !== 200) {
      // return { res, error };
      error = data.detail;
      data = null;
    }
    // throw new Error(values.detail.error);
    // return { res: null, error: res.detail.error };
  } catch (errorMsg) {
    // For testing purposes
    console.log("API Error please check this: ", errorMsg);
    error = errorMsg;
    // throw errorMsg;
  }
  return { data, error };
}

export default fetchAPI;
