import date from "date-and-time";

import dates from "tools/dates";

const getName = (record) => {
  return record.number;
};

// function visibleCheck(fieldName, record) {
//   let res = false;
//   const { registration_state } = record;
//   if (fieldName === "check_in" && registration_state === "pending") {
//     res = true;
//   } else if (fieldName === "check_out" && registration_state === "check_in") {
//     res = true;
//   }
//   return res;
// }
//
// function accessDelete(rec) {
//   if (["check_in", "check_out"].includes(rec.registration_state)) {
//     return false;
//   }
//   return true;
// }
//
// function getNights(arrival, departure) {
//   let arrival_date = arrival;
//   let departure_date = departure;
//   if (typeof arrival === "string") {
//     arrival_date = dates.getTrytonDate2Js(arrival);
//   }
//   if (typeof departure_date === "string") {
//     departure_date = dates.getTrytonDate2Js(departure);
//   }
//   return date.subtract(departure_date, arrival_date).toDays();
// }
//
// const withChangeProduct = (record, store) => {
//   if (record.product) {
//     const product = record.product;
//     let uom = product["sale_uom."];
//     let unit_price = product.sale_price_taxed;
//     unit_price = parseFloat(unit_price.toString());
//     store.unit_price = unit_price;
//     store.uom = uom.id;
//     record.unit_price = unit_price;
//     record.unit_price_w_tax = record.product.sale_price_taxed;
//     record.uom = uom;
//   }
// };
//
// const withChangeDate = (record, store) => {
//   const { arrival_date, departure_date, unit_price_w_tax } = record;
//   if (arrival_date && departure_date) {
//     const nights = getNights(arrival_date, departure_date);
//     record.nights_quantity = nights;
//     store.nights_quantity = nights;
//     if (unit_price_w_tax) {
//       record.total_amount = (nights * unit_price_w_tax).toFixed(2);
//     }
//   }
// };

const getView = () => {
  let DictCtxView = {
    model: "account.statement.line",
    row_selectable: false,
    form_action: ["edit"], // options: ['save', 'delete']
    table_action: ["edit"], // options: ['open', 'delete', 'edit', 'add']
    // activeSearch: true,
    form_rec_name: getName,
    target: "booking",
    autoSave: true,
    // access: {
    //   delete: accessDelete,
    // },
    // title: { field: "booking", component: "title" },
    webfields: {
      statement: {
        type: "many2one",
        model: "account.statement",
        readOnly: true,
      },
      amount: { type: "numeric", readOnly: true },
      // booking: {
      //   type: "many2one",
      //   model: "hotel.booking",
      //   readOnly: true,
      // },
      // journal: {
      //   type: "many2one",
      //   model: "account.statement.journal",
      //   readOnly: true,
      // },
      date: {
        type: "date",
        readOnly: true,
      },
      number: {
        type: "char",
        readOnly: true,
      },
      party: {
        type: "many2one",
        model: "party.party",
        readOnly: true,
        attrs: ["id", "name"],
      },
    },
    webtree: [
      { name: "number", width: "15%" },
      { name: "date", width: "15%" },
      { name: "statement", width: "30%" },
      { name: "amount", width: "10%" },
      { name: "party", width: "10%" },
    ],
    webform: [
      { name: "statement" },
      {
        id: "number",
        grid: [{ name: "date" }, { name: "number" }],
        size: [1, 2],
      },
      { name: "amount" },
      { name: "party" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
