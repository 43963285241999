import React, { useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import BasicModal from "components/Modals/BasicModal";

const base64toBlob = (base64) => {
  const pdfContentType = "application/pdf";
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = base64.substr(
    `data:${pdfContentType};base64,`.length,
  );

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  return new Blob([out], { type: pdfContentType });
};

export default function AllPage(props) {
  const [open, setOpen] = useState(true);
  const base64 = "data:application/pdf;base64," + props.pdf;

  const url = URL.createObjectURL(base64toBlob(base64));

  function handleClose() {
    setOpen(false);
    props.onClose();
  }

  return (
    <BasicModal
      title={props.fileName}
      height="max-h-full overflow-y-scroll"
      width="w-5/6"
      open={open}
      onClose={handleClose}
    >
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        {url && <Viewer fileUrl={url} />}
      </Worker>
    </BasicModal>
  );
}
