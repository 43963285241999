import React from "react";

import Board from "components/Meta/Board";
import InventoryView from "./ModelInventory";

const Inventory = (props) => {
  const { config } = props;
  const ctxView = InventoryView.ctxView(config);

  return <Board ctxView={ctxView} />;
};

export default Inventory;
