import React from "react";
import { CalendarDaysIcon } from "@heroicons/react/20/solid";

const SectionNoData = ({ text = null, children, colors }) => {
  let bgColor = colors?.colorPrimary || "bg-blue-presik";

  return (
    <div className="mx-2">
      <div
        className={`md:h-32 h-20 w-full  shadow-md rounded-md mt-16 md:mt-0 my-5 p-2 ${bgColor}`}
      >
        <div className=" border px-2 border-dashed flex items-center justify-center   w-full h-full space-x-3 md:space-x-7">
          <CalendarDaysIcon className="w-10 md:w-16 text-yellow-300" />
          <div>
            <h2 className="  text-white text-left md:text-3xl text-md flex flex-col">
              {text}
            </h2>
            {children && <div className="mt-2">{children}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionNoData;
