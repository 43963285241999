import React from "react";
import Carousel from "nuka-carousel";

import "./MeCarousel.css";

function MeCarousel(props) {
  // const autoplayReverse={true}
  // slideOffset={20}
  // cellSpacing="10"
  // framePadding="10px 20px"
  // width="100%"
  // wrapAround={true}
  return (
    <Carousel
      scrollMode="remainder"
      speed="3000"
      withoutControls={true}
      autoplay={false}
      cellAlign="center"
      slideIndex={0}
      {...props}
    >
      {props.children}
    </Carousel>
  );
}

export default MeCarousel;
