import { ShoppingBagIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Transition } from "@headlessui/react";
import { signal } from "@preact/signals-react";
import React, { Fragment } from "react";
import imgDefault from "../../../../assets/img/default-image.jpg";
import { useWizardStore } from "store/wizardStore";
import StdButton from "components/Buttons/StdButton";

const isShowing = signal(false);

const ShoppingCart = () => {
  const { record, updateWizard } = useWizardStore();
  console.log(record, " ---- desde el carro");
  const { cart } = record;
  let numberItem = 0;
  if (cart) {
    numberItem = cart.length;
  }
  const handleClose = (e) => {
    e.preventDefault();
    isShowing.value = false;
  };
  const handleOpen = (e) => {
    e.preventDefault();
    isShowing.value = true;
  };

  const IconDelete = () => {
    return <XMarkIcon className="w-3" />;
  };

  const createOrden = () => {
    console.log("creando orden ");
  };

  return (
    <Fragment>
      <div className="relative">
        <ShoppingBagIcon
          className="w-10 bg-white rounded-full p-2 text-blue-presik hover:scale-105 cursor-pointer transform transition-all duration-150 z-10"
          onClick={(e) => handleOpen(e)}
        />
        <span className="bg-blue-presik rounded-full p-1 text-white w-5 h-5 absolute top-[-9px] right-[-4px] text-center text-[10px]">
          {numberItem}
        </span>
      </div>

      <Transition
        show={isShowing.value}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100 "
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed h-screen w-screen bg-black/80 backdrop-blur-md top-0 z-40 flex justify-end -left-5 "
      >
        <div className="w-screen max-w-md h-full bg-white relative">
          <header className="fixed top-0 z-10 flex w-full max-w-md items-center align-middle justify-between border-b border-border-200 border-opacity-75 bg-light px-6 py-4">
            <div className="flex font-semibold text-accent">
              {numberItem} Artículos
            </div>
            <button
              className="flex h-7 w-7 items-center justify-center rounded-full bg-gray-100 text-muted transition-all duration-200 hover:bg-primary hover:text-light focus:bg-primary focus:text-light focus:outline-0 ltr:ml-3 ltr:-mr-2 rtl:mr-3 rtl:-ml-2"
              onClick={(e) => handleClose(e)}
            >
              <XMarkIcon className="w-4" />
            </button>
          </header>
          {/* contenedor productos  */}
          <div className="grow pt-16 pb-20">
            {record &&
              record.cart &&
              record.cart.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center border-b border-solid border-border-200 border-opacity-75 px-4 py-4 text-sm sm:px-6 justify-between"
                >
                  <div>
                    <img
                      src={
                        item && item["images"] && item["images"].length !== 0
                          ? item["images."][0].image_url
                          : imgDefault
                      }
                      className="max-w-[80px] w-[60px] h-[60px] object-cover shadow-sm"
                    />
                  </div>
                  <div className="w-full ml-5 mr-2 space-y-1">
                    <h3 className="font-bold text-heading">{item.name}</h3>
                    <div className="text-sm text-blue-presik text-[13px] flex space-x-2">
                      <span>COP: {item.list_price}</span>
                      <span>x2</span>
                    </div>
                  </div>
                  <button
                    className="flex h-7 w-7 items-center justify-center rounded-full hover:bg-gray-100 hover:scale-105 text-muted transition-all duration-200 hover:bg-primary hover:text-light focus:bg-primary focus:text-light focus:outline-0 ltr:ml-3 ltr:-mr-2 rtl:mr-3 rtl:-ml-2"
                    onClick={(e) => handleRemove(item.id)}
                  >
                    <XMarkIcon className="w-3" />
                  </button>
                </div>
              ))}
          </div>
          <footer className="absolute bottom-0 bg-blue-presik w-full">
            <StdButton
              style="rounded-none !w-full !text-white"
              color={"bluePresik"}
              onClick={createOrden()}
            >
              GENERAR PEDIDO
            </StdButton>
          </footer>
        </div>
      </Transition>
    </Fragment>
  );
};

export default ShoppingCart;
