import React from "react";
import { PhotoIcon } from "@heroicons/react/24/outline";
import { classNames } from "tools/ui";

const CategoryCard = (props) => {
  function onClicked(e) {
    e.preventDefault();
    props.onClicked(props["products."]);
  }

  const style = "text-center my-4 active:scale-95";

  return (
    <div onClick={onClicked} className={classNames(style)} key={props.id}>
      {props["images."].length > 0 ? (
        <img
          className="mx-auto mt-2 rounded-lg shadow-lg"
          width={250}
          src={props["images."][0].image}
        />
      ) : (
        <PhotoIcon className="mx-auto h-25 w-72" />
      )}
      <h2 className="text-2xl font-bold text-slate-800 p-3">{props.name}</h2>
    </div>
  );
};

export default CategoryCard;
