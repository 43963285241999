import React from "react";

import SubTable from "components/MeTable/SubTable";
import MsgInfo from "./MsgInfo";
import CardTotal from "./CardTotal";
import ModelMoney from "./ModelMoney";
import { useWizardStore } from "store/wizardStore";

const FormMoney = (props) => {
  const { store, record } = useWizardStore.getState();
  const data = { value: store.money };

  const parentRec = {
    record: record,
    fieldName: "money",
  };

  const field = {
    name: "money",
    ctxView: ModelMoney.ctxView(),
  };

  const attrs = {
    level: "wizard",
    parentRec: parentRec,
  };
  return (
    <div key={props.key} className="text-gray-600 text-xl">
      <MsgInfo {...props} />
      <div className="flex grow mt-6 pl-3">
        <SubTable field={field} attrs={attrs} data={data} />
        <CardTotal />
      </div>
    </div>
  );
};

export default FormMoney;
