import React, { useEffect, useState } from "react";
import { FormattedMessage as FM } from "react-intl";

import proxy from "api/proxy";
import store from "store";
import InformationCard from "apps/PortalCustomer/Card/InformationCard";

const Profile = () => {
  const session = store.get("ctxSession");
  const [party_, setParty] = useState(null);
  const { web_user, web_user_id, user_name, user_employee, party } = session;

  const getPartyInformation = async () => {
    let result;
    if (web_user) {
      const fields = [
        "name",
        "id_number",
        "phone",
        "street",
        "birthday",
        "city_name",
      ];
      const { data: result } = await proxy.search(
        "party.party",
        [["id", "=", party]],
        fields,
        1,
      );
      if (result.length > 0) {
        setParty(result[0]);
      }
    } else {
      const fields = [
        "party.name",
        "party.id_number",
        "party.phone",
        "party.street",
        "party.city_name",
        "party.birthday",
      ];
      result = await proxy.search(
        "company.employee",
        [["id", "=", user_employee]],
        fields,
        1,
      );

      if (result) {
        setParty(result[0]["party."]);
      }
    }
  };

  useEffect(() => {
    getPartyInformation();
  }, [user_employee, party]);

  return (
    <div id="profile" className="w-full shadow-lg rounded-lg">
      <section className="h-56 bg-slate-600">
        <div className="relative inset-y-44 px-20">
          <img
            className="w-24 h-24 object-cover rounded-full border-2"
            src={`https://ui-avatars.com/api/?name=${user_name}&background=0D8ABC&color=fff `}
            alt="User Profile"
          />
        </div>
      </section>
      <div className="flex px-20 mt-14 divide-y-2 sm:divide-y-0 sm:divide-x-2">
        <div className="w-1/2">
          <h2 className="text-xl font-medium">{party_?.name}</h2>
          <p className="text-lg text-gray-600 font-semibold mt-4 mb-2">
            <FM id={"personal_information"} />
          </p>
          <ul className="">
            <li>
              <span className="font-medium text-slate-700">
                <FM id={"party.party.id_number"} />:
              </span>{" "}
              {party_?.id_number}
            </li>
            <li>
              <span className="font-medium text-slate-700">
                <FM id={"party.party.birthday"} />:
              </span>{" "}
              {party_?.birthday}
            </li>
            <li>
              <span className="font-medium text-slate-700">
                <FM id={"party.party.phone"} />:
              </span>{" "}
              {party_?.phone}
            </li>
            <li>
              <span className="font-medium text-slate-700">
                <FM id={"party.party.email"} />:
              </span>{" "}
              {session.email}
            </li>
            <li>
              <span className="font-medium text-slate-700">
                <FM id={"party.party.address"} />:{" "}
              </span>

              {party_?.city ?? "" + ", " + party_?.street ?? ""}
            </li>
          </ul>
        </div>

        <div className="w-1/2 pl-20">
          {web_user && <InformationCard webUserId={web_user_id} />}
        </div>
      </div>
    </div>
  );
};

export default Profile;
