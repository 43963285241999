import React, { Fragment, useEffect } from "react";
import { signal } from "@preact/signals-react";
import { Transition } from "@headlessui/react";

import proxy from "api/proxy";
import StdButton from "components/Buttons/StdButton";
import { FormattedMessage as FM } from "react-intl";
import {
  CheckIcon,
  ShoppingBagIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { classNames } from "tools/ui";
import CharField from "components/MeForm/CharField";
import { useWizardStore } from "store/wizardStore";

const screenSize = window.innerWidth >= 800 ? true : false;
const KindPerson = [
  {
    id: 1,
    kind: <FM id="hotel.booking.i_colombiano" />,
    msg: <FM id="hotel.booking.msg_iva" />,
  },
  {
    id: 2,
    kind: <FM id="hotel.booking.i_foreigner" />,
    msg: <FM id="hotel.booking.msg_iva_foreign" />,
  },
];

const totalPrice = signal(0);
const openDiscount = signal(false);
const coupon = signal("");
const discount = signal(null);
const msgDiscount = signal(null);
const currentKindPerson = signal(KindPerson[0]);
const displayScreen = signal(screenSize);

const CartBooking = ({
  selectClassification,
  onRemoveItem,
  nights = 0,
  handlePaymentView,
  colors,
}) => {
  const { updateWizard, store } = useWizardStore();
  const bgColor = colors?.colorPrimary || "bg-blue-presik";
  const hoverBgColor =
    `hover:${colors?.colorPrimary}` || "hover:bg-blue-presik";
  const bgColorSecundary = colors?.hoverColorPrimary || "hover:bg-blue-presik";
  const colorTextPrimary = colors?.callToActions || "bg-blue-presik";
  const handleRemoveItem = (item) => {
    discount.value = null;
    onRemoveItem(item);
  };

  const searchCoupon = async () => {
    const { data } = await proxy.search(
      "sale.discount",
      [["name", "=", coupon.value]],
      ["name", "type_discount", "discount", "active", "start_date", "end_date"],
    );

    if (data.length != 0 && data[0].active == true) {
      if (
        data[0].start_date <= store.arrival_date &&
        data[0].end_date >= store.departure_date
      ) {
        discount.value = data[0];
        openDiscount.value = false;
        coupon.value = null;
        updateWizard({ couponDiscount: data[0] });
        msgDiscount.value = null;
      } else {
        msgDiscount.value = `Este cupon promocional solo es valido para el ${data[0].start_date} hasta ${data[0].end_date}`;
      }
    } else {
      msgDiscount.value = "No existe descuentos con este cupon";
    }
  };

  const calculateTotalPrice = () => {
    let total = selectClassification.reduce((accumulator, item) => {
      let price_w_tax_computed =
        item.plan.unit_price + item.plan?.unit_price * (19 / 100);
      return (
        accumulator +
        (currentKindPerson.value.id == 1
          ? price_w_tax_computed
          : item.plan.unit_price) *
          nights
      );
    }, 0);
    if (discount.value && discount.value.type_discount === "percentage") {
      const discountAmount = total * (discount.value.discount / 100);
      total -= discountAmount;
    }
    totalPrice.value = total.toLocaleString("es-ES");
    console.log(total, "este es el total");
  };

  const ContentCupon = () => {
    return (
      <Fragment>
        <div className="pt-4 space-x-3 flex">
          <CharField
            field={{
              name: "coupon",
              required: true,
              translate: true,
              placeholder: "X1X1X1",
            }}
            data={coupon}
            attrs={{
              level: "wizard",
            }}
          />

          <StdButton
            style={"relative !rounded-md relative top-1 !h-10"}
            content="app.booking.btn_add_coupon"
            size="w-full"
            color={colors?.colorSecundary ? colors.colorSecundary : "amber"}
            onClick={() => {
              searchCoupon();
            }}
          />
        </div>
        {msgDiscount.value && (
          <div className="relative top-1 z-10">{msgDiscount.value}</div>
        )}
      </Fragment>
    );
  };
  const SelectKindPerson = () => {
    return (
      <div
        className={`px-4 ${
          selectClassification && selectClassification.length != 0 ? "" : "mb-3"
        }`}
      >
        <div
          className={`grid grid-cols-2 my-3 shadow-sm bg-white rounded-full overflow-hidden text-center ${
            colorTextPrimary || "text-black"
          }`}
        >
          {KindPerson.map(({ kind, msg, id }) => (
            <div
              key={id}
              className={`   ${
                currentKindPerson.value.kind === kind
                  ? `${
                      bgColor || "text-white"
                    } ${colorTextPrimary} text-white flex space-x-1 relative`
                  : ` bg-white !text-black ${hoverBgColor} `
              } transition-colors duration-300 ease-in-out px-4 py-1 cursor-pointer`}
              onClick={() => {
                currentKindPerson.value = { kind, msg, id };
                updateWizard({
                  ...store,
                  ...{ kindPerson: id },
                });
              }}
            >
              <span className="relative">{kind}</span>
              {currentKindPerson.value.kind === kind && (
                <CheckIcon className="w-4 " />
              )}
            </div>
          ))}
        </div>
        <p className="text-left opacity-80 text-xs">
          {currentKindPerson.value.msg}
        </p>
      </div>
    );
  };
  calculateTotalPrice();

  const handleDisplayCard = () => {
    displayScreen.value = displayScreen.value ? false : true;
  };

  const handleDeleteDiscount = () => {
    let _store = { ...store };
    _store.couponDiscount = null;
    _store.coupon = null;
    updateWizard(_store);
    coupon.value = null;
    discount.value = null;
    msgDiscount.value = null;
  };

  useEffect(() => {
    handleDeleteDiscount();
  }, [nights]);

  return (
    <Fragment>
      {displayScreen.value && (
        <div className="md:w-[25%] w-full h-screen fixed md:relative  space-y-5  md:sticky md:top-2 top-0 z-50">
          <div className="bg-gray-100 md:rounded-md shadow-md overflow-hidden pt-2 md:pt-0 h-screen md:h-auto">
            <XMarkIcon
              className={
                "w-10 absolute md:right-16 md:top-10 right-3 top-3 bg-red-400  text-red-800 rounded-full p-2 cursor-pointer md:hidden"
              }
              onClick={() => (displayScreen.value = false)}
            />
            <h2 className="px-5 mt-5 mb-1 font-bold text-blue-presik uppercase text-2xl text-center">
              <FM id="app.booking.label_aside" />
            </h2>
            {selectClassification && selectClassification.length != 0 && (
              <div className="text-gray-700 px-4 text-base w-full lowercase font-medium">
                <span className="font-normal capitalize">Tarifa: </span>
                {store.ratePlan.name}
              </div>
            )}
            <SelectKindPerson />
            {selectClassification && selectClassification.length != 0 && (
              <div className="text-gray-700 px-4 text-xs w-full mt-1 mb-2 lowercase font-medium">
                <span className="font-normal capitalize">
                  <FM id="app.booking.includes" />:{" "}
                </span>
                {store.ratePlan?.name || ""}
              </div>
            )}
            <ul className="divide-y divide-gray-300 overflow-y-scroll md:overflow-visible h-[300px] md:h-auto">
              {selectClassification.map((item, index) => {
                let price_w_tax_computed =
                  item.plan.unit_price + item.plan?.unit_price * (19 / 100);

                return (
                  <li key={index} className="bg-gray-200 p-4 relative">
                    <div
                      className="flex justify-between items-start"
                      title={item.name}
                    >
                      <div className="w-[60%] flex flex-col">
                        <h3 className="text-blue-presik font-semibold line-clamp-2 text-[15px] truncate ">
                          {item.name}
                        </h3>

                        <span className="text-[12px] flex space-x-1">
                          <span className="text-black">{nights} noches</span>
                          <span className="text-green-600 font-semibold">
                            x
                          </span>
                          <span className="text-blue-presik">
                            {(currentKindPerson.value.id == 1
                              ? price_w_tax_computed
                              : item.plan.unit_price
                            ).toLocaleString("es-ES")}
                          </span>
                        </span>
                        <div className="text-[12px] space-x-2">
                          <span className="space-x-2">
                            <span>{item.adults}</span>
                            <span>
                              <FM id={"hotel.folio.num_adults"} />
                            </span>
                          </span>
                          <span>
                            <span>{item.children} </span>
                            <span>
                              <FM id={"hotel.folio.num_children"} />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col text-left items-end w-[40%]">
                        <span className="font-semibold text-[15px] text-blue-presik">
                          $
                          {(
                            (currentKindPerson.value.id == 1
                              ? price_w_tax_computed
                              : item.plan.unit_price) * nights
                          ).toLocaleString("es-ES")}
                        </span>
                      </div>
                    </div>
                    <TrashIcon
                      className="w-6 rounded-full absolute right-4 bottom-4 text-red-500 cursor-pointer hover:scale-90 transition duration-300 transform"
                      onClick={() => handleRemoveItem(item)}
                    />
                  </li>
                );
              })}
            </ul>
            <div className={classNames("text-white px-4 space-x-2", bgColor)}>
              {totalPrice != 0 && <ContentCupon />}
            </div>
            {/* test  */}
            <div>
              <div
                className={classNames(
                  "text-white flex flex-col px-4 pt-2 pb-4 text-right relative",
                  bgColor,
                )}
              >
                <div className="flex justify-between items-center space-x-2">
                  <span className="font-medium text-lg">
                    <FM id="app.booking.amount" />
                  </span>
                  <span className="relative mt-1">
                    <span className="text-yellow-500 font-bold relative">
                      ${totalPrice.toLocaleString("es-ES")} COP
                    </span>
                  </span>
                </div>
                {discount.value != null && (
                  <div className="flex mt-1.5 pt-1.5 border-t border-white justify-between text-base items-center">
                    {`Cupon agregado ${store.couponDiscount?.name} del ${store.couponDiscount?.discount}%`}
                    <TrashIcon
                      className="w-5 text-red-500 cursor-pointer hover:scale-90 transition duration-300 transform"
                      onClick={() => handleDeleteDiscount()}
                    />
                  </div>
                )}
                <StdButton
                  style={`relative rounded-full mt-4 ${
                    colors?.callToActions || ""
                  }`}
                  content="app.booking.btn_confirm"
                  size="w-full"
                  color={
                    colors?.colorSecundary ? colors?.colorSecundary : "amber"
                  }
                  onClick={() => {
                    handlePaymentView();
                    if (!screenSize) {
                      displayScreen.value = false;
                    }
                  }}
                />
              </div>

              <div></div>
            </div>
          </div>
        </div>
      )}
      <Transition
        show={!screenSize && selectClassification.length > 0}
        enter="transition ease-out duration-300"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-200"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          className="fixed left-2 bottom-2 w-min bottom-0 z-40 flex items-center"
          onClick={handleDisplayCard}
        >
          <div className="relative z-10">
            <ShoppingBagIcon
              className={`w-10 ${bgColorSecundary} ${colors?.colorTextPrimary} border border-white rounded-full p-2  hover:scale-105 cursor-pointer transform transition-all duration-150 z-10`}
            />
            <span
              className={`${colors?.colorSecundary} text-white   rounded-full p-1  w-5 h-5 absolute top-[-9px] right-[-4px] text-center text-[10px]`}
            >
              {selectClassification && selectClassification.length}
            </span>
          </div>
          <span
            className={`${bgColor} ${colors?.colorTextPrimary} py-1 pl-7 pr-6 font-semibold rounded-r-full text-right relative -left-3`}
          >
            {totalPrice.value}
          </span>
        </div>
      </Transition>
      {/* )} */}
    </Fragment>
  );
};

export default CartBooking;
