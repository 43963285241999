import React from "react";
import { Carousel } from "react-responsive-carousel";

const CLASS_STYLE_MASK = "content-mask";

const GalleryItem = ({
  data,
  renderItemComponent,
  style,
  numberItem,
  label,
  indicator = true,
  mask = false,
}) => {
  const RenderItems = () => {
    let itemsPerSlide;

    if (window.innerWidth >= 1000) {
      itemsPerSlide = parseInt(numberItem?.desk);
    } else if (window.innerWidth >= 500) {
      itemsPerSlide = parseInt(numberItem?.mobile);
    } else {
      itemsPerSlide = parseInt(numberItem?.mobile);
    }

    const totalSlides = Math.ceil(data.length / itemsPerSlide);
    const slides = [];

    for (let i = 0; i < totalSlides; i++) {
      const start = i * itemsPerSlide;
      const end = start + itemsPerSlide;
      const slideCategories = data.slice(start, end);

      slides.push(
        <div key={i} className={style}>
          {slideCategories.map((item, key) =>
            React.cloneElement(renderItemComponent, { key: key, data: item }),
          )}
        </div>,
      );
    }
    return slides;
  };

  return (
    <div
      className={mask && data.length > numberItem?.desk ? CLASS_STYLE_MASK : ""}
    >
      {label && <h3 className="font-medium mb-4 px-10 text-center">{label}</h3>}
      <Carousel
        showThumbs={true}
        showStatus={false}
        showIndicators={indicator}
        emulateTouch={true}
        showArrows={true}
        renderIndicator={(clickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                className="custom-dot active"
                aria-label={`Slide ${label} - Current Slide`}
              />
            );
          }
          return (
            <li
              className="custom-dot-melhous"
              onClick={clickHandler}
              onKeyDown={clickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label}`}
              aria-label={`Slide ${label}`}
            />
          );
        }}
      >
        {RenderItems()}
      </Carousel>
    </div>
  );
};

export default GalleryItem;
