import React, { useState } from "react";
import { FormattedMessage as FM } from "react-intl";

import BasicModal from "components/Modals/BasicModal";
import Form from "components/MeForm/Form";
import Tab from "components/Tabs/Tab";
import { classNames } from "tools/ui";

export default function Tabs({ field, attrs, data }) {
  const startComponent = <p>No hay componente definido!!!</p>;
  const { ctxView, record, level, parentRec } = attrs;
  const [openModal, setOpenModal] = useState(false);
  const [subform, setSubForm] = useState(null);
  const [title, setTitle] = useState("");

  const buttons = ["close"];

  function onCloseModal(item) {
    setOpenModal(false);
    setSubForm(null);
  }

  function onClick(item) {
    const _title = `${ctxView.model}.${item}`;

    const viewForm = field.tabs[item];
    setSubForm(viewForm);
    setTitle(_title);
    setOpenModal(true);
  }

  function handleButton() {
    console.log("handleButton clicked...");
  }

  const tabs = Object.keys(field.tabs);
  return (
    <div className="flex bg-gray-800 p-4 rounded-lg max-h-20 my-auto">
      <div className="flex space-x-4 sm:items-stretch">
        {tabs.map((item, idx) => (
          <Tab key={idx} item={item} model={ctxView.model} onClick={onClick} />
        ))}
      </div>
      {subform && (
        <BasicModal
          title={title}
          open={openModal}
          onClose={onCloseModal}
          buttons={buttons}
          titleSize="text-2xl"
          width="w-full sm:w-4/5 md:w-3/4 lg:w-2/3"
        >
          {Form({
            keyName: field.name,
            ctxView: ctxView,
            webform: subform,
            handleButton: handleButton,
            record: record,
            parentRec: parentRec,
            level: level,
          })}
        </BasicModal>
      )}
    </div>
  );
}
