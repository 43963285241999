import React, { useState } from "react";
import { FormattedMessage as FM } from "react-intl";

import BasicModal from "components/Modals/BasicModal";
import SubTable from "components/MeTable/SubTable";
import { BUTTON_COLORS_DARK } from "components/Constants/constants";
import { classNames } from "tools/ui";
import funcs from "tools/functions";
import proxy from "api/proxy";

const base =
  "flex w-28 h-18 mb-auto mx-auto p-3 rounded-xl shadow-md active:scale-95 active:shadow-sm cursor-pointer";

function ButtonModal(props) {
  const { field, attrs, data } = props;
  const { name, label, model, color, icon, ctxView } = field;

  const [open, setOpen] = useState(false);
  // const [records, setRecords] = useState([]);

  let [bgColor, txtColor] = BUTTON_COLORS_DARK.blue;
  if (color) {
    [bgColor, txtColor] = BUTTON_COLORS_DARK[color];
  }

  function onClose() {
    setOpen(false);
  }

  async function onClick() {
    if (props.records && props.records.length > 0) {
      const recordsIds = props.records.map((rec) => rec.id);
      const fields_names = funcs.getViewFields(ctxView, "list");
      const res = await proxy.browse(model, recordsIds, fields_names);
      // setRecords(res);
    }
    setOpen(true);
  }

  return (
    <div onClick={onClick} className={classNames(base, bgColor, txtColor)}>
      <div className="mx-auto text-center">
        {icon && (
          <i
            key={icon}
            className={classNames("h-4 w-4 text-xl my-auto", icon)}
          />
        )}
        <span className="flex my-auto text-xs font-medium ml-auto">
          <FM id={label} />
        </span>
      </div>
      <BasicModal
        title={label}
        open={open}
        onClose={onClose}
        titleSize="text-2xl"
        width="w-1/2"
      >
        <SubTable key={name} data={data} field={field} attrs={attrs} />
      </BasicModal>
    </div>
  );
}

export default ButtonModal;
