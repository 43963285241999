import React from "react";
import { PaperClipIcon } from "@heroicons/react/24/outline";

import IconButton from "components/Buttons/IconButton";

export function attachmentIcon(active, onClick) {
  if (!active) return null;
  return (
    <PaperClipIcon
      className="h-8 w-8 text-slate-600 stroke-2 p-1 cursor-pointer hover:stroke-blue-400"
      aria-hidden="true"
      onClick={onClick}
    />
  );
}

export function saveIcon(active, onClick) {
  if (!active) return null;
  return (
    <IconButton
      onClick={() => onClick("save")}
      name="fi fi-rr-disk"
      color="gray"
    />
  );
}

export function deleteIcon(active, onClick) {
  if (!active) return null;
  return <IconButton onClick={onClick} name="fi fi-rr-trash" color="rose" />;
}
