// English translation

const en = {
  "hotel.folio.guest": "Guest",
  "hotel.folio.guest.name": "Name",
  "hotel.folio.guest.adult": "Adult",
  "hotel.folio.guest.child": "Child",
  "hotel.folio.guest.sex": "Sex",
  "hotel.folio.guest.male": "Male",
  "hotel.folio.guest.female": "Female",
  "hotel.folio.guest.nationality": "Nationality",
  "hotel.folio.guest.main_guest": "Main Guest",
  "hotel.folio.guest.type_guest": "Guest Type",
  "hotel.folio.guest.type_document": "Type Document",
  "hotel.folio.guest.id_number": "Id Number",
  "hotel.folio.guest.mobile": "Mobile",
  "hotel.folio.guest.email": "Email",
  "hotel.folio.guest.address": "Address",
  "hotel.folio.guest.origin_country": "Origin Country",
  "hotel.folio.guest.target_country": "Target Country",
  "hotel.folio.guest.birthday": "Birthday",
  "hotel.folio.guest.notes": "Notes",
  "hotel.folio.guest.profession": "Profesion",
  "hotel.folio.guest.registro_civil": "Registro Civil de Nacimiento",
  "hotel.folio.guest.tarjeta_identidad": "Tarjeta de Identidad",
  "hotel.folio.guest.cedula_ciudadania": "Cedula de Ciudadania",
  "hotel.folio.guest.tarjeta_extranjeria": "Tarjeta de Extranjeria",
  "hotel.folio.guest.cedula_extranjeria": "Cedula de Extranjeria",
  "hotel.folio.guest.nit": "NIT",
  "hotel.folio.guest.add_guest": "Add",
  "hotel.folio.guest.cancel": "Cancel",
  "hotel.folio.guest.pasaporte": "Pasaporte",
  "hotel.folio.guest.tipo_documento_extranjero": "Tipo de Documento Extranjero",
  "hotel.folio.guest.pep": "PEP",
  "hotel.folio.guest.nit_otro_pais": "NIT de otro pais",
  "hotel.folio.guest.nuip": "NUIP",
  "hotel.folio.guest.country": "Country",
  "hotel.folio.guest.subdivision": "Subdivision",
};

export default en;
