import React from "react";
import Billing from "./Billing/Billing";

const NavCustomer = () => {
  // const ctxView = puchaseModel.ctxView(config);

  return (
    <div>
      {/* <Profile></Profile>
      <Billing></Billing> */}
    </div>
  );
};

export default NavCustomer;
