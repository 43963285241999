import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import React from "react";
const styleIcon = "w-8 clas inline-block  fill-black mr-2";

const RentalRequirements = () => {
  return (
    <div className="z-10 backdrop-blur-sm bg-white/50 py-12 px-7 rounded-md shadow-md">
      <h1 className="text-5xl mb-8 text-black bg-yellow-300 p-2 md:max-w-max max-w-full md:-mt-16">
        Rental Requirements
      </h1>
      <ul className="text-2xl font-extralight space-y-6">
        <li className="">
          <CheckBadgeIcon className={styleIcon} />
          Deposit and Damages Guarantee: * The deposit is collected as a damages
          guarantee. * It will be refunded in full at the end of the rental
          period.
        </li>
        <li>
          <CheckBadgeIcon className={styleIcon} />
          Database Admin Fee: Customers will be charged a one-time fee of $2.2.
          This fee is associated with the initial Direct Debit payment.
        </li>
        <li>
          <CheckBadgeIcon className={styleIcon} />
          Responsibility for Return: Customers are responsible for carrying out
          the return of the rented item(s).
        </li>
      </ul>
    </div>
  );
};

export default RentalRequirements;
