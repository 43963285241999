// Attachment model

const AttCtxView = {
  model: "ir.attachment",
  form_action: [],
  table_action: [],
  limit: 100,
  webfields: {
    name: {
      type: "char",
      readOnly: true,
    },
    data: {
      type: "input_file",
      readOnly: true,
    },
    link: {
      type: "char",
      readOnly: true,
    },
    description: {
      type: "char",
      readOnly: true,
    },
    type: {
      type: "selection",
      readOnly: true,
    },
  },
  webtree: [
    { name: "name", width: "20%" },
    { name: "description", width: "30%" },
    { name: "type", width: "15%" },
    { name: "link", width: "30%" },
    { name: "data", width: "20%" },
  ],
  webform: [
    { name: "name" },
    { name: "description" },
    { name: "type" },
    { name: "link" },
    // { name: "file" },
  ],
};

export default AttCtxView;
