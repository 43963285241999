import React, { Disclosure } from "@headlessui/react";
import { FormattedMessage as FM } from "react-intl";
import date from "date-and-time";

import ItemRowPanel from "components/Panel/ItemRowPanel";
import ButtonActionPanel from "components/Panel/ButtonActionPanel";
import { ReactComponent as IconHouse } from "assets/apps/iconHouse.svg";
import tools from "tools/dates";
import imgDespegar from "assets/apps/despegar.png";
import imgBooking from "assets/apps/booking.png";
import imgExpedia from "assets/apps/expedia.png";
import imgHouse from "assets/apps/house.png";

const iconOta = {
  DESPEGAR: imgDespegar,
  BOOKING: imgBooking,
  EXPEDIA: imgExpedia,
  HOUSE: imgHouse,
};

const fmt = "YYYY-MM-DD";
const formatter = Intl.NumberFormat("en-US");

function MinitablePanel({ title, data }) {
  const styleLabel = "text-gray-500 font-semibold text-sm";
  const styleValue = "";

  function headerBooking(
    name,
    image = undefined,
    room = 0,
    channel = { name: "HOUSE", id: 0 },
  ) {
    if (!image) {
      image = `https://ui-avatars.com/api/?name=${name}&background=0D8ABC&color=fff`;
    }
    return (
      <div className="flex  items-center justify-stretch relative">
        <div className="flex items-center w-full space-x-3">
          <img src={image} className="w-8 h-8 rounded-full"></img>
          <span className="truncate text-gray-700">{name}</span>
        </div>

        <span className="flex text-gray-500 relative justify-end">
          {room !== 0 ? (
            <div
              className={`w-10 rounded-full flex bg-gray-100 text-transparent relative mr-3 ${
                room == 0 ? "opacity-50" : "fill-white"
              }`}
            >
              <span className="text-bluedeep mx-auto my-auto text-[10px]">
                {room}
              </span>
            </div>
          ) : null}

          <img
            src={
              iconOta[channel?.name] ? iconOta[channel?.name] : iconOta["HOUSE"]
            }
            title="despegar"
            className="w-8 relative"
          />
        </span>
      </div>
    );
  }

  return (
    <div className="bg-white border-gray-100 shadow-sm p-3 flex flex-col  rounded-lg divide-y">
      <h3 className="truncate text-2xl font-bold text-zinc-800 py-3">
        <FM id={title} />
      </h3>

      {data.slice(0, 4).map((res, key) => {
        const {
          contact = "",
          number = "",
          booking_date,
          total_amount,
          state,
          image,
        } = res;

        const name = res["party."]?.name ? res["party."]?.name : "N.A.";
        const phone = res["party."]?.phone;
        const email = res["party."]?.email;
        const _booking_date = tools.getTrytonDateTime2Js(booking_date);
        const fmt_booking_date = tools.fmtDate(_booking_date);
        const _total_amount = formatter.format(total_amount);
        return (
          <div key={res.number}>
            <Disclosure key={key}>
              <Disclosure.Button className="py-2 w-full text-left cursor-pointer uppercase">
                {headerBooking(
                  name,
                  image,
                  res["lines."].length,
                  res["channel."],
                )}
              </Disclosure.Button>
              <Disclosure.Panel className="py-2">
                <h4 className="truncate text-sm font-bold text-gray-600 mb-4">
                  <FM id="booking.widget.customers.title" />
                </h4>
                {res.contact && (
                  <h3
                    className="mb-2 text-white bg-blue-presik py-1 px-3 -mt-2 rounded-full truncate"
                    title={
                      <FM id="booking.widget.customers.name_contact.placeholder" />
                    }
                  >
                    <FM id="booking.widget.customers.name_contact" />{" "}
                    <span className="font-medium">{contact}</span>
                  </h3>
                )}

                <div className="grid grid-cols-2 mb-3 capitalize">
                  <div className="space-y-3">
                    <div className="flex flex-col">
                      <span className={styleLabel}>
                        <FM id="booking.widget.customers.number" />
                      </span>
                      <span className={styleValue}>{number}</span>
                    </div>
                    <div className="flex flex-col">
                      <span className={styleLabel}>
                        <FM id="booking.widget.customers.booking_date" />
                      </span>
                      <span className={styleValue}>{fmt_booking_date}</span>
                    </div>
                  </div>

                  <div className="space-y-3">
                    <div className="flex flex-col">
                      <span className={styleLabel}>
                        <FM id="booking.widget.customers.total_amount" />
                      </span>
                      <span className={styleValue}>{_total_amount}</span>
                    </div>
                    <div className="flex flex-col">
                      <span className={styleLabel}>
                        <FM id="booking.widget.customers.state" />
                      </span>
                      <span className={styleValue}>{state}</span>
                    </div>
                  </div>
                </div>
                <div className="text-gray-500 flex flex-col gap-1">
                  <div className="flex space-x-3 justify-between">
                    <span className={styleLabel}>
                      <FM id="booking.widget.customers.rooms" />
                    </span>
                    <div className="flex space-x-3 items-center">
                      <div
                        id="circle"
                        className="w-4 h-4 mx-auto rounded-full border-[1px] bg-lime-300 border-lime-500"
                      ></div>{" "}
                      <span className="text-xs">
                        <FM id="hotel.room.clean" />
                      </span>
                      <div
                        id="circle"
                        className="w-4 h-4 mx-auto rounded-full border-[1px] bg-rose-300 border-rose-500"
                      ></div>
                      <span className="text-xs">
                        <FM id="hotel.room.dirty" />
                      </span>
                    </div>
                  </div>

                  {res["lines."].map((room, key) => {
                    return <ItemRowPanel data={room["room."]} key={key} />;
                  })}
                </div>
                <hr className="mt-4" />

                <h4 className="truncate text-sm font-bold text-gray-600 my-4">
                  <FM id="booking.widget.customers.info_huesped" />
                </h4>
                <div className="grid grid-cols-2 ">
                  <div className="space-y-3">
                    <div className="flex flex-col">
                      <span className={styleLabel}>
                        <FM id="booking.widget.customers.name" />
                      </span>
                      <span className={styleValue}>{name}</span>
                    </div>

                    <div className="flex flex-col">
                      <span className={styleLabel}>
                        <FM id="booking.widget.customers.phone" />
                      </span>
                      <span className={styleValue}>{phone}</span>
                    </div>
                  </div>

                  <div className="space-y-3">
                    <div className="flex flex-col">
                      <span className={styleLabel}>
                        <FM id="booking.widget.customers.booking_hour" />
                      </span>
                      <span className={styleValue}>8:OO AM</span>
                    </div>

                    <div className="flex flex-col">
                      <span className={styleLabel}>
                        <FM id="booking.widget.customers.email" />
                      </span>
                      <span className={styleValue}>{email}</span>
                    </div>
                  </div>
                </div>
              </Disclosure.Panel>
            </Disclosure>
          </div>
        );
      })}

      <span className="pt-3 text-center text-gray-400">
        <FM id="booking.widget.screen.view_more" />
      </span>
    </div>
  );
}
// <ButtonActionPanel
// phone={phone}
// whatsapp={phone}
// email={email}
// />

export default MinitablePanel;
