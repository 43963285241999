import React, { useEffect, useState } from "react";

import proxy from "api/proxy";
import tools from "tools/dates";
import TicketTurn from "./Components/TicketTurn";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import notificationSound from "./Assets/Audio/sound-ticket.wav";
import BodyTurn from "./Components/BodyTurn";

const today = tools.dateToday();
const dom = [
  ["ticket_control.date_ticket", ">=", today],
  ["state", "=", "busy"],
];
const fields = [
  "turn",
  "number_id",
  "position",
  "position.number",
  "party.name",
];

const WebTickekSystem = () => {
  const [config, setConfig] = useState({});
  const queryClient = useQueryClient();
  const queryTurnActive = useQuery(
    ["ticket_system.ticket", dom],
    async () => {
      let turns = [];
      if (dom) {
        const { data: res, error } = await proxy.search(
          "ticket_system.ticket",
          dom,
          fields,
        );
        turns = res;
      }

      return turns;
    },
    {
      staleTime: 5000,
    },
  );
  const getConfiguration = async () => {
    const { data } = await proxy.search(
      "ticket_system.configuration",
      [],
      [
        "text_info",
        "phone_info",
        "email_info",
        "attention_schedule",
        "content.title",
        "content.image",
        "content.video",
      ],
    );
    if (data.length > 0) {
      setConfig(data[0]);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      queryClient.invalidateQueries(["ticket_system.ticket", dom]);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (config) {
      getConfiguration();
    }
  }, []);

  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  useEffect(() => {
    if (queryTurnActive.data && queryTurnActive.data.length > 0) {
      playNotificationSound();
    }
  }, [queryTurnActive.data]);

  return (
    <div className="w-screen h-screen bg-white p-4 rounded-md  overflow-hidden">
      <div className="bg-gray-100 h-full w-full p-4 ">
        <div className="flex h-full justify-between space-x-20">
          <div className="flex relative  justify-start items-center w-[68vw] h-full 2xl:w-[71vw]">
            <BodyTurn config={config} />
            <footer className="w-full p-4 absolute bg-blue-presik -bottom-4 rounded-b-md text-white  overflow-hidden">
              <div className="scrolling-text xl:text-7xl">
                {config?.text_info}
              </div>
            </footer>
          </div>
          <TicketTurn dataTurn={queryTurnActive?.data} />
        </div>
      </div>
    </div>
  );
};

export default WebTickekSystem;
