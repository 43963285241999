import React, { useState } from "react";

import ModalReport from "components/Modals/ModalReport";
import MenuButton from "components/Buttons/MenuButton";
import ModelLocation from "./ModelLocation";
import ModelPeriod from "./ModelPeriod";
import ComplianceReport from "./ComplianceReport";

function MenuScheduleReport() {
  let [openReport, setOpenReport] = useState(false);

  const reportView = ComplianceReport.ctxView();

  function onClickReport() {
    setOpenReport(true);
  }

  function onClose() {
    setOpenReport(false);
  }

  const fields = [
    {
      label: "surveillance.schedule.period",
      name: "period",
      type: "many2one",
      model: "account.period",
      required: true,
      ctxView: ModelPeriod.ctxView(),
    },
    {
      label: "surveillance.schedule.location",
      name: "location",
      type: "search",
      model: "surveillance.location",
      required: true,
      ctxView: ModelLocation.ctxView(),
    },
  ];

  const options = [
    {
      id: 1,
      name: "schedule.compliance_report",
      description: "Reporte de los horarios de cada frente",
      icon: "fi-rs-table-columns",
      onClick: onClickReport,
    },
    {
      id: 2,
      name: "schedule.guards_report",
      description: "Visualiza la programacion planeada",
      icon: "fi-rs-apps",
      onClick: onClickReport,
    },
  ];

  return (
    <div className="w-72">
      <MenuButton
        id="surveillance-drop-report"
        name="REPORTES"
        className="icon"
        options={options}
      />
      <ModalReport onClose={onClose} open={openReport} ctxView={reportView} />
    </div>
  );
}

export default MenuScheduleReport;
