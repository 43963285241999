import React from "react";

const Footer = () => {
  return (
    <footer className="px-10 py-5 bg-black text-white w-screen relative -left-7 z-20">
      <div className="container mx-auto">
        Todos los derechos reservados <b>Presik Copyright 2024</b>
      </div>
    </footer>
  );
};

export default Footer;
