import React, { Fragment, useState } from "react";
import { FormattedMessage as FM } from "react-intl";
import { useQuery } from "@tanstack/react-query";
import { CursorArrowRaysIcon } from "@heroicons/react/24/solid";

import StdButton from "components/Buttons/StdButton";
import FullTable from "components/MeTable/FullTable";
import ctxViewCard from "./modelCard";
import openPay from "tools/openPay";
import proxy from "api/proxy";
import Card from "./Card";

// fakedata
const records = [
  {
    card_number: "1111",
    holder_name: "Juan Perez Ramirez",
    expiration_year: "20",
    expiration_month: "04",
    address: {
      line1: "Av 5 de febrero",
      line2: "Roble 207",
      line3: "Queretaro",
      state: "Queretaro",
      city: "Queretaro",
      postal_code: "76900",
      country_code: "MX",
    },
    creation_date: "2014-01-30T13:53:11-06:00",
    brand: "visa",
    points_card: false,
    state: "active",
  },
];

// const clientRequest = {
//   name: "customer name",
//   email: "customer_email@me.com",
//   requires_account: false,
// };
// import puchaseModel from "./ModelPurchase";

const InformationCard = ({ webUserId }) => {
  const [openCard, setOpenCard] = useState(false);
  const queryCard = useQuery(["web_user", webUserId], async () => {
    if (webUserId) {
      const { data } = await proxy.search(
        "openpay.account",
        [["web_user", "=", webUserId]],
        ["name", "state", "card_number"],
      );
      return data ?? [];
    } else {
      return [];
    }
  });

  let cards;
  if (queryCard.data && queryCard.data.length > 0) {
    cards = queryCard.data;
  }

  const handleActionTable = async (event, action, rec) => {
    if (action === "delete") {
      const args = {
        model: "openpay.account",
        method: "delete_account",
        instance: rec.id,
      };
      const result = await proxy.methodInstance(args);
      console.log(result);
    }
  };

  const handleClose = (card) => {
    if (card) {
      console.log(card, "this is card");
    }
    setOpenCard(false);
  };

  const addCard = () => {
    setOpenCard(true);
    // openPay.initOpenPay();
    // console.log(window.OpenPay.deviceData.setup());
  };

  const deleteCustomer = () => {
    openPay.initOpenPay();
    console.log(window.OpenPay.deviceData.setup());
  };

  return (
    <Fragment>
      <p
        className="pb-4 flex space-x-2 cursor-pointer"
        onClick={deleteCustomer}
      >
        {cards ? (
          <span className="text-sky-800">
            Eliminar toda la informacion personal bancaria y tarjetas
            registradas.
          </span>
        ) : (
          <span className="text-sky-800">
            Eliminar toda la informacion personal bancaria.
          </span>
        )}
        <CursorArrowRaysIcon className="w-8 fill-cyan-800" />
      </p>
      <div className="flex justify-between mb-4">
        <h2 className="pb-4 text-lg text-gray-600 font-semibold">
          {cards ? (
            <FM id={"information_card"} />
          ) : (
            <FM id={"not_information_card"} />
          )}
        </h2>
        <StdButton
          name={"button_add"}
          color={"blue"}
          noFill={true}
          content={"button_add"}
          onClick={addCard}
        />
      </div>
      {cards && (
        <FullTable
          ctxView={ctxViewCard}
          records={cards}
          onChangeView={handleActionTable}
        />
      )}
      {openCard && <Card open={openCard} onClose={handleClose} />}
    </Fragment>
  );
};

export default InformationCard;
