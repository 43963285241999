import React from "react";

import { classNames } from "tools/ui";

const base = "text-xl grid pb-1";

function CalendarIcon() {
  const _icon = "fi fi-rr-calendar-check";
  return <i className={classNames(base, _icon)}></i>;
}

// Add more icons here.......

export { CalendarIcon };
