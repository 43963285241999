import React from "react";

function CardBasic({ onClick, children }) {
  return (
    <div
      className="bg-white rounded-xl w-full shadow-lg p-4 my-6 hover:cursor-pointer"
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default CardBasic;
