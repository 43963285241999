import MsgInfo from "./MsgInfo";
import FormMoney from "./FormMoney";
import StepOpen from "./StepOpen";

const openView = {
  buttonName: "account.statement.open",
  steps: {
    start: {
      key: "open_start",
      Component: StepOpen,
      args: {
        msg: "account.statement.open.msg_start",
      },
    },
    success: {
      key: "open_success",
      Component: MsgInfo,
      args: {
        msg: "account.statement.open.msg_success",
      },
    },
    error: {
      key: "open_error",
      Component: MsgInfo,
      args: {
        msg: "account.statement.open.msg_error",
      },
    },
  },
};

const closeView = {
  buttonName: "account.statement.close",
  steps: {
    start: {
      key: "close_start",
      Component: FormMoney,
      args: {
        msg: "account.statement.close.msg_start",
      },
    },
    success: {
      key: "close_success",
      Component: MsgInfo,
      args: {
        msg: "account.statement.close.msg_success",
      },
    },
    error: {
      key: "close_error",
      Component: MsgInfo,
      args: {
        msg: "account.statement.close.msg_error",
      },
    },
  },
};

export default { openView, closeView };
