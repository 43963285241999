import React, { Fragment, useState } from "react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";

const NavCategoriesProducts = ({ data, handleCategory, category }) => {
  const [activeSubmenus, setActiveSubmenus] = useState([]);

  let navCategories = null;
  if (!category) {
    navCategories = data;
  } else if (category?.children?.length > 0) {
    navCategories = category?.children;
  }

  const handleMouseEnter = (item) => {
    setActiveSubmenus([...item.parent_ids, item.id]);
  };

  const handleMouseLeave = () => {
    setActiveSubmenus([]);
  };

  const renderSubcategories = (subcategories) => {
    if (!subcategories || subcategories.length === 0) {
      return null;
    }

    return (
      <ul className="ml-4 absolute left-[97%] z-10 bg-white p-3 min-w-[200px] -top-3 hidden group-hover:block  min-h-[100px] rounded-lg shadow-lg divide-y divide-gray-300 space-y-3">
        {subcategories.map((subcategory, index) => (
          <Fragment key={subcategory.id}>
            {/* {index > 0 && <hr className="my-2 border-t border-gray-300" />} */}
            <li
              key={subcategory.id}
              className="text-xs text-gray-500 hover:text-accent cursor-pointer transition-colors relative group [&:not(last-child)]:pt-3"
              onMouseEnter={() => handleMouseEnter(subcategory)}
              onClick={(e) => {
                e.stopPropagation();
                handleCategory(subcategory);
              }}
            >
              {subcategory.name}
              {activeSubmenus.includes(subcategory.id) &&
                renderSubcategories(subcategory.children)}
            </li>
          </Fragment>
        ))}
      </ul>
    );
  };

  return (
    <Fragment>
      <nav className="bg-white rounded-md shadow-sm w-full p-6">
        <h3 className="font-semibold uppercase mb-3">Categorías</h3>
        <ul className="flex flex-col space-y-2 relative">
          {navCategories &&
            navCategories.map((item, key) => (
              <li
                key={key}
                className="relative group"
                onMouseEnter={() => handleMouseEnter(item)}
                onMouseLeave={() => handleMouseLeave(item)}
                onClick={() => handleCategory(item)}
              >
                <span className="flex w-full items-center py-2 font-semibold justify-between text-body-dark outline-none transition-all ease-in-expo ltr:text-left rtl:text-right text-accent text-sm cursor-pointer bg-gray-50 rounded-full px-6 border border-gray-100 hover:bg-gray-100">
                  {item.name}
                  <ArrowRightCircleIcon className="w-6" />
                </span>
                {activeSubmenus.includes(item.id) && (
                  <div className="relative -left-4 -top-5">
                    {renderSubcategories(item.children)}
                  </div>
                )}
              </li>
            ))}
        </ul>
      </nav>
    </Fragment>
  );
};

export default NavCategoriesProducts;
