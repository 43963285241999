// FIX Add required fields
import modelCharge from "./Charge";
import mainStore from "store/mainStore";
import { useWizardStore } from "store/wizardStore";
import proxy from "api/proxy";
import funcs from "tools/functions";

var ModelCharge = modelCharge.ctxView({
  selectable: "multi",
  table_action: [],
});

function visibleCharges(name, record) {
  if (record.kind?.id === "partial") {
    return true;
  }
  return false;
}

async function handleChangeKind(_record) {
  const { record: mainRecord } = mainStore.getState();
  const { record, store } = useWizardStore.getState();
  const folios = Array.from(mainRecord.lines.values());
  const foliosIds = folios.map((fo) => fo.id);
  const model = "hotel.folio.charge";
  const domain = [
    ["folio", "in", foliosIds],
    ["invoice_line", "=", null],
  ];
  const fieldsNames = funcs.getViewFields(ModelCharge, "list");
  const { data, error } = await proxy.search(model, domain, fieldsNames);
  record.charges = data;
}

function searchParty(data, record, parentRec) {
  let targetIds;
  if (parentRec) {
    targetIds = [];
    if (parentRec.party) {
      targetIds.push(parentRec.party.id);
    }
    for (const folio of parentRec.lines) {
      if (folio.main_guest) {
        targetIds.push(folio.main_guest.id);
      }
    }
  }

  const partyDom = targetIds ? [["id", "in", targetIds]] : [];
  return partyDom;
}

const OPTIONS = [
  { id: "all", name: "all" },
  { id: "partial", name: "partial" },
];

const getView = () => {
  let DictCtxView = {
    model: "hotel.booking.bill",
    row_selectable: false,
    form_action: [],
    table_action: [],
    webfields: {
      party: {
        type: "many2one",
        model: "party.party",
        required: true,
        recSearch: searchParty,
      },
      kind: {
        type: "selection",
        required: true,
        options: OPTIONS,
        translate: true,
        withChange: handleChangeKind,
        dependents: ["charges"],
      },
      charges: {
        type: "one2many",
        model: "hotel.folio.charge",
        ctxView: ModelCharge,
        visible: visibleCharges,
      },
      bill: {
        type: "button",
        // method: addPayment, This method is added in custom model
        color: "green",
        iconRight: "fi fi-rr-add",
      },
    },
    webform: [
      { name: "party" },
      { name: "kind" },
      { name: "bill" },
      { name: "charges" },
    ],
    webtree: [],
  };

  return DictCtxView;
};

export default { ctxView: getView };
