import React, { useState } from "react";
import date from "date-and-time";
import { signal } from "@preact/signals-react";

import GridCards from "components/Card/GridCards";
import FullTable from "components/MeTable/FullTable";
import DateField from "components/MeForm/DateField";
import IntegerField from "components/MeForm/IntegerField";
import DropdownField from "components/MeForm/DropdownField";
import StdButton from "components/Buttons/StdButton";
import ModelWizardRooms from "./ModelWizardRooms";
import ModelFolioWizard from "./ModelFolioWizard";
import proxy from "api/proxy";
import dates from "tools/dates";
import funcs from "tools/functions";
import mainStore from "store/mainStore";
import { useWizardStore } from "store/wizardStore";
import editTable from "store/editTable";

const attrs = { level: "wizard" };

var sequenceId = 0;

function getRandomRoom(rooms) {
  const roomRand = Math.floor(Math.random() * rooms.length);
  const foundRooms = rooms.splice(roomRand, 1);
  if (foundRooms.length > 0) {
    return foundRooms[0];
  }
}

const AddRoomWizard = ({ onClose }) => {
  let [records, setRecords] = useState(null);
  let [roomsSelected, setRoomsSelected] = useState(new Map());
  let [loading, setLoading] = useState(false);

  const { record: recordParent } = mainStore();
  const { store, record, reset } = useWizardStore();
  let ctxViewRooms = ModelWizardRooms.ctxView();

  function handleDelete(record) {
    let _roomsSelected = new Map(roomsSelected);
    _roomsSelected.delete(record.room.id);
    setRoomsSelected(_roomsSelected);
    let _records = new Map(records);
    const category = _records.get(record.product.id);
    category.rooms.push(record.room);
    category.available = category.rooms.length;
  }

  function updateRecords(field, value, recId) {
    let _records = new Map(records);
    let category = _records.get(recId);
    category[field] = value;
    if (field === "sale_price_taxed") {
      const discount = (value / category.sale_price_taxed_default - 1) * 100;
      category.discount = discount.toFixed(1);
      setRecords(_records);
    }
  }

  async function handleAccept() {
    setLoading(true);
    let toStore = funcs.cloneMap(roomsSelected);
    const records = Array.from(toStore.values());

    for (const rec of records) {
      sequenceId -= 1;
      delete rec.unit_price_w_tax;
      delete rec.nights_quantity;
      delete rec.total_amount;
      rec.id = sequenceId;
    }
    const args = {
      level: "child",
      action: "create",
      fieldName: "lines",
      records: records,
      // autoSave: true,
    };
    await editTable(args);
    onClose();
    setLoading(false);
    reset();
  }

  function addRoom(record) {
    let _roomsSelected = new Map(roomsSelected);
    let room;
    console.log("addRoom record....", record);
    if (record.room.id) {
      room = record.room;
    } else {
      room = getRandomRoom(record.rooms);
    }
    record.available = record.rooms.length;
    if (room) {
      const newRoom = {
        room: room,
        product: record.product,
        arrival_date: store.arrival_date,
        departure_date: store.departure_date,
        unit_price: record.sale_price.toString(),
        unit_price_w_tax: record.sale_price_taxed.toString(),
        nights_quantity: nights,
        total_amount: (nights * record.sale_price_taxed).toFixed(2),
      };
      _roomsSelected.set(room.id, newRoom);
      setRoomsSelected(_roomsSelected);
    }
  }

  ctxViewRooms.webfields.add.method = addRoom;

  function getNights() {
    const arrival_date = dates.getTrytonDate2Js(store.arrival_date);
    const departure_date = dates.getTrytonDate2Js(store.departure_date);
    return date.subtract(departure_date, arrival_date).toDays();
  }

  const withChange = async (_record, _store) => {
    let _nights = 0;
    let _rate_plan;
    if (_store.arrival_date && _store.departure_date) {
      _nights = getNights();
      _rate_plan = await fillCategories();
    }
    _record.nights_quantity = _nights;
    if (_rate_plan) {
      _record.price_list = _rate_plan;
    }
  };

  const fieldArrival = {
    level: "wizard",
    name: "arrival_date",
    label: "hotel.booking.add_room.arrival_date",
    withChange: withChange,
  };

  const fieldDeparture = {
    level: "wizard",
    name: "departure_date",
    label: "hotel.booking.add_room.departure_date",
    withChange: withChange,
  };

  const fieldNights = {
    label: "hotel.booking.add_room.nights_quantity",
    name: "nights_quantity",
    readOnly: true,
    withChange: null,
  };

  const fieldPriceList = {
    label: "hotel.booking.price_list",
    name: "price_list",
    readOnly: true,
    recSearch: () => [],
  };

  const fillCategories = async () => {
    let rate_plan = recordParent.rate_plan ? recordParent.rate_plan.id : null;
    const { data } = await proxy.methodCall({
      model: "hotel.room",
      method: "available_by_type",
      args: [store.arrival_date, store.departure_date, rate_plan],
      kwargs: {},
    });
    const mapRecs = new Map();
    data.forEach((cat) => {
      cat.id = cat.product.id;
      cat.adults = 2;
      cat.children = 0;
      cat.sale_price_taxed_default = cat.sale_price_taxed;
      cat.room = {};
      mapRecs.set(cat.product.id, cat);
    });
    setRecords(mapRecs);
    if (data.length > 0) {
      const cat = data[0];
      return cat.rate_plan;
    }
  };

  const nights = signal(record.nights_quantity);
  const ratePlan = signal(record.price_list);
  const arrival_date = signal(null);
  const departure_date = signal(null);
  const selectedRooms = new Map(roomsSelected);

  return (
    <div id="hotel-booking-wizard-rooms" className="block">
      <div className="grid grid-cols-4 gap-x-2 pb-3">
        <DateField field={fieldArrival} attrs={attrs} data={arrival_date} />
        <DateField field={fieldDeparture} attrs={attrs} data={departure_date} />
        <IntegerField field={fieldNights} attrs={attrs} data={nights} />
        <DropdownField field={fieldPriceList} attrs={attrs} data={ratePlan} />
      </div>
      {records && records.size > 0 && (
        <FullTable
          records={records}
          isLoading={false}
          limit={30}
          level="wizard"
          updateRecords={updateRecords}
          ctxView={ctxViewRooms}
        />
      )}
      <GridCards
        id="add-room-grid-cards"
        records={selectedRooms}
        handleDelete={handleDelete}
        ctxView={ModelFolioWizard.ctxView()}
      />
      <div className="float-right">
        {selectedRooms.size > 0 && (
          <StdButton
            style="font-semibold"
            color="amber"
            loading={loading}
            onClick={handleAccept}
            content="hotel.booking.add_rooms.accept"
          />
        )}
      </div>
    </div>
  );
};

export default AddRoomWizard;
