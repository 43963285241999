const searchParty = (value) => {
  let search = [["is_affiliate_user", "=", true]];
  if (value) {
    search.push([
      "OR",
      ["name", "ilike", `%${value}%`],
      ["id_number", "ilike", `%${value}%`],
    ]);
  }
  return search;
};

const ETHNICAL_GROUP = () => {
  const ethnical_group = [
    { id: "N.A", name: "N.A" },
    { id: "afrocolombiano", name: "AFROCOLOMBIANO" },
    { id: "palenquero", name: "PALENQUERO" },
    { id: "indigena", name: "INDÍGENA" },
    { id: "raizal", name: "RAIZAL" },
    { id: "rom_gitano", name: "ROM O GITANO" },
    { id: "mulato", name: "MULATO" },
  ];
  // let options = new Map();
  // ethnical_group.forEach((s) => {
  //   const value = { key: s[0], name: s[1], id: s[0] };
  //   options.set(value.key, value);
  // });

  return ethnical_group;
};

const SPECIAL_POPULATION = () => {
  const special_population = [
    ["na", "N.A"],
    ["lgbti", "LGBTI"],
    ["displaced", "DESPLAZADO"],
    ["victim_of_violence", "VICTIMA DE VIOLENCIA"],
    ["disabled_person", "PERSONA CON DISCAPACIDAD"],
    ["pregnant_woman", "MUJER GESTANTE"],
    ["homeless", "HABITANTE DE CALLE"],
    ["deprived_of_freedom", "PRIVADO DE LA LIBERTAD"],
    ["nursing_mother", "MADRE LACTANTE"],
    ["older_adult", "ADULTO MAYOR"],
  ];

  let options = new Map();
  special_population.forEach((s) => {
    const value = { key: s[0], name: s[1], id: s[0] };
    options.set(value.key, value);
  });

  return options;
};

// const DIVERSITY = () => {
//   const diversity_sexual = [
//     ["N.A", "N.A"],
//     ["heteresexual", "HETEROSEXUAL"],
//     ["gay", "GAY"],
//     ["lesbiana", "LESBIANA"],
//     ["travesti", "TRAVESTI"],
//     ["bisexual", "BISEXUAL"],
//   ];
//   let options = new Map();
//   diversity_sexual.forEach((s) => {
//     const value = { key: s[0], name: s[1], id: s[0] };
//     options.set(value.key, value);
//   });

//   return options;
// };

// const OTHER_OPTIONS = () => {
//   const diversity_sexual = [
//     ["N.A", "N.A"],
//     ["yes", "Si"],
//     ["no", "No"],
//   ];
//   let options = new Map();
//   diversity_sexual.forEach((s) => {
//     const value = { key: s[0], name: s[1], id: s[0] };
//     options.set(value.key, value);
//   });

//   return options;
// };

const onChangeParty = async (storeRec, activeRecord) => {
  if (activeRecord) {
    // const partyId = activeRecord.party.id;
    activeRecord.phone = activeRecord.party.mobile;
    activeRecord.email = activeRecord.party.email;
    activeRecord.city_region = activeRecord.party["city_attention."];

    storeRec.phone = activeRecord.party.mobile;
    storeRec.email = activeRecord.party.email;
    storeRec.city_region = activeRecord.party["city_attention."].id;
  }
  return storeRec, activeRecord;
};

const searchProvider = (value, activeRecord) => {
  let search = [
    ["city", "=", activeRecord?.city_region?.id],
    ["asset", "=", true],
  ];
  if (value) search.push(["rec_name", "ilike", `%${value}%`]);
  return search;
};

const searchReason = (value, record) => {
  if (!record.macromotive) return [["id", "=", -1]];
  return [["parent", "=", `%${record.macromotive}%`]];
};

const getRequired = (record) => {
  const res = record?.case?.required_attach;
  return res;
};

const ViewFiduprevisora = {
  model: "crm.customer_service",
  webfields: {
    number: {
      type: "char",
      readOnly: true,
    },
    personal_information: {
      type: "divider",
      text: "crm.customer_service.personal_information",
    },
    pqrs_information: {
      type: "divider",
      text: "crm.customer_service.pqrs_information",
    },
    demographic_information: {
      type: "divider",
      text: "crm.customer_service.demographic_information",
    },
    description_facts: {
      type: "divider",
      text: "crm.customer_service.description_facts",
    },
    attachment: { type: "divider", text: "Adjuntos" },
    divider: { type: "divider" },
    party: {
      type: "many2one",
      model: "party.party",
      recSearch: searchParty,
      withChange: onChangeParty,
      readOnly: true,
      search: true,
      attrs: ["phone", "city_attention.name", "id", "name", "id_number"],
    },
    city_region: {
      type: "many2one",
      model: "party.city_code",
      readOnly: true,
    },
    email: { type: "char", required: true },
    phone: { type: "char", required: true },
    address: { type: "char", required: true },
    description: {
      type: "text",
      required: true,
      labeled: false,
      placeholder: "Describe tu caso aqui",
      colspan: 2,
    },
    health_provider: {
      type: "many2one",
      model: "crm.health_provider",
      required: true,
      // readOnly: true,
      recSearch: searchProvider,
    },
    detailed_especific_1: {
      type: "many2one",
      model: "list.specialty",
      required: true,
      limit: 100,
    },
    // case: {
    //   type: "many2one",
    //   model: "crm.case",
    //   required: true,
    //   attrs: ["required_attach", "id", "name"],
    //   limit: 100,
    // },
    macromotive: {
      type: "many2one",
      model: "crm.case",
      required: false,
      recSearch: () => [["macroprocess", "=", "macroproceso"]],
      limit: 100,
    },
    // general_reason: {
    //   type: "many2one",
    //   model: "crm.case",
    //   required: false,
    //   recSearch: searchReason,
    //   limit: 100,
    // },
    ethnical_group: {
      type: "selection",
      options: ETHNICAL_GROUP(),
      required: true,
    },
    special_population: {
      type: "multiselection",
      options: SPECIAL_POPULATION(),
      data_source: SPECIAL_POPULATION(),
    },
    // displaced: {
    //   type: "selection", data_source: OTHER_OPTIONS(), required: true,
    // },
    // victim_of_violence: {
    //   type: "selection", data_source: OTHER_OPTIONS(), required: true,
    // },
    // disabled_person: {
    //   type: "selection", data_source: OTHER_OPTIONS(), required: true,
    // },
    // pregnant_woman: {
    //   type: "selection", data_source: OTHER_OPTIONS(), required: true,
    // },
    // homeless: {
    //   type: "selection", data_source: OTHER_OPTIONS(), required: true,
    // },
    // deprived_of_freedom: {
    //   type: "selection", data_source: OTHER_OPTIONS(), required: true,
    // },
    // nursing_mother: {
    //   type: "selection", data_source: OTHER_OPTIONS(), required: true,
    // },
    // older_adult: {
    //   type: "selection", data_source: OTHER_OPTIONS(), required: true,
    // },
    attach_customer_1: {
      type: "file",
      required: getRequired,
      help: "Adjunta documentos como: orden médica, formula médica, remisión a especialista, fórmula médica, historia clínica, pendiente de farmacia",
    },
    create_service: {
      type: "button",
      method: "create_service",
      onSave: true,
    },
  },
  // webtree: [
  //   { name: "number", width: "8%" },
  //   { name: "sale_date", width: "10%" },
  //   { name: "consumer", width: "30%" },
  //   { name: "waiting_time", width: "5%" },
  //   { name: "payment_method", width: "7%" },
  //   { name: "order_status", width: "10%" },
  //   { name: "total_amount", width: "10%" },
  //   { name: "billing", width: "8%", stacked: ["print"] },
  //   { name: "state", width: "7%" },
  // ],
  webform: [
    { name: "number" },
    { name: "personal_information" },
    { name: "party" },
    { name: "email" },
    { name: "phone" },
    { name: "address" },
    { name: "pqrs_information" },
    { name: "city_region" },
    { name: "health_provider" },
    // { name: "case" },
    { name: "macromotive" },
    // { name: "general_reason" },
    { name: "detailed_especific_1" },
    { name: "description_facts" },
    { name: "description" },
    // { name: "demographic_information" }, this is title
    { name: "ethnical_group" },
    { name: "special_population" },
    { name: "attachment" },
    { name: "attach_customer_1" },
    // { name: "divider" },
    { name: "create_service" },
  ],
};

export default ViewFiduprevisora;
