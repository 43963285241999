import React from "react";

import Board from "components/Meta/Board";
import restOrder from "./Sale";
import { useStoreRest } from "./storeRest";

const SaleOrder = ({ config }) => {
  const { setTables } = useStoreRest();
  setTables();
  return <Board ctxView={restOrder.ctxView(config)} />;
};

export default SaleOrder;
