import React from "react";

import FormWizard from "components/MeForm/FormWizard";
import ModelWizardPayment from "./ModelWizardPayment";
import { useWizardStore } from "store/wizardStore";
import mainStore from "store/mainStore";
import { useBtnLoading } from "store/btnLoading";
import proxy from "api/proxy";

const ctxView = ModelWizardPayment.ctxView();

const WizardAddPayment = ({ onClose }) => {
  const { store, reset } = useWizardStore();
  const { record: recordParent } = mainStore();
  const { setButtonLoading } = useBtnLoading();

  async function addPayment() {
    if (!recordParent.party) {
      onClose(false, "missing_party");
      setButtonLoading(false);
      return;
    }
    let toStore = {
      booking: recordParent.id,
      statement: store.statement,
      amount_to_pay: store.amount,
      number: store.voucher,
      party: recordParent.party.id,
    };
    const values = {
      model: "hotel.booking",
      method: "add_payment",
      args: [toStore],
    };
    const { error } = await proxy.methodCall(values);
    onClose(true, error);
    await reset();
  }

  return (
    <FormWizard
      key="hotel-booking-wizard-payment"
      ctxView={ctxView}
      parentRec={recordParent}
      handleButton={addPayment}
    />
  );
};

export default WizardAddPayment;
