import React from "react";
import {
  HomeIcon,
  ArrowRightOnRectangleIcon,
  ArrowLeftOnRectangleIcon,
  ClockIcon,
} from "@heroicons/react/20/solid";

const ItemRowPanel = ({ data }) => {
  const roomDayCheckIn = new Date(data?.last_check_in);
  const roomDayCheckIn_ = roomDayCheckIn.toISOString().split("T")[0];
  const roomHour = roomDayCheckIn.toISOString().split("T")[1].substring(0, 8);
  return (
    <div
      className="bg-gray-50 mt-2 border-gray-100 border max-h-max text-black text-left overflow-hidden rounded-md  flex space-x-2 justify-between items-center divide-x"
      title={`Estado de la habitacion: ${data.state}`}
    >
      <div className=" h-full w-1/3 flex items-center space-x-2 align-middle justify-start text-xs overflow-hidden">
        <HomeIcon
          className={`w-10 ${
            data.state == "clean"
              ? "bg-lime-300 fill-lime-500  stroke-lime-500"
              : "bg-rose-300 fill-rose-500 stroke-rose-500"
          }   h-full px-3 py-2.5 fill-white stroke-white`}
        />{" "}
        <span className="text-[12px]">{data?.name}</span>
      </div>
      <div className="flex w-2/3 items-center  align-middle justify-between py-2 px-2">
        <span className="text-sm flex items-start space-x-2">
          <ArrowRightOnRectangleIcon className="w-5  text-gray-500 " />{" "}
          <span
            className="text-[10px] text-gray-500 truncate"
            title={`Hora  ${roomHour}`}
          >
            {roomDayCheckIn_}
          </span>
        </span>
        {data.last_check_out ? (
          <span className="text-sm flex items-start space-x-2 overflow-hidden">
            <ArrowLeftOnRectangleIcon className="w-5  text-gray-500 " />{" "}
            <span className="text-[10px] text-gray-500 truncate">
              {" "}
              {roomDayCheckIn_}
            </span>
          </span>
        ) : (
          <span className="text-sm flex items-start space-x-2 overflow-hidden">
            <ClockIcon className="w-5  text-gray-500 " />{" "}
            <span className="text-[10px] text-gray-500 truncate">
              {" "}
              {roomHour}
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

export default ItemRowPanel;
