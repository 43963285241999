const activities = [
  {
    name: "crm.opportunity.panel.activities_today",
    value: 14,
    color: "amber",
  },
  {
    name: "crm.opportunity.panel.activities_tomorrow",
    value: 3,
    color: "lime",
  },
  {
    name: "crm.opportunity.panel.activities_pending",
    value: 2,
    color: "rose",
  },
];

const leads = [
  {
    name: "crm.opportunity.panel.leads",
    type: "title",
  },
  {
    name: "crm.opportunity.panel.leads_draft",
    value: 6,
    color: "blue",
  },
  {
    name: "crm.opportunity.panel.leads_convert",
    value: 12,
    color: "zinc",
  },
];

const winRate = [
  {
    name: "crm.opportunity.panel.win_rate_today",
    value: "13",
    icon: "%",
  },
  {
    name: "crm.opportunity.panel.win_rate_yesterday",
    value: "7",
    icon: "%",
  },
];

export { activities, leads, winRate };
