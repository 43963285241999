import React, { Fragment, useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { FormattedMessage as FM } from "react-intl";

const GalleryItem = ({ data = [], colors }) => {
  const numData = data.length >= 6;
  const [showMore, setShowMore] = useState(false);

  const AmenitiesItem = ({ data, key }) => {
    return (
      <div
        key={key}
        className="flex space-x-1 items-center max-w-max text-left text-[9px] mt-3 md:mt-0"
      >
        <span>
          <CheckCircleIcon
            className={`w-5 relative -top-[1px] ${colors?.colorIcon || ""}`}
            fill={colors?.colorIcon || ""}
          />
        </span>
        <span className="max-w-max capitalize">{data}</span>
      </div>
    );
  };

  return (
    <Fragment>
      {numData ? (
        <div className="relative mx-auto mb-5 mt-3 z-20 w-full">
          <div className="my-auto gap-x-1 text-md leading-6  text-center bg-gray-50 w-full px-6 pb-3 rounded-md">
            <div className="grid grid-cols-2 md:grid-cols-5  space-y-2 md:space-y-0 md:py-3">
              {data.slice(0, 5).map((item, index) => (
                <AmenitiesItem key={index} data={item} />
              ))}
            </div>
            <div
              className="text-left w-full text-[9px] uppercase mt-2 cursor-pointer underline text-blue-presik"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "" : <FM id="booking.widget.screen.view_more" />}
            </div>
          </div>
          {showMore && (
            <div className="absolute w-full bg-gray-50   md:pt-0 pb-6 gap-3 px-6 -mt-6">
              <div className="grid grid-cols-2 md:grid-cols-5 gap-y-2 ">
                {data.slice(5).map((item, index) => (
                  <AmenitiesItem key={`${index}-amen`} data={item} />
                ))}
              </div>
              <div
                className="text-left w-full text-[9px] uppercase mt-4 cursor-pointer underline"
                onClick={() => setShowMore(!showMore)}
              >
                <FM id="booking.widget.screen.view_less" />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="h-5" />
      )}
    </Fragment>
  );
};

export default GalleryItem;
