// Opportunity model
import date from "date-and-time";

import opportunity_line from "./ModelOpportunityLine";
import party from "./ModelParty";
import prospect from "./Prospect";
import dates from "tools/dates";
const stateColors = {
  lead: "slate",
  opportunity: "sky",
  converted: "amber",
  won: "lime",
  lost: "rose",
  cancelled: "indigo",
};

const TYPE_OPP = [
  { id: "sale", name: "sale" },
  { id: "contract", name: "contract" },
];

function onSendMail(rec) {
  let email = rec.party?.email | "gerente@presik.com";
  return email;
}

function getDateConversion(rec) {
  return "prueba";
}

const getFilters = () => {
  let last_90 = date.addDays(new Date(), -90);
  last_90 = date.format(last_90, "YYYY-MM-DD");
  return {
    lead: [
      ["state", "=", "lead"],
      // ["agent", "=", session.agent],
    ],
    opportunity: [
      ["state", "=", "opportunity"],
      // ["agent", "=", session.agent],
    ],
    more_probabilty: [
      ["conversion_probability", ">=", 0.5],
      // ["agent", "=", session.agent],
    ],
    last_90: [
      ["start_date", ">=", last_90],
      // ["agent", "=", session.agent],
      // ["shop", "=", session.agent],
    ],
  };
};

const visibleButton = (name, record) => {
  let res = false;
  if (record) {
    if (name === "opportunity" && record.state === "lead") {
      res = true;
    } else if (name === "converted" && record.state === "opportunity") {
      res = true;
    } else if (name === "won" && record.state === "converted") {
      res = true;
    }
    if (name === "lost" && record.state === "opportunity") {
      res = true;
    }
    if (name === "cancelled") {
      res = true;
    }
  }
  return res;
};

const getView = (config) => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    limit: 100,
    model: "crm.opportunity",
    activeSearch: true,
    filters: getFilters,
    title: { field: "number", model: true },
    form_action: ["add", "edit"], // options: ['save', 'delete']
    table_action: ["open", "add"], // options: ['open', 'delete', 'edit', 'add']
    reports: [
      {
        name: "crm.opportunity",
        description: "Cotización para el cliente",
        send_print: true,
        send_email: "send_email",
      },
      {
        name: "crm.opportunity.brochure",
        description: "Analisis de Viabilidad Estrategica",
        send_print: true,
        send_email: onSendMail,
      },
    ],
    validate_state: true, // ??? What is this?
    tags: {
      state: stateColors,
    },
    webfields: {
      number: { type: "char", readOnly: true, searchable: true },
      reference: { type: "char" },
      party_contact: { type: "char", searchable: true },
      contact_phone: { type: "char", required: true },
      contact_email: { type: "char" },
      party_category: {
        type: "many2one",
        model: "party.category",
        searchable: true,
      },
      "sales.sale_date": {
        type: "char",
        search: true,
        function: getDateConversion,
      },
      prospect: {
        type: "many2one",
        model: "crm.prospect",
        // required: true,
        ctxView: prospect.ctxView(),
      },
      start_date: {
        type: "date",
        readOnly: false,
        searchable: true,
        required: true,
        default: dates.dateToday(),
      },
      end_date: {
        type: "date",
        // readOnly: false,
      },
      create_uid: {
        type: "many2one",
        model: "res.user",
        readOnly: true,
      },
      // company: {
      //   type: "many2one",
      //   required: true,
      //   model: "company.company",
      // },
      lead_origin: {
        type: "many2one",
        model: "crm.lead_origin",
      },
      agent: {
        type: "many2one",
        model: "commission.agent",
        searchable: true,
        // default: ,
      },
      conversion_probability: {
        type: "float",
        // required: true,
        format: "percent",
      },
      state: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "lead",
        tags: stateColors,
      },
      party: {
        type: "many2one",
        model: "party.party",
        searchable: true,
        // required: true,
        attrs: ["account_receivable"],
        ctxView: party.ctxView(config),
      },
      city: {
        type: "many2one",
        model: "party.city_code",
        searchable: true,
      },
      contact: {
        type: "many2one",
        model: "party.contact_mechanism",
        searchable: true,
      },
      address: {
        type: "many2one",
        model: "party.address",
      },
      type: {
        type: "selection",
        required: true,
        options: TYPE_OPP,
        default: { id: "sale", name: "sale" },
      },
      // payment_term: {
      //   type: "many2one",
      //   model: "account.invoice.payment_term",
      //   recSearch: () => "[]",
      //   readOnly: false,
      // },
      amount: { type: "numeric", decimalPlaces: 2 },
      description: { type: "char" },
      lines: {
        type: "one2many",
        model: "crm.opportunity.line",
        ctxView: opportunity_line.ctxView(config),
      },
      // persons: {
      //   type: "one2many",
      //   model: "crm.person",
      //   ctxView: person.ctxView(config),
      // },
      comment: {
        type: "text",
        readOnly: {
          // state: ["done", "confirmed", "cancelled", "processing"],
        },
      },
      opportunity: {
        type: "button",
        button_method: "opportunity",
        visible: visibleButton,
        color: "blue",
      },
      converted: {
        type: "button",
        button_method: "converted",
        visible: visibleButton,
        color: "green",
      },
      won: {
        type: "button",
        button_method: "won",
        visible: visibleButton,
        color: "amber",
      },
      lost: {
        type: "button",
        button_method: "lost",
        visible: visibleButton,
        color: "indigo",
      },
      cancelled: {
        type: "button",
        button_method: "cancelled",
        visible: visibleButton,
        color: "rose",
      },
    },
    webtree: [
      { name: "number", width: "10%" },
      { name: "prospect", width: "15%" },
      { name: "party_contact", width: "15%" },
      // { name: "conversion_probability", width: "15%" },
      { name: "start_date", width: "10%" },
      { name: "sales.sale_date", width: "10%" },
      { name: "party_category", width: "15%" },
      { name: "city", width: "10%" },
      { name: "agent", width: "20%" },
      { name: "state", width: "10%", widget: "circle" },
    ],
    webform: [
      { name: "prospect", widget: "search_add_new" },
      {
        id: "1",
        grid: [{ name: "start_date" }, { name: "number" }],
        size: [1, 2],
      },
      {
        id: "2",
        grid: [{ name: "contact_phone" }, { name: "city" }],
        size: [1, 2],
      },
      { name: "party", widget: "search_add_new" },
      // { name: "company" },
      {
        id: "3",
        grid: [{ name: "agent" }, { name: "lead_origin" }],
        size: [1, 2],
      },
      {
        id: "4",
        grid: [{ name: "party_category" }, { name: "conversion_probability" }],
        size: [1, 2],
      },
      { name: "description" },
      {
        id: "5",
        grid: [{ name: "type" }, { name: "amount" }],
        size: [1, 2],
      },
      { name: "contact_email" },
      { name: "end_date" },
      { name: "lines" },
      { name: "state" },
      { name: "comment" },
      {
        id: "6",
        grid: [
          { name: "cancelled" },
          { name: "opportunity" },
          { name: "converted" },
          { name: "lost" },
          { name: "won" },
        ],
        size: [1, 5],
        span: "col-span-2",
      },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
