import React from "react";
import date from "date-and-time";

import RackRecord from "./RackRecord";
import Room from "./Room";
import helper from "./helper";
import { tableStyle } from "./constants";
import { classNames } from "tools/ui";

const fmtDate = "YYYY-MM-DD";
const styleBadge = "ml-2 text-[0.6rem] p-1 rounded-md";

function RoomRow(props) {
  const { dates, data, room, initialDate } = props;
  let daysTd = dates.map((day, index) => {
    const currentDay = day.toDateString();
    const _records = data.filter((booking) => {
      let from_date;
      let from_date_map;
      if (initialDate > booking.from_date) {
        from_date = date.parse(initialDate, fmtDate);
        from_date_map = initialDate;
      } else {
        from_date = new Date(booking.from_date);
        from_date = date.addDays(from_date, 1);
        from_date_map = booking.from_date;
      }

      // calculate number of days for booking
      booking.duration = helper.durationOfDays(booking.to_date, from_date_map);
      return from_date.toDateString() === currentDay ? true : false;
    });

    const recordsRack = _records.map((operation) => {
      return <RackRecord operation={operation} key={operation.id} />;
    });

    return (
      <Room key={index} day={day} room={room}>
        {recordsRack}
      </Room>
    );
  });

  const { roomRow, border, cellStyle } = tableStyle;
  return (
    <tr key={room.id} className={classNames(cellStyle)}>
      <td className="text-zinc-600">
        <span className="pl-2 text-sm font-bold">{room.name}</span>
        <span className={classNames(styleBadge, room.categoryColor)}>
          {room.categoryName}
        </span>
      </td>
      {daysTd}
    </tr>
  );
}

export default RoomRow;
