const getView = () => {
  let ctxView = {
    model: "rental.service.product_check_list",
    selectable: "one",
    activeSearch: true,
    filters: null,
    form_action: ["save", "add"],
    table_action: ["add"],
    // selectable: "one",
    webfields: {
      kind: { type: "char" },
      state_delivery: { type: "boolean", editable: true },
      state_return: { type: "boolean", editable: true },
      rental_service: {
        type: "many2one",
        model: "rental.service",
        translate: true,

        // ctxView: ModelParty.ctxView(),
        // attrs: ["account_receivable"],
      },
      item: {
        type: "many2one",
        model: "product.check_list_item",
        // ctxView: ModelParty.ctxView(),
        // attrs: ["account_receivable"],
      },
    },
    webtree: [
      { name: "item" },
      // { name: "rental_service" },
      { name: "state_delivery" },
      { name: "state_return" },
    ],
    webform: [
      { name: "kind" },
      { name: "item" },
      { name: "rental_service" },
      { name: "state_delivery" },
      { name: "state_return" },
    ],
  };

  return ctxView;
};

export default { ctxView: getView };
