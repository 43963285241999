import React from "react";

import Board from "components/Meta/Board";
import statement from "./ModelStatement";
import Toolbar from "./Toolbar";
import funcs from "tools/functions";
import SelectDevice from "./SelectDevice";

const Statement = ({ config }) => {
  const terminal = funcs.getCookie();
  const ctxView = statement.ctxView(config);
  return (
    <div className="w-full">
      <Toolbar />
      <Board ctxView={ctxView} style="max-w-7xl" />
      {!terminal.device_id && <SelectDevice />}
    </div>
  );
};

export default Statement;
