import React from "react";
import imgMelhous from "assets/img/logo.png";

// const imgMelhous =
//   "https://www.terrabiohotel.com/wp-content/uploads/2020/03/Logo-TerraBioHotel.png";
const LogoVersus = () => {
  return (
    <img
      src="https://res.cloudinary.com/dl4sdiukx/image/upload/v1710185075/rjmyr42neqsg3u050y82.png"
      className="max-w-[120px] filter grayscale contrast-200"
    />
  );
};

export default LogoVersus;
