import React from "react";
import { FormattedMessage as FM } from "react-intl";

import ColForm from "components/MeForm/ColForm";
import upStore from "store/upStore";

function CheckboxField(props) {
  const { field, attrs, data } = props;
  const { name, readOnly, label } = field;
  const { level } = attrs;

  let checked = data.value ?? false;

  function handleCheck() {
    if (readOnly) {
      return;
    }
    // let child;
    // if (props.recId) {
    //   child = { id: props.recId };
    // }
    // props.onChange(name, !checked, child);
    data.value = !data.value;
    upStore(level, {
      fieldName: name,
      value: data.value,
      field: field,
    });
  }

  return (
    <ColForm {...field}>
      <label key={name} className="flex mt-10 pl-1">
        <input
          id={name}
          type="checkbox"
          name={name}
          onChange={handleCheck}
          readOnly={readOnly}
          disabled={readOnly}
          checked={data.value ?? false}
          className="h-5 w-5 rounded-lg accent-sky-600"
        />
        <p className="text-sm ml-4">
          <FM id={label} key={name} />
        </p>
      </label>
    </ColForm>
  );
}

export default CheckboxField;
