import React, { useState } from "react";

import Table from "components/MeTable/Table";
import TableHeader from "components/MeTable/TableHeader";
import BodyTable from "components/MeTable/BodyTable";
import SubtableHeader from "components/MeTable/SubtableHeader";
import ModalForm from "components/Modals/ModalForm";
import mainStore from "store/mainStore";
import childStore from "store/childStore";
import grandStore from "store/grandStore";
import editTable from "store/editTable";

function SubTable(props) {
  const { field, attrs, data } = props;
  const { readOnly, model, ctxView } = field;
  const { level: _level, parentRec } = attrs;
  // const { order, order_by } = ctxView;
  const { selectable } = ctxView;
  let level = _level;
  if (_level === "child") {
    level = "grand";
  } else if (_level === "main") {
    level = "child";
  }

  const [openModalForm, setOpenModalForm] = useState(false);
  const [openMode, setOpenMode] = useState("edit");

  // Child record opened in modal
  const { store } = mainStore();
  const {
    setRecord,
    record: recordChild,
    newRecord: newChild,
    updateRecord: updateChildRecord,
    updateStore: updateChildStore,
  } = childStore();
  const { newRecord: newGrand, setRecord: setRecordGrand } = grandStore();

  // FIXME
  // let orderDirection;
  // if (order) {
  //   orderDirection = order === "asc" ? "ascending" : "descending";
  // }

  // deprecated
  function updateRecords(field, value, recordId) {
    const records = store[field.name];
    let record = records.get(recordId);
    const storeTable = record[field.name];
    let storeChild = storeTable?.get("write")?.get(recordId);
    if (!storeChild) {
      storeChild = { id: recordId };
    }
    storeChild[field] = value;
    record[field] = value;
    const args = {
      action: "write",
      record: record,
      activeRecord: storeChild,
    };
    editTable(args);
  }

  async function handleAddModal() {
    if (level === "child") {
      setOpenMode("add");
      newChild(ctxView, parentRec);
    } else {
      newGrand(ctxView, parentRec);
    }
    setOpenModalForm(true);
  }

  async function onActionButton(event, action, record) {
    const records = [record];
    if (action === "delete") {
      editTable({ level, action, records, fieldName: field.name });
    } else {
      setOpenMode("edit");
      if (level === "child") {
        if (record.id > 0) {
          await setRecord(record.id, model, ctxView);
        } else {
          updateChildRecord(record);
          const { store } = mainStore.getState();
          const storeField = store[field.name];
          const storeAction = storeField.get("create");
          const storeChildCurrent = storeAction.get(record.id);
          updateChildStore(storeChildCurrent);
        }
      } else if (level === "grand") {
        setRecordGrand(record.id, model, ctxView);
      }
      setOpenModalForm(true);
    }
  }

  function handleSort() {}

  function onClose() {
    setOpenModalForm(false);
  }

  return (
    <div
      id="subtable"
      className="w-full sm:p-2 col-span-2 rounded-md border border-slate-400 border-dashed  overflow-scroll xl:overflow-visible"
    >
      <SubtableHeader
        ctxView={ctxView}
        handleAddModal={handleAddModal}
        readOnly={readOnly}
      />
      <Table>
        <TableHeader
          ctxView={ctxView}
          handleSort={handleSort}
          parentRec={parentRec}
        />
        <BodyTable
          ctxView={ctxView}
          selectable={selectable}
          records={data.value}
          onActionButton={onActionButton}
          onClose={onClose}
          updateRecords={updateRecords}
          onClickRow={props.onClickRow}
          onDoubleClickRow={props.onDoubleClickRow}
          parentRec={parentRec}
          multiselect={props.multiselect}
          fieldName={field.name}
          level={level}
        />
      </Table>
      {openModalForm && recordChild && (
        <ModalForm
          onClose={onClose}
          ctxView={ctxView}
          parentRec={parentRec}
          open={openModalForm}
          level={level}
          mode={openMode}
          fieldName={field.name}
        />
      )}
    </div>
  );
}

export default SubTable;
