import React, { useState } from "react";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import upStore from "store/upStore";
import StdButton from "components/Buttons/StdButton";
import FormField from "components/MeForm/FormField";

import {
  CLOUDINARY_UPLOAD_PRESET,
  CLOUDINARY_UPLOAD_URL,
  CLOUDINARY_API_KEY,
} from "env";

function TakeCapture(props) {
  console.log(props);
  const { field, data, attrs } = props;
  const { name } = field;
  const { parentRec, record, level } = attrs;
  let [active, setActive] = useState(false);
  let [photo, setPhoto] = useState(data.value);

  function onActiveCamera() {
    setActive(true);
    setPhoto(null);
  }

  async function onTakePhoto(dataUri) {
    console.log(CLOUDINARY_API_KEY);
    console.log(CLOUDINARY_UPLOAD_PRESET);
    console.log(CLOUDINARY_UPLOAD_URL);
    setActive(false);
    var formData = new FormData();
    formData.append("file", dataUri);
    formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
    formData.append("api_key", CLOUDINARY_API_KEY);
    const config = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(CLOUDINARY_UPLOAD_URL, config);
    const res = await response.json();
    setPhoto(res.secure_url);
    // props.onChange(name, res.secure_url);
    const args = {
      field,
      parentRec,
      recId: record.id,
      fieldName: field.name,
      value: res.secure_url,
    };
    console.log(args, "aca");
    upStore(level, args);
  }

  return (
    <FormField {...field}>
      <StdButton onClick={onActiveCamera} size={"w-full"}>
        Take a photo
      </StdButton>
      {active ? (
        <Camera
          sizeFactor={0.5}
          className="mx-auto my-4"
          onTakePhoto={(dataUri) => {
            onTakePhoto(dataUri);
          }}
          isImageMirror={false}
        />
      ) : (
        <img key={name} src={photo} className="my-12 mx-auto w-48" />
      )}
    </FormField>
  );
}

export default TakeCapture;
