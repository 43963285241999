import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "chartjs-plugin-annotation";

import ChartCard from "components/Chart/ChartCard";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  responsive: true,
  maintainAspectRatio: false,
};

function DoughnutChart({ data, name, getReport }) {
  const dataObj = {
    labels: data.labels,
    datasets: [
      {
        label: "Valor",
        data: data.values,
        backgroundColor: [
          "rgba(141, 193, 75, 0.6)",
          "rgba(255, 255, 255, 0.6)",
        ],
        borderColor: ["rgba(141, 193, 75, 1)", "rgba(150, 150, 150, 1)"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <ChartCard
      header={name}
      header_meta={data.header_meta}
      desc={data.desc}
      desc_meta={data.desc_meta}
      getReport={getReport}
    >
      <div className="chart-segment-container">
        <Doughnut data={dataObj} height={285} options={options} />
      </div>
      <div className="text-center text-3xl text-cyan-600">
        {data.center_label}
      </div>
    </ChartCard>
  );
}

export default DoughnutChart;
