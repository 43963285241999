import React, { Fragment } from "react";
import { signal } from "@preact/signals-react";
import { FormattedMessage as FM } from "react-intl";

import proxy from "api/proxy";
import QuickTable from "components/Meta/QuickTable";
import PanelRight from "components/Panel/PanelRight";
import StdButton from "components/Buttons/StdButton";
import { IdentificationIcon } from "@heroicons/react/20/solid";

const currentTurn = signal(false);

const ScreenSystenControl = ({ ctxView, control, position }) => {
  const _ctxView = { ...ctxView };

  const nextTurn = async (e, next) => {
    e.preventDefault();
    const argGetTurn = {
      model: "ticket_system.ticket_control",
      method: "close_ticket",
      args: [
        {
          next: next,
          position: position?.id || {},
          ticket_control: control.id,
        },
      ],
    };
    const { data } = await proxy.methodCall(argGetTurn);
    if (data) {
      currentTurn.value = data;
    } else {
      currentTurn.value = {};
    }
  };

  const ContentCurrentTurn = () => {
    return (
      <div className="py-3 px-2 rounded-md mt-3">
        {Object.keys(currentTurn.value).length !== 0 ? (
          <Fragment>
            <div className="text-[80px] font-bold text-center relative w-36 h-36 shadow-md bg-white mx-auto rounded-full flex justify-center items-center mb-5">
              <span>{currentTurn?.value.turn || 2}</span>
              <span className="absolute !text-xs bg-blue-presik rounded-md py-1 px-4 -bottom-2  text-white left-auto flex justify-center space-x-3 items-center">
                <IdentificationIcon className="w-5" />
                <span>{currentTurn?.value.number_id}</span>
              </span>
            </div>
            {currentTurn?.value?.name && (
              <div className="flex flex-col justify-center text-xs space-y-1">
                <span>
                  <b>Nombre:</b> {currentTurn?.value?.name}
                </span>
                <span>
                  <b>Email:</b> {currentTurn?.value?.email}
                </span>
                <span>
                  <b>Telefono:</b> {currentTurn?.value?.mobile}
                </span>
              </div>
            )}
          </Fragment>
        ) : (
          <p>{<FM id="ticket_system.ticke_not_found" />}</p>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-row justify-between">
      <div className="md:w-[80vw] xl:w-[70vw] ">
        <QuickTable ctxView={_ctxView} />
      </div>
      <div className="md:w-[30vw] xl:w-[20vw]  flex justify-end text-center">
        <PanelRight
          position={"right"}
          widgets={["status", "numPeople"]}
          title={position?.number}
          bgColor="bg-gray-100"
          status={true}
          handleChangeStatus={false}
          displayOpen={true}
        >
          {currentTurn.value ? (
            <ContentCurrentTurn />
          ) : (
            <p>{<FM id="ticket_system.ticket.add_turn" />}</p>
          )}

          <StdButton
            onClick={(e) => nextTurn(e, true)}
            style={"w-full"}
            color={"bluePresik"}
          >
            {currentTurn.value ? (
              <FM id="ticket_system.ticket.next_turn" />
            ) : (
              <FM id="ticket_system.ticket.take_turn" />
            )}
          </StdButton>
          {currentTurn.value && Object.keys(currentTurn.value).length !== 0 && (
            <StdButton
              onClick={(e) => nextTurn(e, false)}
              next={false}
              style={"w-full"}
              color={"yellowPresik"}
            >
              <FM id="ticket_system.ticket.finish_turn" />
            </StdButton>
          )}
        </PanelRight>
      </div>
    </div>
  );
};

export default ScreenSystenControl;
