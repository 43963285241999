import React, { useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import LogoCompany from "pages/Apps/LogoCompany";
import TextField from "components/MeForm/TextField";
import StdButton from "components/Buttons/StdButton";
import FullTable from "components/MeTable/FullTable";
import { ctxView } from "./ModelCustomerService";
import proxy from "api/proxy";

function ConsultPQR() {
  const [number, setNumber] = useState(null);
  const [party_id_number, setParty_id_number] = useState(null);
  let [records, setRecords] = useState([]);
  let [msg, setMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  function onChange(field, value) {
    setMsg(false);
    if (field === "number") {
      setNumber(value);
    } else {
      setParty_id_number(value);
    }
    const _btnDisabled = number && party_id_number ? false : true;
    setBtnDisabled(!_btnDisabled);
  }

  async function handleSearch() {
    setLoading(true);
    const dom = [
      ["number", "=", number],
      ["party.id_number", "=", party_id_number],
    ];
    const fields = [
      "number",
      "state",
      "cs_date",
      "party.rec_name",
      "case.rec_name",
      "description",
      "effective_date",
      "health_provider.rec_name",
      "response",
    ];
    const { data } = await proxy.search("crm.customer_service", dom, fields);
    if (data.length > 0) {
      setRecords(data);
    } else {
      setMsg(true);
      setRecords([]);
    }
    setLoading(false);
  }

  const _ctxView = ctxView();

  return (
    <div className="">
      <LogoCompany />
      <div className="my-8">
        <h1 className="text-3xl font-bold text-gray-600 text-center">
          Consulta de Radicado PQRs
        </h1>
      </div>
      <div className="block sm:flex md:flex lg:flex gap-4 pb-6 columns-1 sm:columns-2 md:columns-2">
        <TextField
          name="number"
          label="crm.customer_service.number"
          onChange={onChange}
          placeholder="Ejemplo, UTRED-2023-00-0000"
          value={number || ""}
        />
        <TextField
          name="party_id_number"
          label="crm.customer_service.party_id_number"
          onChange={onChange}
          placeholder="Ejemplo, 2222222222"
          value={party_id_number || ""}
        />
        <StdButton
          onClick={handleSearch}
          size="w-full"
          loading={loading}
          disabled={btnDisabled}
          color="blue"
          content="crm.customer_service.search"
          style="mb-3 my-6 sm:mt-auto md:mt-auto lg:mt-auto"
        >
          <MagnifyingGlassIcon
            className="h-5 w-5 ml-3 text-white"
            aria-hidden="true"
          />
        </StdButton>
      </div>
      {msg && (
        <div className="my-2">
          <p className="text-lg text-rose-600 text-left py-4">
            No hay registros encontrados, verifique el número de radicado o su
            número de cédula.
          </p>
        </div>
      )}
      <FullTable ctxView={_ctxView} records={records} />
    </div>
  );
}

export default ConsultPQR;
