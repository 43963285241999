import React from "react";
import { FormattedMessage as FM } from "react-intl";

import { classNames } from "tools/ui";
import intl from "i18n/messages";
import { tagsColor } from "./constants";

const base = "mr-4 h-8 inline-flex w-8 rounded-full px-2.5 py-0.5 border-[1px]";

function GroupTags() {
  const values = Object.entries(tagsColor);
  return (
    <div className="my-auto flex">
      {values.map(([name, color]) => {
        const _name = intl.msgs[`room.${name}`];
        return (
          <span key={name} title={_name} className={classNames(base, color)} />
        );
      })}
    </div>
  );
}

export default GroupTags;
