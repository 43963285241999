import React from "react";

import ButtonProduct from "./ButtonProduct";
import BasicModal from "components/Modals/BasicModal";
import { useWizardStore } from "store/wizardStore";
// import cats from "./test_data";

function ModalProducts(props) {
  const { record } = useWizardStore();
  function onClick(value) {
    props.onClicked(value);
    props.onClose();
  }

  function getProducts() {
    return props.products.map((product) => {
      let currentQty = 0;
      Array.from(record?.lines?.values()).forEach((item) => {
        if (item.product.id === product.id) {
          currentQty = currentQty + 1;
        }
      });
      // if (product.salable) {
      if (product.salable && product.quantity - currentQty > 0) {
        console.log(currentQty + product.quantity, "validate recor");
        return (
          <ButtonProduct key={product.id} {...product} onClick={onClick} />
        );
      }
    });
  }

  return (
    <BasicModal
      width="w-11/12"
      height="h-5/6"
      open={props.open}
      title="self_service_sale.select_product"
      titleSize="text-4xl text-slate-600"
      onClose={props.onClose}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-4 pt-10 mb-4">
        {getProducts()}
      </div>
    </BasicModal>
  );
}

export default ModalProducts;
