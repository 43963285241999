import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import { useRecordsCount } from "hooks/records";
import { classNames } from "tools/ui";

/**
 *
 * @param {*} props
 * @returns Component Pagination
 */

function Pagination(props) {
  const { ctxView, limit, offset, lenRecords } = props;

  let firstItem = 1;
  let lastItem = null;
  if (lenRecords) {
    firstItem = offset + 1;
    lastItem = offset + lenRecords;
  }

  const domain = ctxView.pagination;
  const countTotal = useRecordsCount(ctxView.model, domain);

  function onClick(value) {
    if (value === "previous" && firstItem === 1) {
      return;
    } else if (value === "next" && countTotal === lastItem) {
      return;
    }
    props.onChangePage(value);
  }

  function handlePage(page) {
    props.onChangePage(null, page);
  }

  let numPages = Math.ceil(countTotal / limit);
  if (countTotal < limit) {
    return null;
  }

  // Calculate the starting and ending page numbers for the visible range
  let startIndex = 1;
  let endIndex = numPages;

  const currentPage = Math.ceil(firstItem / limit);
  if (numPages > 5) {
    // If there are more than 5 pages, determine the visible range based on the current page
    const rangeOffset = Math.max(2, Math.floor(5 / 2));

    startIndex = Math.max(1, currentPage - rangeOffset);
    endIndex = Math.min(numPages, currentPage + rangeOffset);
    if (startIndex < 6 && endIndex < 6) {
      startIndex = 2;
      endIndex = 6;
    }
  }

  const visiblePages = Array.from(
    Array(endIndex - startIndex + 1),
    (_, i) => startIndex + i - 1,
  );

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{firstItem}</span> to{" "}
            <span className="font-medium">{lastItem}</span> of{" "}
            <span className="font-medium">{countTotal}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <div
              disabled={props.firstItem === 1}
              onClick={() => onClick("previous")}
              className="cursor-pointer relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </div>
            {visiblePages.map((page, idx) => {
              const bg_color =
                page === currentPage ? "bg-gray-100" : "bg-white";
              return (
                <div
                  key={idx}
                  onClick={() => handlePage(page)}
                  aria-current="page"
                  className={classNames(
                    "w-12 H-12 z-10 inline-flex cursor-pointer items-center border border-stone-300 px-4 py-2 text-sm font-semibold text-stone-500 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                    bg_color,
                  )}
                >
                  {page}
                </div>
              );
            })}
            <div
              onClick={() => onClick("next")}
              className="cursor-pointer relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
