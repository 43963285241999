import React from "react";

import FormField from "./FormField";
import upStore from "store/upStore";
import { classNames, colors } from "tools/ui";

const style =
  "block w-full h-24 rounded-md py-3 pb-2 px-2 text-gray-700 border border-slate-300 focus:outline-none focus:border focus:border-cyan-500";

function TextField(props) {
  const { field, attrs, data } = props;
  const { name, readOnly, placeholder } = field;
  const { level } = attrs;

  function onChange(event) {
    if (props.readOnly) {
      return;
    }
    data.value = event.target.value;
  }

  let _color = "bg-white";
  if (readOnly) {
    _color = colors.readOnly;
  }

  function onBlur() {
    upStore(level, {
      fieldName: name,
      value: data.value,
      field: field,
    });
  }

  const _value = data.value ?? "";
  return (
    <FormField {...field}>
      <textarea
        disabled={readOnly}
        rows={4}
        key={name}
        name={name}
        value={_value}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder ?? ""}
        className={classNames(_color, style)}
      />
    </FormField>
  );
}

export default TextField;
