import React, { Fragment, useRef, useEffect } from "react";
import { signal } from "@preact/signals-react";

import SignatureCanvas from "react-signature-canvas";
import StdButton from "components/Buttons/StdButton";
import mainStore from "store/mainStore";
import ModelRentalBooking from "../ModelRentalBooking";
import proxy from "api/proxy";
import {
  CLOUDINARY_UPLOAD_PRESET,
  CLOUDINARY_UPLOAD_URL,
  CLOUDINARY_API_KEY,
  // CLOUDINARY_UPLOAD_URL2,
  // CLOUDINARY_API_SECRET,
} from "env";

const btnDisabled = signal(true);
const isLoading = signal(false);
const cloudConf = signal({});
const textContract = signal(null);

const AddSignature = () => {
  let ctxView = ModelRentalBooking.ctxView();
  const signatureCanvasRef = useRef();
  const { record, store, setRecord } = mainStore();
  const session = proxy.getSession();

  const getConf = async () => {
    try {
      const fields = [
        "cloudinary_api_key",
        "cloudinary_api_secret",
        "cloudinary_upload_preset",
        "cloudinary_upload_url",
        "cloudinary_name",
      ];
      const { data, error } = await proxy.search(
        "company.company",
        [["id", "=", session.company]],
        fields,
      );

      if (error) {
        console.error("Error al obtener la configuración:", error);
      } else {
        cloudConf.value = data[0];
      }
    } catch (error) {
      console.error("Error inesperado:", error);
    }
  };

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
    btnDisabled.value = true; // Deshabilita el botón después de borrar la firma
  };

  useEffect(() => {
    getContract();
  }, []);

  useEffect(() => {
    getConf();
  }, []);

  const saveSignature = async () => {
    const signatureDataURL = signatureCanvasRef.current.toDataURL();
    let _activeRecord = { ...record };
    _activeRecord.signatureImage = signatureDataURL;

    try {
      const formData = new FormData();
      formData.append("file", signatureDataURL);
      formData.append(
        "upload_preset",
        CLOUDINARY_UPLOAD_PRESET || cloudConf.value?.cloudinary_upload_preset,
      );
      formData.append("api_key", CLOUDINARY_API_KEY);

      const config = {
        method: "POST",
        body: formData,
      };

      const response = await fetch(
        CLOUDINARY_UPLOAD_URL || cloudConf.value?.cloudinary_upload_url,
        config,
      );
      const res = await response.json();

      if (res) {
        const model = "rental.booking";
        const args = {
          model: model,
          storeRec: {
            id: store.id,
            signature: res.url,
            equipment: store.equipment,
          },
        };

        let addAsignatureBooking = await proxy.saveQuery(args);
        if (addAsignatureBooking) {
          // setImgSignature(res.url);
          btnDisabled.value = false;
        }
      } else {
        console.error(
          "Error al subir la imagen a Cloudinary:",
          response.statusText,
        );
      }
    } catch (error) {
      console.error("Error al subir la imagen a Cloudinary:", error);
    }
  };

  const actionProcess = async () => {
    // setStore({});
    let _storeRecord = { ...store };

    let createService = await proxy.buttonMethod({
      model: "rental.booking",
      method: "process",
      ids: [_storeRecord.id],
    });
    console.log(createService);
    isLoading.value = false;
    setRecord(record.id, "rental.booking", ctxView);
    clearSignature();
  };

  const getContract = async () => {
    const { data, error } = await proxy.search(
      "rental.configuration",
      [],
      ["rental_contract"],
    );
    let htmlContract = data[0].rental_contract;
    const replacements = {
      email: record.email,
      fullname: `${record.first_name} ${record.last_name}`,
      address: record.address,
      doc_number: record.doc_number,
    };
    Object.keys(replacements).forEach((key) => {
      console.log(replacements[key], " ---");
      const regex = new RegExp(`\\{\\{${key}\\}\\}`, "g");
      htmlContract = htmlContract.replace(regex, replacements[key]);
    });
    textContract.value = htmlContract;
  };
  return (
    <Fragment>
      <div className="text-lg max-h-96 overflow-y-scroll scroll-pl-6 pr-3 pl-3 mb-5">
        {textContract ? (
          <div>
            <div dangerouslySetInnerHTML={{ __html: textContract.value }} />
          </div>
        ) : (
          <p>El formato de contrato no esta configurado</p>
        )}
      </div>
      <div className="border border-dashed border-gray-400 p-3 col-span-2 mx-20">
        <SignatureCanvas
          ref={signatureCanvasRef}
          penColor="black"
          canvasProps={{
            width: 800,
            height: 120,
            className: "signature-canvas",
          }}
        />
        <button
          onClick={saveSignature}
          className="mr-5 bg-blue-presik rounded-full py-1 px-4 text-white"
        >
          Add
        </button>
        <button
          onClick={clearSignature}
          className="mr-5 bg-red-900  rounded-full py-1 px-4 text-white"
        >
          Clean
        </button>
      </div>
      <div className="py-5 gap-3 justify-center col-span-2 flex">
        <StdButton
          key="add"
          color="bluePresik"
          disabled={btnDisabled.value}
          loading={isLoading.value}
          onClick={actionProcess}
          size="w-1/3"
          content="rental.service.add_signature"
        />
      </div>
    </Fragment>
  );
};

export default AddSignature;
