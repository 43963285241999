import React from "react";

import FormField from "./FormField";
import intl from "i18n/messages";
import upStore from "store/upStore";
import { classNames, colors } from "tools/ui";

const style =
  "h-10 rounded-md w-full py-2 px-2 text-gray-700 border border-slate-300 focus:outline-none focus:border focus:border-cyan-500";

/**
 *
 * @param {*} props -
    const _valueRef = `${mode
 * @param {Boolean} readOnly -
 * @param {String} type -
 * @param {String} label -
 * @param {String} name -
 * @param {String} placeholder -
 * @param {String} value -
 * @param {Function} onChange -
 * @param {Boolean} translate -
 * @returns Component TextField
 */

function CharField(props) {
  const { field, attrs, data } = props;
  const { name, readOnly, placeholder, translate, uppercase } = field;
  const { level, ctxView } = attrs;

  let _value = data.value || "";
  if (_value && ctxView && translate) {
    const _valueRef = `${ctxView.model}.${_value}`;
    _value = intl.msgs[_valueRef] || _value;
  }

  let _color = props.color ?? "bg-white";

  if (field.readOnly) {
    _color = colors.readOnly;
  }
  let _style = style;
  if (field.className) {
    _style = field.className;
  }

  function setUpStore(_value) {
    upStore(level, {
      fieldName: name,
      value: _value,
      field: field,
    });
  }

  function onChange(event) {
    let _value = event.target.value;
    if (uppercase) {
      _value = _value.toUpperCase();
    }
    if (field.specialCharacters && field.type === "char") {
      _value = _value.replace(/[^\w\s]|[\dñ]/gi, "");
      _value = _value.replace("  ", " ").toUpperCase();
    }
    data.value = _value;
    if (props.onChange) {
      setUpStore(_value);
      props.onChange(name, _value);
    }
  }

  function onBlur(event) {
    let _value = event.target.value;
    setUpStore(_value);
    if (props.onChange) {
      props.onChange(name, _value);
    }
  }

  return (
    <FormField {...field}>
      <div className="relative rounded-md">
        <input
          key={name}
          id={name}
          name={name}
          className={classNames(_color, _style)}
          type={field.type || "text"}
          value={_value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={readOnly ?? false}
          placeholder={placeholder ?? ""}
        />
        {field.icon || null}
      </div>
    </FormField>
  );
}

export default CharField;
