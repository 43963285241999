import mainStore from "store/mainStore";
import childStore from "store/childStore";
import grandStore from "store/grandStore";
import { useWizardStore } from "store/wizardStore";
import funcs from "tools/functions";

function storeCtx(level) {
  let _useStore;
  if (level === "main") {
    _useStore = mainStore.getState();
  } else if (level === "child") {
    _useStore = childStore.getState();
  } else if (level === "grand") {
    _useStore = grandStore.getState();
  } else if (["many2one", "wizard"].includes(level)) {
    _useStore = useWizardStore.getState();
    return _useStore;
  }
  let { record, store, reset, save, reload, setRecord, newRecord } = _useStore;
  store = funcs.recToTryton(store);
  return { record, store, reset, save, reload, setRecord, newRecord };
}

export default storeCtx;
