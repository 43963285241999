import React from "react";

import ItemRow from "./ItemRow";
import records from "./test_data";
import "./OrderView.css";

// const groupColumns = [
//   { id: "commanded", name: "COMANDADA" },
//   { id: "in_preparation", name: "EN PREPARACION" },
//   { id: "dispatched", name: "DESPACHADA" },
// ];

function OrderViewer() {
  // const ctxView = saleOrder.ctxView(config)

  function convertData(records) {
    let data = [];
    for (const rec of Object.values(records)) {
      let cardData = {
        id: rec.id,
        titleCard: rec.table_assigned,
        contentMeta: rec.number,
        extraContent: rec.employee,
        status: "commanded",
      };
      cardData.content = rec.lines.map((line) => (
        <ItemRow key={line.id} {...line} />
      ));
      data.push(cardData);
    }
    return data;
  }

  // const data = convertData(records);
  // return <QuickCard records={data} session={session} columns={groupColumns} />;
  return <p>ACTUALIZAR ESTE COMPONENTE A TAILWIND</p>;
}

export default OrderViewer;
