import React, { Fragment } from "react";

/**
 * Custom Widget
 *
 * @param {object} props - component props
 * @return {Component} - Custom Fragment component
 */
function CustomWidget(props) {
  const { attrs, field, record } = props;
  const Component = field.component({ attrs, field, record });
  return <Fragment>{Component}</Fragment>;
}

export default CustomWidget;
