import React from "react";

import imageEmpty from "assets/img/image_empty.svg";
import FormField from "./FormField";

function ImageField(props) {
  const { field, data } = props;
  const { name, readOnly } = field;
  let size = "small";
  if (field.size) {
    size = field.size;
  }
  let src = data.value || imageEmpty;
  // src = imageEmpty;
  if (field.bytes && data.value) {
    src = "data:image/png;base64," + data.value;
  }

  return (
    <FormField {...field}>
      <img
        style={stylesCtx(size)}
        id={name}
        name={name}
        key={name}
        disabled={readOnly}
        src={src}
      />
    </FormField>
  );
}

const stylesCtx = (size) => {
  let maxWidth, maxHeight;
  if (size === "small") {
    maxWidth = 100;
    maxHeight = 100;
  } else if (size === "middle") {
    maxWidth = 200;
    maxHeight = 200;
  } else {
    maxWidth = 300;
    maxHeight = 300;
  }
  return {
    maxWidth,
    maxHeight,
    margin: "auto",
  };
};

export default ImageField;
