import React from "react";
import date from "date-and-time";

import { classNames } from "tools/ui";
import sleepImg from "./assets/sleep.png";
import travellerImg from "./assets/traveller.png";
import pillowImg from "./assets/pillow.png";
import checkedImg from "./assets/checked.png";
import enterImg from "./assets/enter.png";
import exitImg from "./assets/exit.png";

const base =
  "w-full h-full p-3 mb-3 border-2 border-zinc-300 rounded-xl border-dashed text-center";

function WidgetGuest(kind, record) {
  let title;
  let iconStatus = pillowImg;
  let personImg;
  let check_time;
  let size = "w-10 h-10 ";
  let titleStyle = "text-zinc-300";
  const {
    occupied_today,
    arrival_today,
    check_in_today,
    departure_today,
    check_out_today,
  } = record;
  if (kind === "check_in") {
    title = "Check In";
    if (!occupied_today) {
      if (arrival_today) {
        personImg = travellerImg;
        iconStatus = enterImg;
        titleStyle = "text-zinc-600";
        if (check_in_today) {
          iconStatus = checkedImg;
          check_time = check_in_today;
        }
      } else {
        size = "w-16 h-16";
      }
    } else {
      iconStatus = sleepImg;
      size = "w-16 h-16";
    }
  } else {
    title = "Check Out";
    if (!occupied_today) {
      if (departure_today) {
        personImg = sleepImg;
        iconStatus = exitImg;
        titleStyle = "text-zinc-600";
        if (check_out_today) {
          iconStatus = checkedImg;
          check_time = check_out_today;
        }
      } else {
        size = "w-16 h-16";
      }
    } else {
      iconStatus = sleepImg;
      size = "w-16 h-16";
    }
  }
  if (check_time) {
    check_time = date.parse(check_time, "HH:mm:ss", true);
    check_time = date.format(check_time, "hh:mm A");
  } else if (
    (departure_today && kind === "check_out") ||
    (arrival_today && kind === "check_in")
  ) {
    check_time = "_ _ : _ _";
  }

  if (record)
    return (
      <div key="widget-custom" className={base}>
        <h2 className={classNames("text-3xl mb-3 font-extrabold", titleStyle)}>
          {" "}
          {title}
        </h2>
        <div key="widget-custom-check" className="flex justify-center">
          {personImg && (
            <img key="sleep" className="w-20 h-20 mx-2" src={personImg} />
          )}
          <img
            key="icon-status"
            className={classNames("my-auto mx-4", size)}
            src={iconStatus}
          />
          {check_time && (
            <h2 className="my-auto text-3xl mx-2 text-stone-500 font-extrabold">
              {check_time}
            </h2>
          )}
        </div>
      </div>
    );
}

export default WidgetGuest;
