import React from "react";

import { useQuery } from "@tanstack/react-query";
import { FormattedMessage as FM } from "react-intl";

import ChartPaymentTerm from "./ChartPaymentTerm";
import ChartDailyAmount from "./ChartDailyAmount";
import CardsGroupTotal from "./CardsGropTotal";
import ChartTableProduct from "./ChartTableProduct";

import proxy from "api/proxy";
import tools from "tools/common";

function DashBoardSeller(props) {
  const { agent } = props;

  const { data: dataCharts } = useQuery(
    ["dataChartSeller", agent],
    async () => {
      const date = tools.dateToday();
      const model = "sale.sale";
      const method = "get_data_dashboard_seller";
      const args = [
        {
          date,
          agent: agent.id,
        },
      ];
      const { data } = await proxy.methodCall({ model, method, args });
      return data;
    },
  );

  return (
    <div className="w-full">
      <div
        id="grid-chart"
        className="grid grid-cols-1 sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
      >
        <CardsGroupTotal data={dataCharts && dataCharts} />
        <ChartDailyAmount data={dataCharts && dataCharts?.sales_daily} />
        <ChartPaymentTerm data={dataCharts && dataCharts?.payment_term} />
        <ChartTableProduct data={dataCharts && dataCharts?.products} />
      </div>
    </div>
  );
}

export default DashBoardSeller;
