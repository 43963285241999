import React from "react";

const StepHeader = (props) => {
  return (
    <div className="my-6">
      <h2 className="flex mx-auto text-4xl font-bold text-slate-700">
        {props.label}
      </h2>
    </div>
  );
};

export default StepHeader;
