import React from "react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";

import { BUTTON_COLORS } from "components/Constants/constants";
import { classNames } from "tools/ui";

const [bgBtnColor, txtBtnColor] = BUTTON_COLORS.rose;
const baseIcon = "absolute right-4 top-3 w-9 rounded-full p-2 active:scale-90";
const defaultStylePanel =
  "bg-gray-100 px-2 custom-transition duration-500 sticky w-full";

const PanelRight = ({ children, title, displayOpen, handleDisplay, style }) => {
  const headerStyle = displayOpen ? "mt-5 space-y-3" : "";

  let stylePanel =
    "absolute bottom-[50%] right-[-123px] bg-bluedeep md:w-1/6 h-[70px] rounded-b-2xl overflow-hidden rotate-90 animate-fade-left animate-ease-linear transition-all duration-500 shadow-2xl shadow-slate-200";
  let buttonStyle = "right-3 top-1";
  if (displayOpen) {
    stylePanel = style || defaultStylePanel;
    buttonStyle = "right-1 top-3";
  }

  return (
    <div className={stylePanel}>
      {handleDisplay && (
        <button
          className={classNames("absolute", buttonStyle)}
          onClick={() => handleDisplay(!displayOpen)}
        >
          {displayOpen ? (
            <XMarkIcon
              className={classNames(baseIcon, bgBtnColor, txtBtnColor)}
            />
          ) : (
            <ChevronDownIcon
              className={classNames(baseIcon, "bg-white text-bluedeep")}
            />
          )}
        </button>
      )}

      <div className={classNames("px-3 py-3", headerStyle)}>
        <h3
          className={classNames(
            "truncate font-bold mb-2",
            displayOpen
              ? "text-zinc-700 text-center text-2xl"
              : "pt-3 pl-3 text-white text-left text-xl",
          )}
        >
          {title}
        </h3>
        {displayOpen && children}
      </div>
    </div>
  );
};

export default PanelRight;
