import React from "react";
import { FormattedMessage as FM } from "react-intl";

import { classNames } from "tools/ui";

const base =
  "group shadow-sm rounded-md px-2 cursor-pointer py-3 flex flex-col justify-center items-center w-full";

const titleStyle = "text-3xl font-semibold text-center";

const COLORS = {
  amber: ["bg-amber-200", "text-amber-800"],
  lime: ["bg-lime-200", "text-lime-700"],
  blue: ["bg-sky-100", "text-blue-800"],
  zinc: ["bg-zinc-100", "text-zinc-800"],
  rose: ["bg-rose-200", "text-rose-700"],
  gray: ["", "text-zinc-700"],
  white: ["", "text-zinc-700"],
};

function CardSidePanel({ name, value, color }) {
  const subStyle = "uppercase text-center";
  let textSize = "text-sm";
  if (value || value === 0) {
    textSize = "text-xs";
  }

  const [bgColor, textColor] = COLORS[color ?? "white"];

  return (
    <div className={classNames(base, bgColor)}>
      {value || value === 0 ? (
        <div className="text-center h-8 mb-2 top-3">
          <span className={classNames(titleStyle, textColor)}>{value}</span>
        </div>
      ) : null}
      <div className="text-center h-auto">
        <span className={classNames(subStyle, textSize, textColor)}>
          <FM id={name} />
        </span>
      </div>
    </div>
  );
}

export default CardSidePanel;
