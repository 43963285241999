// Shift model
import date from "date-and-time";
import proxy from "api/proxy";

import locationModel from "./ModelLocation";
import scheduleModel from "./ModelSchedule";
import guardModel from "./ModelGuard";
import dates from "tools/dates";
// import imgEmployee from "assets/img/employee.png";

function getSchedule() {
  let res;
  res = [
    // ["location", "=", record.location.id],
    ["state", "=", "planned"],
  ];
  return res;
}

function getPositions(value, record) {
  let res;
  if (record.schedule) {
    res = [["location", "=", record.schedule.location]];
  }
  return res;
}

function isReadonly(record) {
  return record.id > 0 ? true : false;
}

function getEnterExitTime(start_time, duration, record) {
  const _date = record.shift_date;
  const _enter = dates.getTrytonDateTime2Js(`${_date} ${start_time}`);
  const _exit = date.addHours(_enter, duration);
  return [_enter, _exit];
}

function withChangeKind(record) {
  let resActive = {};
  let resStore = {};
  if (record.shift_date && record.kind) {
    let value = record.kind;
    const enterHour = value.start.slice(0, 2);
    const exitHour = value.end.slice(0, 2);
    const duration = Math.abs(Number(exitHour) - Number(enterHour));
    const [_enter, _exit] = getEnterExitTime(value.start, duration, record);
    resActive = {
      enter_dtime: _enter,
      exit_dtime: _exit,
      duration: duration,
    };
    resStore = {
      enter_dtime: _enter,
      exit_dtime: _exit,
      type: "work",
    };
    // if (record.id < 1) {
    //   resActive.schedule = 1;
    //   resStore.schedule = 1;
    // }
  }
  return [resStore, resActive];
}

async function setReplicate(record) {
  // FIXME
  if (
    record.replicate &&
    record.event_category &&
    record.event_start_date &&
    record.event_end_date
  ) {
    const data = {
      model: "surveillance.schedule.shift",
      method: "replicate_shift",
      args: record,
    };
    await proxy.method(data);
  }
}

function getVisibleEvent(record) {
  if (record && record.event_category) {
    return true;
  }
  return false;
}

function getDuration(record) {
  const { enter_dtime, exit_dtime } = record;
  if (enter_dtime && enter_dtime !== "" && exit_dtime && exit_dtime !== "") {
    let delta = (
      (exit_dtime.getTime() - enter_dtime.getTime()) /
      1000 /
      3600
    ).toFixed(2);
    return delta;
  }
}

const getView = () => {
  let DictCtxView = {
    model: "surveillance.schedule.shift",
    row_selectable: false,
    activeSearch: true,
    table_action: [],
    form_action: ["edit"],
    filters: null,
    webfields: {
      shift_date: { type: "date", readOnly: { state: ["performed"] } },
      duration: {
        type: "numeric",
        readOnly: true,
        function: getDuration,
        depends: ["enter_dtime", "exit_dtime"],
      },
      state: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "draft",
      },
      schedule: {
        type: "many2one",
        model: "surveillance.schedule",
        recSearch: getSchedule,
        searchable: true,
        readOnly: isReadonly,
        ctxView: scheduleModel.ctxView(),
      },
      location: {
        type: "many2one",
        model: "surveillance.location",
        searchable: true,
        ctxView: locationModel.ctxView(),
        readOnly: isReadonly,
      },
      guard: {
        type: "many2one",
        model: "company.employee",
        searchable: true,
        ctxView: guardModel.ctxView(),
        readOnly: isReadonly,
        // This examples works
        // readOnly: (rec) => (rec.id > 0 ? true : false),
        // readOnly: { state: ["performed"] },
      },
      replicate: { type: "boolean", function: setReplicate },
      extra_shift: { type: "boolean" },
      enter_dtime: { type: "datetime", readOnly: { state: ["performed"] } },
      exit_dtime: { type: "datetime", readOnly: { state: ["performed"] } },
      kind: {
        type: "many2one",
        model: "staff.shift.kind",
        readOnly: { state: ["performed"] },
        recSearch: () => [],
        withChange: withChangeKind,
        attrs: ["start", "end"],
      },
      position: {
        type: "many2one",
        model: "surveillance.location.position",
        recSearch: getPositions,
        readOnly: { state: ["performed"] },
      },
      "guard.photo_link": { type: "image" },
      event_category: {
        type: "many2one",
        model: "staff.event_category",
        recSearch: () => [],
        readOnly: { state: ["performed"] },
      },
      event: {
        type: "many2one",
        model: "staff.event",
        visible: getVisibleEvent,
        readOnly: true,
      },
      event_start_date: {
        type: "date",
        visible: getVisibleEvent,
        readOnly: { state: ["performed"] },
      },
      event_end_date: {
        type: "date",
        visible: getVisibleEvent,
        readOnly: { state: ["performed"] },
      },
      notes: { type: "text", readOnly: { state: ["performed"] } },
    },
    webtree: [
      { name: "guard", width: "40%" },
      { name: "kind", width: "40%" },
      // {name: 'city', width: '20%'},
      // { name: "active", width: "10%" },
    ],
    webform: [
      {
        id: "main_info",
        grid: [
          { name: "guard", component: "search" },
          // { name: "location", component: "search" },
          { name: "schedule", component: "search" },
          { name: "shift_date" },
        ],
        size: [1, 1],
      },
      { name: "guard.photo_link" },
      {
        id: "shift_info",
        grid: [{ name: "kind" }, { name: "duration" }],
        size: [1, 2],
      },
      {
        id: "time_info",
        grid: [{ name: "enter_dtime" }, { name: "exit_dtime" }],
        size: [1, 2],
      },
      { name: "position" },
      {
        id: "extra_info",
        grid: [{ name: "replicate" }, { name: "extra_shift" }],
        size: [1, 2],
      },
      { name: "event_category" },
      {
        id: "events_info",
        grid: [{ name: "event_start_date" }, { name: "event_end_date" }],
        size: [1, 2],
      },
      { name: "event" },
      { name: "state" },
      { name: "notes" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
