import React, { useState, useEffect } from "react";
import { FormattedMessage as FM } from "react-intl";
import date from "date-and-time";
import store from "store";
import { signal } from "@preact/signals-react";

import QuickTable from "components/Meta/QuickTable";
import StdButton from "components/Buttons/StdButton";
import IconButton from "components/Buttons/IconButton";
import DropdownField from "components/MeForm/DropdownField";
import ModalForm from "components/Modals/ModalForm";
import MenuScheduleReport from "./MenuScheduleReport";
import ModelShift from "./ModelShift";
import Sheet from "./Sheet";
import mainStore from "store/mainStore";
import funcs from "tools/functions";
import proxy from "api/proxy";
import modelLocation from "./ModelLocation";
import { useWizardStore } from "store/wizardStore";

const locationView = modelLocation.ctxView();
const HISTORY_PERIODS = -290;

function SurveillanceSchedule(props) {
  let [modeView, setModeView] = useState("locations"); // list or sheet
  let [messageCtx, setMessageCtx] = useState(null);
  let [domLocations, setDomLocations] = useState(null);
  let [btnStartDisabled, setBtnStartDisabled] = useState(true);
  let [openModalShift, setOpenModalShift] = useState(false);
  let [selectedRows, setSelectedRows] = useState(new Map());
  let [filter, setFilter] = useState("");

  const { record, storeMain, setRecord, reset } = mainStore();
  const { record: recordWiz, store: storeWiz } = useWizardStore();

  const multiselect = true;
  let toUpdate = new Map();
  let toRemove = [];
  let toCreate = {};

  const onSearchSchedule = async (target) => {
    console.log("here......", recordWiz);
    // let center = target.oc;
    // if (!center) {
    //   center = oc;
    // }
    // if (!center) {
    //   return;
    // }
    const shDom = [
      ["operation_center", "=", recordWiz.operation_center.id],
      ["period", "=", recordWiz.period.id],
    ];
    const { data: activesSch } = await proxy.search(
      "surveillance.schedule",
      shDom,
      ["location"],
    );
    const activesLocations = activesSch.map((val) => val.location);
    const dom = [["id", "in", activesLocations]];
    setDomLocations(dom);
  };

  const onStartSchedule = (target) => {
    setBtnStartDisabled(true);
    if (selectedRows.size > 0) {
      setModeView("sheet");
      setFilter("");
    } else if (target !== "") {
      setFilter(target);
      setModeView("sheet");
    }
  };

  const updateCtx = (type, value) => {
    if (type === "toRemove") {
      toRemove = value;
    } else if (type === "toCreate") {
      toCreate = value;
    } else if (type === "toUpdate") {
      toUpdate = value;
    } else {
      setMessageCtx(null);
    }
    setFilter("");
  };

  const toSave = async () => {
    const model = "surveillance.schedule.shift";
    if (toUpdate.size > 0) {
      const values = toUpdate.values();
      for (const val of values) {
        delete val.readOnly;
        const data = {
          model: model,
          storeRec: val,
        };
        await proxy.saveQuery(data);
      }
    }

    const messageCtx = {
      type: "msgInfo",
      msg: "board.records_saved",
    };

    setMessageCtx(messageCtx);
    if (Object.keys(toCreate).length > 0) {
      for (const rec of Object.values(toCreate)) {
        delete rec.id;
        delete rec.location;
        delete rec.readOnly;
        await proxy.create(model, rec);
      }
    }
    if (toRemove.length > 0) {
      const args = { model: model, ids: toRemove };
      await proxy.remove(args);
    }

    await toReload();
  };

  const toAdd = async () => {
    const newRecord = {
      id: 0,
    };
    setOpenModalShift(true);
    // setRecord(newRecord);
    record(newRecord);
    storeMain(newRecord);
  };

  const toReload = async () => {
    toCreate = {};
    toRemove = [];
    toUpdate = new Map();
    setMessageCtx(null);
  };

  function onDoubleClickCell(rec) {
    const ctxView = ModelShift.ctxView();
    setRecord(rec.id, ctxView.model, ctxView);
    setOpenModalShift(true);
  }

  const closeEditModal = () => {
    setOpenModalShift(false);
    reset();
  };

  const toCloseSheet = () => {
    setModeView("locations");
    setSelectedRows(new Map());
    setMessageCtx(null);
  };

  function onDoubleClickRow(rec) {
    if (rec && rec.id) {
      props.onSelectedRecord(rec);
      props.onClose();
    }
  }

  function onClickRow(rec) {
    if (rec && multiselect) {
      if (selectedRows.has(rec.id)) {
        selectedRows.delete(rec.id);
      } else {
        selectedRows.set(rec.id, rec);
      }
    }
    if (selectedRows.size > 0) {
      setBtnStartDisabled(false);
    } else {
      setBtnStartDisabled(true);
    }
  }

  // function handleMultiselect(name) {
  //   if (name === "selectAll") {
  //     for (const record of records) {
  //       onClickRow(record[1]);
  //     }
  //   }
  // }

  function getActiveLocations() {
    let _locations = Array.from(selectedRows.values());
    _locations = _locations.map((loc) => loc.id);
    return _locations;
  }

  let periodProps = {
    field: {
      name: "period",
      model: "account.period",
      recSearch: () => [],
    },
    attrs: { level: "wizard" },
    data: signal(0),
  };

  let ocProps = {
    field: {
      name: "operation_center",
      model: "company.operation_center",
      recSearch: () => [],
    },
    attrs: { level: "wizard" },
    data: signal(0),
  };

  return (
    <div className="w-full h-full px-4">
      {messageCtx && (
        <div className="" id={`surveillance-${messageCtx.type}`}>
          <p className="">
            <FM id={messageCtx.msg} />
          </p>
        </div>
      )}
      <div className="flex gap-4 my-2">
        <div className="w-2/3 flex gap-2">
          <DropdownField {...periodProps} />
          <DropdownField {...ocProps} />
          <StdButton
            color="blue"
            onClick={onSearchSchedule}
            style="w-full"
            content={"schedule.search"}
          />
          <StdButton
            color="green"
            onClick={onStartSchedule}
            style="w-full"
            disabled={btnStartDisabled}
            content={"schedule.scheduling"}
          />
        </div>
        {modeView == "sheet" && (
          <>
            <IconButton
              key="return"
              color="rose"
              onClick={toCloseSheet}
              name="fi-br-cross-small"
              content="Return"
            />
            <StdButton
              color="blue"
              onClick={toAdd}
              size="w-40"
              iconRight="fi fi-br-add"
              content="board.button_add"
            />
            <StdButton
              color="amber"
              onClick={toSave}
              size="w-40"
              iconLeft="fi-sr-disk"
              content={"board.button_save"}
            />
            <MenuScheduleReport />
            {openModalShift && (
              <ModalForm
                open={openModalShift}
                onClose={closeEditModal}
                ctxView={ModelShift.ctxView()}
                level="main"
              />
            )}
          </>
        )}
      </div>
      <div>
        {modeView == "locations" && domLocations && (
          <QuickTable
            model={"surveillance.location"}
            ctxView={locationView}
            domain={domLocations}
            onDoubleClickRow={onDoubleClickRow}
            onClickRow={onClickRow}
          />
        )}
        {modeView == "sheet" && (
          <Sheet
            updateCtx={updateCtx}
            locations={getActiveLocations()}
            onDoubleClickCell={onDoubleClickCell}
            filter={filter}
            oc={recordWiz.operation_center.id}
            period={recordWiz.period.id}
          />
        )}
      </div>
    </div>
  );
}

export default SurveillanceSchedule;
