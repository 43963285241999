const getView = () => {
  let ctxView = {
    model: "crm.prospect",
    selectable: "one",
    activeSearch: true,
    filters: null,
    form_action: ["edit", "add"],
    table_action: ["add"],
    webfields: {
      name: { type: "char", searchable: true, required: true },
      phone: { type: "char", searchable: true },
      email: { type: "char", searchable: true },
      agent: {
        type: "many2one",
        model: "commission.agent",
        searchable: true,
      },
    },
    webtree: [
      { name: "name", width: "30%" },
      { name: "phone", width: "10%" },
      { name: "email", width: "15%" },
      { name: "agent", width: "15%" },
    ],
    webform: [
      { name: "name" },
      { name: "phone" },
      { name: "email" },
      { name: "agent" },
    ],
  };

  return ctxView;
};

export default { ctxView: getView };
