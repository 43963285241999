import store from "store";

import fetchLocalAPI from "printing/printing";
import proxy from "api/proxy";

function wizardPrint(rec) {
  const print = async () => {
    const ctxSession = store.get("ctxSession");
    let data = {};
    let contextArgs = {
      model: "sale.sale",
      method: "get_printing_context",
      args: { user_id: ctxSession.user },
    };
    const { data: ctx } = await proxy.method(contextArgs);
    data["context"] = ctx;
    const apiPrint = data["context"].local_printer;
    const saleArgs = {
      model: "sale.sale",
      method: "get_order2print",
      args: {
        sale_id: rec.id,
        repeat: false,
      },
    };

    const { data: order2print } = await proxy.method(saleArgs);
    data["orders"] = order2print[0];

    console.log(" contextPrint >>> ", data);
    // let opts = {
    //   method: "POST",
    //   uri: "/print_order",
    //   body: data,
    // };

    const printer = new fetchLocalAPI(apiPrint);
    const { data: result } = await printer.post("/print_order", data);
    console.log("result Print...", result);

    if (result.msg === "ok") {
      const _args = {
        model: "sale.sale",
        method: "mark_commanded",
        args: {
          sale_id: rec.id,
        },
      };
      const { data: res } = await proxy.method(_args);
      data.result = res;
    } else {
      window.alert("LA IMPRESION DE COMANDA FALLÓ!");
    }
  };
  print();
}

export default wizardPrint;
