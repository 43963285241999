import React from "react";

import { classNames } from "tools/ui";

function ColForm(props) {
  let colspan = "";
  if (props.colspan) {
    colspan = "md:col-span-2 lg:col-span-2 xl:col-span-2";
  }
  return (
    <div id="col-form" className={classNames("mt-1 mb-2 w-full", colspan)}>
      {props.children}
    </div>
  );
}

export default ColForm;
