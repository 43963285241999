import React from "react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const baseStyle =
  "max-w-2xl space-x-8 bg-gray-100 rounded-md px-5 py-2 items-center flex justify-between";

function DeviceItem({ item, onSelectDevice }) {
  return (
    <div key={item.id} value={item.id} className={baseStyle}>
      <div className="w-full flex justify-between text-gray-600">
        <span>{item.name}</span>
        <span className="font-bold">COD: {item.code}</span>
      </div>

      <CheckCircleIcon
        className="w-8 cursor-pointer hover:text-sky-500 transition-all duration-200"
        onClick={() => onSelectDevice(item)}
      />
    </div>
  );
}

export default DeviceItem;
