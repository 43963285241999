import React, { useEffect, useState } from "react";
import proxy from "api/proxy";
import { useParams, useLocation } from "react-router-dom";

import Layout from "../layout/Layout";
import SectionRestaurant from "../components/pages/retaurant/SectionRestaurant";
import Menu from "../components/pages/retaurant/Menu";
import Loading from "components/Tools/Loading";

const PageRestaurant = (props) => {
  const { db, id } = useParams();
  const baseUrl = `app/${db}/web_melhous`;

  const [arrayDataShops, setArrayDataShops] = useState([]);
  const [arrayDataProducs, setArrayDataProducs] = useState([]);

  const dataShops = async () => {
    let dom = id ? [["id", "=", id]] : [];
    const { data } = await proxy.search("sale.shop", dom, [
      "id",
      "name",
      "ticket_footer",
      "address.street",
      "address.party.name",
      "product_categories",
      "product_categories.name",
      "local_printer",
    ]);
    setArrayDataShops(data);
  };

  const getData = async (value) => {
    const { product_categories } = value;

    const { data: dataCategories } = await proxy.browse(
      "product.category",
      product_categories,
      [
        "id",
        "name",
        "products",
        "products.name",
        "products.id",
        "products.list_price",
        "products.description",
        "products.images",
        "products.images.image_url",
      ],
    );
    setArrayDataProducs(dataCategories);
  };

  useEffect(() => {
    dataShops(); // Llamar a dataShops() en la primera renderización
  }, []); // Se ejecuta solo una vez al montar el componente

  useEffect(() => {
    if (id && arrayDataShops.length > 0) {
      getData(arrayDataShops[0]);
    }
  }, [id, arrayDataShops]);

  console.log("renderizo aca");
  if (!id) {
    return (
      <Layout>
        {arrayDataShops && arrayDataShops.length != 0 ? (
          <SectionRestaurant data={arrayDataShops} baseUrl={baseUrl} />
        ) : (
          <Loading />
        )}
      </Layout>
    );
  } else {
    return (
      <Layout>
        {arrayDataProducs && arrayDataProducs.length != 0 && (
          <Menu
            data={arrayDataProducs}
            shop={arrayDataShops[[0]]}
            baseUrl={baseUrl}
          />
        )}
      </Layout>
    );
  }
};

export default PageRestaurant;
