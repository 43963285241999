import React from "react";
import TitleSection from "../components/ui/TitleSection";
import Button from "../components/ui/Button";
import {
  ArrowDownIcon,
  ArrowLeftOnRectangleIcon,
  ArrowRightOnRectangleIcon,
  ClockIcon,
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  PhoneIcon,
} from "@heroicons/react/20/solid";
import CallSocialIcon from "../components/ui/CallSocialIcon";
import {
  IconAir,
  IconParking,
  IconsPets,
  IconsTree,
  IconsWifi,
} from "../components/ui/Icons";
import SliderHotel from "../components/SliderHotel";
import { SLIDERHOTEL } from "../fakeData";
import CustonMapGoogle from "../components/CustonMapGoogle";

const PageHotel = () => {
  return (
    <div className=" mb-10">
      <div className="grid md:grid-cols-2 grid-cols-1 ">
        <div className="space-y-5 py-20">
          <h2 className="text-5xl melhous-menu-title max-w-xl text-gray-900 uppercase">
            Conoce nuestros
            <div className="w-fit mx-6 relative -left-6">
              espacios
              <div className="w-full h-3 bg-yellow-500/50 skew-y-3 relative -top-4" />
            </div>
          </h2>
          <p className="melhous-menu-description text-gray-900 max-w-xl pb-5">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed iaculis
            ante quis maximus ultricies. Mauris eu turpis eu ligula dictum
            ullamcorper. Pellentesque sed ultrices metus, a convallis orci.
            Aliquam viverra posuere neque hendrerit ultrices. Quisque vehicula
            dui ipsum, et finibus erat malesuada nec.
          </p>
          <div className="md:w-[380px] z-10 relative overflow-hidden rounded-2xl bg-[#f1eae2] text-black px-4 py-3 text-center shadow-md border border-[#d8d4cf] grid grid-cols-2 justify-items-center justify-stretch  divide-x  divide-[#d8d4cf]">
            <div className="w-full">
              <h3 className="text-15px uppercase font-medium melhous-menu-title">
                Check-in
              </h3>
              <span className="flex space-x-2 justify-center text-[#878787]">
                <ArrowRightOnRectangleIcon className="w-5 text-[#d8d4cf]" />
                <span className="text-[##a4a3a1] melhous-">3:00pm</span>
              </span>
              <ClockIcon className="h-full -left-5 top-0 text-[#d8d4cf] absolute" />
            </div>
            <div className="w-full">
              <h3 className="text-15px uppercase font-medium melhous-menu-title">
                Check-out
              </h3>
              <span className="flex space-x-2 justify-center text-[#878787]">
                <ArrowLeftOnRectangleIcon className="w-5 text-[#d8d4cf]" />
                <span>10:00am</span>
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="relative">
            <div className="border w-[50px] border-yellow-500 h-[150px] absolute -left-5 -top-[100px] "></div>
            <div className="border w-[150px] rounded-full border-yellow-500 h-[150px] absolute -right-16 -bottom-[60px] "></div>
            <div className="border w-[30vw]  h-[150px] absolute -right-[7.5vw] -top-[40px] bg-[#f1eae2]"></div>

            <img
              src="https://res.cloudinary.com/dl4sdiukx/image/upload/v1697659746/ahg7dnnnfgcdhe2cjuwd.jpg"
              className="relative z-10 w-full"
            />
            <img
              src="https://res.cloudinary.com/dl4sdiukx/image/upload/v1695592929/hguijl6hoz8drvdr30q2.jpg"
              className=" z-10 md:w-[200px] w-[100px] md:h-[200px]  object-cover absolute -bottom-[50px] -left-[50px] "
            />
          </div>
        </div>
      </div>

      {/* contadores  */}

      <div className="max-w-6xl rounded-2xl bg-[#f1eae2] text-black mt-28 mx-auto p-5 text-center shadow-md border border-[#d8d4cf]">
        <div className="grid md:grid-cols-4 grid-cols-2  p-5 justify-items-stretch md:divide-x divide-[#d8d4cf] ">
          <div>
            <span className="text-[60px] font-medium">23</span>
            <h3 className="md:text-[20px] melhous-menu-description">
              {" "}
              Habitaciones
            </h3>
          </div>
          <div>
            <span className="text-[60px] font-medium">8</span>
            <h3 className="md:text-[20px] melhous-menu-description">
              {" "}
              Eventos
            </h3>
          </div>
          <div>
            <span className="text-[60px] font-medium">23</span>
            <h3 className="md:text-[20px] melhous-menu-description">
              {" "}
              Experiencias
            </h3>
          </div>
          <div>
            <span className="text-[60px] font-medium">10</span>
            <h3 className="md:text-[20px] melhous-menu-description">
              {" "}
              Clientes
            </h3>
          </div>
        </div>
      </div>
      <div className="">
        <div className="h-[80px] w-[0.5px] bg-[#d8d4cf] mx-auto mt-5 -mb-2" />
        <ArrowDownIcon className="w-3 text-[#d8d4cf] mx-auto" />
      </div>

      {/* ameneties */}

      <div className=" rounded-2xl  text-black  mx-auto md:mb-64 mb-96 relative ">
        <h2 className="text-gray-800 melhous-menu-title text-center md:text-5xl text-2xl relative mx-auto mt-20  max-w-6xl">
          Bienvenido a nuestro hotel, donde te haremos sentir como en casa
        </h2>

        <div className="grid grid-cols-5 max-w-6xl mx-auto  text-gray-800 justify-items-center relative z-10">
          <div className="bg-gray-100 p-8 relative  rounded-xl top-0 ">
            <IconAir size="4em" fill="#d8d4cf" />
            <h3 className="melhous-menu-description uppercase text-center mt-2">
              Aire
            </h3>
          </div>
          <div className="bg-gray-100 p-8 relative  rounded-xl md:top-20 top-48  flex flex-col justify-center text-center">
            <IconParking size="4em" fill="#d8d4cf" />
            <h3 className="melhous-menu-description uppercase text-center mt-2">
              Parqueadero
            </h3>
          </div>
          <div className="bg-gray-100 p-8 relative  rounded-xl md:top-40 top-96 ">
            <IconsWifi size="4em" fill="#d8d4cf" />
            <h3 className="melhous-menu-description uppercase text-center mt-2">
              Wifi
            </h3>
          </div>
          <div className="bg-gray-100 p-8 relative  rounded-xl md:top-20 top-48 ">
            <IconsPets size="4em" fill="#d8d4cf" />
            <h3 className="melhous-menu-description uppercase text-center mt-2">
              Mascotas
            </h3>
          </div>
          <div className="bg-gray-100 p-8 relative  rounded-xl top-0 ">
            <IconsTree size="4em" fill="#d8d4cf" />
            <h3 className="melhous-menu-description uppercase text-center mt-2">
              Zonas verdes
            </h3>
          </div>
        </div>
      </div>
      <hr className="border-t border-[#d8d4cf] h-1 md:mt-10 mt-20" />
      <div className="mt-20 ">
        <TitleSection text="Quienes somos" />
        <CallSocialIcon />

        <p className="melhous-menu-description text-gray-700 text-center max-w-6xl mx-auto mt-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur a
          luctus augue, in pulvinar mi. Duis vehicula diam nec turpis ultrices
          bibendum. Integer sed elit in velit auctor condimentum. Praesent
          semper dolor ut augue pellentesque porta. Praesent id massa congue,
          posuere nisi vel, commodo erat. Curabitur quis tellus lobortis,
          interdum justo quis, vestibulum sem. Ut ut pulvinar metus.
          Pellentesque habitant morbi tristique senectus et netus et malesuada
          fames ac turpis egestas. Etiam vulputate dignissim erat, vel consequat
          mi semper quis. Nullam mollis, ante eu molestie volutpat, eros ligula
          commodo quam, sit amet varius magna purus vel quam. Sed quis volutpat
          neque, ut pharetra nunc. Praesent eget iaculis leo.
        </p>
      </div>

      <div className="mb-32">
        <SliderHotel data={SLIDERHOTEL} />
      </div>
      {/* otra section */}
      <TitleSection text="Donde estamos" />

      <div className="grid md:grid-cols-2 grid-cols-1  items-center  bg-black md:h-[50vh] p-5 md:w-screen md:-left-40  relative bg-no-repeat bg-cover  ">
        <div className="w-screen  absolute left-0 h-[50vh] hidden md:block ">
          <CustonMapGoogle />
        </div>

        <div className=""></div>
        <div className="  h-full w-full relative text-white p-5 z-10   bg-black/70 rounded-2xl overflow-hidden backdrop-blur-md  ">
          <div className=" h-full p-3 md:p-16 flex flex-col justify-center space-y-4 ">
            <h2 className="md:text-4xl uppercase font-medium melhous-menu-title md:leading-[52px] ">
              Encuentranos facil
            </h2>
            <div className="md:flex md:space-x-2 space-y-3 md:space-y-0 pb-5">
              <Button text="315 3364 782" style="text-xs">
                <DevicePhoneMobileIcon className="w-5 bg-white rounded-full p-1" />
              </Button>
              <Button text="315 3364 782" style="text-xs">
                <PhoneIcon className="w-5 bg-white rounded-full p-1" />
              </Button>
              <Button text="vr24355@gmail.com" style="text-xs">
                <EnvelopeIcon className="w-5 bg-white rounded-full p-1" />
              </Button>
            </div>
            <hr className="w-[200px]  h-[0.5px] border-gray-500 mt-5 " />
            <h3 className="melhous-menu-title uppercase">Direccion</h3>

            <p className=" melhous-menu-description text-lg">
              CRA 14B # 45-39, Cra. 15 # 45-52, Bucaramanga, Santander
            </p>
            <h3 className="melhous-menu-title uppercase">Redes sociales</h3>
            <div className="md:flex space-x-4">
              <a
                href="#"
                target="_blank"
                className="text-left underline text-yellow-500 melhous-menu-description uppercase"
              >
                FACEBOOK
              </a>
              <a
                href="#"
                target="_blank"
                className="text-left underline text-yellow-500 melhous-menu-description uppercase"
              >
                INSTAGRAM
              </a>
              <a
                href="#"
                target="_blank"
                className="text-left underline text-yellow-500 melhous-menu-description uppercase"
              >
                TELEGRAM
              </a>
              <a
                href="#"
                target="_blank"
                className="text-left underline text-yellow-500 melhous-menu-description uppercase"
              >
                YOUTUBE
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHotel;
