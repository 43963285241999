const getView = () => {
  let DictCtxView = {
    model: "sale_activity.schedule",
    form_action: ["edit", "add", "save"],
    table_action: ["add", "edit"],
    domain: [],
    webfields: {
      quantity: { type: "integer" },
      date_activity: { type: "date" },
      available: { type: "integer" },
      kind: {
        type: "many2one",
        recSearch: () => [],
        model: "sale_activity.kind",
        searchable: true,
        // readOnly: true,
      },

      add: {
        type: "button",

        button_method: "confirm",
        visible: [{ state: ["scheduled"] }],
        // triggerAction: { handleClick },
        onSuccessMsg: "Confirmacion exitosa!",
        color: "blue",
      },
      // name: { type: "char", editable: true, editableTree: true },
    },
    webtree: [
      { name: "kind", width: "30%" },
      // { name: "available", width: "30%" },
      { name: "quantity", width: "30%" },
      { name: "date_activity", width: "30%" },
      { name: "note", width: "30%" },
      { name: "add", width: "30%" },
      // { name: "name", width: "30%" },
    ],
    webform: [{ name: "note" }, { name: "add" }, { name: "kind" }],
  };

  return DictCtxView;
};

export default { ctxView: getView };
