import React from "react";

import intl from "i18n/messages";
import WidgetCell from "./widgetCell";

// h-[2.4rem]
/**
 *
 * @param {*} props -
 * @param {Boolean} readOnly -
 * @param {String} type -
 * @param {String} name -
 * @param {String} placeholder -
 * @param {String} value -
 * @param {Function} onChange -
 * @param {Boolean} translate -
 * @returns Component TextField
 */

function CharCell(props) {
  const { field, attrs, data } = props;
  let editedValue = data.value ?? "";
  if (data.value && field.translate) {
    const _value = `${attrs.model}.${data.value}`;
    editedValue = intl.msgs[_value] || data.value;
  }
  if (field.widget) {
    return <WidgetCell field={field} attrs={attrs} data={data} />;
  }

  if (!field.editable) return editedValue;

  function onChange(event) {
    let _value = event.target.value;
    if (props.type === "integer") {
      _value = parseInt(_value);
    }
    editedValue = _value;
  }

  return (
    <div className="">
      <input
        key={props.name}
        id={props.name}
        name={props.name}
        className={
          "w-full py-2 px-2 text-gray-700 rounded-md bg-inherit focus:outline-none focus:border focus:border-cyan-500"
        }
        type={props.type || "text"}
        value={editedValue}
        onChange={onChange}
        readOnly={props.readOnly ?? false}
        placeholder={props.placeholder ?? ""}
      />
      {props.icon || null}
    </div>
  );
}

export default CharCell;
