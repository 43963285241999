import React from "react";

import { classNames } from "tools/ui";

function Point({ color }) {
  const base = "w-1.5 h-1.5 mx-auto rounded-full";
  return <div className={classNames(base, color)} />;
}

export default Point;
