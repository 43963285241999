// Folio Charge model
import dates from "tools/dates";

const getUnitPrice = (record) => {
  if (record.product) {
    let res = record.product.sale_price_taxed;
    record.unit_price_w_tax = res;
    return res;
  }
};

const onSearchProduct = () => {
  let res = [
    ["account_category", "!=", null],
    ["salable", "=", true],
  ];
  return res;
};

const onChangeQty = (record) => {
  const { product, unit_price_w_tax, quantity } = record;
  if (product) {
    record.amount = (Number(unit_price_w_tax) * quantity).toFixed(2).toString();
  }
};

const onChangeProduct = (record, store) => {
  if (record.product) {
    const product = record.product;
    let unit_price = product.list_price;
    const sale_price_taxed = record.product.sale_price_taxed;
    const fmt_unit_price = unit_price.toFixed(2).toString();
    store.unit_price = fmt_unit_price;
    record.unit_price = unit_price;
    record.unit_price_w_tax = sale_price_taxed;
    if (record.quantity) {
      record.amount = record.quantity * sale_price_taxed;
    }
  }
};

const getAmount = (record) => {
  if (record.unit_price_w_tax && record.quantity) {
    const amount = (record.unit_price_w_tax * record.quantity).toFixed(2);
    return amount;
  }
};

const getView = (props) => {
  const selectable = props?.selectable;
  const table_action = props ? props.table_action : ["edit", "delete"];

  let DictCtxView = {
    model: "hotel.folio.occupancy",
    form_action: ["edit"], // options: ['edit', 'delete']
    table_action: table_action, // options: ['open', 'delete', 'edit', 'add']
    domain: [], // Options: null or valid domain
    orderBy: [["occupancy_date", "ASC"]],
    limit: 100,
    autoSave: true,
    selectable: selectable, // Options for table rows: null, multi, one,
    target: "folio",
    webfields: {
      occupancy_date: {
        type: "date",
        readOnly: true,
        required: true,
      },
      unit_price: {
        type: "numeric",
        required: true,
        decimalPlaces: 2,
      },
      unit_price_w_tax: {
        type: "numeric",
        readOnly: true,
        decimalPlaces: 2,
      },
      state: { type: "char", readOnly: true, translate: true },
      folio: {
        type: "many2one",
        model: "hotel.folio",
        readOnly: true,
      },
      charge: {
        type: "many2one",
        model: "hotel.folio.charge",
        readOnly: true,
      },
    },
    webtree: [
      { name: "occupancy_date", width: "20%" },
      { name: "unit_price", width: "20%" },
      { name: "unit_price_w_tax", width: "20%" },
      { name: "charge", width: "20%" },
      { name: "state", width: "20%" },
    ],
    webform: [
      { name: "occupancy_date" },
      { name: "folio" },
      {
        id: "prices",
        grid: [{ name: "unit_price" }, { name: "unit_price_w_tax" }],
        size: [1, 2],
        span: "col-span-1",
      },
      { name: "state" },
      // { name: "add_product" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
