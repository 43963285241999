import store from "store";
import tools from "tools/common";

import WizardAddProduct from "./WizardAddProduct";
import inventoryLine from "./ModelInventoryLine";
import InventoryUpdate from "./InventoryUpdate";
import useMainStore from "store/mainStore";

const today = new Date();
const end_date = tools.fmtDate2Tryton(today);
const start_date = tools.dateAdd(today, "months", -2);

function isAdmin() {
  const session = store.get("ctxSession");
  let admin = false;
  for (var v of session.groups) {
    if (v.name === "Administración de logística") {
      admin = true;
    }
  }
  return admin;
}

const getFilters = () => {
  const session = store.get("ctxSession");
  const admin = isAdmin();
  let filters = {
    checkup: [
      ["state", "=", "checkup"],
      ["assign_to", "=", session.user_employee],
    ],
  };
  if (admin) {
    filters = {
      last_two_months: [
        ["date", "<=", `${end_date}`],
        ["date", ">=", `${start_date}`],
      ],
      draft: [["state", "=", "draft"]],
      checkup: [["state", "=", "checkup"]],
      pre_count: [["state", "=", "pre_count"]],
    };
  }
  return filters;
};

const searchAssign = (value) => {
  if (value === null) {
    return [];
  }
  const search = [
    "OR",
    [["party.name", "ilike", `%${value}%`]],
    [["party.id_number", "ilike", `${value}%`]],
  ];
  return search;
};

const getVisible = (name, record) => {
  const admin = isAdmin();
  if (record.state === "pre_count" && admin) return true;
  return false;
};

const getVisibleAddProduct = (name, record) => {
  const admin = isAdmin();
  return record?.state == "draft" && admin;
};

const searchLocation = (value) => {
  let dom = [
    ["type", "=", "storage"],
    ["active", "=", true],
    ["rec_name", "ilike", "ZA%"],
  ];
  if (value) {
    dom.push([
      "OR",
      [["name", "ilike", `%${value}%`]],
      [["code", "ilike", `${value}%`]],
    ]);
  }
  return dom;
};

function onAcceptAddProduct(storeWizard, activeWizard) {
  const { store, record } = useMainStore.getState();
  let _storeRecord = { ...store };
  if (!_storeRecord.lines) {
    _storeRecord.lines = new Map();
    _storeRecord.lines.set("create", new Map());
  }
  let to_create = _storeRecord.lines.get("create");

  let activeRecord_ = { ...record };
  if (!activeRecord_.lines) {
    activeRecord_.lines = new Map();
  }

  const prods = Array.from(activeRecord_.lines.values()).map(
    (line) => line.product.id,
  );

  const time_ = new Date();
  let sq = -time_.getTime();
  for (let v of activeWizard.selectedRows.values()) {
    if (!prods.includes(v.id)) {
      const rec = {
        id: sq,
        product: {
          id: v.id,
          name: v.name,
          code: v.code,
          description: v.description,
        },
      };
      activeRecord_.lines.set(sq, rec);
      to_create.set(sq, { id: sq, product: v.id });
      sq += -1;
    }
  }
}

const getView = (config) => {
  // const session = store.get('ctxSession');
  let DictCtxView = {
    row_selectable: true,
    selectable: true,
    activeSearch: true,
    model: "stock.inventory",
    form_action: ["edit", "save"],
    table_action: ["edit", "add"],
    domain: null,
    filters: getFilters,
    webfields: {
      number: { type: "char", readOnly: true },
      location: {
        type: "many2one",
        model: "stock.location",
        recSearch: searchLocation,
        readOnly: { state: ["checkup", "pre_count"] },
        required: true,
      },
      date: {
        type: "date",
        readOnly: { state: ["checkup", "pre_count"] },
      },
      state: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "draft",
      },
      assign_to: {
        type: "many2one",
        model: "company.employee",
        recSearch: searchAssign,
        readOnly: { state: ["checkup", "pre_count"] },
      },
      add_product: {
        type: "button_custom_modal",
        Component: WizardAddProduct,
        color: "blue",
        visible: getVisibleAddProduct,
        onAccept: onAcceptAddProduct,
        // button_ok: false,
      },
      form_set_inventory: {
        type: "button_custom_modal",
        Component: InventoryUpdate,
        color: "lime",
        visible: (field, record) => {
          console.log(record);
          return record?.state === "checkup";
        },
        size: "w-48",
        button_ok: false,
      },
      lines: {
        type: "one2many",
        model: "stock.inventory.line",
        ctxView: inventoryLine.ctxView(config, isAdmin),
        // readOnly: { state: ["checkup", "pre_count"] },
      },
      pre_count: {
        type: "button",
        button_method: "pre_count",
        visible: (field, record) => record["state"] === "checkup",
      },
      checkup: {
        type: "button",
        button_method: "checkup",
        visible: (field, record) => record["state"] === "draft",
      },
      confirm: {
        type: "button",
        button_method: "confirm",
        visible: getVisible,
      },
      cancel: {
        type: "button",
        button_method: "cancel",
        visible: getVisible,
        color: "rose",
      },
      comment: { type: "text" },
    },
    webtree: [
      { name: "number", width: "20%" },
      { name: "assign_to", width: "20%" },
      { name: "date", width: "15%" },
      { name: "location", width: "35%" },
      { name: "state", width: "10%" },
    ],
    webform: [
      {
        id: "header",
        grid: [
          { name: "number" },
          { name: "assign_to" },
          { name: "location" },
          { name: "date" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      {
        id: "footer",
        grid: [{ name: "comment" }],
        size: [1, 1],
        span: "col-span-2 md:col-span-1 row-span-2",
      },
      {
        id: "footer2",
        grid: [
          { name: "state" },
          { name: "pre_count" },
          { name: "checkup" },
          { name: "confirm" },
          { name: "cancel" },
        ],
        size: [1, 1],
        span: "col-span-2 md:col-span-1 row-span-2",
      },
      { name: "add_product" },
      { name: "form_set_inventory" },
      { name: "lines", component: "modal" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
