import React from "react";
import { QrCodeIcon, MapPinIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
const backgroundImage =
  "https://upload.wikimedia.org/wikipedia/commons/e/ef/Restaurant_N%C3%A4sinneula.jpg";

const ItemRestaurant = ({ data, index, baseUrl = "#" }) => {
  const {
    image = backgroundImage,
    name,
    category,
    id,
    "address.": address,
    local_printer,
  } = data;
  return (
    <Link to={`/${baseUrl}/shops/${id}`}>
      <article
        key={index}
        className="h-[400px]  bg-cover flex items-end rounded-lg overflow-hidden shadow-xl relative"
        style={{ backgroundImage: `url(${local_printer || backgroundImage})` }}
        title={name}
      >
        <div className=" bg-gradient-to-t from-black via-black/90 to-transparent px-5  pb-3 pt-10  w-full text-white">
          <QrCodeIcon className="w-10 absolute top-3 right-3 z-10 bg-white p-2 text-black rounded-md" />

          <h2 className="text-lg truncate w-full text-left font-medium  melhous-menu-title uppercase">
            {name}
            <hr className="border-[0,5] border-gray-700 mb-2 mt-1 w-[70%]" />
          </h2>

          <div className="flex  justify-between">
            <div className="text-[13px] text-yellow-500 font-medium text-left text-base flex space-x-2 items-center">
              <MapPinIcon className="w-5" /> <span>{address?.street}</span>
            </div>
          </div>
        </div>
      </article>
    </Link>
  );
};

export default ItemRestaurant;
