import React, { useEffect } from "react";

import HeaderStep from "./HeaderStep";

function StepTicket(props) {
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  async function getGoBack() {
    // Wait 7 seconds and return to start step
    const resetStore = true;
    await sleep(7000);
    props.onChangeView(null, "order", resetStore);
  }

  useEffect(() => {
    getGoBack();
  }, []);
  const anim =
    "animate-text bg-gradient-to-r from-teal-500 via-purple-500 to-orange-500 bg-clip-text text-transparent text-5xl font-bold";

  return (
    <div>
      <HeaderStep label="PASO 3. RECLAMA TU TICKET" />
      <div className="flex flex-col mt-32">
        <p className="text-3xl text-cyan-600">
          Tu pedido No. {props.data.saleNumber}
        </p>
        <p className={anim}>
          en 10 minutos salen tus productos!, toma tu ticket y con el código QR
          podras abrir el casillero.
        </p>
      </div>
    </div>
  );
}

export default StepTicket;
