import mainStore from "store/mainStore";
import { useWizardStore } from "store/wizardStore";

function getDefAmount(value1, value2) {
  const { record: mainRecord } = mainStore.getState();
  const { store } = useWizardStore.getState();
  store.amount = mainRecord.pending_to_pay;
  return mainRecord.pending_to_pay;
}

function defaultAmount() {
  // const { record: mainRecord } = mainStore.getState();
  // const { record, store, updateWizard } = useWizardStore.getState();
  // // const res = { amount: record.pending_to_pay };
  // // updateWizard(res);
  // record.amount = mainRecord.pending_to_pay;
  // store.amount = mainRecord.pending_to_pay;
}

const getView = () => {
  let DictCtxView = {
    model: "hotel.booking.add_payment",
    form_action: [],
    table_action: [],
    defaults: defaultAmount,
    webfields: {
      statement: {
        type: "many2one",
        model: "account.statement",
        readOnly: false,
        required: true,
        recSearch: () => [["state", "=", "draft"]],
      },
      amount: {
        type: "numeric",
        readOnly: false,
        required: true,
        default: getDefAmount,
      },
      voucher: {
        type: "char",
        readOnly: false,
        // required: true,
      },
      pay: {
        type: "button",
        // method: addPayment, This method is added in custom model
        color: "green",
        iconRight: "fi fi-rr-add",
      },
    },
    webform: [
      { name: "statement" },
      { name: "amount" },
      { name: "voucher" },
      { name: "pay" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
