const colorsBg = [
  "bg-orange-200 text-orange-700",
  "bg-teal-200 text-teal-700",
  "bg-yellow-200 text-yellow-600",
  "bg-violet-200 text-violet-700",
  "bg-zinc-300 text-zinc-700",
  "bg-red-200 text-red-700",
  "bg-purple-200 text-purple-500",
  "bg-sky-200 text-sky-700",
  "bg-rose-200 text-rose-700",
  "bg-cyan-200 text-cyan-700",
  "bg-pink-200 text-pink-700",
  "bg-amber-200 text-amber-700",
];

const colorsTxt = [
  "text-purple-700",
  "text-amber-700",
  "text-emerald-700",
  "text-rose-700",
  "text-zinc-700",
  "text-blue-700",
  "text-lime-700",
  "text-orange-700",
  "text-yellow-700",
  "text-red-700",
  "text-cyan-700",
  "text-pink-700",
];

export default { colorsBg, colorsTxt };
