import React, { Fragment, useEffect, Suspense } from "react";
import { FormattedMessage as FM } from "react-intl";
import date from "date-and-time";
import { signal } from "@preact/signals-react";
import store from "store";

// import mainStore from "store/mainStore";
import useMainStore from "store/mainStore";
import tool from "tools/functions";
import DateRangeFilter from "components/Tools/DateRangeFilter";
import PanelRight from "components/Panel/PanelRight";
import WidgetSideStatusActivity from "./components/WidgetSideStatusActivity";
import QuickForm from "components/Meta/QuickForm";
import QuickTable from "components/Meta/QuickTable";
import StdButton from "components/Buttons/StdButton";
import dates from "tools/dates";
// import PureModal from "components/Modals/PureModal";
// import ProductDiscountItem from "./components/ProductDiscountLines";
import proxy from "api/proxy";
const ScheduledActivities = React.lazy(
  () => import("./components/ScheduledActivities"),
);
const dayToday = new Date();
const statusSection = signal(true);
const statusActivities = signal(true);
const filterDay = signal(dayToday);
const statusShedule = signal(true);
// const recordLines = signal(null);
// const addDiscount = signal(false);
const view = signal("create");

var nextId = -1;

const ScreenActivity = (props) => {
  let { ctxView, ctxViewAc, permits } = props;
  const { record, setRecord, updateRecord, updateStore } = useMainStore();
  const session = store.get("ctxSession");

  const handleChangeDate = (value) => {
    const now = dates.getNow().setUTCHours(0, 0, 0, 0);
    const value_ = new Date(value).setUTCHours(0, 0, 0, 0);
    if (permits && now <= value_) filterDay.value = new Date(value);
  };

  const handleChangeStatusActivities = (status) => {
    statusActivities.value = status;
  };

  useEffect(() => {
    resetNewRecord();
  }, [filterDay.value, props, view.value]);

  const resetNewRecord = async () => {
    const sale = await getSalesForUser();
    if (!sale) {
      nextId = nextId - 1;
      const [toStore, toRecord] = await tool.getDefaults(nextId, ctxView);
      console.log(toStore, toRecord, "VALIDATE");
      let agent = session.agent;
      if (!agent) {
        const { data: agents } = await proxy.search(
          "commission.agent",
          [["user", "=", session.user]],
          ["rec_name"],
        );
        if (agents) {
          agent = agents[0];
          let session_ = { ...session };
          session_.agent = agent;
          await store.set("ctxVirtualSession", session_);
        }
      }
      toStore.agent = agent.id;
      toRecord.agent = agent;
      updateStore(toStore);
      updateRecord(toRecord);
    }
  };

  const getSalesForUser = async () => {
    let filterDayStart = date.format(filterDay.value, "YYYY-MM-DD");
    let dom = [
      ["create_uid", "=", session.user],
      ["state", "=", "draft"],
      ["reservation", "!=", true],
      ["sale_date", "=", filterDayStart],
      ["lines.origin", "ilike", "sale_activity.activity%"],
    ];
    const { data: resSale, error } = await proxy.search("sale.sale", dom, [
      "id",
    ]);
    if (resSale.length != 0) {
      setRecord(resSale[0].id, ctxView.model, ctxView);
      return true;
    }
    return false;
  };

  // const addDiscountItem = (_record) => {
  //   addDiscount.value = true;
  //   recordLines.value = _record;
  // };
  // ctxView.webfields.lines.ctxView.webfields.add_discount.method =
  //   addDiscountItem;
  return (
    <Fragment>
      <DateRangeFilter action={handleChangeDate} />
      {view.value === "create" &&
        (record.id < 0 || record.state == "draft") && (
          <Suspense fallback={<div className="h-20 bg-red">Loading</div>}>
            <ScheduledActivities
              currentDay={filterDay.value}
              handleChangeStatusActivities={handleChangeStatusActivities}
              ctxView={ctxViewAc}
              view={view.value}
              statusShedule={statusShedule.value}
            />
          </Suspense>
        )}
      {statusActivities.value && (
        <div
          className={`flex ${
            statusSection.value ? "flex-row justify-between" : "flex-col"
          }`}
        >
          <div
            className={
              statusSection.value == true
                ? "md:w-[65vw] xl:w-[70vw] "
                : "md:w-12/12 relative pr-10"
            }
          >
            {view.value === "create" ? (
              <QuickForm ctxView={ctxView} level="main" />
            ) : view.value === "history" ? (
              <QuickTable ctxView={ctxView} />
            ) : (
              <p>Seleccionar algo</p>
            )}
          </div>
          <div className="w-[22vw]">
            {props.permits && (
              <PanelRight
                position={"right"}
                widgets={["status", "numPeople"]}
                title="INFO ACTIVIDADES"
                bgColor={statusSection.value ? "bg-gray-100" : "bg-blue-presik"}
                status={statusSection.value}
                handleChangeStatus={false}
                displayOpen={true}
                style="bg-gray-100 px-2 custom-transition duration-500 sticky w-full"
              >
                <WidgetSideStatusActivity
                  title={<FM id="booking.widget.screen.huesped_house" />}
                  background={"bg-gray-100"}
                  name="statusHotel"
                  day={filterDay}
                />
              </PanelRight>
            )}

            <PanelRight
              position={"right"}
              widgets={["status", "numPeople"]}
              title=""
              bgColor={statusSection.value ? "bg-gray-100" : "bg-blue-presik"}
              status={statusSection.value}
              handleChangeStatus={false}
              displayOpen={true}
              style="bg-gray-100 px-2 custom-transition duration-500 sticky w-full"
            >
              <StdButton
                content={
                  view.value == "create"
                    ? "sale.sale.restart_sale"
                    : "sale.sale.new_sale"
                }
                size="w-full"
                color={view.value == "create" ? "rose" : "green"}
                onClick={() => {
                  resetNewRecord();
                  view.value = "create";
                  statusShedule.value = true;
                }}
              />

              <StdButton
                content={"sale.sale.history"}
                size="w-full"
                onClick={() => (view.value = "history")}
                color={"bluePresik"}
                disabled={view.value == "history" ? true : false}
              />
            </PanelRight>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ScreenActivity;
