import React from "react";
import TitleSection from "../components/ui/TitleSection";
import TextField from "components/MeForm/TextField";
import Button from "../components/ui/Button";

const PagePqrs = () => {
  return (
    <div className="bg-[#f1eae2]  mx-auto py-5 px-5  rounded-2xl mt-32 mb-10 ">
      <div className=" w-full h-full rounded-2xl overflow-hidden border border-[#d8d4cf] min-h-[500px] grid grid-cols-1 md:grid-cols-2 justify-center items-center">
        <div>
          <img
            src="https://cloudx2.presik.com/static/media/future.e0ea4b7d0dcbd64d2bc4.jpg"
            className="max-h-[610px] w-full  object-cover"
          />
        </div>
        <div className="py-5 md:px-14 px-5">
          <TitleSection text="Cuentanos " />
          <p className="text-gray-400 -mt-5 mb-5 text-center melhous-menu-description md:text-xl ">
            Envianos tus quejas y reclamos y te responderemos lo antes posible
          </p>
          <form className="mx-auto grid grid-cols-1  md:grid-cols-2 gap-x-6 gap-y-8">
            <TextField
              placeholder="Nombre completo"
              className="!bg-[#f1eae2] w-full outline-none pb-4 border-b border-[#d8d4cf] placeholder:text-gray-800 melhous-menu-description"
            />
            <TextField
              placeholder="Correo"
              className="!bg-[#f1eae2] w-full outline-none pb-4 border-b border-[#d8d4cf] placeholder:text-gray-800 melhous-menu-description"
            />
            <TextField
              placeholder="Telefono"
              className="!bg-[#f1eae2] w-full outline-none pb-4 border-b border-[#d8d4cf] placeholder:text-gray-800 melhous-menu-description"
            />
            <TextField
              placeholder="Nombre completo"
              className="!bg-[#f1eae2] w-full outline-none pb-4 border-b border-[#d8d4cf] placeholder:text-gray-800 melhous-menu-description"
            />
            <TextField
              placeholder="Celular"
              className="!bg-[#f1eae2] w-full outline-none pb-4 border-b border-[#d8d4cf] placeholder:text-gray-800 melhous-menu-description"
            />
            <TextField
              placeholder="Tipo de queja o reclamo"
              className="!bg-[#f1eae2] w-full outline-none pb-4 border-b border-[#d8d4cf] placeholder:text-gray-800 melhous-menu-description"
            />

            <div className="md:col-span-2">
              <TextField
                placeholder="Escribe aca tu pregunta"
                className="!bg-[#f1eae2] w-full outline-none pb-4 border-b border-[#d8d4cf] placeholder:text-gray-800 melhous-menu-description"
              />
            </div>
          </form>
          <div className="flex mt-10 flex-col items-center align-middle space-y-3">
            <div className=" text-gray-400 melhous-menu-description">
              Estoy deacuerdo con guardar datos{" "}
              <span className="font-medium text-yellow-500">aquí</span>
              <input type="checkbox" className="ml-3" />
            </div>
            <Button align="text-center" text="Enviar PQRS"></Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagePqrs;
