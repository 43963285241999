import React from "react";

function SegLabel() {
  // function onClick() {
  //   props.onClick();
  // }

  return <div></div>;
}

export default SegLabel;
