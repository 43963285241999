import React from "react";

import Board from "components/Meta/Board";
import travelPerson from "./ModelTravelPerson";

const TravelPerson = ({ config }) => {
  const ctxView = travelPerson.ctxView(config);

  return <Board ctxView={ctxView} />;
};

export default TravelPerson;
