import date from "date-and-time";

// const FMT_DATETIME = '%Y-%b-%d-  %I:%M %p'
const re = /^[0-9]+$/;

const getTrytonDate2Js = (value) => {
  if (value) {
    return date.parse(value, "YYYY-MM-DD");
  }
};

const fmtDate = (value) => {
  if (value) {
    let value_ = date.parse(value, "YYYY-MM-DD");
    value_ = date.format(value_, "YYYY-MM-DD");
    return value_;
  }
};

const fmtHour = (value) => {
  if (value) {
    const dateObj = new Date(value);
    const hour = dateObj.getHours();
    const minute = dateObj.getMinutes();
    const second = dateObj.getSeconds();
    const formattedHour = `${hour}:${minute}:${second}`;
    return formattedHour;
  }
};

const fmtDatetime = (value) => {
  if (value) {
    const value_ = date(new Date(value)).format("YYYY-MM-DD HH:mm");
    return value_;
  }
};

const fmtDatetimeForm = (value) => {
  if (value) {
    // let val = date.utc(value).toDate();
    const value_ = date.format(value, "DD-MM-YYYY HH:mm");
    return value_;
  }
};

const dateToday = () => {
  const _date = new Date();
  return date.format(_date, "YYYY-MM-DD");
};

const dateAdd = (value, delta, diff) => {
  if (value) {
    let _date;
    if (delta == "years") {
      _date = date.addYears(value, diff);
    } else if (delta === "months") {
      _date = date.addMonths(value, diff);
    } else if (delta === "days") {
      _date = date.addDays(value, diff);
    }
    return date.format(_date, "YYYY-MM-DD");
  }
  // const _date = new Date();
  return date.format(value, "YYYY-MM-DD");
};

const fmtDate2Tryton = (value) => {
  if (value) {
    return date.format(value, "YYYY-MM-DD");
  }
};

const fmtDateAndTime2Tryton = (dt, tm) => {
  const datetm = dt + " " + tm;
  const dateutc = date.format(datetm, "DD-MM-YYYY HH:mm A");
  const _datetm = dateutc.utc();
  return _datetm.format("YYYY-MM-DD HH:mm:ss");
};

const fmtDatetime2Tryton = (value) => {
  const val = date.addHours(value, 5);
  return date.format(val, "YYYY-MM-DD HH:mm:ss");
};

const fmtDatetime2Epoch = (value, fromDb) => {
  if (value) {
    let _date;
    if (fromDb) {
      _date = date.format(value, "YYYY-MM-DDTHH:mm");
    } else {
      _date = date.parse(value, "DD-MM-YYYY | hh:mm A");
    }
    return _date.getTime() / 1000;
  }
};

const fmtEpochToDatetime = (value) => {
  if (value) {
    const _val = new Date(value * 1000);
    let dateFormatted = date.format(_val, "YYYY-MM-DD HH:mm:ss", true);
    return dateFormatted;
  }
};

const fmtCurrency = (value) => {
  return value.toLocaleString("es", { useGrouping: true });
};

const isNumber = (value) => {
  return value === "" || re.test(value);
};

function subtractTimeFromDate(objDate, intHours) {
  var numberOfMlSeconds = objDate.getTime();
  var addMlSeconds = intHours * 60 * 60000;
  var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
  return newDateObj;
}

function formatNumber(number) {
  if (typeof number == "number") {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + "m";
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + "k";
    }
    return number.toString();
  } else {
    return number;
  }
}

const tools = {
  fmtCurrency,
  fmtDate,
  fmtDatetime,
  fmtDatetimeForm,
  fmtDate2Tryton,
  fmtDatetime2Tryton,
  fmtDatetime2Epoch,
  fmtEpochToDatetime,
  getTrytonDate2Js,
  isNumber,
  dateToday,
  dateAdd,
  fmtDateAndTime2Tryton,
  subtractTimeFromDate,
  fmtHour,
  formatNumber,
};

export default tools;
