const getOptions = () => {
  const options = [
    { id: "11", name: "registro_civil" },
    { id: "12", name: "tarjeta_identidad" },
    { id: "13", name: "cedula_ciudadania" },
    { id: "21", name: "tarjeta_extranjeria" },
    { id: "22", name: "cedula_extranjeria" },
    { id: "31", name: "nit" },
    { id: "41", name: "pasaporte" },
    { id: "42", name: "tipo_documento_extranjero" },
    { id: "47", name: "pep" },
    { id: "50", name: "nit_otro_pais" },
    { id: "91", name: "nuip" },
  ];

  return options;
};

const getOptionsSex = [
  { id: "male", name: "male" },
  { id: "female", name: "female" },
];

function getCities(data, record) {
  let dom = null;
  if (record && record.subdivision) {
    dom = [["subdivision", "=", record.subdivision.id]];
  }
  return dom;
}

function getCity(record) {}

function reqByCountry(record) {
  let res = false;
  if (record.country && record.country.code === "CO") {
    res = true;
  }
  return res;
}

const getView = () => {
  let ctxView = {
    model: "party.party",
    selectable: "one",
    activeSearch: true,
    filters: null,
    form_action: ["edit", "add"],
    table_action: ["add"],
    webfields: {
      name: { type: "char", searchable: true, required: true },
      id_number: { type: "char", searchable: true, required: true },
      account_payable: { type: "boolean" },
      type_document: {
        type: "selection",
        options: getOptions(),
        translate: true,
        required: true,
      },
      sex: {
        type: "selection",
        options: getOptionsSex,
        translate: true,
      },
      check_digit: {
        type: "integer",
      },
      main_email: {
        type: "char",
        required: true,
      },
      main_mobile: {
        type: "char",
        // searchable: true,
        required: true,
      },
      phone: {
        type: "char",
      },
      birthday: {
        type: "date",
      },
      street: {
        type: "char",
        required: true,
        // setter: true,
        // attrs: ["addresses", "addresses.street"],
      },
      country: {
        type: "many2one",
        model: "country.country",
        required: true,
      },
      subdivision: {
        type: "many2one",
        model: "country.subdivision",
        required: reqByCountry,
        visible: reqByCountry,
      },
      city: {
        type: "many2one",
        model: "country.city_co",
        setter: true,
        function: getCity,
        required: reqByCountry,
        visible: reqByCountry,
        recSearch: getCities,
      },
      nationality: {
        type: "many2one",
        model: "country.country",
      },
      account_receivable: {
        type: "many2one",
        model: "account.account",
        readOnly: true,
      },
    },
    webtree: [
      { name: "name", width: "30%" },
      { name: "id_number", width: "10%" },
      { name: "account_receivable", width: "15%" },
      // { name: "amount", width: "15%" },
      // { name: "description", width: "30%" },
    ],
    webform: [
      { name: "name" },
      { name: "type_document" },
      { name: "id_number" },
      { name: "street" },
      { name: "country" },
      { name: "subdivision" },
      { name: "city" },
      { name: "main_email" },
      { name: "main_mobile" },
      { name: "nationality" },
      { name: "sex" },
      { name: "birthday" },
    ],
  };

  return ctxView;
};

export default { ctxView: getView };
