import React from "react";

import { classNames } from "tools/ui";

const classes =
  "w-full inline-flex items-center my-auto h-20 hover:shadow-md bg-gradient-to-r rounded-lg py-2 px-3 text-xl hover:opacity-90 disabled:opacity-50 justify-center";

const colors = {
  blue: "from-blue-400 to-cyan-500",
  yellow: "from-yellow-400 to-orange-500",
  amber: "from-amber-400 to-amber-500",
  green: "from-lime-500 to-green-500",
  indigo: "from-indigo-400 to-violet-500",
  pink: "from-pink-500 to-pink-600",
  rose: "from-rose-400 to-rose-500",
};

function Button({ name, content, style, color, disabled, onClick }) {
  function handleClick(e) {
    e.preventDefault();
    onClick(e, name);
  }

  let _style = "";
  if (style) {
    _style += style;
  }
  let _color = colors[color];
  const _disabled = disabled ? disabled : false;

  return (
    <button
      key={name}
      type="button"
      onClick={handleClick}
      className={classNames(classes, _style, _color)}
      disabled={_disabled}
    >
      <p className="text-3xl text-white">{content}</p>
    </button>
  );
}

export default Button;
