// Guest Model

function isMainGuest(rec) {
  return rec.main_guest;
}

import { TYPE_DOC, TYPE_GUEST, SEX } from "./constants";

const getView = () => {
  let DictCtxView = {
    row_selectable: false,
    activeSearch: true,
    filters: null,
    model: "hotel.folio.guest",
    webfields: {
      main_guest: { type: "boolean" },
      type_guest: {
        type: "selection",
        required: true,
        options: TYPE_GUEST,
        translate: true,
      },
      type_document: {
        type: "selection",
        required: true,
        options: TYPE_DOC,
        translate: true,
      },
      sex: { type: "selection", required: true, options: SEX, translate: true },
      id_number: { type: "char", required: true },
      name: { type: "char", required: true },
      mobile: { type: "char", required: isMainGuest },
      address: { type: "char", required: isMainGuest },
      email: { type: "char", required: isMainGuest },
      nationality: {
        type: "many2one",
        model: "country.country",
        limit: 800,
        required: true,
      },
      origin_country: {
        type: "many2one",
        model: "country.country",
        limit: 800,
        required: true,
      },
      target_country: {
        type: "many2one",
        model: "country.country",
        limit: 800,
        required: true,
      },
      birthday: { type: "date" },
      notes: { type: "text" },
      add_guest: {
        type: "button",
        // visible: true,
        size: "w-full",
        color: "blue",
        method: null,
      },
      cancel: {
        type: "button",
        // visible: true,
        size: "w-full",
        color: "pink",
        // method: null,
      },
    },
    webcard: [{ name: "name" }, { name: "type_guest" }],
    webform: [
      { name: "name" },
      { name: "main_guest" },
      { name: "type_guest" },
      { name: "type_document" },
      { name: "sex" },
      { name: "id_number" },
      { name: "mobile" },
      { name: "email" },
      { name: "address" },
      { name: "nationality" },
      { name: "origin_country" },
      { name: "target_country" },
      { name: "birthday" },
      { name: "notes" },
      { name: "cancel" },
      { name: "add_guest" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
