// Surveillance Report Compliance

const getView = () => {
  let ctxView = {
    model: "surveillance.schedule_month.report",
    reportName: "surveillance.schedule_month.report",
    webfields: {
      period: { type: "many2one", model: "account.period", searchable: true },
      location: {
        type: "many2one",
        model: "surveillance.location",
        searchable: true,
      },
    },
    webtree: [],
    webform: [{ name: "period" }, { name: "location" }],
  };
  return ctxView;
};

export default { ctxView: getView };
