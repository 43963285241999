import React from "react";
import date from "date-and-time";

import CategoryRow from "./CategoryRow";
import RoomRow from "./RoomRow";

const fmtDate = "YYYY-MM-DD";

function CalendarBody({ rooms, rack, dates, prices }) {
  const initialDate = date.format(dates[0], fmtDate);
  let rows = [];
  for (const [roomAccoId, grouped] of rooms) {
    rows.push(
      <CategoryRow
        key={grouped.accoName}
        data={grouped}
        dates={dates}
        prices={prices}
      />,
    );
    for (const [roomId, room] of grouped.rooms.entries()) {
      let foliosByRoom = rack.get(roomId);
      if (!foliosByRoom) {
        foliosByRoom = [];
      }
      rows.push(
        <RoomRow
          key={room.id}
          room={room}
          data={foliosByRoom}
          dates={dates}
          initialDate={initialDate}
        />,
      );
    }
  }

  return <tbody>{rows}</tbody>;
}

export default CalendarBody;
