import React, { useState } from "react";
import { FormattedMessage as FM } from "react-intl";

import ModalReport from "components/Modals/ModalReport";
import { classNames } from "tools/ui";
import proxy from "api/proxy";
import funcs from "tools/functions";

const styleIcon = "pr-3 flex text-2xl my-auto active:scale-95";
const iconDiv =
  "flex p-3 w-12 rounded-md mx-2 bg-stone-100 text-stone-500 hover:bg-white hover:text-sky-700 active:bg-sky-800 active:text-white active:scale-95";

function MenuActionReport({ report }) {
  let [openReport, setOpenReport] = useState(false);

  function onClick() {
    setOpenReport(true);
  }

  function onClose() {
    setOpenReport(false);
  }

  return (
    <div
      key={report.name}
      className="flex px-3 py-2 relative gap-x-6 rounded-lg hover:bg-stone-100 hover:text-sky-700 hover:cursor-pointer"
      onClick={onClick}
    >
      <div className="grid">
        <p className="mb-2">
          <FM id={report.name} />
        </p>
        <p className="text-stone-400 mt-auto">
          <FM id={report.description} />
        </p>
      </div>
      <ModalReport
        onClose={onClose}
        open={openReport}
        ctxView={report.ctxView}
      />
    </div>
  );
}

export default MenuActionReport;
