import React, { useState, useEffect } from "react";
import store from "store";
import proxy from "api/proxy";

import BasicModal from "components/Modals/BasicModal";
import DropdownField from "components/MeForm/DropdownField";
import Divider from "components/MeForm/Divider";
import ProgressBar from "./ProgressBar";
import { useWizardStore } from "store/wizardStore";
import { signal } from "@preact/signals-react";
const statement = signal(null);

function ModalPayment({ open, onClose }) {
  if (!open) return;

  const { record } = useWizardStore();
  const [progress, setProgress] = useState(0);
  const [processing, setProcessing] = useState(false);

  const selectedRows = record.selectedRows ?? new Map();
  const session = store.get("ctxSession");
  statement.value = record.statement;

  let amount = 0;
  let ids = [];
  if (selectedRows) {
    ids = Array.from(selectedRows.keys());
    amount = Array.from(selectedRows.values()).reduceRight(
      (acc, cur) => acc + cur.total_amount,
      0,
    );
  }

  async function getStatements(debouncedValue) {
    let dom = [];
    const { data } = await proxy.search(
      "res.user",
      [["id", "=", session.user]],
      ["sale_device"],
    );
    if (data) {
      dom = [
        ["sale_device", "=", data[0].sale_device],
        ["state", "=", "draft"],
      ];
      if (debouncedValue) {
        dom.push(["rec_name", "ilike", `%${debouncedValue}%`]);
      }
    }
    return dom;
  }

  async function acceptModal() {
    setProcessing(true);
  }

  useEffect(() => {
    // const totalRecords = props.ids.length;
    // const totalRecords = ids.length;

    const processRecords = async () => {
      if (ids.length > 0) {
        for (let i = 0; i < ids.length; i++) {
          await proxy.methodCall({
            model: "account.statement",
            method: "multipayment_invoices",
            args: [
              {
                statement_id: statement.value.id,
                sale_ids: [ids[i]],
              },
            ],
          });
          const progress = i + 1;
          setProgress(progress); // Actualizar el estado de la barra de progreso
        }
      }
      closeProcessing();
    };

    if (processing) {
      processRecords();
    }
  }, [processing]);

  function closeProcessing() {
    setTimeout(() => {
      setProcessing(false);
      onClose("modalPayment");
    }, 1000);
  }

  const fieldStatement = {
    name: "statement",
    model: "account.statement",
    recSearch: getStatements,
    labeled: false,
    placeholder: "Seleccione el medio de pago...",
  };

  return (
    <BasicModal
      open={true}
      buttons={["cancel", "ok"]}
      onClose={() => onClose("modalPayment")}
      onAccept={acceptModal}
      title={"CONFIRME LA FORMA DE PAGO"}
      width="w-full sm:w-5/6 md:w-1/2"
      height="h-[52vh]"
    >
      <Divider />
      <div className="h-[30vh]">
        <h2 className="font-semibold">
          Total a recibir:{" "}
          {Number(amount).toFixed(0).toLocaleString("es", {
            useGrouping: true,
          })}
        </h2>
        {!processing ? (
          <DropdownField
            field={fieldStatement}
            attrs={{ level: "wizard" }}
            data={statement}
          />
        ) : (
          <>
            <div className="flex flex-col md:flex-row items-center justify-center gap-4 mb-4">
              <h3 className="text-slate-800 font-semibold">
                Espere un momento... procesando las facturas!
              </h3>
              <div className="flex items-center justify-center rounded-full w-10 h-10 bg-gradient-to-tr from-indigo-400 via-cyan-400 to-white animate-spin">
                <div className="h-7 w-7 rounded-full bg-gray-200"></div>
              </div>
            </div>
            <div className="text-right"></div>
            <ProgressBar progress={progress} maxValue={ids.length} />
          </>
        )}
      </div>
    </BasicModal>
  );
}

export default ModalPayment;
