import React from "react";
import { FormattedMessage as FM } from "react-intl";

import { classNames } from "tools/ui";

const base = "mt-2 text-zinc-500 font-bold";

function Heading(props) {
  const { field, data } = props;
  let _value;
  let colspan = "";
  if (field.colspan) {
    colspan = "md:col-span-2 lg:col-span-2 xl:col-span-2";
  }

  if (field.translate) {
    _value = <FM id={`model.${field.model}.${field.name}`} />;
  } else {
    _value = data.value;
  }

  let style = field.style ?? "text-4xl";
  return (
    <p key={_value} className={classNames(base, colspan, style)}>
      {_value}
    </p>
  );
}

export default Heading;
