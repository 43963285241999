import { useQuery } from "@tanstack/react-query";

import proxy from "api/proxy-old";

/**
 * Hook for search record in model
 *
 * @param {object} props
 * @param {string} props.model - name model tryton
 * @param {Array} props.domain - list of lists for define search criteria
 * @param {Array} props.fields_names - list of fields for get
 * @param {number} props.limit - number records for get
 * @param {number} props.offset - partition records
 * @returns Array list records
 */

export function useRecords(model, domain, fields_names, order, limit, offset) {
  let enabled = false;
  if (domain) {
    enabled = true;
  }
  const result = useQuery(
    ["records", model, domain, fields_names, order, limit, offset],
    proxy.searchQuery,
    { enabled: enabled },
  );
  return result;
}

export function useRecordsCount(model, domain) {
  const { data } = useQuery(["count", model, domain], proxy.searchQueryCount);
  if (data !== undefined) {
    return data;
  }
  return 0;
}

export function useMethod({ model, method, args }) {
  return useQuery(["method", model, method, args], proxy.methodQuery);
}
