export const Routes = {
  shops: "/shops",
  shop: (id) => {
    return `${id}`;
  },
  home: "/",
  hotel: "/hotel",
  contact: "/contact",
  activities: "/activities",
};
