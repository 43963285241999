import en_account from "views/AccountStatement/en.js";
import es_account from "views/AccountStatement/es.js";
import es_invoice from "views/Invoice/es.js";
import en_invoice from "views/Invoice/en.js";
import es_guest from "views/AppWebCheckIn/es.js";
import en_guest from "views/AppWebCheckIn/en.js";

let en = {
  ...en_account,
  ...en_invoice,
  ...en_guest,
};

let es = {
  ...es_account,
  ...es_invoice,
  ...es_guest,
};

export { en, es };
