import React from "react";
import { FormattedMessage as FM } from "react-intl";

const style =
  "group-hover:opacity-100 transition-opacity w-max bg-gray-500 p-2 text-sm text-gray-100 rounded-md absolute left-1/2 -translate-x-1/2 translate-y-[110%] opacity-0 m-4 mx-auto";

function Tooltip({ tooltip }) {
  return (
    <span className={style}>
      <FM id={tooltip} />
    </span>
  );
}

export default Tooltip;
