// Shipment Internal model
import store from "store";

import proxy from "api/proxy";
import moves from "./ModelStockRequestLine";
import tools from "tools/common";

let today = tools.fmtDate2Tryton(new Date());

const getFromLocation = async () => {
  const fields = ["id", "warehouse_default.name"];
  const { data } = await proxy.search("dash.app.shipment_internal", [], fields);
  const warehouse = data[0]["warehouse_default."];
  return warehouse;
};

const getToLocation = async () => {
  const session = await store.get("ctxSession");
  let fields_names = ["warehouse.storage_location.name"];
  const { data } = await proxy.search(
    "res.user",
    [["login", "=", session.login]],
    fields_names,
    1,
  );
  try {
    const location = data[0]["warehouse."]["storage_location."];
    return location;
  } catch (error) {
    // console.log(error);
    alert(
      "error: debe definir la bodega para el usuario, \n consulta con el area administrativa",
    );
  }
};

const searchLocation = () => {
  const dom = [["type", "=", "storage"]];
  return dom;
};

const getFilters = (session) => {
  return {
    today: [
      ["create_date", ">=", `${today} 00:00:00`],
      ["create_uid", "=", session.user],
    ],
  };
};

const searchRequested = (value) => {
  const search = [
    "OR",
    [["party.name", "ilike", `%${value}%`]],
    [["party.id_number", "ilike", `${value}%`]],
  ];
  return search;
};

const visibleWait = (field, record) => {
  return record["number"] == null && record.moves?.size > 0;
};

export default {
  model: "stock.shipment.internal",
  row_selectable: false,
  form_action: ["add", "edit"],
  table_action: ["edit", "add"],
  activeSearch: true,
  filters: getFilters,
  webfields: {
    number: { type: "char", readOnly: true },
    company: {
      default: store.get("ctxSession") && store.get("ctxSession").company,
    },
    from_location: {
      type: "many2one",
      recSearch: searchLocation,
      model: "stock.location",
      required: true,
      default: getFromLocation,
      readOnly: { state: ["waiting"] },
    },
    to_location: {
      type: "many2one",
      model: "stock.location",
      default: getToLocation,
      readOnly: true,
    },
    requested_by: {
      type: "many2one",
      model: "company.employee",
      recSearch: searchRequested,
      required: true,
      readOnly: { state: ["waiting"] },
    },
    effective_date: {
      type: "date",
      readOnly: { state: ["waiting"] },
      required: true,
    },
    state: { type: "char", readOnly: true, translate: true, default: "draft" },
    reference: { type: "char", readOnly: { state: ["waiting"] } },
    moves: {
      type: "one2many",
      model: "stock.move",
      ctxView: moves,
      required: true,
      readOnly: { state: ["waiting"] },
    },
    wait: {
      type: "button",
      button_method: "wait",
      visible: visibleWait,
    },
  },
  webtree: [
    { name: "number", width: "20%" },
    { name: "from_location", width: "20%" },
    { name: "to_location", width: "35%" },
    { name: "effective_date", width: "10%" },
    { name: "state", width: "10%" },
  ],
  webform: [
    {
      id: "infoShipment",
      grid: [
        { name: "reference" },
        { name: "effective_date" },
        { name: "number" },
      ],
      size: [1, 3],
      span: "col-span-1",
    },
    {
      id: "infoShipment2",
      grid: [{ name: "from_location" }, { name: "to_location" }],
      size: [1, 2],
      span: "col-span-1",
    },
    {
      id: "moves",
      grid: [{ name: "moves", component: "modal" }],
      size: [1, 1],
      span: "col-span-2",
    },

    { name: "requested_by" },
    { name: "state" },
    { name: "wait" },
  ],
};
