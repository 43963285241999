import React, { useEffect, useState, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { FormattedMessage as FM } from "react-intl";
import {
  BellAlertIcon,
  ListBulletIcon,
  ArrowUturnLeftIcon,
} from "@heroicons/react/24/outline";
import store from "store";
import date from "date-and-time";

import FullTable from "components/MeTable/FullTable";
import Button from "components/Buttons/StdButton";
import ModelSale from "./ModelSale";
import ListSaleHistory from "./ListSaleHistory";
import proxy from "api/proxy";
import funcs from "tools/functions";
import { classNames } from "tools/ui";
import dates from "tools/dates";
import doorbell from "./Assets/Audio/doorbell.mp3";

function OrderNotification(props) {
  const ctxView = ModelSale.ctxView(props.config);
  const title = ctxView.model && <FM id={ctxView.model} />;
  const [titleView, setTitleView] = useState(title);
  const [viewType, setViewType] = useState("");
  const [audio, setAudio] = useState(null);
  const session = store.get("ctxSession");

  useEffect(() => {
    // Crear instancia de Audio
    const audioElement = new Audio(doorbell);
    setAudio(audioElement);
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log("Audio es compatible y permitido.");
    } else {
      console.log("Audio no es compatible o no está permitido.");
    }
  }, []);

  // We search orders last 10 hours (15 - 5utc)
  const now = new Date();
  const lastHours = date.addHours(now, -15);
  const _date = dates.fmtDatetime2Tryton(lastHours);
  const queryOrders = useQuery(
    ["notification", ctxView],
    async () => {
      const dom = [
        ["shop", "=", session.shop],
        ["state", "in", ["draft", "quotation"]],
        ["lines.order_status", "=", "requested"],
        ["create_date", ">=", _date],
      ];
      const fieldsNames = funcs.getViewFields(ctxView, "form");
      const { data } = await proxy.search("sale.sale", dom, fieldsNames, 60);
      return data;
    },
    { refetchInterval: 10000, refetchIntervalInBackground: true },
  );

  async function refreshSales() {
    if (queryOrders?.data?.length > 0) {
      if (typeof window.IntervalSound !== "number" && viewType !== "history") {
        window.IntervalSound = setInterval(() => {
          audio.play();
        }, 2000);
      }
    } else if (queryOrders?.data?.length == 0 && window.IntervalSound) {
      clearInterval(window.IntervalSound);
      window.IntervalSound = null;
    }
  }

  function reloadRecords() {
    queryOrders.refetch();
    refreshSales();
  }

  useEffect(() => {
    refreshSales();
  }, [queryOrders.data, viewType]);

  let styleIcon = "";

  if (queryOrders?.data?.length > 0) {
    styleIcon = " animate-ping";
  }

  const onClickAction = (event, name) => {
    let name_ = name;
    let title = "Registro de pedidos";
    if (name_ === "return") {
      name_ = "";
    } else if (name_ === "history") {
      title = "Historial";
      clearInterval(window.IntervalSound);
      window.IntervalSound = null;
    }
    setViewType(name_);
    setTitleView(title);
  };
  // console.log(audioRef, "validate refs");
  return (
    <div className="m-4 md:m-6 p-2 w-full">
      <div className="flex flex-col sm:flex-row pb-2 text-slate-600 justify-between mb-3">
        <h1 className="truncate text-3xl font-bold">{titleView}</h1>
        <div className="flex flex-row space-x-4">
          {viewType === "history" ? (
            <Button
              color="slate"
              size="w-48"
              name={"return"}
              content={"sale.sale.return"}
              onClick={onClickAction}
            >
              <ArrowUturnLeftIcon
                className="h-5 w-5 mx-auto stroke-2"
                aria-hidden="true"
              />
            </Button>
          ) : (
            viewType === "" && (
              <>
                <div className="relative items-center flex">
                  <span className="rounded-full w-8 h-8 bg-slate-800 text-white py-1 text-center shadow-slate-500 shadow-md">
                    {queryOrders?.data?.length ?? 0}
                  </span>

                  <BellAlertIcon
                    className={classNames(
                      "w-5 h-5 stroke-slate-900 stroke-2 absolute left-7 top-0",
                      styleIcon,
                    )}
                  />
                </div>
                <Button
                  color="blue"
                  size="w-48"
                  name={"history"}
                  content={"sale.sale.history"}
                  onClick={onClickAction}
                >
                  <ListBulletIcon
                    className="h-5 w-5 mx-auto stroke-2"
                    aria-hidden="true"
                  />
                </Button>
              </>
            )
          )}
        </div>
      </div>
      {viewType === "" ? (
        <div>
          <FullTable
            ctxView={ctxView}
            records={queryOrders?.data ?? []}
            reloadRecords={reloadRecords}
          />
        </div>
      ) : (
        viewType === "history" && <ListSaleHistory {...props} />
      )}
    </div>
  );
}

export default OrderNotification;
