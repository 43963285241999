import React from "react";
import store from "store";

const PageDefault = React.lazy(() => import("./pages/PageHome"));
const PageNeon = React.lazy(() => import("./pages/PageHomeTwo"));

const type = {
  theme: "default",
};

const MAP_LAYOUT_TO_GROUP = {
  default: PageDefault,
  neon: PageNeon,
};

const Component = MAP_LAYOUT_TO_GROUP[type.theme];

const ScreenMelhous = (session) => {
  return <Component variables={type} />;
};

export default ScreenMelhous;
