// Travel Person model
import date from "date-and-time";

import tools from "tools/dates";

let today = tools.dateToday();
const todayMonthStart = new Date();
let start_month = todayMonthStart.setDate(1);
const _start_month = tools.fmtDate2Tryton(new Date(start_month));

const todayMonthEnd = new Date();
let end_month = todayMonthEnd.setDate(30);
const _end_month = tools.fmtDate2Tryton(new Date(end_month));

// const weekEnd = new Date();
let next_days = date.addDays(new Date(), 7);
const _next_days = tools.fmtDate2Tryton(next_days);

const searchSale = (value) => {
  let res = "";
  if (value["sale."]) {
    res = value["sale."]["number"];
  }
  return res;
};

const getFilters = () => {
  return {
    today: [
      [
        "OR",
        ["arrival_date", "=", `${today}`],
        ["create_date", ">=", `${today} 00:00:00`],
      ],
    ],
    month: [
      [
        ["arrival_date", ">=", _start_month],
        ["arrival_date", "<=", _end_month],
      ],
    ],
    week: [
      [
        ["arrival_date", ">=", today],
        ["arrival_date", "<=", _next_days],
      ],
    ],
  };
};

const getView = () => {
  let DictCtxView = {
    selectable: false, // options: multi - one - false
    activeSearch: true,
    filters: getFilters,
    domain: [],
    form_action: ["add", "edit"],
    table_action: ["edit", "add"],
    model: "sale.line.person",
    webfields: {
      name: { type: "char", readOnly: true },
      phone: { type: "char", readOnly: true },
      email: { type: "char", readOnly: true },
      id_number: { type: "char", readOnly: true },
      arrival_date: {
        type: "date",
        readOnly: true,
        searchable: true,
        // readOnly: { state: ["quotation"] },
      },
      sale: {
        type: "many2one",
        model: "sale.sale",
        function: searchSale,
        // withChange: onChangeParty,
        // required: true,
        // readOnly: { state: ["quotation", "processing"] },
        attrs: ["number"],
      },
      line: {
        type: "many2one",
        model: "sale.line",
        readOnly: true,
        searchable: true,
        attrs: ["sale", "sale.number", "rec_name"],
      },
    },
    webtree: [
      { name: "name", width: "20%" },
      { name: "phone", width: "20%" },
      { name: "email", width: "20%" },
      { name: "id_number", width: "15%" },
      { name: "arrival_date", width: "15%" },
      { name: "sale", width: "10%" },
      { name: "line", width: "10%" },
    ],
    webform: [
      { name: "name" },
      { name: "id_number" },
      {
        id: "infoSale",
        grid: [{ name: "phone" }, { name: "email" }],
        size: [1, 3],
        span: "col-span-1",
      },
      { name: "arrival_date" },
      // { name: "shipment_address" },
      // {
      //   id: "infoSale2",
      //   grid: [{ name: "price_list" }, { name: "invoice_type" }],
      //   size: [1, 2],
      //   span: "col-span-1",
      // },
      // { name: "description" },
      // { name: "lines", component: "modal" },
      // { name: "comment" },
      // { name: "total_amount_cache" },
      // { name: "state" },
      // { name: "quote" },
      // { name: "draft" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
