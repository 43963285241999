import React from "react";
import { signal } from "@preact/signals-react";

import { useWizardStore } from "store/wizardStore";
import NumericField from "components/MeForm/NumericField";
import MsgInfo from "./MsgInfo";

function StepOpen(props) {
  const attrs = { level: "wizard" };
  const field = {
    label: "account.statement.start_balance",
    name: "start_balance",
  };
  const data = signal(0);

  return (
    <div>
      <MsgInfo {...props} />
      <div className="mt-8 mx-24">
        <NumericField field={field} attrs={attrs} data={data} />
      </div>
    </div>
  );
}

export default StepOpen;
