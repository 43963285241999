import date from "date-and-time";

import dates from "tools/dates";

const getName = (record) => {
  return record.number;
};

const getView = () => {
  let DictCtxView = {
    model: "account.invoice.line",
    row_selectable: false,
    form_action: [], // options: ['save', 'delete']
    table_action: [], // options: ['open', 'delete', 'edit', 'add']
    // activeSearch: true,
    form_rec_name: getName,
    target: "invoice",
    // access: {
    //   delete: accessDelete,
    // },
    // title: { field: "booking", component: "title" },
    webfields: {
      invoice: {
        type: "many2one",
        model: "account.invoice",
        readOnly: true,
      },
      amount: { type: "numeric", readOnly: true },
      unit_price: { type: "numeric", readOnly: true },
      product: {
        type: "many2one",
        model: "product.product",
        readOnly: true,
      },
      unit: {
        type: "many2one",
        model: "product.uom",
        readOnly: true,
      },
      description: {
        type: "char",
        readOnly: true,
      },
      quantity: {
        type: "float",
        readOnly: true,
      },
    },
    webtree: [
      { name: "product", width: "25%" },
      { name: "quantity", width: "10%" },
      { name: "unit", width: "10%" },
      { name: "unit_price", width: "15%" },
      { name: "description", width: "20%" },
      { name: "amount", width: "10%" },
    ],
    webform: [
      { name: "product" },
      {
        id: "quantity",
        grid: [{ name: "quantity" }, { name: "unit" }],
        size: [1, 2],
      },
      { name: "amount" },
      { name: "description" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
