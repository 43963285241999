import React from "react";
import { FormattedMessage as FM } from "react-intl";

import IconButton from "components/Buttons/IconButton";

/**
 * create component SubtableHeader
 * @param {Object} props
 * @param {Array} props.ctxView
 * @param {Function} props.onChangeView
 * @param {String} props.viewType
 * @returns component SubtableHeader
 */
const SubtableHeader = (props) => {
  const { ctxView, handleAddModal, readOnly } = props;
  const title = ctxView.model && <FM id={ctxView.model} />;

  const getButtons = () => {
    return ctxView.table_action &&
      !readOnly &&
      ctxView.table_action.includes("add") ? (
      <IconButton
        onClick={(e) => handleAddModal(e, "add")}
        color="blue"
        name="fi fi-rr-add"
      />
    ) : null;
  };

  return (
    <div id="subtable-header" className="grid-cols-12 flex px-2 py-1">
      <div className="flex col-span-12 md:col-span-8 w-4/5 text-zinc-500">
        <h2 className="my-auto truncate text-xl">{title}</h2>
      </div>
      <div className="flex col-span-12 md:col-span-4 ml-auto gap-x-4">
        {getButtons()}
      </div>
    </div>
  );
};

export default SubtableHeader;
