import React from "react";

function CalendarHeaderRow({ title, data, attr }) {
  return (
    <tr className="text-zinc-600 h-8 bg-amber-200">
      <th className="!bg-zinc-700 text-white sticky top-0 z-10">{title}</th>
      {data.map((val, idx) => {
        return (
          <th className={"text-sm sticky top-0 z-10"} key={idx}>
            {val[attr]}%
          </th>
        );
      })}
    </tr>
  );
}

export default CalendarHeaderRow;
