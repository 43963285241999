import React, { useState, Fragment } from "react";
import { useQuery } from "@tanstack/react-query";

import StdButton from "components/Buttons/StdButton";
import mainStore from "store/mainStore";
import childStore from "store/childStore";
import proxy from "api/proxy";

function ProductCombo({ onClose }) {
  const [selected, setSelected] = useState([]);
  const { store, record, updateRecord, updateStore } = mainStore();
  const { record: child, seqId } = childStore();
  const queryProductCombo = useQuery(
    ["productCombo", child?.product?.products_mix],
    async () => {
      let prods = [];
      if (child?.product?.products_mix) {
        const { data } = await proxy.search(
          "product.product",
          [["id", "in", child?.product?.products_mix]],
          [
            "template.sale_price_w_tax",
            "sale_uom",
            "name",
            "template.list_price",
          ],
        );
        prods = data;
      }

      return prods;
    },
  );

  const handleSelect = (index) => {
    let selected_ = [...selected];
    if (selected_.includes(index)) {
      const idx = selected_.findIndex((t) => index == t);
      selected_.splice(idx, 1);
    } else {
      selected_.push(index);
    }
    setSelected(selected_);
  };

  const closeProductCombo = () => {
    const products = queryProductCombo.data.filter((option, index) => {
      return selected.includes(index);
    });
    let _activeRecord = { ...record };
    let lines = _activeRecord.lines;
    let _storeRecord = { ...store };
    if (!_storeRecord.lines) {
      _storeRecord.lines = new Map();
      _storeRecord.lines.set("create", new Map());
    }
    let to_create = _storeRecord.lines.get("create");
    const timeStamp = new Date();
    let sequence = -timeStamp.getTime();
    for (const p of products) {
      const rec = {
        id: sequence,
        quantity: 1,
        unit: p.sale_uom,
        product: p,
        unit_price: 0,
        base_price: 0,
        amount: 0,
        total_amount: 0,
      };
      const storeRec = {
        id: sequence,
        quantity: 1,
        unit: p.sale_uom,
        product: p.id,
        unit_price: 0,
        base_price: 0,
      };
      lines.set(sequence, rec);
      to_create.set(sequence, storeRec);
      sequence -= 1;
    }
    updateRecord(_activeRecord);
    updateStore(_storeRecord);
    onClose();
  };

  return (
    <Fragment>
      <div className="px-4 sm:px-6 lg:px-8 py-2 md:col-span-2">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-2">
          {queryProductCombo.data?.map((product, index) => {
            return (
              <div
                className={
                  "rounded-md  cursor-pointer shadow-sm hover:opacity-50 text-center py-8 " +
                  (!selected.includes(index)
                    ? "bg-orange-100"
                    : "bg-orange-300")
                }
                key={product.id}
                onClick={() => {
                  handleSelect(index);
                }}
                name={index}
              >
                <p
                  key={product.id}
                  className="pointer-events-none flex-wrap p-2"
                >
                  {product.name}
                </p>
              </div>
            );
          })}
        </div>
        <div className="flex flex-row-reverse space-x-3 pt-3">
          <StdButton
            color={"blue"}
            onClick={closeProductCombo}
            name={"done"}
            content={"modal_form.button_accept"}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default ProductCombo;
