import { create } from "zustand";
import intl from "../i18n/messages";

const useLangStore = create((set, get) => {
  const [messages, localeLang] = intl.getBaseLang("es");

  return {
    locale: localeLang,
    messages: messages,
    changeLang: (newLocale) => {
      let [messages, localeLang] = intl.getBaseLang(newLocale);

      set((state) => ({
        ...state,
        locale: localeLang,
        messages: messages,
      }));
    },
  };
});

export default useLangStore;
