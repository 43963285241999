import React from "react";

import FormGrid from "components/MeForm/FormGrid";
import Form from "components/MeForm/Form";
import { useWizardStore } from "store/wizardStore";

/**
 *
 * @param {object} props - component props
 * @param {object} props.ctxView - object for render record
 * @param {object} props.handleButton - object record to show
 * @param {boolean} props.parentRec - true or false
 * @return {Component} - Modal Form for show form record
 */
function FormWizard({ ctxView, handleButton, parentRec }) {
  const { record } = useWizardStore();
  return (
    <FormGrid>
      <Form
        key="wizard-form"
        ctxView={ctxView}
        webform={ctxView.webform}
        parentRec={parentRec}
        handleButton={handleButton}
        record={record}
        level="wizard"
      />
    </FormGrid>
  );
}

export default FormWizard;
