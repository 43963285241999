import React, { Fragment, useEffect } from "react";
import proxy from "api/proxy";
import { signal } from "@preact/signals-react";
import Loading from "components/Tools/Loading";
import StdButton from "components/Buttons/StdButton";
import tools from "tools/functions";
import {
  BookmarkIcon,
  CurrencyDollarIcon,
  ShoppingCartIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { useWizardStore } from "store/wizardStore";
import {
  TAGS,
  ADDITIONALSPRODUCTS,
  RECOMMENDEDPRODUCT,
  DEFAULT_TEXT,
  PRESENTATION_PRODUCT,
  FOODSPECIFICATIONS,
} from "ext-apps/WebMelhous/fakeData";

import "../../../style.css";
import GalleryItem from "components/Tools/GalleryItem";
const imgDefault =
  "https://www.recetasnestle.com.co/sites/default/files/inline-images/comida-internacional-cocina-de-la-india.jpg";

const arrayProducts = signal({});
const stateButton = signal(false);
const viewMore = signal(false);

const ModalProduct = ({ product, imgDefault, onClose, mode = "catalog" }) => {
  const { record, updateWizard } = useWizardStore();
  console.log(TAGS, " etiquetas");

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: dataProduct, error } = await proxy.browse(
          "product.product",
          [product.id],
          [
            "id",
            "name",
            "description",
            "images",
            "images.image_url",
            "list_price",
          ],
        );
        if (dataProduct) {
          arrayProducts.value = dataProduct[0];
        } else {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [product.id]);

  const handlerAddCard = (e) => {
    const _record = record;
    if (!_record.cart) {
      _record.cart = [];
    }
    _record.cart.push(product);
    updateWizard(_record);
  };

  const TagsComponent = () => {
    return (
      <div className="grid grid-cols-3 gap-x-2 divide-x divide-gray-200">
        {TAGS.slice(0, 8).map((tag, index) => (
          <div
            key={index}
            className={
              "flex space-x-2 text-gray-600 py-6 px-3 mb-2 items-center text-base"
            }
          >
            {tag.icon && <span>{tag.icon}</span>}
            <span>{tag.name}</span>
          </div>
        ))}
      </div>
    );
  };

  const CustomItemRecommendedProducts = ({ data }) => {
    return (
      <div className="flex flex-col shadow-md text-xs rounded-xl bg-gray-50 border border-gray-100 text-black overflow-hidden">
        <figure>
          <img
            src={
              data.img ||
              "https://www.recetasnestle.com.co/sites/default/files/inline-images/comida-internacional-cocina-de-la-india.jpg"
            }
            className="md:max-w-full md:h-[100px] max-w-full h-[150px] object-cover "
          />
        </figure>
        <div className="flex flex-col space-y-1 px-3 py-3 ">
          <span className="md:text-base text-base truncate font-medium text-left">
            {data.name}
          </span>
          <span className=" text-right">${data.price}</span>
        </div>
      </div>
    );
  };
  const ContentProduct = () => {
    return (
      <Fragment>
        <h2 className="text-blue-presik font-bold text-2xl">
          {arrayProducts.value.name}
        </h2>
        <p className={viewMore.value ? "line-clamp-none" : "line-clamp-2"}>
          {(arrayProducts.value.name && arrayProducts.value.description) ||
            DEFAULT_TEXT.description}
        </p>
        <button
          className="text-gray-700 underline"
          onClick={() => (viewMore.value = viewMore.value ? false : true)}
        >
          {!viewMore.value ? "Ver más" : "Ver menos"}
        </button>
        <div className="text-base md:text-base font-bold pt-2 flex justify-end mb-4">
          <span className="px-5 rounded-full  border-gray-400 flex justify-center space-x-1 text-3xl">
            <CurrencyDollarIcon className="w-8" />
            <span>
              {tools.fmtMoneyLocal(arrayProducts.value.list_price, 0) ||
                arrayProducts.value.price}
            </span>
          </span>
        </div>
        <hr className="border-b-gray-200 border-spacing-1 mx-auto h-7  max-w-[70%]" />
        {mode !== "catalog" && (
          <StdButton
            style="uppercase text-yellow-500 w-[250px]"
            color="bluePresik"
            disabled={stateButton.value}
            onClick={(e) => handlerAddCard(e)}
          >
            <ShoppingCartIcon className="w-5" /> <span>Agregar al carrito</span>
          </StdButton>
        )}
      </Fragment>
    );
  };

  const ContentAddittionalInfo = () => {
    return (
      <div className="">
        {FOODSPECIFICATIONS.map((item, index) => (
          <div
            className={`grid grid-cols-2 py-3 px-5 ${
              index % 2 === 0 ? "bg-white" : "bg-gray-100"
            }`}
          >
            <span className="font-medium">{item.name}: </span>
            <span>{item.value}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Fragment>
      {arrayProducts ? (
        <article
          className=" z-[100] w-full md:max-w-6xl bg-light md:rounded-md overflow-hidden bg-white mx-auto relative"
          id="modal-melhous"
        >
          {/* header */}
          <div className="p-5 md:p-5 overflow-hidden min-h text-white w-full fixed top-0 left-0 z-20">
            <XMarkIcon className="w-22 p-2 bg-yellow-500 rounded-l-full text-blue-presik absolute -right-1 -top-0 h-full" />
          </div>
          {/* Contenedor del producto */}
          <div className="">
            <div className="relative">
              {/* <EyeIcon className="absolute w-10 text-white left-2 top-2" /> */}
              <img
                src={
                  arrayProducts.value &&
                  arrayProducts.value["images"] &&
                  arrayProducts.value["images"].length !== 0
                    ? arrayProducts.value["images."][0].image_url
                    : imgDefault
                }
                className="md:w-full  w-full md:h-96 h-42 object-cover md:rounded-md max-w-none cursor-pointer"
                alt="Product Image"
              />
              {/* <PresentationComponent /> */}
            </div>
            {/* body product  */}
            <div className="">
              {/* Info product , desc , price , name */}
              <div className="space-y-3 px-5 md:px-10 md:pl-10 py-5">
                <ContentProduct />
              </div>
              {/* other info */}
              <div>
                <ContentAddittionalInfo />
                <TagsComponent />
              </div>
              <div className="mt-4  pb-10 md:pl-4 w-[180%] md:w-[140%]  relative -left-[40%] md:-left-[20%]">
                <GalleryItem
                  data={RECOMMENDEDPRODUCT}
                  renderItemComponent={<CustomItemRecommendedProducts />}
                  numberItem={{ desk: 5, mobile: 3 }}
                  mode="default"
                  style="grid grid-cols-3 md:grid-cols-5 gap-x-6"
                  label="Acompáñalo con"
                  indicator={false}
                />
              </div>
            </div>
          </div>
        </article>
      ) : (
        <Loading />
      )}
    </Fragment>
  );
};

export default ModalProduct;
