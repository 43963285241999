const initOpenPay = () => {
  const openPay = window.OpenPay;
  openPay.setSandboxMode(true);
  openPay.setApiKey("xxx");
  openPay.setId("xxx");
};

export default {
  initOpenPay,
};
