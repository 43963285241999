import React from "react";
import { EyeIcon } from "@heroicons/react/20/solid";
import imgDefault from "../../../assets/img/default-image.jpg";

const CardItemProduct = ({ data, handleProduct }) => {
  let img = imgDefault;

  if (data?.images?.length > 0) {
    img = data?.images[0];
  }
  console.log(data);
  return (
    <article
      className="product-card group h-full transform overflow-hidden rounded border border-border-200 bg-light shadow-sm transition-all duration-200 hover:-translate-y-0.5 hover:shadow"
      onClick={() => handleProduct(data)}
    >
      <div
        className="relative flex  w-auto cursor-pointer items-start justify-center"
        // onClick={handleProductQuickView}
      >
        <img src={img} className="product-image object-cover h-48" />
      </div>
      {/* End of product image */}

      <div className="py-2 md:py-3 px-4 content">
        <h3
          className="mb-2 font-semibold cursor-pointer truncate text-xs text-body md:text-sm"
          // onClick={handleProductQuickView}
        >
          {data?.name}
        </h3>
        <div className="flex items-center justify-between">
          <div className="space-x-3">
            <span className="text-xs  text-heading md:text-sm">
              <span
                className="font-semibold
"
              >
                Codigo:
              </span>{" "}
              {data?.code}
            </span>
            <span className="text-xs  text-heading md:text-sm">
              <span className="font-semibold">ID:</span> {data?.id}
            </span>
          </div>
          <EyeIcon className="w-8 text-gray-400 relative -top-1 group-hover:text-bluePresik" />
        </div>

        {/* End of add to cart */}
      </div>
    </article>
  );
};

export default CardItemProduct;
