import { CurrencyDollarIcon, PlusCircleIcon } from "@heroicons/react/20/solid";
import React from "react";
import imgDefault from "../../../assets/img/default-image.jpg";

const ItemCard = ({
  activity,
  keyId,
  handleChangeActivity,
  mode = "image",
  // mode = "icon",
}) => {
  const quotaSum = (activities) => {
    let sum = 0;

    if (activities.length !== 0) {
      activities.forEach((activity) => {
        if (activity.state === "scheduled" && activity.quota >= 0) {
          sum += activity.quota;
        }
      });
    }

    return sum;
  };

  const availableSum = (activities) => {
    let sum = 0;
    if (activities.length !== 0) {
      activities.forEach((activity) => {
        if (activity.state === "scheduled" && activity.available >= 0) {
          sum += activity.available;
        }
      });
    }

    return sum;
  };

  return (
    <div
      key={keyId} // Ensure to provide a unique key for each element in the array
      className={`group h-36 hover:shadow-md ${
        keyId % 2 == 1 ? "bg-blue-presik text-white" : "bg-gray-200"
      } shadow-sm relative active:scale-95 cursor-pointer overflow-hidden text-bluePresik  rounded-md flex `}
      onClick={() => handleChangeActivity(activity.id)}
      title={activity.name}
    >
      {mode === "icon" ? (
        <div className="w-1/4 flex items-center overflow-hidden">
          <img
            src={activity.image}
            className=" w-20 relative -left-1 animate-fade-right animate-ease-linear  transform "
          />
        </div>
      ) : (
        <div className="w-2/4 flex items-center overflow-hidden">
          <img
            src={activity.img || imgDefault}
            className=" w-full h-full object-cover relative -left-1 animate-fade-right animate-ease-linear group-hover:scale-110 duration-100 transition transform "
          />
        </div>
      )}

      <div className="p-3 flex flex-col justify-between w-3/4">
        <div className="space-y-1 divide-y divide-gray-300" id="headerCard">
          <span className="text-sm font-semibold uppercase line-clamp-2 text-left">
            {activity.name}
          </span>
          <span className="flex pt-2 items-center space-x-1.5 align-middle">
            <CurrencyDollarIcon className="w-5 " />{" "}
            <span>{activity.price.toLocaleString()}</span>
          </span>
        </div>
        <div className="flex flex-col items-start ">
          <span>
            <span className="font-medium">Cupo:</span>{" "}
            {quotaSum(activity["activities"])}
          </span>
          <span>
            <span className="font-medium">Disponibilidad:</span>{" "}
            {availableSum(activity["activities"])}
          </span>

          <PlusCircleIcon
            className={`w-10  absolute right-1.5 bottom-1.5 ${
              keyId % 2 == 0 ? "text-bluePresik" : "text-white"
            } bg group-hover:scale-125 transform duration-150 `}
          />
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
