// Spanish translation
// es
import { es as views_es } from "./views";

const es = {
  "app.global.spanish": "Español",
  "app.global.english": "Ingles",
  "login.welcome": "Bienvenido!",
  "login.start_session": "INICIAR SESIÓN",
  "login.invalid_user_password": "Usuario o contraseña inválida!",
  "login.button_enter": "INGRESAR",
  "login.password": "CONTRASEÑA",
  "login.database": "BASE DE DATOS",
  "login.username": "USUARIO",
  "login.forgot_password": "Olvidó su contraseña?",
  "board.loading_please_wait": "Cargando... espere un momento!",
  "board.loading": "Cargando...",
  "board.confirm": "APROBAR",
  "board.button_edit": "Editar",
  "board.button_add": "Agregar",
  "board.button_addSearch": "Agregar Titular",
  "hotel.booking.add_room.arrival_date": "Llegada",
  "hotel.booking.add_room.departure_date": "Salida",
  "hotel.booking.add_room.nights_quantity": "Noches",
  "board.button_back": "Volver",
  "board.button_save": "Guardar",
  "board.button_remove": "Eliminar",
  "board.button_replace": "Reemplazar",
  "board.button_attachment": "Adjunto",
  "board.filter": "Filtro",
  "board.button_confirm": "CONFIRMAR",
  "board.chart.in_thousands": "En miles",
  "board.chart.see_report": "Ver Informe",
  "board.sheet_select": "Hoja de Calculo",
  "board.missing_required_fields": "Faltan campos obligatorios!",
  "quicktable.select_all": "TODO",
  "board.button_field_create": "Create",
  "board.button_field_edit": "Editar",
  missing_required_fields: "Faltan campos obligatorios!",
  "board.records_found": "Registros encontrados",
  "board.record_saved": "REGISTRO GUARDADO!",
  "board.records_saved": "REGISTROS GUARDADOS!",
  "board.record_created": "RECORD CREATED!",
  "basic_modal.button_cancel": "CANCELAR",
  "basic_modal.button_ok": "OK",
  "basic_modal.button_accept": "Aceptar",
  "basic_modal.button_add": "Agregar",
  "basic_modal.button_close": "Cerrar",
  "basic_modal.button_create": "Crear",
  available: "Habitaciones disponibles",
  not_available: "No Disponible",
  select_time: "Seleccione la hora...",
  "form_cloud_image.add_photo": "Agregar una imagen",
  "menu.dashboard": "DASHBOARD",
  "menu.delivery": "DOMICILIOS",
  "menu.order": "PEDIDOS",
  "menu.sale_order": "PEDIDOS",
  "menu.purchase": "COMPRAS",
  "menu.purchase_approval": "APROBACION DE COMPRAS",
  "menu.housekeeping": "AMA DE LLAVES",
  "menu.rest_order": "ORDEN REST.",
  "menu.travel_person": "VIAJEROS",
  "menu.ticket": "TURNADOR",
  "menu.affiliates": "AFFILIATES",
  "sale.shop.discounts.help":
    "Descuentos (selecciona los descuentos a eliminar)",
  "sale.line.help":
    "Productos (selecciona los productos a eliminar para no aplicar descuento)",
  "sale.shop.discounts.name": "Descripcion",
  "sale.line.selectable": "Seleccionar Productos",
  "sale.shop.discounts.discount": "Descuento",
  "sale.sale.kind": "Clase",
  "sale.sale.shop": "Tienda",
  "sale.sale.number": "Numero",
  "sale.sale.delivery_invoice": "Domicilio en Fact.",
  "sale.sale.invoice_type": "Tipo de Factura",
  "sale.sale.quotation": "Cotizacion",
  "sale.sale.sale_date": "Fecha",
  "sale.sale.consumer": "Consumidor",
  "sale.sale.channel": "Canal",
  "sale.sale.delivery_state": "Estado de Domic.",
  "sale.sale.total_amount": "Total",
  "sale.sale.table": "Mesa",
  "sale.sale.change_table": "Cambiar Mesa",
  "sale.sale.persons": "Persons",
  "sale.sale.reservation": "Reservación",
  "sale.sale.add_courtesy": "Agregar Cortesia",
  "sale.line.amount_w_tax": "Total",
  "sale.line.total_amount": "Total",
  "sale.line.comment": "NOTA",
  "sale.line.button_combo": "Eleccion",
  "sale.taxes": "Impuestos",
  "sale.taxes.description": "Descripción",
  "sale.taxes.base": "Base",
  "sale.taxes.percent": "Porcentaje",
  "sale.taxes.value_tax": "Valor",
  button_end: "FINALIZAR",
  "sale.line.button_combine": "Combinar",
  "sale.line.button_discount": "Descuento/Bono",
  "sale.sale.status": "ESTADO",
  "sale.sale.delivery_amount": "Valor Domicilio",
  "sale.sale.message": "MENSAJE",
  "delivery.panel": "PANEL DE DOMICILIOS",
  "delivery.panel.send": "SALIDA",
  "delivery.panel.delivery": "DOMICILIARIO",
  "delivery.panel.dispached": "POR ENTREGAR",
  "delivery.panel.by_dispached": "POR DESPACHAR",
  "delivery.panel.confirm_send": "CONFIRMAR ENTREGA",
  "delivery.panel.close": "CERRAR",
  must_bill: "No puede dar salida a pedidos sin facturar!",
  missing_delivery: "Falte seleccionar el domiciliario!",
  no_invoices_selected: "No hay facturas seleccionadas!",
  "sale.sale.comment": "Observación",
  "sale.sale.party": "Tercero",
  "sale.sale.waiting_time": "Tiempo",
  "sale.sale.order_status": "Status",
  "sale.sale.source": "Canal",
  "sale.sale.add_consumer": "AGREGAR CONSUMIDOR",
  "sale.consumer.title": "Datos de Consumidor",
  "sale.sale.state": "Estado",
  "sale.sale.billing": "Facturar",
  "sale.sale.pay": "Pago",
  "sale.sale.commanded": "COMANDADO",
  "sale.sale.dispatched": "DESPACHADO",
  "sale.sale.rejected": "RECHAZADO",
  "sale.sale.delivered": "ENTREGADO",
  "sale.sale.payment_method": "Medio De Pago",
  "sale.sale.processing": "Procesado",
  "sale.sale.cash": "Efectivo",
  "sale.sale.terminal": "Datafono",
  "sale.sale.gateway": "Pasarela",
  "sale.sale.position": "Posicion",
  "sale.line.product": "Producto",
  "sale.line.quantity": "Cant.",
  "sale.line.set_delivery_fee": "Agregar Tarifa",
  "sale.line.delivery_fee": "Valor Tarifa",
  "sale.line.sale_price_taxed": "Precio Unit",
  "sale.sale.cancelled": "Cancelado",
  "sale.sale.done": "Finalizado",
  "sale.sale.shipment_state": "Estado Envio",
  "sale.sale.invoice_state": "Estado Factura",
  "sale.sale.sent": "Enviado",
  "sale.sale.waiting": "En Espera",
  "sale.sale.paid": "Pagada",
  terminal: "DATAFONO",
  cash: "EFECTIVO",
  partial_paid: "PAGO PARCIAL",
  all_paid: "TODO PAGO",
  stock: "INVENTARIO",
  quantity_suggested: "SUGERIDO",
  cost_price: "PRECIO COSTO",
  sales: "Ventas",
  purchases: "COMPRAS",
  "sale.sale.quote": "CONFIRMAR",
  "sale.sale.draft": "Borrador",
  "sale.callcenter.create": "CREAR ORDEN",
  "sale.callcenter.view": "VER ORDENES",
  "sale.callcenter.cancel": "CANCELAR",
  "sale.sale.salesman": "Vendedor",
  "sale.sale.shipment_address": "Dir. Envio",
  "sale.sale.agent": "Agente",
  "sale.sale.description": "Descripcion",
  "sale.sale.shipment_date": "Fecha Entrega",
  "sale.sale.delivery_time": "Hora Entrega",
  "menu.portal_customer": "PORTAL DE CLIENTE",
  "sale.sale.total_amount_cache": "Total",
  "sale.sale.order_status_time.created": "Hr. Creado",
  "sale.sale.order_status_time.commanded": "Hr. Comandado",
  "sale.sale.order_status_time.requested": "Hr. Solicitado",
  "sale.sale.elapsed_time": "Tiempo",
  "sale.sale.created": "Creado",
  "sale.sale.today": "Hoy",
  today: "HOY",
  "sale.sale.print": "Imprimir",
  "sale.sale": "PEDIDO",
  "sale.sale.restart_sale": "REINICIAR VENTA",
  "sale.sale.new_sale": "NUEVA VENTA",
  "sale.sale.history": "HISTORIAL",
  "sale.line.discount": "Descuento",
  "sale.line.discount_amount": "Descuento",
  "sale.line.discount_rate": "% Dcto",
  "sale.line.unit_price": "Precio Unit.",
  "sale.line.unit": "UoM",
  "sale.line.unit_price_w_tax": "Precio Unitario con Impuesto",
  "sale.line.amount": "Valor",
  "sale.line.calculate_discount": "Calcular descuento",
  "sale.sale.discount_amount": "Valor",
  "sale.sale.type_discount": "Tipo Descuento",
  "sale.sale.untaxed_amount": "Base",
  "sale.line": "Productos",
  sale_device: "Terminal de Venta",
  diference: "DIFERENCIA",
  button_add: "Agregar",
  button_edit: "Editar",
  button_cancel: "Cancelar",
  button_delete: "Eliminar",
  successful_order: "ORDEN CONFIRMADA!",
  "sale.sale.price_list": "Lista de Precios",
  "model.sale.sale.draft": "Borrador",
  "menu.stock_request": "REQUISICION",
  "stock.shipment.internal": "REQUISICION",
  "stock.shipment.internal.reference": "REFERENCIA",
  "stock.shipment.internal.from_location": "DE BODEGA",
  "stock.shipment.internal.to_location": "A BODEGA",
  "stock.shipment.internal.moves": "PRODUCTOS",
  "stock.shipment.internal.effective_date": "FECHA",
  "stock.shipment.internal.number": "NUMERO",
  "stock.shipment.internal.state": "ESTADO",
  "stock.shipment.internal.wait": "EN ESPERA",
  "stock.shipment.internal.waiting": "EN ESPERA",
  "stock.shipment.internal.draft": "BORRADOR",
  "stock.shipment.internal.requested_by": "SOLICITADO POR",
  "stock.move.uom": "UdM",
  "stock.move.product": "PRODUCTO",
  "stock.move.quantity": "CANTIDAD",
  default_uom: "UdM",
  "stock.move": "PRODUCTOS",
  "stock.shipment.internal.today": "HOY",
  wait: "CONFIRMAR",
  request: "SOLICITADO",
  user_has_not_access_permissions: "El usuario no tiene permisos de acceso!",
  "purchase.purchase.today": "HOY",
  "purchase.purchase.draft": "BORRADOR",
  "purchase.purchase.pending": "PENDIENTE",
  "purchase.purchase.cancelled": "CANCELADO",
  "purchase.purchase.confirm": "CONFIRMAR",
  "purchase.purchase.quotation": "COTIZACION",
  "purchase.purchase.approved": "APROBADO",
  "purchase.purchase.confirmed": "CONFIRMADO",
  "purchase.purchase.done": "FINALIZADO",
  "purchase.purchase.processing": "PROCESADO",
  "purchase.purchase.cancelled": "CANCELADO",
  "purchase.purchase.approval": "APROBAR",
  "purchase.purchase.urgent": "URGENCIA",
  "purchase.purchase.high": "ALTA",
  "purchase.purchase.low": "BAJO",
  "purchase.purchase": "COMPRAS",
  "purchase.purchase.all": "TODAS",
  "purchase.purchase.purchase_date": "FECHA",
  "purchase.purchase.number": "NUMERO",
  "purchase.purchase.total_amount": "TOTAL",
  "purchase.purchase.party": "PROVEEDOR",
  "purchase.purchase.comment": "NOTA",
  "purchase.purchase.reference": "REFERENCIA",
  "purchase.purchase.confirm_user": "CONFIRMADO POR",
  "purchase.purchase.approval_user": "APROBADO POR",
  "purchase.purchase.state": "ESTADO",
  "purchase.purchase.project": "PROYECTO",
  "purchase.purchase.priority": "PRIORIDAD",
  "purchase.purchase.approval_signature": "FIRMA DE APROBACION",
  "purchase.purchase.confirmation_signature": "FIRMA DE CONFIRMACION",
  "purchase.purchase.create_uid": "USUARIO",
  "purchase.purchase.payment_term": "PLAZO DE PAGO",
  "purchase.purchase.description": "DESCRIPCION",
  "purchase.line.description": "DESCRIPCION",
  "purchase.line.product": "PRODUCTO",
  "purchase.line.quantity": "CANTIDAD",
  "purchase.line.unit_price": "PRECIO UNITARIO",
  "purchase.line.amount": "TOTAL",
  "purchase.purchase.untaxed_amount": "BASE",
  "purchase.purchase.tax_amount": "IMPUESTO",
  "purchase.purchase.sign_image": "FIRMA",
  "purchase_requisition.requisition": "SOLICITUDES",
  "purchase_requisition.suggested": "SUGERIDO PROVEEDOR",
  "purchase_requisition.reload": "RECARGAR",
  "purchase_requisition.create": "CREAR COMPRA",
  "purchase_requisition.warehouse": "LOCACION",
  "purchase_requisition.supplier": "PROVEEDOR",
  "purchase_requisition.brand": "MARCA",
  "purchase_requisition.file": "ARCHIVO DE PROVEEDOR",
  "purchase_requisition.productsNotFound": "PRODUCTOS NO ENCONTRADOS",
  "purchase.request.party": "Proveedor",
  "purchase.request.product.code": "Codigo",
  "purchase.request.product.name": "Producto",
  "purchase.request.product.description": "Descripcion",
  "purchase.request.current_stock": "Stock",
  "purchase.request.sale_history": "Ventas",
  "purchase.request.quantity": "Cantidad",
  "purchase.request.product.cost_price": "Precio Costo",
  "purchase.request.purchases": "Compras",
  "sale.sale.all_paid": "TODO PAGO",
  "purchase.line": "PRODUCTOS",
  "menu.surveillance_schedule": "VIGILANCIA",
  "menu.purchase_requisition": "REQUISICION COMPRAS",
  "surveillance.schedule": "Turnos",
  supplier: "PROVEEDOR",
  start_date: "FECHA INICIAL",
  end_date: "FECHA FINAL",
  code: "COD.",
  // employee: "EMPLEADO",
  active: "ACTIVO",
  city: "CIUDAD",
  name: "NOMBRE",
  unit: "UDM",
  position: "POSICION",
  "board.button_reload": "RECARGAR",
  "menu.staff_access": "ACCESS CONTROL",
  "stock.inventory.date": "Fecha",
  "stock.inventory.add_quantity": "Agregar Cantidad",
  "stock.inventory.form_set_inventory": "Ingresar Inventario",
  "stock.inventory.substract_quantity": "Disminuir Cantidad",
  "stock.inventory.current_quantity": "Cantidad Actual",
  "stock.inventory.product": "Producto",
  "stock.inventory.reset": "Reset",
  "stock.inventory.done": "FINALIZADO",
  "stock.inventory.confirm_quantity": "Confirmar",
  "stock.inventory.number": "Numero",
  "stock.inventory.location": "Ubicacion",
  "stock.inventory.state": "Estado",
  "stock.inventory": "INVENTARIO",
  "stock.inventory.comment": "COMENTARIO",
  "stock.inventory.confirm": "CONFIRMAR",
  "stock.inventory.cancel": "CANCELAR",
  "menu.inventory": "INVENTARIO",
  "stock.inventory.today": "HOY",
  "stock.inventory.line.product.code": "Codigo",
  "stock.inventory.line.product": "Producto",
  "stock.inventory.line.quantity": "Cantidad",
  "stock.inventory.line.diference": "Diferencia",
  "stock.inventory.line.product.description": "Descripcion",
  "stock.inventory.assign_to": "Asignado A",
  "stock.inventory.pre_count": "Pre-conteo",
  pre_count: "Pre-conteo",
  "stock.inventory.last_two_months": "ULTIMOS DOS MESES",
  "stock.inventory.line": "PRODUCTOS DE INVENTARIO",
  "stock.inventory.line.expected_quantity": "Cantidad Esperada",
  "stock.inventory.draft": "BORRADOR",
  "stock.inventory.checkup": "REVISION",
  "product.product.code": "CODE",
  "product.product.name": "NOMBRE",
  "product.product.description": "DESCRIPCION",
  "product.product.default_uom": "UDM",
  checkup: "REVISION",
  "stock.inventory.add_product": "AGREGAR PRODUCTOS",
  successful: "TRANSACCION EXITOSA!",
  total_amount_cache: "VALOR TOTAL",
  untaxed_amount_cache: "SUBTOTAL",
  tax_amount_cache: "IMPUESTOS",
  "menu.hotel_planner": "PLANNER",
  "menu.hotel_calendar": "CALENDAR",
  "menu.hotel_rack": "RACK",
  "menu.take_order": "TOMA DE PEDIDO",
  "menu.order_viewer": "VISOR DE ORDENES",
  "menu.shipment_internal": "ENVIOS",
  "sale.sale.table_assigned": "Mesa",
  command: "Comandar",
  "sale.line.note": "Nota",
  "sale.line.requested": "Solicitado",
  "sale.line.draft": "Borrador",
  "sale.line.commanded": "Coman.",
  "room.none": "None",
  "room.blocked": "Bloqueada",
  "room.check_in": "Check-In",
  "room.check_out": "Check-Out",
  "room.maintenance": "Mantenimiento",
  "room.pending": "Pendiente",
  "hotel.folio.registration_state": "Status",
  "hotel.folio.room": "Habitacion",
  "hotel.folio.main_guest": "Huesped Principal",
  "hotel.folio.nights_quantity": "Noches",
  "hotel.folio.arrival_date": "Llegada",
  "hotel.folio.departure_date": "Salida",
  "hotel.folio.notes": "Notas",
  "hotel.folio.check_in": "Check-In",
  "hotel.folio.check_out": "Check-Out",
  "hotel.folio.confirm": "Confirmar",
  "hotel.folio.pending": "Pendiente",
  "hotel.folio.booking": "Reserva",
  "hotel.folio.unit_price": "Precio Unit.",
  "hotel.folio.registration_card": "Tarjeta de Registro",
  "hotel.folio.product": "Acomodación",
  "hotel.folio.channel": "Canal",
  "hotel.folio.contact": "Contacto",
  "hotel.folio.group": "Grupo",
  "hotel.folio.total_amount": "Total",
  "hotel.folio.payment_status": "Payment Status",
  "hotel.folio.bed_breakfast": "Cama & Desayuno",
  "hotel.folio.no_breakfast": "Sin Desayuno",
  "hotel.folio.all_inclusive": "All Inclusive",
  "hotel.folio.full_american": "Full American",
  "hotel.folio.half_american": "Half American",
  "hotel.folio.num_adults": "Adultos",
  "hotel.folio.num_children": "Niños",
  "hotel.folio.number": "Número",
  "hotel.folio.charge.folio": "Folio",
  "hotel.folio.charge.order": "Orden",
  "hotel.folio.charge.kind": "Clase",
  "hotel.folio.charge.invoice_line": "Factura",
  "hotel.folio.occupancy": "Ocupación",
  "hotel.folio.occupancy.occupancy_date": "Fecha",
  "hotel.folio.occupancy.unit_price": "Precio",
  "hotel.folio.occupancy.unit_price_w_tax": "Precio Imp.",
  "hotel.folio.occupancy.charge": "Cargo",
  "hotel.folio.occupancy.state": "Estado",
  "hotel.folio.occupancy.confirmed": "Confirmad0",
  "hotel.folio.occupancy.invoiced": "Facturado",
  "hotel.folio.occupancy.draft": "Borrador",
  "hotel.folio.pax": "PAX",
  "menu.self_service_sale": "AUTO-SERVICIO",
  "self_service_sale.select_product": "SELECCIONE SUS PRODUCTOS",
  "sale_turn.new": "ingresar",
  "hotel.maintenance": "Mantenimiento",
  "hotel.maintenance.start_date": "Fecha Inicial",
  "hotel.maintenance.end_date": "Fecha Final",
  "hotel.maintenance.room": "Hab.",
  "hotel.maintenance.criticality": "Criticidad",
  "hotel.maintenance.issue": "Situación",
  "hotel.maintenance.confirm": "Confirmar",
  "hotel.maintenance.low": "Baja",
  "hotel.maintenance.urgent": "Urgente",
  "hotel.maintenance.important": "Importante",
  "hotel.maintenance.total_days": "Total Dias",
  current_stock: "INVENTARIO",
  sale_history: "VENTAS",
  "menu.web_checkin": "WEB CHECK IN",
  "app.webcheckin.no_booking": "No. RESERVA",
  webcheckin_success_msg:
    "Felicidades, Ahora tu ingreso será mucho más rápido a nuestro hotel, y desde ya podrás disfrutar de nuestros servicios Premiun.",
  webcheckin_success_header: "Registro Exitoso!!!",
  webcheckin_success_our_services: "Nuestros Servicios",
  webcheckin_booking_page:
    "Ahora puedes agregar los huespedes a cada una de tus habitaciones reservadas!, cada vez que quieras agregar una persona haz click en la habitación",
  "party.party.male": "Masculino",
  "party.party.female": "Femenino",
  "party.party.cedula_de_ciudadania": "Cedula de Ciudadania",
  "party.party.tarjeta_de_identidad": "Tarjeta de Identidad",
  "party.party.tarjeta_de_extranjeria": "Tarjeta de Extranjeria",
  "party.party.cedula_de_extranjeria": "Cedula de Extranjeria",
  "app.booking.cedula_de_ciudadania": "Cedula de Ciudadania",
  "app.booking.tarjeta_de_identidad": "Tarjeta de Identidad",
  "app.booking.pasaporte": "Pasaporte",
  "app.booking.tarjeta_de_extranjeria": "Tarjeta de Extranjeria",
  "app.booking.cedula_de_extranjeria": "Cedula de Extranjeria",
  adult: "Adulto",
  child: "Niño/a",
  type: "TIPO",
  link: "LINK",
  file: "ARCHIVO",
  "menu.charts": "DASH REPORT",
  payroll: "Nomina",
  hotel: "Hotel",
  crm: "CRM",
  dashboard: "Dashboard",
  go_back: "REGRESAR",
  sexual_diversity: "DIVERSIDAD SEXUAL",
  displaced: "DESPLAZADO",
  victim_of_violence: "VICTIMA DE LA VIOLENCIA",
  disabled_person: "PERSONA CON DISCAPACIDAD",
  pregnant_woman: "MUJER GESTANTE",
  homeless: "HABITANTE DE CALLE",
  deprived_of_freedom: "PRIVADO DE LA LIBERTAD",
  "crm.customer_service.create_service": "CREAR RADICADO",
  nursing_mother: "MADRE LACTANTE",
  older_adult: "ADULTO MAYOR",
  "board.dialog.button_close": "Cerrar",
  "board.dialog.button_ok": "OK",
  "board.dialog.button_cancel": "Cancelar",
  "board.dialog.button_discard": "Descartar",
  "crm.customer_service.city_region": "Ciudad",
  "crm.customer_service.phone": "Telefono",
  "crm.customer_service.case": "DETALLE CAUSAL",
  "crm.customer_service.detailed_especific_1": "Detalle Especifico",
  "crm.customer_service.address": "Dirección",
  "crm.customer_service.attach_customer_1": "Adjunto",
  "crm.customer_service.ethnical_group": "Grupo Étnico",
  "crm.customer_service.special_population": "Población Especial",
  "crm.customer_service.personal_information": "INFORMACIÓN PERSONAL",
  "crm.customer_service.pqrs_information": "INFORMACIÓN PQRS",
  "crm.customer_service.demographic_information":
    "INFORMACIÓN SOCIODEMOGRAFICA",
  "crm.customer_service.description_facts": "DESCRIPCIÓN DE LOS HECHOS",
  "crm.customer_service.search": "Buscar",
  "crm.customer_service.response": "Respuesta",
  "crm.customer_service.description": "Resumen de Solicitud",
  "crm.customer_service.state": "Estado",
  "crm.customer_service.party": "Nombre",
  "crm.customer_service.cs_date": "Fecha de Solicitud",
  "crm.customer_service.effective_date": "Fecha de Respuesta",
  "crm.customer_service.health_provider": "Prestador de Servicio",
  "crm.customer_service.number": "Número de Radicado",
  "crm.customer_service.party_id_number": "Número de Identificación",
  "crm.customer_service.draft": "Radicado",
  "crm.customer_service.open": "Abierto",
  "crm.customer_service.close": "Cerrado",
  "crm.customer_service.email": "Correo",
  "sale_call_center.source": "CANAL",
  "sale_call_center.shop": "TIENDA",
  "sale_call_center.kind": "CLASE",
  to_table: "A LA MESA",
  take_away: "PARA LLEVAR",
  delivery: "DOMICILIO",
  "menu.sale_call_center": "REGISTRO DE PEDIDOS",
  address: "DIRECCION",
  country_residence: "PAIS DE RESIDENCIA",
  company: "Company",
  user: "User",
  password: "Password",
  enter: "Ingresar",
  "modal_msg.record_successful": "Registro ingresado correctamente!",
  "modal_msg.record_warning": "Adverencia!",
  "modal_msg.record_ask": "Pregunta",
  "modal_msg.record_error": "Error de Registro",
  record_error: "Error de Registro!",
  id_document: "DOCUMENTO",
  button_open: "Ver",
  "form_modal.button_close": "CERRAR",
  "hotel.room": "Ama de Llaves",
  "hotel.room.state": "Estado",
  "hotel.room.name": "Hab.",
  "hotel.room.cleaning_type": "Tipo Limpieza",
  "hotel.room.not_authorized": "No Autor.",
  "hotel.room.type_": "Tipo",
  "hotel.room.last_check_in": "Ult. Check-In",
  "hotel.room.last_check_out": "Ult. Check-Out",
  "hotel.room.last_clean": "Ult. Limpieza",
  "hotel.room.notes": "Notas",
  "hotel.room.dirty": "Sucia",
  "hotel.room.clean": "Limpia",
  "hotel.room.all": "Todas",
  "hotel.room.maintenance": "Manten.",
  "hotel.room.location": "Locación",
  "hotel.room.check_out_today": "Check Out Hoy",
  "hotel.room.arrival_today": "Llegada Hoy",
  "hotel.room.check_in_today": "Check In Hoy",
  "hotel.room.departure_today": "Salida Hoy",
  "hotel.room.housekeeping": "Ama de Llaves",
  "hotel.booking.add_rooms": "Habitaciones",
  "hotel.booking.add_rooms.accept": "Aceptar",
  "hotel.booking_statement": "PRE-CUENTA",
  "hotel.booking.add_payment": "Pago",
  // inicio
  "hotel.booking.plan+rate": "Plan + Tarifas sin impuestos",
  "hotel.booking.fees_taxes_not_included": "CARGOS E IMPUESTOS NO INCLUIDOS",
  "hotel.booking.msg_iva_foreign":
    "Impuesto IVA no incluido a extranjeros, previa verificación de pasaporte y tarjeta migratoria al momento del check-in.",
  "hotel.booking.msg_iva": "IVA incluido del 19%.",
  "hotel.booking.i_foreigner": "Soy Extranjero",
  "hotel.booking.i_colombiano": "Soy Colombiano",

  // fin
  "planner.date": "FECHA",
  "hotel.folio.breakfast_included": "Desayuno",
  "hotel.folio.meal_plan": "Regimen Comida",
  "hotel.folio.pending_total": "Pendiente por Pagar",
  "hotel.folio.agent": "Agente",
  "hotel.folio.unit_price_w_tax": "Precio con Imp.",
  "hotel.folio.vehicle_plate": "Placa",
  "hotel.folio.guests": "Huespedes",
  "hotel.folio.charge": "Cargo",
  "hotel.folio.charges": "Cargos",
  // "hotel.folio.guest": "Huesped",
  // "hotel.folio.guest.main_guest": "Huesped Principal",
  // "hotel.folio.guest.name": "Nombre",
  // "hotel.folio.guest.type_document": "Tipo Documento",
  // "hotel.folio.guest.id_number": "Numero de Doc.",
  // "hotel.folio.guest.mobile": "Móvil",
  // "hotel.folio.guest.email": "Email",
  // "hotel.folio.guest.address": "Direccion",
  // "hotel.folio.guest.nationality": "Nacionalidad",
  // "hotel.folio.guest.origin_country": "Pais Origen",
  // "hotel.folio.guest.target_country": "Pais Destino",
  // "hotel.folio.guest.type_guest": "Tipo",
  // "hotel.folio.guest.birthday": "Fecha de Nacimiento",
  // "hotel.folio.guest.notes": "Notas",
  // "hotel.folio.guest.visa_date": "Fecha de Visa",
  // "hotel.folio.guest.visa_number": "Número Visa",
  // "hotel.folio.guest.sex": "Sexo",
  // "hotel.folio.guest.profession": "Profesion",
  // "hotel.folio.guest.add_guest": "Agregar",
  // "hotel.folio.guest.cancel": "Cancelar",
  // "hotel.folio.guest.country": "País",
  // "hotel.folio.guest.subdivision": "State",
  // "hotel.folio.guest.clone_holder": "Clonar Titular",
  "party.party.registro_civil": "Registro Civil de Nacimiento",
  "party.party.tarjeta_identidad": "Tarjeta de Identidad",
  "party.party.cedula_ciudadania": "Cedula de Ciudadania",
  "party.party.tarjeta_extranjeria": "Tarjeta de Extranjeria",
  "party.party.cedula_extranjeria": "Cedula de Extranjeria",
  "party.party.nit": "NIT",
  "party.party.pasaporte": "Pasaporte",
  "party.party.tipo_documento_extranjero": "Tipo de Documento Extranjero",
  "party.party.pep": "PEP",
  "party.party.nit_otro_pais": "NIT de otro pais",
  "party.party.nuip": "NUIP",
  "hotel.folio.charge.sale_date": "Fecha",
  "hotel.folio.charge.product": "Producto",
  "hotel.folio.charge.quantity": "Cantidad",
  "hotel.folio.charge.unit_price_w_tax": "Precio Unit.",
  "hotel.folio.charge.amount": "Importe",
  "hotel.folio.charge.status": "Status",
  "hotel.folio.charge.pending": "Pendiente",
  "hotel.folio.charge.draft": "Borrador",
  "hotel.folio.charge.add_product": "Agregar Producto",
  "hotel.booking.number": "Número",
  "hotel.booking.party": "Titular",
  "hotel.booking.contact": "Contacto",
  "hotel.booking.bill_to": "Facturar a",
  "hotel.booking.booking_date": "Fecha de Reserva",
  "hotel.booking.total_amount": "Importe",
  "hotel.booking.total_advances": "Total Anticipos",
  "hotel.booking.lines": "No. Hab",
  "hotel.booking.media": "Medio",
  "hotel.booking.meal_plan": "Regimen Comida",
  "hotel.booking.space": "Espacio",
  "hotel.booking.price_list": "Lista de Precios",
  "hotel.booking.payment_term": "Plazo de Pago",
  "hotel.booking.rate_plan": "Plan Tarifario",
  "hotel.booking.pending_to_pay": "Pendiente de Pago",
  "hotel.booking.group": "Grupo",
  "hotel.booking.corporative": "Corporativo",
  "hotel.booking.state": "Estado",
  "hotel.booking.offer": "Oferta",
  "hotel.booking.complimentary": "Complimentary",
  "hotel.booking.confirm": "Confirmar",
  "hotel.booking.cancelled": "Cancelado",
  "hotel.booking.not_show": "No Presentado",
  "hotel.booking.finished": "Terminado",
  "hotel.booking": "RESERVA",
  "hotel.booking.today": "Hoy",
  "hotel.booking.confirmed": "Confirmada",
  "hotel.booking.bill": "Facturar",
  "hotel.booking.channel": "Canal",
  "hotel.booking.ota_booking_code": "Cod. OTA",
  "hotel.booking.agent": "Agente",
  "hotel.booking.notes": "Notas",
  "hotel.booking.advances_payments": "Anticipos/Pagos",
  "hotel.booking.invoices": "Facturas",
  "hotel.booking.notifications": "Notificaciones",
  "hotel.booking.pax": "PAX",
  "hotel.booking.taxes_exception": "Excepción de IVA",
  "hotel.booking.holder": "Titular",
  "hotel.booking.holder_guest": "Titular / Huesped",
  "hotel.booking.guest": "Huesped",
  "hotel.booking.blocked": "Bloqueo",
  "hotel.folio": "FOLIO",
  "hotel.booking.lead_origin": "Origen",
  button_info: "Info",
  "menu.booking": "RESERVAS",
  "ir.attachment": "ADJUNTOS",
  "ir.attachment.name": "NOMBRE",
  "ir.attachment.description": "DESCRIPCION",
  "ir.attachment.type": "TIPO",
  "ir.attachment.link": "LINK",
  "ir.attachment.data": "ARCHIVO",
  "app.webcheckin.customer": "CLIENTE",
  "app.webcheckin.channel": "CANAL",
  "app.webcheckin.arrival_date": "ARRIVAL DATE",
  "app.webcheckin.departure_date": "DEPARTURE DATE",
  "app.webcheckin.finish": "FINALIZAR",
  "app.webcheckin.direct": "DIRECTO",
  "app.webcheckin.main_guest": "ERES HUESPED PRINCIPAL?",
  "app.webcheckin.name": "NOMBRE",
  "app.webcheckin.mobile": "MOVIL",
  "app.webcheckin.email": "CORREO ELECTRONICO",
  "app.webcheckin.address": "DIRECCION",
  "app.webcheckin.nationality": "NACIONALIDAD",
  "app.webcheckin.country_residence": "PAIS DE RESIDENCIA",
  "app.webcheckin.sex": "SEXO",
  "app.webcheckin.type_guest": "TIPO DE HUESPED",
  "app.webcheckin.id_number": "NUMERO DE ID.",
  "app.webcheckin.type_document": "TIPO DE DOCUMENTO",
  "app.webcheckin.visa_number": "No VISA",
  "app.webcheckin.visa_date": "FECHA EXP. VISA",
  "app.webcheckin.birthday": "FECHA DE NACIMIENTO",
  "app.webcheckin.profession": "PROFESION",
  "app.webcheckin.add_guest": "AGREGAR HUESPED",
  "app.webcheckin.cancel": "CANCELAR",
  "app.webcheckin.total_guests": "Total Huespedes",
  "surveillance.location.code": "COD.",
  "surveillance.location.name": "NOMBRE",
  "surveillance.location.customer": "CLIENTE",
  "surveillance.location.city": "CIUDAD",
  "schedule.scheduling": "PROG.",
  "menu.order_notification": "NOTIFICACION DE PEDIDOS",
  "sale.sale.take_away": "PARA LLEVAR",
  "sale.sale.to_table": "A LA MESA",
  "sale.sale.delivery": "DOMICILIO",
  "sale.sale.requested": "SOLICITADO",
  "sale.sale.in_preparation": "EN PREPARACION",
  "sale.sale.command": "Comandar",
  "sale.sale.return": "VOLVER",
  "sale.sale.history": "HISTORIAL",
  "sale.sale.confirm": "CONFIRMAR",
  "surveillance.schedule.shift": "Turno",
  "surveillance.schedule.shift.guard": "Guarda",
  "surveillance.schedule.shift.location": "Ubicacion",
  "surveillance.schedule.shift.shift_date": "Fecha",
  "surveillance.schedule.shift.replicate": "Replicar",
  "surveillance.schedule.shift.extra_shift": "Turno Extra",
  "surveillance.schedule.shift.shift": "Clase de Turno",
  "surveillance.schedule.shift.duration": "Duracion",
  "surveillance.schedule.shift.enter_dtime": "Ingreso",
  "surveillance.schedule.shift.exit_dtime": "Salida",
  "surveillance.schedule.shift.position": "Posicion",
  "surveillance.schedule.shift.event_category": "Novedad Prog.",
  "surveillance.schedule.shift.event": "Novedad Efectiva",
  "surveillance.schedule.shift.notes": "Notas",
  "surveillance.schedule.shift.event_start_date": "Inicio de Novedad",
  "surveillance.schedule.shift.event_end_date": "Fin de Novedad",
  "surveillance.schedule.shift.kind": "Turno",
  "surveillance.schedule.shift.state": "Estado",
  "surveillance.schedule.shift.guard.photo_link": "Foto",
  "surveillance.schedule.shift.draft": "Borrador",
  "surveillance.schedule.shift.performed": "Realizado",
  "surveillance.schedule.shift.schedule": "Programacion",
  "surveillance.schedule.draft": "Borrador",
  "surveillance.schedule.planned": "Planeado",
  "schedule.search": "Buscar",
  "company.employee.party": "EMPLEADO",
  "company.employee.code": "COD.",
  "modal.search": " BUSCAR",
  "surveillance.schedule.period": "Period",
  "surveillance.schedule.state": "Estado",
  "surveillance.schedule.location": "Ubicacion",
  "button.print": "IMPRIMIR",
  "menu.crm_sales": "CRM VENTAS",
  "crm.opportunity": "Oportunidad",
  "crm.opportunity.state": "Estado",
  "crm.opportunity.lead": "Iniciativa",
  "crm.opportunity.opportunity": "Oportunidad",
  "crm.opportunity.converted": "Convertida",
  "crm.opportunity.won": "Ganada",
  "crm.opportunity.lost": "Perdida",
  "crm.opportunity.cancelled": "Cancelada",
  "crm.opportunity.number": "Numero",
  "crm.opportunity.party": "Tercero",
  "crm.opportunity.start_date": "Fecha Inic.",
  "crm.opportunity.end_date": "Fecha Final.",
  "crm.opportunity.contact": "Contacto",
  "crm.opportunity.payment_term": "Plazo de Pago",
  "crm.opportunity.address": "Direccion",
  "crm.opportunity.description": "Description",
  "crm.opportunity.prospect": "Prospecto",
  "crm.opportunity.line": "Detalle",
  "crm.opportunity.line.product": "Producto",
  "crm.opportunity.line.quantity": "Cant.",
  "crm.opportunity.line.unit_price": "Precio Unit.",
  "crm.opportunity.line.payment_term": "Plazo",
  "crm.opportunity.line.description": "Descripción",
  "crm.opportunity.line.billing_frecuency": "Facturación",
  "crm.opportunity.line.unit": "Unidad",
  "crm.opportunity.line.availability": "Disponibilidad",
  "crm.opportunity.comment": "Comentario",
  "crm.opportunity.agent": "Agente",
  "crm.opportunity.party_contact": "Contacto Cliente",
  "crm.opportunity.party_category": "Categoria",
  "crm.opportunity.contact_phone": "Telefono",
  "crm.opportunity.city": "Ciudad",
  "crm.opportunity.conversion_probability": "Prob. de Exito",
  "crm.opportunity.lead_origin": "Origen",
  "crm.opportunity.amount": "Valor Estimado",
  "crm.opportunity.type": "Tipo",
  "crm.opportunity.brochure": "Brochure",
  "crm.opportunity.company": "Propiedad",
  "crm.opportunity.contact_email": "Email Contacto",
  "crm.opportunity.state.lead": "Iniciativa",
  "crm.opportunity.state.opportunity": "Oportunidad",
  "crm.opportunity.more_probabilty": "Más Probable",
  "crm.opportunity.last_90": "Ultimos 90 dias",
  "crm.opportunity.last_quotations": "Últ. Cotizaciones",
  "crm.opportunity.panel.win_rate": "Win Rate",
  "crm.opportunity.panel.win_rate_today": "Today",
  "crm.opportunity.panel.win_rate_yesterday": "Yesterday",
  "crm.opportunity.panel.activities_today": "Activ. Hoy",
  "crm.opportunity.panel.activities_tomorrow": "Activ. Mañana",
  "crm.opportunity.panel.activities_pending": "Activ. Pendient.",
  "crm.opportunity.panel.leads": "Leads",
  "crm.opportunity.panel.leads_draft": "Borrador",
  "crm.opportunity.panel.leads_convert": "Convert.",
  "crm.opportunity.sales.sale_date": "Fecha",
  "crm.prospect.name": "Nombre",
  "crm.prospect.phone": "Telefono",
  "crm.prospect.email": "Correo Electronico",
  "crm.prospect.agent": "Agente",
  "sale.sale.see_menu": "Ver Menu",
  "sale.sale.send_order": "Enviar Orden",
  "sale.line.status_order": "Status Order",
  "sale.line.description": "Descripcion",
  "modal_form.button_accept": "Aceptar",
  "modal_form.button_add": "Agregar",
  "modal_form.button_save": "Guardar",
  "hotel.booking.add_rooms.selector.product": "Categoria",
  "hotel.booking.add_rooms.selector.unit_price_w_tax": "Tarifa",
  "hotel.booking.add_rooms.selector.available": "Disponibles",
  "hotel.booking.add_rooms.selector.adults": "Adultos",
  "hotel.booking.add_rooms.selector.children": "Niños",
  "hotel.booking.add_rooms.selector.room": "Hab.",
  "hotel.booking.add_rooms.selector.add": "Agregar",
  "hotel.booking.add_rooms.selector.sale_price_taxed": "Precio",
  "hotel.booking.add_rooms.selector.discount": "Desc.",
  "hotel.booking.add_rooms.nights_quantity": "Noches",
  "hotel.booking.add_payment.amount": "Valor",
  "hotel.booking.add_payment.voucher": "Voucher",
  "hotel.booking.add_payment.statement": "Medio de Pago",
  "hotel.booking.add_payment.pay": "Pagar",
  "booking.widget.customers.title": "Información de reserva",
  "booking.widget.customers.name_contact": "Contacto: ",
  "booking.widget.customers.name_contact.placeholder": "Nombre de contacto ",
  "booking.widget.customers.number": "Numero",
  "booking.widget.customers.booking_date": "Fecha registro",
  "booking.widget.customers.total_amount": "Importe Total",
  "booking.widget.customers.state": "Estado",
  "booking.widget.customers.rooms": "Habitaciones",
  "booking.widget.customers.info_huesped": "Información titular",
  "booking.widget.customers.name": "Nombre completo",
  "booking.widget.customers.phone": "Telefono",
  "booking.widget.customers.booking_hour": "Hora",
  "booking.widget.customers.email": "Correo",
  "booking.widget.customers.call": "Llamar",
  "booking.widget.screen.huesped_house": "Huéspedes en casa",
  "booking.widget.screen.occupied_rooms": "Habitaciones ocupadas",
  "booking.widget.screen.last_bookings": "últimas reservas",
  "booking.panel.more_options": "Más Opciones",
  "booking.widget.screen.view_more": "Ver más",
  "booking.widget.screen.view_less": "Ver menos",
  "booking.widget_side.today": "Hoy",
  "booking.widget_side.tomorrow": "Mañana",
  "booking.widget_side_status.revenue_today": "Ingresos hoy",
  "booking.widget_side_status.revenue_tomorrow": "Ingresos mañana",
  "party.party.name": "Nombre",
  "party.party.id_number": "Numero ID",
  "party.party.type_document": "Tipo de Documento",
  "party.party.nationality": "Nacionalidad",
  "party.party.main_email": "Correo Electrónico",
  "party.party.main_mobile": "Movil",
  "party.party.phone": "Telefono",
  "party.party.sex": "Sexo",
  "party.party.birthday": "Fecha de Nacimiento",
  "party.party": "Cliente",
  "party.party.street": "Direccion",
  "party.party.account_receivable": "Cuenta",
  "party.party.country": "Pais",
  "party.party.subdivision": "Departmento",
  "party.party.city": "Ciudad",
  holder: "Titular",
  holder_guest: "Titular / Huesped",
  guest: "Huesped",
  no_breakfast: "No Breakfast",
  all_inclusive: "All Inclusive",
  bed_breakfast: "Bed & Breakfast",
  full_american: "Full American",
  half_american: "Half American",
  "menu.activity": "ACTIVIDADES",
  "sale_activity.activity.time_start": "Hora Inicio",
  "sale_activity.activity.time_end": "Hora Final",
  "sale_activity.activity.available": "Disponibles",
  "sale_activity.activity.quota": "Cupos",
  "sale_activity.activity.state": "Estado",
  "sale_activity.activity.add": "Agregar",
  "sale_activity.activity.employee": "Empleado",
  "sale.sale.add_payment": "Pago",
  "sale.sale.courtesy": "Cortesia",
  "activity.add_payment.statement": "Medio de Pago",
  "activity.add_payment.amount": "Valor",
  "activity.add_payment.voucher": "Voucher",
  "activity.add_payment.pay": "Pagar",
  "hotel.booking.bill.party": "Tercero",
  "hotel.booking.bill.kind": "Clase",
  "hotel.booking.bill.bill": "Facturar",
  all: "Todo",
  partial: "Parcial",
  personal_information: "Información Personal",
  "rental.booking.number": "Number",
  "rental.booking.equipment": "Equipment",
  "rental.booking.type_document": "Type Document",
  "rental.booking.doc_number": "Document Number",
  "rental.booking.booking_date": "Date",
  "rental.booking.first_name": "Name",
  "rental.booking.last_name": "Last Name",
  "rental.booking.mobile": "Mobile",
  "rental.booking.state": "State",
  "rental.booking.draft": "Draft",
  "rental.booking.booking": "Booking",
  "rental.booking.processed": "Processed",
  "rental.booking.cancelled": "Cancelled",
  "rental.booking": "Rental Booking",
  "rental.booking.company": "Compañia",
  "rental.service": "Sale Service",
  "rental.service.number": "Number",
  "rental.service.product": "Product",
  "rental.service.booking": "Booking",
  "rental.service.start_date": "Start Date",
  "rental.booking.party": "Customer",
  "rental.booking.product": "Product",
  "rental.booking.send_sms": "SEND SMS",
  "rental.booking.email": "Email",
  "rental.booking.address": "Address",
  "rental.booking.zip": "Zip",
  "rental.booking.subdivision": "State",
  "rental.booking.suburb": "Suburb",
  "rental.booking.city": "City",
  "rental.booking.post_code": "Post Code",
  "rental.booking.start_date": "Start Date",
  "rental.booking.end_date": "End Date",
  "rental.booking.lapse_time": "Semanas",
  "menu.rental": "RENTAL SERVICE",
  "menu.rentalb": "BOOKING",
  "rental.booking.process": "Process",
  "rental.booking.add_booking": "Booking",
  "rental.booking.notification_pickup": "Notification Pickup",
  "rental.booking.comment": "Comment",
  driver_license: "Driver License",
  passport: "Passport",
  medicare_card: "Medicare Card",
  phone: "Telefono",
  fax: "Fax",
  email: "Correo Electronico",
  chat: "Chat",
  walking: "Walking",
  web: "Web",
  channel_manager: "Gestor de Canales",
  ota: "OTA",
  information_card: "Informacion de Tarjetas",
  not_information_card: "No tienes tarjetas registradas",
  "web.user.card_number": "Número de Tarjeta",
  "web.user.holder_name": "Nombre",
  "web.user.name": "Nombre",
  "web.user.brand": "Tipo",
  "web.user.state": "Estado",
  "web.user.active": "Activar",
  "web.user.deactive": "Desactivar",
  "portal_customer.add_card": "Agregar Tarjeta",
  "portal_customer.holder_name": "Nombre del Titular",
  "portal_customer.card_number": "Número de Tarjeta",
  "portal_customer.expiration_date": "Fecha de Expiración",
  "portal_customer.secret_code": "Código de Seguridad",
  invalid_card_number: "Número de tarjeta invalido",
  invalid_cvc: "Código de seguridad invalido",
  invalid_expire_date: "Fecha de expiración invalida",
  invalid_cvc_card_number: "Número de tarjeta invalido o cvc invalido",
  card_validated: "Tarjeta valida, desea registrarla?",
  button_confirm: "Confirmar",
  button_accept: "Aceptar",
  validation_card_openpay: "Validación de tarjeta",
  confirm_card_openpay: "Confirmación registro de tarjeta",
  "activity.widget_side_status.revenue_today": "Porcentaje de ventas",
  "sale_activity.activity.quantity": "Cantidad",
  "sale.sale.pay_method": "FACTURAR",
  "sale.sale.payment_term": "Plazo de Pago",
  "rental.service.appbooking": "ENVIAR RESERVACION",
  "rental.booking.week": "Weeks",
  "sale.line.base_price": "Precio Base",
  "sale.sale.add_discount": "Agregar Descuento",
  "sale.sale.paid_amount": "Pagado",
  "sale.sale.residual_amount": "Pendiente",
  "rental.booking.add_signature": "Add Signature ",
  "rental.booking.send_confirmation_email": "Resend Confirmation",
  "rest_order.options": "Opciones",
  "surveillance.schedule_month.report.period": "Periodo",
  "surveillance.schedule_month.report.location": "Locacion",
  "surveillance.schedule_month.report": "Compliance Report",
  "schedule.compliance_report": "REPORTE DE CUMPLIMIENTO",
  "schedule.guards_report": "REPORTE DE GUARDAS",
  "rental.service.add_signature": "Create Service",
  "maintenance.equipment.state": "State",
  "maintenance.equipment.excellent": "Excellent",
  "maintenance.equipment.good": "Good",
  "maintenance.equipment.acceptable": "Acceptable",
  "maintenance.equipment.bad": "Bad",
  "maintenance.equipment.product": "Product",
  "maintenance.equipment.active": "Active",
  "maintenance.equipment.gps_serial": "Gps serial",
  "maintenance.equipment.serial": "Serial",
  "maintenance.equipment.vin": "Vin",
  "maintenance.equipment.model_num": "Model num",
  "maintenance.equipment.available": "Habilitado",
  "maintenance.equipment.maintenance": "Mantenimiento",
  "maintenance.equipment.not_available": "No habilitado",
  "maintenance.equipment.stolen": "Robado",
  "maintenance.equipment.status": "Estado",
  "maintenance.equipment": "EQUIPMENT AND INSTRUMENT",
  "menu.maintenance": "EQUIPMENT AND INSTRUMENT",
  "rental.service.party": "Customer",
  "rental.service.id_number": "Number",
  "rental.service.email": "Email",
  "rental.service.mobile": "Mobile",
  "rental.service.end_date": "End Date",
  "rental.service.equipment": "Equipment",
  "rental.service.state": "State",
  "rental.service.draft": "Draft",
  "rental.service.processed": "Processed",
  "rental.service.confirmed": "Confirmed",
  "rental.service.product_check_list": "PRODUCT CHECK LIST ",
  "rental.service.product_check_list.item": "Item ",
  "rental.service.product_check_list.state_delivery": "Start State",
  "rental.service.product_check_list.state_return": "Return Check",
  "rental.service.service_date": "Service Date",
  "rental.service.bank_account_number": "Bank Account Number",
  "rental.service.bank_bsb": "Bank BSB",
  "rental.service.hiring_time": "Semanas",
  "rental.service.issues_presented": "Issues Description",
  "rental.service.odometer_start": "Start Odometer",
  "rental.service.photo_link_party_id": "PHOTO PARTY ID",
  "rental.service.photo_link_agree": "PHOTO AGREE",
  "rental.service.cover_prices": "Cover Prices",
  "rental.service.odometer_end": "Odometer Return",
  "rental.service.part_missing": "Issues Known - Parts Missing / Broken",
  "rental.service.confirm": "Confirm",
  "rental.service.renewed": "Renewed",
  "rental.service.cancelled": "Cancelled",
  "rental.service.done": "Done",
  monthly: "Monthly",
  one_payment: "One Payment",
  bimonthly: "One Payment",
  biannual: "Biannual",
  annual: "Annual",
  "crm.customer_service": "Formulario de radicado",
  "activity.folio.charges.title":
    "Selecciona la habitación donde deseas agregar los cargos de la actividad",
  "sale.sale.confirmed": "Confirmado",
  "sale.sale.party.mobile": "Movil",
  "sale.sale.party.name": "Cliente",
  "party.addresses": "Direcciones",
  "party.addresses.street": "Calle",
  "party.addresses.country_code": "Pais",
  "party.addresses.department_code": "Departamento",
  "party.addresses.city_code": "Ciudad",
  "party.contact_mechanisms": "Metodos de contacto",
  "party.contact_mechanisms.type": "Tipo",
  "party.contact_mechanisms.name": "Nombre",
  "party.contact_mechanisms.value": "Dato de contacto",
  "sale.sale.party.id_number": "N° Documento",
  "sale.sale.reference": "Referencia",
  "menu.catalog": "CATALOGO",
  "date_range.select": "Select",
  "date_range.filter_date": "Fecha por filtrar",
  "sale.sale.month": "Mes",
  "sale.sale.month_3": "3 Meses",
  "sale.sale.month_6": "6 Meses",
  "app.booking.name": "Nombre",
  "app.booking.lastname": "Apellidos",
  "app.booking.add_pay": "Agregar pago",
  "app.booking.sex": "Genero",
  "app.booking.type_document": "Tipo de documento",
  "app.booking.id_number": "N° documento",
  "app.booking.mobile": "Teléfono",
  "app.booking.email": "Email",
  "app.booking.address": "Dirección",
  "app.booking.btn-form": "RESERVAR",
  "app.booking.country": "País",
  "app.booking.department": "Departamento",
  "app.booking.city": "Ciudad",
  "app.booking.btn_add_coupon": "Agregar cupon",
  "sale.line.origin.time_start": "Inicio",
  "sale.line.origin.time_end": "Fin",
  "hotel.folio.charge.origin.time_start": "Inicio",
  "hotel.folio.charge.origin.time_end": "Fin",
  "sale.sale.printBrowser": "Imprimir",
  "hotel.folio.main_guest.id_number": "Identificacion ",
  "sale.sale.folios": "Transferir a folio",
  "hotel.folio.room.code": "Habitacion",
  "hotel.folio.add": "Agregar",
  "sale.line.add_discount": "Descuento",
  "sale.sale.transferred": "Transferido",
  "sale.sale.add_new": "Nuevo",
  "sale.sale.add": "Transferir",
  "sale_activity.add_customer": "Agregar cliente",
  agent: "Agente",
  news: "Nuevos",
  leads: "Iniciativa",
  opportunity: "Oportunidad",
  won: "Ganadas",
  lost: "Perdidas",
  cancelled: "Cancelada",
  amount: "Valor Negociado",
  success_rate: "Tasa de Exito",
  "menu.activity_information": "INFORMACIÓN DE ACTIVIDADES",
  "sale.sale.sale_device": "Terminal de venta",
  "sale_activity.activity.sale": "Vendidos",
  "sale.sale.create_uid": "Vendido por",
  "sale.sale.transferred_events": "Transferir a evento",
  product: "Product",
  accomodation: "Accommodation",
  "hotel.booking.add_rooms.selector.rooms": "Hab.",
  "sale.line.invoice_lines.quantity": "Cant. Fact.",
  "account.invoice": "Factura",
  "account.invoice.line": "Productos",
  "account.invoice.invoice_date": "Fecha Factura",
  "account.invoice.state": "Estado",
  "account.invoice.number": "Numero",
  "account.invoice.party": "Tercero",
  "account.invoice.description": "Descripcion",
  "account.invoice.total_amount": "Total",
  "account.invoice.amount_to_pay": "Pendiente a Pagar",
  "account.invoice.posted": "Contabilizado",
  "account.invoice.paid": "Pagada",
  "account.invoice.validated": "Validado",
  "account.invoice.cancelled": "Cancelado",
  "account.invoice.draft": "Borrador",
  "account.invoice.untaxed_amount": "Base",
  "account.invoice.line.product": "Producto",
  "account.invoice.line.unit": "Unidad",
  "account.invoice.line.quantity": "Cantidad",
  "account.invoice.line.unit_price": "Precio Unitario",
  "account.invoice.line.amount": "Base",
  "sale.line.moves.quantity": "Cant. Env",
  "stock.shipment.out.number": "Numero",
  "stock.move.state": "Estado",
  "stock.move.done": "Finalizado",
  "stock.move.waiting": "En Espera",
  "stock.move.draft": "Borrador",
  "stock.shipment.out": "Envio de Cliente",
  "stock.shipment.out.reference": "Referencia",
  "stock.shipment.out.effective_date": "Fecha Efectiva",
  "stock.shipment.out.planned_date": "Fecha Programada",
  "stock.shipment.out.state": "Estado",
  "stock.shipment.out.done": "Finalizado",
  "stock.shipment.out.waiting": "En Espera",
  "stock.shipment.out.cancelled": "Cancelado",
  "stock.shipment.out.draft": "Borrador",
  "stock.shipment.out.warehouse_storage": "Bodega",
  "stock.shipment.out.return.number": "Numero",
  "stock.shipment.out.return.reference": "Referencia",
  "stock.shipment.out.return.effective_date": "Fecha Efectiva",
  "stock.shipment.out.return.planned_date": "Fecha Programada",
  "stock.shipment.out.return.state": "Estado",
  "stock.shipment.out.return.warehouse_storage": "Bodega",
  "stock.shipment.out.return.done": "Finalizado",
  "stock.shipment.out.return.waiting": "En Espera",
  "stock.shipment.out.return.cancelled": "Cancelado",
  "sale.sale.none": "Pendiente",
  "sale.sale.pending": "Pendiente",
  "sale.sale.exception": "Excepción",
  "sale.sale.canceled": "Cancelada",
  "sale.sale.shipments": "Envios",
  "sale.sale.shipment_returns": "Dev. Env.",
  "sale.sale.value_invoiced": "Valor Facturado",
  "sale.sale.value_to_invoiced": "Valor por Facturar",
  "sale.sale.invoices": "Facturas",
  "menu.sale_audit": "ADITORIA DE VENTAS",
  "crm.person": "Acompañantes",
  "crm.person.name": "Nombre",
  "crm.person.id_number": "Num. Id",
  "crm.person.phone": "Telefono",
  "crm.person.email": "Correo",
  "sale.line.person": "Acompañantes",
  "sale.line.person.name": "Nombre",
  "sale.line.person.id_number": "Num. Id",
  "sale.line.person.phone": "Telefono",
  "sale.line.person.email": "Correo",
  "sale.line.person.arrival_date": "Fecha de Llegada",
  "sale.line.person.today": "Hoy",
  "sale.line.person.month": "Mes",
  "sale.line.person.week": "Semana",
  "sale.line.person.line": "Servicio",
  "sale.line.person.sale": "Venta",
  "web.booking.avalaible": "Disponibles",
  "web.booking.occupation": "Max Ocup",
  "web.booking.minimum_stay": "Estadía Mínima",
  "app.booking.msg_nodata": "No hay habitaciones disponibles",
  "app.booking.msg_nodate": "Selecciona Check-In y Check-Out",
  "app.booking.breakfast": "Desayuno",
  "menu.account_statement": "ESTADO DE CUENTA",
  "account.statement": "Estados de Cuenta",
  "account.statement.name": "Nombre",
  "account.statement.journal": "Medio de Pago",
  "account.statement.sale_device": "Terminal",
  "account.statement.end_balance": "Saldo Final",
  "account.statement.start_balance": "Saldo Inicial",
  "account.statement.turn": "Turno",
  "account.statement.state": "Estado",
  "account.statement.draft": "Borrador",
  "account.statement.validated": "Validado",
  "account.statement.posted": "Contabilizado",
  "account.statement.open": "Abrir Estados de Cuenta",
  "account.statement.close": "Cerrar Estados de Cuenta",
  "account.statement.reports": "Reportes",
  "account.statement.select_device": "Selecciona terminal de venta",
  "account.statement.open.msg_start":
    "Desea abrir los estados de cuenta de su terminal?",
  "account.statement.open.msg_success":
    "Estados de cuenta abiertos exitosamente!",
  "account.statement.open.msg_error": "Falta la configuración del terminal!",
  "account.statement.close.msg_start":
    "Desea cerrar los estados de cuenta de su terminal?",
  "account.statement.close.msg_success":
    "Estados de cuenta cerrados exitosamente!",
  "account.statement.close.msg_error": "Falta la configuración del terminal!",
  "web.booking.txt_iva": "Cargos e impuestos no incluidos",
  "app.booking.term_cond": "Términos y Condiciones",
  "sale_pos.money_count.bill": "Moneda / Billete",
  "sale_pos.money_count.quantity": "Cantidad",
  "sale_pos.money_count.amount": "Importe",
  "sale_pos.money_count.total": "Total",
  "app.booking.privacy": "Política de Privacidad",
  "app.booking.redirect": "Estás siendo redireccionado al pago",
  "app.booking.label_form": "Agrega los datos del titular",
  "app.booking.label_aside": "Tu Reserva",
  "app.booking.btn_confirm": "CONFIRMAR",
  "app.booking.amount": "Total reserva a pagar",
  "app.booking.right_reserved": "Todos los derechos reservados",
  "app.booking.copyright": "Presik Copyright © 2024",
  "app.booking.validate_email": " Válida tu correo electrónico",
  "app.booking.create_reserve": "Reserva creada",
  "app.booking.successfully": "exitosamente",
  "app.booking.alert_filter_date":
    "Seleccione una fecha de Check-out menor a la fecha de Check-in. ",
  "app.booking.alert_select_room":
    "Selecciona un tipo de habitación para tu reservación.  ",
  "app.booking.date_payment": "Fecha de pago",
  "app.booking.night": "Noche",
  "app.booking.price": "Tarifa",
  "app.booking.includes": "Incluye",
  "app.booking.btn_reserve_item": "Agregar a reserva",
  "quickform.want_to_save_data": "Desea guardar los cambios?",
  "sale_pos.money_count": "Conteo de Dinero",
  "hotel.booking.undefined": "Indefinido",
  "booking.panel.check_in_today": "Check-In Today",
  "booking.panel.check_out_today": "Check-Out Today",
  "booking.panel.pending_today": "Pending",
  "booking.panel.guests": "Guests",
  "booking.panel.guests_today": "Today",
  "booking.panel.guests_tomorrow": "Tomorrow",
  "booking.panel.last_reservations": "Last Reservations",
  "booking.panel.occupation_rate": "Occupation Rate",
  "booking.panel.occupation_rate_today": "Today",
  "booking.panel.occupation_rate_tomorrow": "Tomorrow",
  "ticket_system.ticket.status": "Estado",
  "ticket_system.ticket.waiting": "Esperando",
  "ticket_system.ticket.done": "Terminado",
  "ticket_system.ticket.busy": "Ocupada",
  "ticket_system.ticket.cancelled": "Cancelado",
  "ticket_system.ticket.turn": "# Turno",
  "ticket_system.ticket.number_id": "# Documento",
  "ticket_system.ticket.position.number": "Posición",
  "ticket_system.ticket.state": "Estado",
  "ticket_system.ticke_not_found": "No hay turnos en el estado de espera.",
  "ticket_system.ticket.add_turn": "Debes asignarte un turno",
  "ticket_system.ticket.next_turn": "SIGUIENTE TURNO",
  "ticket_system.ticket.take_turn": "TOMAR TURNO",
  "ticket_system.ticket.finish_turn": "TERMINAR TURNO",
  "ticket_system.ticket.currents_turn": "Módulo",
  "ticket_system.ticket.attention_schedule": "Horario de atención",
  "ticket_system.ticket.weekday": "Lun - Vie",
  "ticket_system.ticket.wekend": "Sab - Dom",
  "sale.gift.first_name": "Nombre",
  "sale.gift.search_order": "Buscar numero de orden",
  "sale.gift.last_name": "Apellidos",
  "sale.gift.phone": "Telefono",
  "sale.gift.email": "Email",
  "sale.gift.from": "De",
  "sale.gift.to": "Para",
  "sale.gift.phone_gift": "Telefono",
  "sale.gift.message_gift": "Nota",
  "sale.gift.address_gift": "Dirección",
  "sale.gift.receive": "¿Quien recibe?",
  "hotel.calendar.add_booking": "Reserva",
  "hotel.calendar.add_maintenance": "Mantenimiento",
  "hotel.guest.you_can_not_clone_holder":
    "No puede crear automáticamente un huesped si antes no ha creado el titular",
  "account_statement_co.advanced_report": "Estado de Cuenta Avanzado",
  "account_statement_co.advanced": "Estado de Cuenta Avanzado",
  "account_statement_co.advanced.description":
    "Reporte de estados de cuenta global incluyendo todos los ambientes / tiendas",
  "account_statement_co.advanced.start.operation_center":
    "Centro de Operaciones",
  "account_statement_co.advanced.start.date": "Fecha",
  "account_statement_co.cash_up_report": "Cuadre de Caja",
  "account_statement_co.cash_up": "Cuadre de Caja",
  "account_statement_co.cash_up.description":
    "Reporte de cuadre de caja individual (por usuario)",
  "account_statement_co.cash_up.start.operation_center": "Centro de Operac.",
  "account_statement_co.cash_up.start.date": "Fecha",
  "account_statement_co.cash_up.start.shop": "Tienda / Ambiente",
  "account_statement_co.cash_up.start.user": "Usuario",
  "hotel.calendar.select_date": "Seleccionar Fecha",
  "hotel.calendar.rates": "Tarifas y Disponibilidad",
  "hotel.calendar.update_range": "Update Range",
  "hotel.calendar.update_start_date": "Desde",
  "hotel.calendar.update_end_date": "Hasta",
};

const _es = { ...es, ...views_es };

export default _es;
