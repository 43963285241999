import React, { Fragment, useState } from "react";

import Loading from "components/Tools/Loading";
import tools from "tools/functions";
import mainStore from "store/mainStore";

function RestCardProduct({ product, updateMsg, handleModal, optional }) {
  const { store, record, updateRecord, updateStore } = mainStore();
  const [loading, setLoading] = useState(false);

  function onSelect(e) {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    // increment();
    const timeStamp = new Date();

    const rec_id = -timeStamp.getTime();
    let line = {
      id: rec_id,
      product: { id: product.id, name: product.name },
      quantity: 1,
      status_order: "draft",
      unit: product.sale_uom,
      sale_price_taxed: product.sale_price_taxed.toString(),
    };
    if (product.default_uom !== product.sale_uom) {
      line.unit_price = product.sale_price.toString();
    } else {
      line.unit_price = product.list_price.toString();
    }
    if (optional) {
      line.sale_price_taxed = 0;
      line.unit_price = 0;
    }
    updateMsg(product.name);
    let linesRecord = record.lines;
    let linesStore = store.lines;

    linesRecord.set(rec_id, line);
    updateRecord(record);

    let _line = { ...line };
    _line.product = product.id;
    delete _line.total_amount;
    delete _line.sale_price_taxed;

    let to_create;
    if (!linesStore) {
      linesStore = new Map();
      linesStore.set("create", new Map());
    }
    to_create = linesStore.get("create");
    to_create.set(rec_id, _line);
    store.lines = linesStore;
    updateStore(store);

    const options = product["products_mix."];
    if (options && options.length > 0) {
      handleModal(options);
    }
    setLoading(false);
  }

  return (
    <div
      className={
        "overflow-hidden mx-2 my-2 cursor-pointer rounded-lg border-[1px] border-gray-200 active:scale-95 active:bg-lime-100"
      }
      onClick={onSelect}
    >
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className="py-1 px-3 h-12 bg-stone-700">
            <p className="text-xs md:text-sm text-left text-stone-200 mx-auto my-auto font-medium line-clamp-2">
              {product.name}
            </p>
          </div>
          <div className="flex bg-stone-600 h-18">
            <div className="bg-amber-300 w-3 h-3 my-auto ml-4 rounded-full" />
            <p className="text-xs md:text-base text-right ml-auto mr-3 py-[4px] text-stone-200 mx-auto my-auto">
              {tools.fmtMoney(product.sale_price_taxed)}
            </p>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default RestCardProduct;
