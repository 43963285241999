import React, { useState } from "react";
import store from "store";
import date from "date-and-time";

import ctxViewSale from "./ModelViewSale";
import QuickTable from "components/Meta/QuickTable";
import QuickForm from "components/Meta/QuickForm";
import TimeLine from "./TimeLine";
import mainStore from "store/mainStore";

function ListOrder() {
  const [viewType, setViewType] = useState("list");
  const { setRecord, reset } = mainStore();
  const today = date.format(
    date.addHours(new Date(), -12, true),
    "YYYY-MM-DD HH:mm:ss",
  );

  // const today_ = tools.fmtDate2Tryton(today);
  const domain = [
    ["create_date", ">=", today],
    [
      "order_status",
      "in",
      [
        "draft",
        "commanded",
        "in_preparation",
        "dispatched",
        "requested",
        "delivered",
      ],
    ],
    // ["state", "!=", "done"],
  ];

  async function onChangeView(event, action, rec) {
    console.log(rec, "validar rec");
    let viewType_ = viewType;
    if (["edit", "add"].includes(action)) {
      viewType_ = "form";
    } else if (["editModal", "addModal"].includes(action)) {
      viewType_ = "modalForm";
    } else {
      viewType_ = "list";
    }
    if (!rec) {
      reset(true);
    } else {
      setRecord(rec.id, ctxViewSale.model, ctxViewSale);
    }

    setViewType(viewType_);
  }

  return (
    <div className="mt-4">
      {viewType === "list" ? (
        <QuickTable
          onChangeView={onChangeView}
          ctxView={ctxViewSale}
          domain={domain}
        />
      ) : (
        <>
          <TimeLine />
          <QuickForm
            level={"main"}
            ctxView={ctxViewSale}
            onChangeView={onChangeView}
          />
        </>
      )}
    </div>
  );
}

export default ListOrder;
