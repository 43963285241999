import React, { Fragment } from "react";

import mainStore from "store/mainStore";
import ModelProduct from "./ModelProduct.js";
import QuickTable from "components/Meta/QuickTable";
import StdButton from "components/Buttons/StdButton.js";

function WizardAddProduct(props) {
  let selectedRows = new Map();
  const ctxView = ModelProduct.ctxView();
  const { store, record, updateRecord, updateStore } = mainStore();

  function onDoubleClickRow() {
    return;
  }

  function onClickRow(records) {
    if (records === null) {
      selectedRows = new Map();
    } else if (records instanceof Map) {
      selectedRows = new Map(records);
    } else {
      if (selectedRows.get(records.id)) {
        selectedRows.delete(records.id);
      } else {
        selectedRows.set(records.id, records);
      }
    }
  }

  function handleAccept() {
    let _storeRecord = { ...store };
    if (!_storeRecord.lines) {
      _storeRecord.lines = new Map();
      _storeRecord.lines.set("create", new Map());
    }
    let to_create = _storeRecord.lines.get("create");

    let activeRecord_ = { ...record };
    if (!activeRecord_.lines) {
      activeRecord_.lines = new Map();
    }

    const prods = Array.from(activeRecord_.lines.values()).map(
      (ItemRow) => ItemRow.product.id,
    );

    const time_ = new Date();
    let sq = -time_.getTime();
    for (let v of selectedRows.values()) {
      if (!prods.includes(v.id)) {
        const rec = {
          id: sq,
          product: {
            id: v.id,
            name: v.name,
            code: v.code,
            description: v.description,
          },
        };
        activeRecord_.lines.set(sq, rec);
        to_create.set(sq, { id: sq, product: v.id });
        sq += -1;
      }
    }
    updateRecord(activeRecord_);
    updateStore(_storeRecord);
    props.onClose();
  }

  return (
    <Fragment>
      <QuickTable
        model={ctxView.model}
        ctxView={ctxView}
        selectable={ctxView.selectable}
        onDoubleClickRow={onDoubleClickRow}
        onClickRow={onClickRow}
        activeSearch={true}
        level="wizard"
      />
    </Fragment>
  );
}

export default WizardAddProduct;
