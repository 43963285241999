import React, { Component } from "react";
// import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
// const MAP_STYLE_CONFIG = [
//   {
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#f5f5f5",
//       },
//     ],
//   },
//   {
//     elementType: "labels.icon",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#616161",
//       },
//     ],
//   },
//   {
//     elementType: "labels.text.stroke",
//     stylers: [
//       {
//         color: "#f5f5f5",
//       },
//     ],
//   },
//   {
//     featureType: "administrative.land_parcel",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#bdbdbd",
//       },
//     ],
//   },
//   {
//     featureType: "poi",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#eeeeee",
//       },
//     ],
//   },
//   {
//     featureType: "poi",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#757575",
//       },
//     ],
//   },
//   {
//     featureType: "poi.park",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#e5e5e5",
//       },
//     ],
//   },
//   {
//     featureType: "poi.park",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#9e9e9e",
//       },
//     ],
//   },
//   {
//     featureType: "road",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#ffffff",
//       },
//     ],
//   },
//   {
//     featureType: "road.arterial",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#757575",
//       },
//     ],
//   },
//   {
//     featureType: "road.highway",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#dadada",
//       },
//     ],
//   },
//   {
//     featureType: "road.highway",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#616161",
//       },
//     ],
//   },
//   {
//     featureType: "road.local",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#9e9e9e",
//       },
//     ],
//   },
//   {
//     featureType: "transit.line",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#e5e5e5",
//       },
//     ],
//   },
//   {
//     featureType: "transit.station",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#eeeeee",
//       },
//     ],
//   },
//   {
//     featureType: "water",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#c9c9c9",
//       },
//     ],
//   },
//   {
//     featureType: "water",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#9e9e9e",
//       },
//     ],
//   },
// ];

class CustomMapGoogle extends Component {
  render() {
    // const mapStyles = {
    //   width: "100vw",
    //   height: "50vh",
    //   position: "absolute",
    // };

    return (
      <p>aca iba un mapá</p>
      // <Map
      //   google={this.props.google}
      //   style={mapStyles}
      //   initialCenter={{ lat: 6.189805905938066, lng: -75.56111457671413 }} // Coordenadas iniciales
      //   zoom={12} // Nivel de zoom inicial
      //   styles={MAP_STYLE_CONFIG}
      //   disableDefaultUI={true}
      // >
      //   <Marker position={{ lat: 6.189805905938066, lng: -75.56111457671413 }}>
      //     <MapIcon
      //       width={40} // Ancho deseado del ícono
      //       height={40} // Alto deseado del ícono
      //       fill="#faf089" // Color del ícono
      //     />
      //   </Marker>
      // </Map>
    );
  }
}

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyDag8fpjfaFgfJ62bRlswPlG4enAXgaf_M", // Reemplaza 'TU_CLAVE_DE_API' con tu clave de API
// })(CustomMapGoogle);

export default CustomMapGoogle;
