import React from "react";

import RestCardCategory from "./RestCardCategory";

function RestCategories({ onChange, sessionUser, visibleCats }) {
  if (!visibleCats) return null;

  let cardCategories = [];
  for (const cat of visibleCats.values()) {
    cardCategories.push(
      <RestCardCategory key={cat.id} onChange={onChange} record={cat} />,
    );
  }

  return <div className="grid grid-cols-2 w-full">{cardCategories}</div>;
}

export default RestCategories;
