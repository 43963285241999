import React, { useState } from "react";
import { EyeIcon } from "@heroicons/react/20/solid";

import FormField from "./FormField";
import intl from "i18n/messages";
import upStore from "store/upStore";
import { classNames, colors } from "tools/ui";

const style =
  "h-10 rounded-md w-full py-2 px-2 text-gray-700 border border-slate-300 focus:outline-none focus:border focus:border-cyan-500";

/**
 *
 * @param {*} props -
    const _valueRef = `${mode
 * @param {Boolean} readOnly -
 * @param {String} type -
 * @param {String} label -
 * @param {String} name -
 * @param {String} placeholder -
 * @param {String} value -
 * @param {Function} onChange -
 * @param {Boolean} translate -
 * @returns Component TextField
 */

function PasswordField(props) {
  const { field, attrs, data } = props;
  const { name, readOnly, placeholder, translate } = field;
  const { level, ctxView } = attrs;
  const [showPassword, setShowPassword] = useState(false);

  let _value = data.value || "";
  if (_value && ctxView && translate) {
    const _valueRef = `${ctxView.model}.${_value}`;
    _value = intl.msgs[_valueRef] || _value;
  }

  let _color = props.color ?? "bg-white";

  if (field.readOnly) {
    _color = colors.readOnly;
  }
  let _style = style;
  if (field.className) {
    _style = field.className;
  }

  function setUpStore(_value) {
    upStore(level, {
      fieldName: name,
      value: _value,
      field: field,
    });
  }

  function onChange(event) {
    let _value = event.target.value;
    if (props?.uppercase) {
      _value = _value.toUpperCase();
    }
    if (field.specialCharacters && field.type === "char") {
      _value = _value.replace(/[^\w\s]|[\dñ]/gi, "");
      _value = _value.replace("  ", " ").toUpperCase();
    }
    data.value = _value;
    if (props.onChange) {
      setUpStore(_value);
      props.onChange(name, _value);
    }
  }

  function onBlur(event) {
    let _value = event.target.value;
    setUpStore(_value);
    if (props.onChange) {
      props.onChange(name, _value);
    }
  }

  return (
    <FormField {...field}>
      <div className="relative rounded-md">
        <input
          key={name}
          id={name}
          name={name}
          className={classNames(_color, _style)}
          type={showPassword ? "text" : "password"}
          value={_value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={readOnly ?? false}
          placeholder={placeholder ?? ""}
        />
        <div
          className="absolute hover:cursor-pointer inset-y-0 right-0 flex items-center pr-3"
          onClick={() => setShowPassword(!showPassword)}
        >
          <EyeIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
        </div>
      </div>
    </FormField>
  );
}

export default PasswordField;
