import React from "react";
import { FormattedMessage as FM } from "react-intl";

function HeaderPanel({ dataInfo }) {
  function getItem(data) {
    return (
      <div key={data.name} className="grid grid-cols-1 pb-2 px-3">
        <div className="flex mx-auto">
          <span className="text-2xl font-semibold text-zinc-500">
            {data.value}
          </span>
          <span className="text-2xl font-semibold text-zinc-400 pl-1">
            {data.icon}
          </span>
        </div>
        <span className="flex mx-auto text-1xl text-zinc-700 mb-2 text-center">
          <FM id={data.name} />
        </span>
      </div>
    );
  }

  if (!dataInfo.title) return;

  return (
    <div className="grid w-full rounded-xl border-2 border-white shadow-md">
      <div className="mx-auto bg-white py-2 px-4 w-full text-center text-zinc-700 w-full border-b-8 border-amber-300">
        <FM id={dataInfo.title} />
      </div>
      <div className="grid grid-cols-2 mt-2">
        {dataInfo.elements &&
          dataInfo.elements.map((element) => getItem(element))}
      </div>
    </div>
  );
}

export default HeaderPanel;
