import React from "react";

import FormField from "./FormField";
import upStore from "store/upStore";

function RadioGroupField(props) {
  const { field, data, attrs } = props;
  const { level } = attrs;
  const { options } = field;
  const option = data.value;

  function handleField(value) {
    data.value = value;
    upStore(level, {
      fieldName: field.name,
      value: value,
      field: field,
    });
  }

  return (
    <FormField {...field}>
      <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-slate-300 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white h-10 divide-x divide-gray-200">
        {options &&
          options.map((item) => {
            return (
              <li
                key={item.value}
                className="w-full h-full border-b border-gray-200 sm:border-b-0  dark:border-gray-600 "
              >
                <div className="flex items-center pl-3 h-full">
                  <input
                    key={`${item.value}-checkbox-list`}
                    id={`${item.value}-checkbox-list`}
                    type="radio"
                    value={item.value}
                    checked={item.value === option}
                    disabled={field.readOnly ?? false}
                    name={field.name}
                    onChange={(e) => {
                      handleField(item.value, e);
                    }}
                    className="w-4 h-4 border-gray-300 rounded"
                  />
                  <label
                    key={`${item.value}-checkbox-label`}
                    htmlFor={`${item.value}-checkbox-list`}
                    className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    onClick={() => {
                      handleField(item.value);
                    }}
                  >
                    {item.text}
                  </label>
                </div>
              </li>
            );
          })}
      </ul>
    </FormField>
  );
}

export default RadioGroupField;
