import React from "react";

import { classNames } from "tools/ui";
// import { ICON_COLORS } from "./Constants/constants";

const COLORS = {
  slate: ["bg-slate-300", "border-slate-500"],
  blue: ["bg-blue-300", "border-cyan-500"],
  sky: ["bg-sky-300", "border-sky-500"],
  yellow: ["bg-orange-300", "border-orange-500"],
  amber: ["bg-amber-300", "border-amber-500"],
  lime: ["bg-lime-300", "border-lime-500"],
  indigo: ["bg-violet-300", "border-violet-500"],
  pink: ["bg-pink-300", "border-pink-500"],
  rose: ["bg-rose-300", "border-rose-500"],
  gray: ["bg-gray-300", "border-gray-500"],
  stone: ["bg-stone-300", "border-stone-500"],
};

const base = "w-5 h-5 mx-auto my-auto rounded-full border-[1px]";

function Circle({ color }) {
  let bgColor = "bg-slate-300";
  let borderColor = "border-slate-500";
  if (COLORS[color]) {
    [bgColor, borderColor] = COLORS[color];
  }
  return <div id="circle" className={classNames(base, bgColor, borderColor)} />;
}

export default Circle;
