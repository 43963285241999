import React from "react";
import { FormattedMessage } from "react-intl"; // Importar el componente correcto
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { useWizardStore } from "store/wizardStore";

const ButtomBooking = ({
  category,
  roomValues,
  handleSelectClassification,
  textColorPrimary,
  bgColorPrimary,
  bgColorSecundary,
}) => {
  const { store, updateWizard } = useWizardStore.getState();
  const adultsValue = roomValues[category.id]?.adults || 1;
  const childrenValue = roomValues[category.id]?.children || 0;
  let availableRooms = category.rooms > 0 ? false : true;

  const handleBooking = () => {
    updateWizard({ ...store, ratePlan: category.plan });

    handleSelectClassification({
      ...category,
      adults: adultsValue,
      children: childrenValue,
    });
  };

  return (
    <div className="md:mb-0 flex justify-center md:justify-end md:bottom-0 right-0">
      <button
        className={`group px-5 pt-2 uppercase ${
          textColorPrimary || "text-white"
        } flex space-x-2 ${
          "hover:" + bgColorSecundary
        } hover:text-yellow-500 transition-all duration-300 ${
          bgColorPrimary || "bg-blue-presik"
        }`}
        onClick={handleBooking}
        // disabled={availableRooms}
      >
        <span className="text-sm">
          <FormattedMessage id="app.booking.btn_reserve_item" />{" "}
        </span>
        <PlusCircleIcon
          className={`w-7 group-hover:text-white transition-all duration-300 relative -top-1 ${textColorPrimary}`}
        />
      </button>
    </div>
  );
};

export default ButtomBooking;
