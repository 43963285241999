import React from "react";

const InputSearchTable = (props) => {
  const { handleValue, searchClicked } = props;

  return (
    <input
      value={props.value}
      className="rounded-md shadow-sm w-full h-10 my-1 py-3 pb-2 px-3 text-gray-700 border border-slate-400 focus:outline-none focus:border focus:border-cyan-500"
      placeholder="Search..."
      onChange={(e) => handleValue(e.target.value)}
      onKeyDown={searchClicked}
    />
  );
};

export default InputSearchTable;
