import React from "react";

import Tooltip from "components/Tools/Tooltip";
import { ICON_COLORS } from "components/Constants/constants";
import { classNames } from "tools/ui";

const classes =
  "group relative flex my-auto h-10 w-10 cursor-pointer rounded-xl py-1 px-2 text-xl items-center text-center hover:opacity-90 disabled:opacity-50 justify-center gap-2 active:scale-95";

function IconButton({ name, size, style, color, disabled, onClick, tooltip }) {
  function handleClick(e) {
    e.preventDefault();
    onClick(e, name);
  }
  let _size = "w-12";
  if (size) {
    _size = size;
  }

  const [bgColor, iconColor] = ICON_COLORS[color];
  const _disabled = disabled ? disabled : false;

  return (
    <div
      onClick={(e) => handleClick(e)}
      className={classNames(style, _size, bgColor, classes)}
      disabled={_disabled}
    >
      <i
        key={name}
        className={classNames("flex text-2xl my-auto fi", name, iconColor)}
      />
      {tooltip && <Tooltip tooltip={tooltip} />}
    </div>
  );
}

export default IconButton;
