import modelCharge from "../Charge";

const getView = () => {
  let DictCtxView = {
    model: "hotel.folio",
    row_selectable: false,
    form_action: [], // options: ['save', 'delete']
    table_action: [], // options: ['open', 'delete', 'edit', 'add']
    card_action: ["delete"], // open (open modal form)
    title: { field: "booking", component: "title" },
    webfields: {
      "main_guest.phone": {
        type: "char",
      },
      "main_guest.id_number": {
        type: "char",
      },
      "main_guest.name": {
        type: "char",
      },
      // "main_guest.address": {
      //   type: "char",
      // },
      // "main_guest.addresses": {
      //   type: "char",
      // },
      "main_guest.mobile": {
        type: "char",
        call: ["mobile", "whatsapp"],
      },
      "main_guest.account_payable": {
        type: "many2one",
      },
      "main_guest.account_receivable": {
        type: "many2one",
      },

      main_guest: {
        type: "many2one",
        model: "party.party",
        readOnly: true,
        attrs: [
          "account_receivable",
          "mobile",
          "account_payable",
          // // "address",
          // "addresses",
          "phone",
        ],
      },

      // party: {
      //   type: "many2one",
      //   model: "party.party",
      //   // ctxView: ModelParty.ctxView(),
      // },
      booking: {
        type: "many2one",
        model: "hotel.booking",
        readOnly: true,
      },
      arrival_date: {
        type: "date",
        // required: true,
        readOnly: true,
      },
      departure_date: {
        type: "date",
        readOnly: true,
      },
      unit_price: {
        type: "numeric",
        // required: true,
        readOnly: true,
        // editable: true,
      },
      nights_quantity: {
        type: "numeric",
        readOnly: true,
      },
      "room.code": {
        type: "char",
      },
      room: {
        type: "many2one",
        model: "hotel.room",
        required: true,
        readOnly: true,
      },
      product: {
        type: "many2one",
        model: "product.product",
        recSearch: () => [],
        // recSearch: searchProduct,
        readOnly: true,
      },
      channel: {
        type: "many2one",
        model: "hotel.channel",
        readOnly: true,
        images: { targetField: "code", source: {} },
      },
      registration_state: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "pending",
      },
      unit_price_w_tax: {
        type: "numeric",
        readOnly: true,
      },
      charges: {
        type: "one2many",
        model: "hotel.folio.charge",
        ctxView: modelCharge.ctxView(),
        readOnly: {
          registration_state: ["check_in", "check_out"],
        },
      },
      total_amount: { type: "numeric", readOnly: true },
      notes: {
        type: "text",
        readOnly: true,
      },
      vehicle_plate: {
        type: "char",
        readOnly: ["check_out"],
      },
      group: {
        type: "boolean",
        readOnly: {
          registration_state: ["check_out", "check_in"],
        },
        translate: true,
      },
      payment_status: {
        type: "char",
        readOnly: true,
        translate: true,
      },
      add: {
        type: "button",
        color: "blue",
        icon: "fi fi-rr-add",
      },
    },

    webtree: [
      { name: "room.code", width: "15%" },
      { name: "main_guest", width: "15%" },
      { name: "main_guest.id_number", width: "15%" },
      { name: "arrival_date", width: "15%" },
      { name: "departure_date", width: "15%" },
      { name: "add", width: "15%" },
    ],
    webform: [
      { name: "product" },
      {
        id: "booking",
        grid: [
          { name: "booking" },
          { name: "registration_card" },
          { name: "room" },
        ],
        size: [1, 2],
      },

      {
        id: "info",
        grid: [
          { name: "main_guest" },
          { name: "arrival_date" },
          { name: "departure_date" },

          // { name: "nights_quantity" },
        ],

        size: [1, 3],
        // border: "visible",
        span: "md:col-span-2",
      },
      { name: "charges" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
