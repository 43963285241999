import React from "react";
import { FormattedMessage as FM } from "react-intl";

import Divider from "components/MeForm/Divider";

const Kinds = ({ section, onClick, record }) => {
  let selected = record?.id ?? "";

  function handleClick(value) {
    selected = value;
    onClick(section, { id: value, name: value });
  }

  const renderOption = (value) => (
    <div
      className={`text-center cursor-pointer shadow-sm py-8 rounded-md hover:opacity-50 ${
        selected === value ? "bg-blue-300" : "bg-blue-100"
      }`}
      key={value}
      onClick={() => handleClick(value)}
    >
      <p className="pointer-events-none">{<FM id={value} />}</p>
    </div>
  );

  return (
    <div className="mt-3" id={`${section}-section`}>
      <Divider text={`sale_call_center.${section}`} key={section} />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-4">
        {renderOption("to_table")}
        {renderOption("take_away")}
        {renderOption("delivery")}
      </div>
    </div>
  );
};

export default Kinds;
