import React from "react";
import store from "store";

import DropUser from "./DropUser";
import funcs from "tools/functions";

function NavBar() {
  const session = store.get("ctxSession");
  const terminal = funcs.getCookie();

  return (
    <div className="container w-full h-full ">
      <div className="flex columns-2 h-full justify-end">
        <div className="hidden h-full items-center md:flex lg:flex xl:flex 2xl:flex border-r-[0.4px] border-gray-700">
          <p className="truncate text-white text-base text-end pr-4 my-auto">
            {session && session.company_name}
          </p>
        </div>
        {terminal && terminal.device_name && (
          <div className="hidden h-full space-x-2 items-center md:flex lg:flex xl:flex 2xl:flex border-r-[0.4px] border-gray-700 px-3">
            <p className="truncate text-white text-base text-center mx-auto my-auto">
              TERMINAL:{" "}
            </p>
            <span className="text-white font-bold">{terminal.device_name}</span>
          </div>
        )}

        <div className="container text-end pr-5 my-auto w-auto">
          <DropUser session={session} />
          <img
            className="inline-block h-9 w-9 rounded-full"
            src={`https://ui-avatars.com/api/?name=${session.login}&background=0D8ABC&color=fff `}
            alt="user"
          />
        </div>
      </div>
    </div>
  );
}

export default NavBar;
