import React, { useState } from "react";
import TextField from "components/MeForm/TextField";
import StdButton from "components/Buttons/StdButton";

export default function SaleTurn() {
  const [sale, setSale] = useState(null);
  const [lastSale, setLastSale] = useState(null);
  const [value, setValue] = useState(null);

  function handleChange(name, value) {
    setValue(value);
  }

  function handleNew() {
    setLastSale(sale);
    setSale(value);
  }

  return (
    <div className="mx-10 min-h-screen">
      <div className="flex flex-row gap-4">
        <TextField name={"sale"} onChange={handleChange}></TextField>
        <StdButton
          noFill
          content={"sale_turn.new"}
          color={"blue"}
          onClick={handleNew}
        ></StdButton>
      </div>
      {sale && (
        <div className="grid w-2/4 mx-auto my-10 h-60 md:h-80 rounded-md shadow-sm border-x-2 border-b-4 place-items-center justify-center p-8 bg-teal-100">
          <p className="font-bold text-3xl md:text-5xl text-center">
            TU PEDIDO ESTA LISTO...!
          </p>
          <p className="font-bold text-6xl md:text-9xl">{sale}</p>
        </div>
      )}
      {lastSale && (
        <div className="grid w-2/4 mx-auto my-10 h-60 md:h-80 rounded-md shadow-sm border-x-2 border-b-4 place-items-center justify-center p-8 bg-rose-100">
          <p className="font-bold text-3xl md:text-5xl text-center">
            PEDIDO ANTERIOR
          </p>
          <p className="font-bold text-6xl md:text-9xl">{lastSale}</p>
        </div>
      )}
      <div className="grid w-full mx-auto mt-1 h-40 place-items-center justify-center">
        <p className="font-bold text-xl md:text-3xl text-slate-700 text-center">
          POR FAVOR ACERQUE EL CODIGO DE BARRAS AL LECTOR PARA ENTREGARLE SU
          PEDIDO
        </p>
      </div>
      <div className="flex flex-row h-10">
        <p className="text-md text-slate-700 text-center pl-12">
          Powered by &nbsp;
        </p>
        <p className="text-md font-bold text-slate-700 text-center">
          www.presik.com
        </p>
      </div>
    </div>
  );
}
