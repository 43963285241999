import React from "react";
import date from "date-and-time";
import { FormattedMessage as FM } from "react-intl";

import Circle from "components/Tools/Circle";
import common from "tools/common";
import { classNames } from "tools/ui";

const base =
  "inline-block mx-2 w-72 sm:w-64 md:w-60 lg:w-60 xl:w-72 my-4 rounded-lg pb-3 border border-stone-100 shadow-lg shadow-gray-200 overflow-hidden h-40";
const styleContent = "flex px-4 my-1";
const btnDeleteStyle =
  "fi fi-sr-trash cursor-pointer text-2xl text-rose-400 text-right mr-auto";

const formatter = Intl.NumberFormat("en-US");

function getCardRow(row, record, webfields, model, handleDelete, onClick) {
  const _fields = [];
  for (const rowField of row) {
    _fields.push(
      <CardField
        key={rowField.name}
        schema={rowField}
        record={record}
        model={model}
        styleContent
        webfields={webfields}
      />,
    );
  }
  return _fields;
}

function getContent(contentSchema, record, webfields, model) {
  const elements = contentSchema.map((schema, idx) => {
    return (
      <div key={idx} id="card-row" className={classNames(styleContent)}>
        {getCardRow(schema.row, record, webfields, model)}
      </div>
    );
  });
  return elements;
}

function getHead(headSchema, rec, webfields, actionDelete, handleDelete) {
  const nCols = headSchema.length;
  const cols = `grid-cols-${nCols}`;
  let mainElelement = (
    <div
      id="card-head"
      key="card-head"
      className={classNames(
        cols,
        "flex w-full bg-zinc-200 pt-3 pb-3 px-4 mb-3",
      )}
    >
      {headSchema.map((schema, idx) => {
        if (schema.col) {
          return (
            <div key={idx} className={classNames("grid", schema.width)}>
              {schema.col.map((schema, idx) => {
                return (
                  <CardField
                    key={idx}
                    record={rec}
                    schema={schema}
                    webfields={webfields}
                    type="head"
                  />
                );
              })}
            </div>
          );
        } else {
          console.log("Warning: a row bug.....fix schema rows please....!");
          return null;
        }
      })}
      {actionDelete && (
        <i onClick={() => handleDelete(rec)} className={btnDeleteStyle} />
      )}
    </div>
  );
  return mainElelement;
}

function CardField({ record, schema, webfields, type, model }) {
  let _record = { ...record };
  let field = webfields[schema.name];
  const _type = field.type;
  const cardStyle = type === "head" ? "text-zinc-700" : "text-stone-700";
  let width = schema.width ?? "w-full";
  let schName = schema.name;
  let customStyle = schema.style || "text-sm";
  if (_type === "many2one") {
    const _schName = `${schName}.`;
    if (_record[_schName]) {
      schName = _schName;
    }
  }
  let value = _record[schName] ?? null;
  if (value && typeof value === "object") {
    value = value.name || value.rec_name;
  } else if (value && _type === "numeric") {
    value = formatter.format(value);
  } else if (value && _type === "time") {
    const valueDate = date.parse(value, "HH:mm:ss", true);
    value = date.format(valueDate, "hh:mm A");
    customStyle = "text-sm text-right";
  }
  let component;
  let label = null;
  if (schema.label) {
    const _name_label = `${model}.${schema.name}`;
    label = (
      <p className="font-bold pr-2">
        <FM id={_name_label} />
      </p>
    );
  }
  if (schema.widget === "circle") {
    const colorCircle = field.tags[value];
    component = <Circle className={width} color={colorCircle} />;
  } else {
    component = (
      <div className={classNames(width, cardStyle, customStyle)}>
        {label}
        {value}
      </div>
    );
  }
  return component;
}

function CardRecord({ onClick, record, ctxView, handleDelete }) {
  // color ??
  const { webfields, model, card_action } = ctxView;
  const { head, content } = ctxView.webcards;
  const key = record.id;
  let clickable = "";
  let selectable = card_action.includes("open");
  if (selectable) {
    clickable = "cursor-pointer active:scale-95 active:shadow-xl";
  }

  let actionDelete = false;
  for (const action of card_action) {
    if (action === "delete") {
      actionDelete = true;
    }
  }

  function handleClick(record) {
    if (selectable) {
      onClick(record);
    }
  }

  return (
    <div
      key={key}
      className={classNames(base, clickable)}
      onClick={() => handleClick(record)}
    >
      {getHead(head, record, webfields, actionDelete, handleDelete)}
      {getContent(content, record, webfields, model)}
    </div>
  );
}

export default CardRecord;
