import ModelParty from "./ModelParty";
import ModelCheckList from "./ModelCheckList";
import tools from "tools/dates";

const stateColors = {
  draft: "gray",
  booking: "rose",
  processed: "sky",
  confirmed: "lime",
  done: "amber",
};
const visibleConfirmed = (name, record) => {
  console.log(name, "records");
  let res = false;
  if (record.state === "draft" && record.equipment) {
    res = true;
  }
  return res;
};

const visibleDone = (name, record) => {
  let res = false;
  if (record.state === "confirmed") {
    res = true;
  }
  return res;
};

const visibleCancelled = (name, record) => {
  let res = false;
  if (record.state !== "draft") {
    res = true;
  }
  return res;
};

const visibleRenewed = (name, record) => {
  let res = false;
  if (record.state === "done" || record.state === "processed") {
    res = true;
  }
  return res;
};

const onChangeTimeEnd = (activeRecord, fieldValue) => {
  const lapse = activeRecord.hiring_time;
  if (fieldValue == "lapse_time") {
    lapse = fieldValue["hiring_time"];
  }
  let date = activeRecord["start_date"];
  let value = lapse || 1;
  let _end_date = new Date(date);
  _end_date.setDate(_end_date.getDate() + value * 7);
  activeRecord["end_date"] = tools.fmtDate2Tryton(_end_date);
  return activeRecord;
};
// const changeProduct = (activeRecord, fieldValue) => {
//   console.log("-----------", activeRecord);
//   console.log("-----------", fieldValue);
// };
const getView = (config) => {
  let DictCtxView = {
    model: "rental.service",
    form_action: ["edit"],
    table_action: ["add", "edit"],
    activeSearch: true,
    // title: { field: "schedule", model: true },
    domain: [],
    tags: {
      state: stateColors,
    },
    webfields: {
      number: { type: "char", readOnly: true, activeSearch: true },
      state: { type: "char", tags: stateColors, readOnly: true },
      start_date: { type: "date", withChange: onChangeTimeEnd },
      service_date: { type: "date", readOnly: true },
      odometer_start: { type: "numeric" },
      odometer_end: { type: "numeric" },
      hiring_time: { type: "integer", withChange: onChangeTimeEnd },

      booking: {
        type: "many2one",
        model: "product.product",
        searchable: true,
        readOnly: true,

        // ctxView: ModelParty.ctxView(),
        // attrs: ["account_receivable"],
      },

      photo_link_agree: {
        type: "take_capture",
        help: "asdsad",
      },
      photo_link_party_id: {
        type: "take_capture",
        help: "asdsad",
      },
      check_list: {
        type: "one2many",
        model: "rental.service.product_check_list",
        ctxView: ModelCheckList.ctxView(config),
      },
      party: {
        type: "many2one",
        model: "party.party",
        ctxView: ModelParty.ctxView(),
        searchable: true,
        required: true,
        readOnly: true,
        // attrs:
      },
      "party.id_number": {
        type: "char",
        readOnly: true,
        activeSearch: true,
      },

      equipment: {
        type: "many2one",
        model: "maintenance.equipment",
        required: true,
      },
      // product: {
      //   type: "many2one",
      //   recSearch: () => [],
      //   model: "maintenance.equipment",
      //   searchable: true,
      //   // readOnly: true,
      // },
      bank_account_number: { type: "char", required: true },
      bank_bsb: { type: "char", required: true },
      mobile: { type: "char", activeSearch: true },
      email: { type: "char", activeSearch: true },
      end_date: { type: "date", readOnly: true },
      comment: { type: "char" },
      issues_presented: { type: "char" },
      part_missing: { type: "char" },
      cover_prices: { type: "boolean" },
      confirm: {
        type: "button",
        button_method: "confirm",
        color: "green",
        icon: "fi fi-rr-add",
        visible: visibleConfirmed,
        depends: ["equipment"],
      },
      cancelled: {
        type: "button",
        button_method: "cancel",
        color: "rose",
        icon: "fi fi-rr-add",
        visible: visibleCancelled,
      },
      renewed: {
        type: "button",
        button_method: "renew",
        color: "blue",
        icon: "fi fi-rr-add",
        visible: visibleRenewed,
      },
      done: {
        type: "button",
        button_method: "done",
        color: "green",
        icon: "fi fi-rr-add",
        visible: visibleDone,
      },
    },
    webtree: [
      { name: "number", width: "30%" },
      { name: "booking", width: "30%" },
      { name: "party", width: "25%" },
      { name: "email", width: "30%" },
      { name: "mobile", width: "30%" },
      { name: "start_date", formatHour: false },
      { name: "end_date", width: "30%", formatHour: false },
      { name: "equipment" },
      { name: "state", widget: "badge" },
    ],
    webform: [
      {
        id: "meta",
        grid: [
          { name: "party" },
          { name: "number" },
          { name: "booking" },
          { name: "service_date" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      {
        id: "meta-equipment",
        grid: [
          { name: "state" },
          { name: "equipment" },
          { name: "bank_account_number" },
          { name: "bank_bsb" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      {
        id: "meta-date",
        grid: [
          { name: "start_date" },
          { name: "hiring_time" },
          { name: "end_date" },
        ],
        size: [1, 3],
        span: "col-span-2",
      },

      // { name: "product" },
      { name: "check_list" },

      {
        id: "meta-images",
        grid: [
          { name: "cover_prices" },
          { name: "issues_presented" },
          { name: "photo_link_party_id" },
          { name: "photo_link_agree" },
        ],
        size: [1, 2],
        span: "col-span-2",
      },

      {
        id: "meta-odom",
        grid: [
          { name: "odometer_start" },
          { name: "odometer_end" },
          { name: "part_missing" },
        ],
        size: [1, 3],
        span: "col-span-2",
      },

      {
        id: "buttoms",
        grid: [
          { name: "confirm" },
          { name: "done" },
          { name: "renewed" },
          { name: "cancelled" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
