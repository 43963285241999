import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import store from "store";
import Loading from "components/Tools/Loading.js";
import StepOrder from "./StepOrder";
import StepPayment from "./StepPayment";
import StepTicket from "./StepTicket";
import Cart from "./Cart";
import Button from "./ButtonAction";
import logoCompany from "./assets/logo.png";
import proxy from "api/proxy";
import { useWizardStore } from "store/wizardStore";
const session = store.get("ctxVirtualSession");

const stepsCtx = {
  order: StepOrder,
  payment: StepPayment,
  ticket: StepTicket,
};

// route access http://my.domain/app/DB/self_service_sale/id_shop

function SelfServiceSale(props) {
  const { shop } = useParams();
  const { record, updateWizard } = useWizardStore();
  let [step, setStep] = useState("order");
  const btnDisabled = record?.lines?.size > 0 ? false : true;
  let [btnCancelDisabled, setBtnDisabled] = useState(true);
  // const [storeCart, setStoreCart] = useState(null);
  let [saleNumber, setSaleNumber] = useState(null);
  const cartReadOnly = step === "order" ? false : true;

  async function getShopCtx() {
    if (!shop) {
      return;
    }
    const dom = [["id", "=", shop]];
    const fields = [
      "name",
      "party",
      "company",
      "product_categories",
      "warehouse.storage_location",
    ];
    let { data: res } = await proxy.search("sale.shop", dom, fields);
    if (res) {
      const _shop = res[0];
      let ctx = store.get("ctxVirtualSession");
      ctx.locations = [_shop["warehouse."].storage_location];
      store.set("ctxVirtualSession", ctx);
      const timeStamp = new Date().getTime();
      const hashRec = -(timeStamp + ctx.user * 10 ** 13);
      const _data = {
        id: hashRec,
        kind: "take_away",
        party: _shop.party,
        shop: _shop.id,
        company: _shop.company,
        currency: _shop.currency,
        invoice_type: "1",
        lines: new Map(),
        total_amount: 0,
        payment_amount: 0,
        state: "draft",
        qr_code: "www.presik.com",
      };
      console.log(_data);
      updateWizard(_data);
      // setStoreCart(_data);
    }
  }
  console.log(record, "njnj");
  useEffect(() => {
    // validateApp();
    getShopCtx();
  }, [props]);

  async function onChangeView(e, nextStep, resetStore, data) {
    if (e) {
      e.preventDefault();
    }
    let nextStep_ = nextStep;
    if (nextStep === "ticket") {
      setSaleNumber(data.number);
    } else if (nextStep === "cancel") {
      nextStep_ = "order";
      await cancelSale(data);
    } else if (nextStep === "payment") {
      setTimeout(() => {
        setBtnDisabled(false);
      }, 30000);
    }
    setStep(nextStep_);
    if (resetStore) {
      await getShopCtx();
      // setBtnDisabled(true);
    }
  }

  async function cancelSale(data) {
    const sale_id = data?.id > 0 ? data.id : record.id;
    await proxy.buttonMethod({
      model: "sale.sale",
      method: "cancel",
      ids: [sale_id],
    });
    window.location.reload();
  }

  const StepComponent = stepsCtx[step];

  const BUTTON = {
    order: (
      <Button
        name="payment"
        content="IR A PAGAR"
        color="blue"
        disabled={btnDisabled}
        onClick={onChangeView}
      />
    ),
    payment: (
      <></>
      // <Button
      //   name="cancel"
      //   content="CANCELAR"
      //   color="blue"
      //   onClick={(e, name) => {
      //     onChangeView(e, name, true);
      //   }}
      //   disabled={btnCancelDisabled}
      // />
    ),
    ticket: (
      <>
        {/* <Button
          name="ticket"
          content="IMPRIMIR"
          color="blue"
          onClick={onChangeView}
        /> */}
      </>
    ),
  };

  if (!record) {
    return <Loading />;
  }

  const data = {
    saleNumber: saleNumber,
  };
  return (
    <div className="grid grid-cols-2 py-6">
      <div className="px-12 justify-center">
        <StepComponent data={data} onChangeView={onChangeView} />
      </div>
      <div className="justify-center">
        <img
          src={logoCompany}
          alt="logo-company"
          width={180}
          className="mx-auto"
        />
        <div className="mt-12">
          <Cart readOnly={cartReadOnly} />
        </div>
        <div className="py-12">{BUTTON[step]}</div>
      </div>
      <div className="pt-12 flex bottom-px">
        <p className="text-md text-slate-700 text-center pl-12">
          Powered by &nbsp;
        </p>
        <p className="text-md font-bold text-slate-700 text-center">
          www.presik.com
        </p>
      </div>
    </div>
  );
}

export default SelfServiceSale;
