import React, { useEffect, useState } from "react";
import ReactDataSheet from "react-datasheet";
import ScrollContainer from "react-indiana-drag-scroll";
import "react-datasheet/lib/react-datasheet.css";

import ModalMsg from "components/Modals/ModalMsg";
import Popup from "components/Tools/Popup";
import Point from "./Point";
import proxy from "api/proxy";
import { classNames } from "tools/ui";
import "./Sheet.css";

function Sheet(props) {
  let [data, setData] = useState([]);
  let [options, setOptions] = useState({});
  let [modalMsg, setModalMsg] = useState(null);
  let [openModalMsg, setOpenModalMsg] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  let toUpdate = new Map();
  let toCreate = {};
  let toRemove = [];

  useEffect(() => {
    setDataSheet({ filter: "" });
  }, [props.locations]);

  const setDataSheet = async () => {
    let searchText = null;
    if (props.filter) {
      searchText = props.filter;
    }

    let locations = null;
    if (props.locations) {
      locations = props.locations;
    }

    const ctxArgs = {
      model: "surveillance.schedule",
      method: "get_sheet",
      args: [
        {
          period: props.period,
          oc: props.oc,
          searchText: searchText,
          locations: locations,
        },
      ],
    };

    const { data } = await proxy.methodCall(ctxArgs);
    const [headers, _data, kinds] = data;
    setOptions({
      headers,
      kinds,
    });
    setData(_data);
  };

  const onDoubleClick = (e, rec) => {
    props.onDoubleClickCell(rec);
  };

  const validateData = async (data) => {
    let newData = { ...data };
    newData["new_kind"] = data["kind"];
    const ctxArgs = {
      model: "surveillance.schedule.shift",
      method: "validate_shift",
      args: [newData],
    };
    const { data: res } = await proxy.methodCall(ctxArgs);
    if (res === "ok") {
      return;
    }
    if (res) {
      setOpenModalMsg(true);
      setModalMsg(res);
    }
  };

  const onDataChange = async (changes) => {
    const grid = data.map((row) => [...row]);

    for (let ch of changes) {
      let val_id;
      if (ch.value.length === 0 || ch.value === "0") {
        val_id = 0;
      } else {
        val_id = options.kinds[ch.value];
      }
      let newData = { ...ch.cell };
      newData["kind"] = val_id;
      if (val_id || val_id === 0) {
        if (!ch.value || ch.value === "0") {
          toRemove.push(ch.cell.id);
          val_id = null;
          ch.value = null;
        } else if (ch.cell.id > 0) {
          let newDataCell = { id: ch.cell.id, kind: val_id };
          toUpdate.set(ch.cell.id, newDataCell);
          validateData(newData);
        } else {
          toCreate[ch.cell.id] = ch.cell;
          toCreate[ch.cell.id]["kind"] = val_id;
          toCreate[ch.cell.id]["type"] = "work";
          validateData(newData);
        }
      } else {
        return;
      }
    }
    changes.forEach(({ row, col, value }) => {
      data[row][col] = { ...grid[row][col], value };
    });

    props.updateCtx("toRemove", toRemove);
    props.updateCtx("toCreate", toCreate);
    props.updateCtx("toUpdate", toUpdate);
  };

  const sheetRenderer = (props) => {
    // <table className="mb-96 text-center m-w-14 data-grid">
    return (
      <table className="data-grid">
        <tbody>{props.children}</tbody>
      </table>
    );
  };

  const closeModalMsg = () => {
    setOpenModalMsg(false);
  };

  function handleMouse(value) {
    setOpenPopup(value);
  }

  const valueRenderer = (cell) => {
    let value = cell.value;
    if (value && value.length > 20) {
      value = value.slice(0, 24);
    }
    return value;
  };

  const cellRenderer = (propsCell) => {
    const { cell, style, ...rest } = propsCell;
    let colSpan = 1;
    let popup = false;
    let popupContent = null;
    let readOnly = false;
    let _textAlign = "!text-center";
    let _fontSize = "!text-sm";
    let _width = "w-3";
    let _fontWeight = "";
    let _minWidth = "!min-w-[4rem]";
    let _color = "!text-black";
    let _backgroundColor = "!bg-white";
    let _height = "!h-10";
    let _padding = "!px-1.5";
    let _border = "!border-stone-400";
    let _verticalAlign = "!align-middle";
    let _fontFamily = "!font-mono";

    let baseClassName = rest.className;

    // let className = "value-viewer";
    if (cell.style === "sheet-head-location") {
      _backgroundColor = "!bg-sky-900 !border-stone-600";
      _color = "!text-white";
      _fontWeight = "!font-bold";
      _fontSize = "!text-md";
      colSpan = 2;
      popup = true;
      if (cell.tags) {
        popupContent = (
          <div className="flex flex-wrap mt-3">
            {cell.tags.map((tag) => {
              return (
                <span
                  key={tag.id}
                  className="m-1 px-3 py-1 bg-sky-200 rounded-xl text-sky-800 text-sm"
                >
                  {tag.name}
                </span>
              );
            })}
          </div>
        );
      }
    } else if (cell.state === "performed") {
      readOnly = true;
    } else if (cell.style === "sheet-holiday") {
      _backgroundColor = "!bg-amber-200";
    } else if (cell.style === "sheet-head-day") {
      baseClassName = "!border !border-stone-700";
      _fontWeight = "!font-bold";
      _backgroundColor = "!bg-sky-200";
    } else if (cell.style === "sheet-position") {
      _minWidth = "!min-w-[12rem]";
      _textAlign = "!text-left";
      popupContent = null;
    } else if (cell.style === "sheet-guard") {
      _minWidth = "!min-w-[14rem]";
      _textAlign = "!text-left";
    }

    const dataCell = propsCell.children.props.cell;
    dataCell.readOnly = readOnly;

    rest.className = classNames(
      baseClassName,
      _width,
      _height,
      _minWidth,
      _padding,
      _textAlign,
      _backgroundColor,
      _verticalAlign,
      _color,
      _border,
      _fontWeight,
      _fontSize,
      _fontFamily,
    );

    // This lines fix warning in module reactDataSheet
    rest.updated = "false";
    rest.editing = "false";
    delete rest.attributesRenderer;

    const CellData = ({ onDoubleClick, onMouseMove, children }) => {
      let propsMouse = {};
      if (onMouseMove) {
        propsMouse = {
          onMouseEnter: () => onMouseMove(true),
          onMouseLeave: () => onMouseMove(false),
        };
      }
      return (
        <td
          {...rest}
          {...propsMouse}
          style={style}
          colSpan={colSpan}
          readOnly={readOnly}
          onDoubleClick={(e) => onDoubleClick(e, dataCell)}
        >
          {propsCell.children}
          {children}
          <div className="flex mt-auto">
            {cell.event_category && <Point color="bg-red-500" />}
            {cell.notes && <Point color="bg-sky-500" />}
          </div>
        </td>
      );
    };

    if (popup) {
      // const key = `${cell.value.row}-${cell.value.col}`;
      return (
        <CellData onDoubleClick={onDoubleClick} onMouseMove={handleMouse}>
          <Popup open={openPopup} handleMouseOut={handleMouse}>
            {popupContent}
          </Popup>
        </CellData>
      );
    } else {
      return CellData({ onDoubleClick });
    }
  };

  return (
    <div className="pt-0 overflow-auto">
      {data ? (
        <ScrollContainer ignoreElements=".cell">
          <ReactDataSheet
            className="mb-96"
            cellRenderer={cellRenderer}
            sheetRenderer={sheetRenderer}
            data={data}
            valueRenderer={valueRenderer}
            onCellsChanged={(changes) => onDataChange(changes)}
          />
        </ScrollContainer>
      ) : (
        <p></p>
      )}
      {openModalMsg && (
        <ModalMsg
          open={openModalMsg}
          buttons={["close"]}
          msg={modalMsg}
          onClose={closeModalMsg}
        />
      )}
    </div>
  );
}

export default Sheet;
