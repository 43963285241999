import React from "react";
import { FormattedMessage as FM } from "react-intl";

import Divider from "components/MeForm/Divider";
import MeCarousel from "components/Tools/MeCarousel";
import bagaggeImg from "./bagagge.png";
import photo1 from "./photo-spa.png";
import photo2 from "./photo-tour.png";
import photo3 from "./photo-rest.png";

const servicesImgs = [photo1, photo2, photo3];

function SuccessPage() {
  window.scrollTo(0, 0);
  const services = servicesImgs.map((imgService, idx) => {
    const photoName = `photo-${idx}`;
    return (
      <img
        key={photoName}
        className="webcheckin-success-img"
        src={imgService}
        alt={photoName}
      />
    );
  });

  const carouselProps = {
    // autoplay: true,
  };
  return (
    <div className="block px-6 text-center">
      <img width={110} src={bagaggeImg} className="mx-auto" />
      <h1 className="mt-8 text-slate-600 text-center text-3xl font-bold">
        <FM id="webcheckin_success_header" name="webcheckin_success_header" />
      </h1>
      <div className="my-6">
        <p className="text-lg text-slate-700">
          <FM id="webcheckin_success_msg" name="webcheckin_success_msg" />
        </p>
      </div>
      <Divider />
      <div className="my-6 text-center">
        <h1 className="text-3xl text-cyan-600">
          <FM
            id="webcheckin_success_our_services"
            name="webcheckin_success_our_services"
          />
        </h1>
      </div>
      <div className="mb-12">
        <MeCarousel {...carouselProps}>{services}</MeCarousel>
      </div>
    </div>
  );
}

export default SuccessPage;
