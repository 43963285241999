import ModelParty from "./ModelParty";
import AddSignature from "./components/AddSignature";
import tools from "tools/dates";

const stateColors = {
  draft: "amber",
  booking: "lime",
  processed: "sky",
  cancelled: "rose",
};

const TYPE_DOCUMENT = [
  { id: "passport", name: "passport" },
  { id: "driver_license", name: "driver_license" },
  { id: "medicare_card", name: "medicare_card" },
];

const visibleBooking = (name, record) => {
  let res = false;
  if (record.state === "offer" && record.equipment) {
    res = true;
  }
  return res;
};

const visibleProcess = (name, record) => {
  let res = false;
  if (record.state === "booking") {
    res = true;
  }
  return res;
};

const visibleSendEmail = (name, record) => {
  let res = false;
  if (record.state === "draft") {
    res = true;
  }
  return res;
};

const onChangeTimeEnd = (activeRecord, fieldValue) => {
  const lapse = activeRecord.lapse_time;
  let date = activeRecord["start_date"];
  let value = lapse || 1;
  let _end_date = new Date(date);
  _end_date.setDate(_end_date.getDate() + value * 7);
  activeRecord["end_date"] = tools.fmtDate2Tryton(_end_date);
  // activeRecord["start_date"] = `${activeRecord.start_date} 00:00:00`;
  return activeRecord;
};
const getView = () => {
  let DictCtxView = {
    model: "rental.booking",
    form_action: ["edit", "save"],
    table_action: ["edit", "add"],
    activeSearch: true,
    orderBy: [["booking_date", "DESC"]],
    limit: 30,
    selectable: null,
    tags: {
      state: stateColors,
    },
    domain: [],

    webfields: {
      number: { type: "char", readOnly: true },
      state: {
        type: "char",
        translate: true,
        default: "draft",
        readOnly: true,
        tags: stateColors,
      },
      doc_number: { type: "char", searchable: true },
      notification_pickup: { type: "boolean" },
      first_name: { type: "char", searchable: true },
      last_name: { type: "char" },
      mobile: { type: "char", searchable: true },
      type_document: {
        type: "selection",
        translate: true,
        options: TYPE_DOCUMENT,
      },
      email: { type: "char", searchable: true },
      address: { type: "char" },
      comment: { type: "text" },
      booking_date: {
        type: "date",
        readOnly: true,
        default: () => new Date(),
        required: true,
      },
      start_date: {
        type: "datetime",
        default: () => new Date(),
        required: true,
        withChange: onChangeTimeEnd,
      },
      end_date: {
        type: "datetime",
        default: () => new Date(),
        required: true,
      },
      suburb: { type: "char" },
      post_code: { type: "char" },
      city: { type: "char" },
      lapse_time: { type: "integer", withChange: onChangeTimeEnd },
      subdivision: {
        type: "many2one",
        recSearch: () => [],
        model: "country.subdivision",
        searchable: true,
      },
      product: {
        type: "many2one",
        model: "product.product",
        searchable: true,
        ctxView: ModelParty.ctxView(),
      },
      company: {
        type: "many2one",
        model: "company.company",
        searchable: true,
        ctxView: ModelParty.ctxView(),
        required: true,
        readOnly: true,
      },

      add_signature: {
        type: "button_wizard",
        Component: AddSignature,
        color: "blue",
        icon: "fi fi-rr-add",
        visible: visibleProcess,
      },
      equipment: {
        type: "many2one",
        recSearch: () => [],
        model: "maintenance.equipment",
        searchable: true,
      },

      send_sms: {
        type: "button",
        button_method: "send_notification_pickup",
        color: "blue",
        icon: "fi fi-rr-add",
        visible: true,
      },
      process: {
        type: "button",
        button_method: "process",
        color: "green",
        icon: "fi fi-rr-add",
        visible: visibleProcess,
      },

      send_confirmation_email: {
        type: "button",
        button_method: "send_email_confirmation",
        color: "amber",
        icon: "fi fi-rr-add",
        visible: visibleSendEmail,
      },
      add_booking: {
        type: "button",
        button_method: "booking",
        color: "amber",
        icon: "fi fi-rr-add",
        visible: visibleBooking,
      },
      add_draft: {
        type: "button",
        button_method: "booking",
        color: "amber",
        icon: "fi fi-rr-add",
        visible: visibleBooking,
      },
    },

    webtree: [
      { name: "number", width: "30%" },
      { name: "equipment", width: "30%" },
      { name: "first_name", width: "30%" },
      { name: "last_name", width: "30%" },
      { name: "type_document", width: "30%" },
      { name: "doc_number", width: "30%" },
      { name: "mobile", width: "30%" },
      { name: "booking_date", formatHour: false },
      { name: "state", width: "30%", widget: "badge" },
    ],
    webform: [
      {
        id: "infoDate",
        grid: [
          { name: "state" },
          { name: "booking_date" },
          { name: "number" },
          { name: "equipment" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      {
        id: "infoNames",
        grid: [
          { name: "first_name", uppercase: true, specialCharacters: true },
          { name: "last_name", uppercase: true, specialCharacters: true },
          { name: "type_document" },
          { name: "doc_number" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      {
        id: "infoContact",
        grid: [{ name: "email" }, { name: "mobile" }, { name: "subdivision" }],
        size: [1, 3],
        span: "col-span-2",
      },

      {
        id: "infoCity",
        grid: [
          { name: "suburb" },
          { name: "city" },
          { name: "post_code" },
          { name: "address" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      {
        id: "infoDates",
        grid: [
          { name: "start_date" },
          { name: "lapse_time" },
          { name: "end_date" },
          { name: "company" },

          // { name: "notification_pickup" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },

      { name: "comment" },

      {
        id: "buttoms",
        grid: [
          { name: "add_signature" },
          { name: "send_confirmation_email" },
          { name: "add_booking" },
          { name: "add_draft" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
