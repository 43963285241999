// Fields for search booking record

const fields = [
  "number",
  "channel.name",
  "booking_date",
  "company.rec_name",
  "party.name",
  "lines.room.name",
  "lines.product.template.name",
  "lines.arrival_date",
  "lines.departure_date",
  "lines.nights_quantity",
  "lines.guests",
];

export default fields;
