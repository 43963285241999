import React from "react";

import StdButton from "./StdButton";

function ButtonTable(props) {
  const { attrs, field, data } = props;
  const { model, record } = attrs;
  const label = `${model}.${field.name}`;

  function onClick(event) {
    event.stopPropagation();
    event.preventDefault();
    const method_action = field.button_method ?? field.method;
    attrs.triggerAction(record, method_action);
  }

  const color = field.color || "grey";
  return (
    <StdButton
      key={data.value}
      size="w-full"
      height="h-8"
      iconRight={field.iconRight}
      iconLeft={field.iconLeft}
      onClick={(event) => onClick(event)}
      color={color}
      content={label}
    />
  );
}

export default ButtonTable;
