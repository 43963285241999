// Guest model

const getView = () => {
  let DictCtxView = {
    model: "hotel.folio.guest",
    form_action: [], // options: ['save', 'delete']
    table_action: [], // options: ['open', 'delete', 'edit', 'add']
    domain: [], // Options: null or valid domain
    orderBy: [["name", "ASC"]],
    limit: 10,
    selectable: null, // Options for table rows: null, multi, one
    webfields: {
      name: { type: "char", readOnly: true },
      type_guest: { type: "char", readOnly: true },
      mobile: { type: "char", readOnly: true },
      main_guest: {
        type: "boolean",
        readOnly: true,
        translate: true,
      },
    },
    webtree: [
      { name: "name", width: "25%" },
      { name: "type_guest", width: "20%" },
      { name: "mobile", width: "25%" },
    ],
    webform: [
      { name: "name" },
      { name: "main_guest" },
      { name: "type_guest" },
      { name: "mobile" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
