import React, { useState, Fragment } from "react";

import BasicModal from "components/Modals/BasicModal";
import TableWidget from "./TableWidget";
import { useStoreRest } from "./storeRest";

function TablesModal({ onClose, open }) {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { tables } = useStoreRest();

  return (
    <BasicModal open={open} onClose={onClose} buttons={["close"]}>
      <div className="text-4xl font-bold text-stone-500 ml-4 my-6">Mesas</div>
      <div className="grid grid-cols-3 my-4 pb-8">
        {tables.map((rec, idx) => {
          return <TableWidget key={idx} record={rec} onClose={onClose} />;
        })}
      </div>
    </BasicModal>
  );
}

export default TablesModal;
