// Order Notification model
// import store from "store";
import ModelSaleLine from "./ModelSaleLine";
import wizardPrint from "./wizardPrint";

// const stateColors = {
//   requested: "bg-sky-400",
//   in_preparation: "bg-indigo-500",
//   dispatched: "bg-rose-400",
//   delivered: "bg-lime-500",
//   dispatched: "bg-gray-500",
// };

// function getDomain(config) {
//   return [];
// }

const getView = (config) => {
  let DictCtxView = {
    model: "sale.sale",
    domain: [],
    form_action: [], // options: ['save', 'delete']
    table_action: ["open"], // options: ['open', 'delete', 'edit', 'add']
    orderBy: [["name", "ASC"]],
    limit: 1000,
    selectable: null, // Options for table rows: null, multi, one
    title: { field: "number", model: true },
    // tags: {
    //   order_status: stateColors,
    // },
    webfields: {
      number: { type: "char", readOnly: true },
      delivery_amount: { type: "numeric", readOnly: true },
      party: {
        type: "many2one",
        model: "party.party",
        // recSearch: searchParty,
        readOnly: true,
      },
      source: {
        type: "many2one",
        model: "sale.source",
        readOnly: true,
      },
      lines: {
        type: "one2many",
        model: "sale.line",
        ctxView: ModelSaleLine.ctxView(config),
        readOnly: true,
      },
      consumer: {
        type: "many2one",
        model: "party.consumer",
        // recSearch: searchConsumer,
        readOnly: true,
      },
      sale_date: {
        type: "date",
        readOnly: true,
      },
      state: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "draft",
      },
      order_status: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "draft",
      },
      shop: {
        type: "many2one",
        model: "sale.shop",
        readOnly: true,
      },
      total_amount: { type: "numeric", readOnly: true },
      description: { type: "char", readOnly: true },
      kind: { type: "char", readOnly: true },
      comment: { type: "text", readOnly: true },
      invoice_type: {
        type: "radio",
        default: "p",
        options: [
          { value: "p", text: "POS" },
          { value: "1", text: "ELECTRONICA" },
        ],
        readOnly: true,
      },
      command: {
        type: "button",
        button_method: wizardPrint,
        color: "blue",
        visible: true,
        onSuccessMsg: "ORDEN COMANDADA...!",
      },
    },
    webtree: [
      { name: "number", width: "20%" },
      { name: "sale_date", width: "25%" },
      { name: "consumer", width: "25%" },
      { name: "party", width: "25%" },
      { name: "delivery_amount", width: "25%" },
      { name: "total_amount", width: "25%" },
      {
        name: "order_status",
        width: "10%",
        // widget: "circle" ,
      },
      // { name: "command", width: "25%" },
    ],
    webform: [
      { name: "number" },
      { name: "source" },
      { name: "shop" },
      { name: "kind" },
      { name: "sale_date" },
      { name: "invoice_type" },
      { name: "consumer" },
      { name: "party" },
      { name: "delivery_amount" },
      { name: "comment" },
      { name: "lines" },
      { name: "total_amount" },
      { name: "order_status" },
      { name: "command" },
      // { name: "lines", component: "modal" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
