import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { signal } from "@preact/signals-react";
import { useNavigate } from "react-router-dom";
import store from "store";

import Footer from "pages/Footer";
import CharField from "components/MeForm/CharField";
import PasswordField from "components/MeForm/PasswordField";
import StdButton from "components/Buttons/StdButton";
import proxy from "api/proxy";
import presikLogo from "assets/img/presik-login.png";
import futureImg from "assets/img/future.jpg";
import { useWizardStore } from "store/wizardStore";

const level = "wizard";
const loading = signal(false);
const btnDisabled = signal(true);
const username = signal("");
const database = signal("");
const passwd = signal("");
const formMessage = signal(null);

const ATTRS = {
  level: level,
};

const dbField = {
  name: "database",
  label: "company",
  color: "gray",
};

const userField = {
  name: "username",
  label: "user",
};

const passwdField = {
  name: "passwd",
  label: "password",
};

function LoginScreen(props) {
  const navigate = useNavigate();

  const handleSession = async () => {
    const { reset } = useWizardStore.getState();
    loading.value = true;
    const formData = { database, username, passwd };
    let { data: res } = await proxy.fastLogin(formData);
    if (res && res.user) {
      res.db = database;
      await store.set("ctxSession", res);
      navigate(`/dash/${database}`, { replace: true });
      props.handleSession(res);
      reset();
    } else {
      formMessage.value = "login.invalid_user_password";
      loading.value = false;
    }
  };

  const blankSpaces = (value) => {
    let _value = value.replace(" ", "");
    return _value;
  };

  const handleChange = async (field, value) => {
    let _value = "";
    switch (field) {
      case "database":
        _value = blankSpaces(value);
        database.value = _value.toUpperCase();
        break;
      case "username":
        _value = blankSpaces(value);
        username.value = _value;
        break;
      default:
        break;
    }
    const { store: storeW } = useWizardStore.getState();
    btnDisabled.value = !(storeW.database && storeW.username && storeW.passwd);
    formMessage.value = null;
  };

  return (
    <div className="container flex w-full mx-auto py-2 sm:py-20 max-h-max">
      <div className="flex border-slate-300 container mt-0 rounded-xl overflow-hidden lg:border mx-auto xl:w-8/12 lg:w-12/12 w-full lg:shadow-xl md:shadow-xl sm:shadow-xl bg-white">
        <div className="px-8 py-0 lg:w-6/12 lg:px-10 xl:px-14 md:w-8/12 sm:w-8/12 mx-auto">
          <img
            src={presikLogo}
            alt="PRESIK LOGO"
            width="170"
            className="my-12 mx-auto"
          />
          <p className="my-4 text-center text-4xl text-gray-600 font-bold">
            Log In
          </p>
          <p className="font-roboto font-extralight opacity-70 pt-2 text-[16px] text-gray-700">
            Ingrese sus credenciales para iniciar sesión. Tres intentos de
            contraseña incorrecta bloquearán su usuario.
          </p>
          <form className="py-3" title="login.welcome">
            <CharField
              field={dbField}
              data={database}
              attrs={ATTRS}
              blankSpaces={true}
              onChange={handleChange}
            />
            <CharField
              field={userField}
              data={username}
              attrs={ATTRS}
              onChange={handleChange}
              blankSpaces={true}
            />
            <PasswordField
              field={passwdField}
              data={passwd}
              attrs={ATTRS}
              onChange={handleChange}
            />
            <div className="my-8" />
            {formMessage.value && (
              <div className="text-red-400 my-3">
                <span>
                  <FM id="login.invalid_user_password" />
                </span>
              </div>
            )}
            <StdButton
              onClick={handleSession}
              size="w-full"
              loading={loading.value}
              disabled={btnDisabled}
              color="blue"
              content="enter"
            />
          </form>
          <Footer />
        </div>
        <div className="hidden w-0 xl:w-1/2 lg:w-1/2 overflow-hidden lg:flex invisible lg:visible xl:visible ">
          <div className="backdrop-blur-sm bg-white/30 w-1/4 my-40 h-[260px] absolute overflow-hidden rounded-r-2xl">
            <div className="absolute mx-20 h-1 mt-20 sm:mx-8" />
            <p className="font-roboto font-extralight absolute py-12 px-20 sm:px-8 text-5xl text-white">
              Ready <br /> for <br /> Future...
            </p>
          </div>
          <img
            src={futureImg}
            alt="future"
            loading="lazy"
            className="my-0 mx-0 h-full object-cover overflow-hidden"
          />
        </div>
      </div>
    </div>
  );
}
//
export default LoginScreen;
