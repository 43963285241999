import React, { Fragment, useEffect } from "react";
import { FormattedMessage as FM } from "react-intl";
import cn from "classnames";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Transition } from "@headlessui/react";

export const Alert = ({
  text = "app.booking.alert_msg_default",
  color = "bg-red-500",
  position = "relative",
  onClose,
  show,
  children,
}) => {
  useEffect(() => {
    let timer;
    if (show) {
      timer = setTimeout(() => {
        onClose();
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [show, onClose]);

  return (
    <Fragment>
      <Transition
        show={show}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={cn(
            color,
            "p-4 pr-20 rounded-md flex items-center mb-4 shadow-md space-x-3",
            position,
          )}
        >
          {children ? (
            children
          ) : (
            <XMarkIcon
              className="w-8 h-8 cursor-pointer hover:text-black bg-red-950 rounded-full p-1"
              onClick={onClose}
            />
          )}

          <span>
            <FM id={text} />
          </span>
        </div>
      </Transition>
    </Fragment>
  );
};
