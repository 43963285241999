import React, { useRef } from "react";

import QuickTable from "components/Meta/QuickTable";
import BasicModal from "components/Modals/BasicModal";
import StdButton from "components/Buttons/StdButton";
// import GroupLabelRecords from "components/Tools/GroupLabelRecords";

/**
 * Define component search window
 * @param { Boolean } open - boolean
 * @param { Function } onClose - Callback function onClose
 * @param { String } model - string model tryton
 * @param { Array } ctxView - {ModelProduct.ctxView()}
 * @param { Function } onSelectedRecord - {() => onSelectedRecord()}
 * @returns Component search window
 */

function SearchWindow(props) {
  const selectedRef = useRef(null);
  const selectedRowsRef = useRef(new Map());

  let modeSelect = props.modeSelect;
  if (!props.modeSelect) {
    modeSelect = props?.ctxView?.selectable ?? false;
  }

  async function acceptModal() {
    if (modeSelect === "multi") {
      props.onSelectedRecord(selectedRowsRef.current);
    } else if (modeSelect === "one") {
      props.onSelectedRecord(selectedRef.current);
    }
    props.onClose();
  }

  function onClose() {
    props.onClose();
  }

  function onDoubleClickRow(rec) {
    if (rec && rec.id) {
      props.onSelectedRecord(rec);
      props.onClose();
    }
  }

  function onClickRow(rec) {
    console.log("X onClickRow....", modeSelect);
    if (!rec) return;
    let selectedRows_ = new Map();
    if (modeSelect) {
      selectedRows_ = new Map(selectedRowsRef.current);
      if (selectedRows_.has(rec.id)) {
        selectedRows_.delete(rec.id);
      } else {
        selectedRows_.set(rec.id, rec);
      }
    }
    selectedRowsRef.current = selectedRows_;
    selectedRef.current = rec;
  }

  let buttons = [
    <StdButton
      key="accept"
      name={"accept"}
      color="blue"
      content={"button_accept"}
      onClick={() => acceptModal()}
    />,
  ];

  return (
    <BasicModal
      open={props.open}
      onClose={onClose}
      title="modal.search"
      width="w-4/5"
      // onAccept={acceptModal}
      buttons={buttons}
    >
      <QuickTable
        model={props.model}
        ctxView={props.ctxView}
        domain={props.domain}
        selectable={modeSelect}
        onDoubleClickRow={onDoubleClickRow}
        onClickRow={onClickRow}
        activeSearch={true}
        onClickCell={props.onClickCell}
      />
    </BasicModal>
  );
}

export default SearchWindow;
