const dataFake = {
  title: "Resultados del Equipo CRM",
  description:
    "Este reporte muestra el desempeño del equipo comercial, en un rango de fechas. El valor negociado esta en COP.",
  header: {},
  cols: [
    { name: "agent", type: "char", width: "w-[15%]" },
    { name: "news", type: "integer", width: "25%" },
    { name: "leads", type: "integer", width: "10%" },
    { name: "opportunity", type: "integer", width: "10%" },
    { name: "won", type: "integer", width: "10%" },
    { name: "lost", type: "integer", width: "10%" },
    { name: "cancelled", type: "integer", width: "10%" },
    { name: "amount", type: "number", width: "15%" },
    { name: "success_rate", type: "float", width: "10%" },
  ],
  rows: [
    ["Juan Jimenez", 20, 30, 10, 5, 4, 7, 200000, "34%"],
    ["Robert Martinez", 22, 30, 4, 9, 3, 1, 13000, "27%"],
    ["Carla Duarte", 66, 23, 1, 25, 10, 0, 800000, "60%"],
    ["Robin Mendieta", 4, 7, 11, 15, 1, 0, 20000, "95%"],
  ],
};

export default dataFake;
