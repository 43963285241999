import React, { useEffect, useRef, useState, Fragment } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useQuery } from "@tanstack/react-query";

import InputSearchTable from "components/MeTable/InputSearchTable";
import { useDebounce } from "hooks/useDebounce";
import proxy from "api/proxy";
import mainStore from "store/mainStore";
import childStore from "store/childStore";

function ProductCombine({ onClose }) {
  const [selected, setSelected] = useState(-1);
  const [filter, setFilter] = useState("");
  const [domain, setDomain] = useState(null);
  const debouncedValue = useDebounce(filter, 500);
  const { store, record, updateRecord, updateStore } = mainStore();
  const {
    record: recordChild,
    store: storeChild,
    updateRecord: updateChildRecord,
    updateStore: updateChildStore,
  } = childStore();

  const queryProductCombine = useQuery(["productCombine", domain], async () => {
    let prods = [];
    if (domain) {
      const { data } = await proxy.search("product.product", domain, [
        "template.sale_price_w_tax",
        "sale_uom",
        "name",
        "template.list_price",
      ]);
      prods = data;
    }

    return prods;
  });

  function searchClicked() {
    let dom = [];

    if (recordChild?.product?.categories) {
      dom.push(["template.categories", "in", recordChild.product.categories]);
    }

    if (debouncedValue && debouncedValue.length > 3) {
      let arrText = debouncedValue.split(" ");
      let clause = ["OR"];
      for (const tw of arrText) {
        clause.push(["rec_name", "ilike", `%${tw}%`]);
      }
      dom.push(clause);
    }

    if (dom.length > 0) {
      setDomain(dom);
    } else {
      setDomain(null);
    }
  }

  useEffect(() => {
    searchClicked();
  }, [record, debouncedValue]);

  const handleSelect = (product) => {
    const timeStamp = new Date();
    const sequence = -timeStamp.getTime();
    let product_ = {
      id: sequence,
      quantity: recordChild.quantity,
      unit: product.sale_uom,
      product: product,
    };
    let _activeRecord = { ...record };
    let lines = _activeRecord.lines;

    let _storeRecord = { ...store };
    if (!_storeRecord.lines) {
      _storeRecord.lines = new Map();
      _storeRecord.lines.set("create", new Map());
    }
    let to_create = _storeRecord.lines.get("create");
    if (
      recordChild.product.sale_price_taxed <=
      product["template."].sale_price_w_tax
    ) {
      const sale_price_w_tax = product["template."].sale_price_w_tax;
      const total_amount = sale_price_w_tax * recordChild.quantity;
      const list_price = product["template."].list_price;

      const record_ = {
        ...recordChild,
        unit_price: list_price,
        amount: sale_price_w_tax,
        total_amount: total_amount,
      };
      const storeRec = {
        ...storeChild,
        unit_price: list_price,
        base_price: list_price,
        amount: sale_price_w_tax,
        total_amount: total_amount,
      };

      to_create.set(record_.id, storeRec);
      lines.set(record_.id, record_);
      product_["unit_price"] = list_price;
      product_["base_price"] = list_price;
      product_["amount"] = sale_price_w_tax;
      product_["total_amount"] = total_amount;
      updateChildRecord(record_);
      updateChildStore(storeRec);
    } else {
      product_["unit_price"] = recordChild.unit_price;
      product_["base_price"] = recordChild.unit_price;
      product_["amount"] = recordChild.amount;
      product_["total_amount"] = recordChild.total_amount;
    }
    to_create.set(sequence, product_);
    lines.set(sequence, { ...product_ });
    record.lines = lines;
    store.lines = _storeRecord.lines;
    updateRecord(record);
    updateStore(store);
    onClose();
  };

  return (
    <Fragment>
      <div className="px-4 sm:px-6 lg:px-8 py-2 md:col-span-2">
        <div className="flex flex-row relative w-full">
          <InputSearchTable
            // ref={inputRef}
            value={filter}
            handleValue={setFilter}
            searchClicked={searchClicked}
          />
          <button
            onClick={searchClicked}
            className="flex bg-stone-700 hover:shadow-md rounded-r-lg px-3 my-1 text-xl items-center text-center hover:opacity-90 disabled:opacity-50 w-12 h-10 absolute right-0"
          >
            <MagnifyingGlassIcon className="h-6 w-6 text-white" />
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-2">
          {queryProductCombine.data?.map((product, index) => {
            return (
              <div
                className={
                  "rounded-md hover:opacity-50 text-center py-8 px-2 " +
                  (index !== selected ? "bg-orange-100" : "bg-orange-300")
                }
                key={product.id}
                onClick={() => {
                  setSelected(index);
                  handleSelect(product);
                }}
                name={index}
              >
                <p key={product.id} className="pointer-events-none">
                  {product.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
}

export default ProductCombine;
