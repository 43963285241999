import React, { useState } from "react";

import QuickForm from "components/Meta/QuickForm";
import StdButton from "components/Buttons/StdButton";
import Kind from "./Kind";
import Shop from "./Shop";
import Source from "./Source";
import ScrollToTop from "./ScrollToTop";
import Consumer from "./Consumer";
import useMainStore from "store/mainStore";

import dates from "tools/dates";
import funcs from "tools/functions";
import Timer from "./Timer";
import Divider from "components/MeForm/Divider";

function BtnConsumer({ onClick }) {
  return (
    <div>
      <Divider text={"sale_call_center.btn_consumer"} key={"btn_consumer"} />

      <div className="flex justify-center pb-2">
        <StdButton
          color={"blue"}
          size={"w-3/4 h-16"}
          content={"sale.sale.add_consumer"}
          onClick={() => {
            onClick("modalConsumer");
          }}
        />
      </div>
    </div>
  );
}

const STEPS = {
  source: Source,
  kind: Kind,
  btn_consumer: BtnConsumer,
  shop: Shop,
};

function CreateOrder({ ctxView }) {
  const { store, record, updateRecord, updateStore } = useMainStore();
  const [openConsumer, setOpenConsumer] = useState(false);
  const [startTimer, setStartTimer] = useState(null);
  const stopTimer = startTimer && Object.keys(store).length <= 0;

  const closeConsumer = async (consumer) => {
    if (consumer) {
      await updateForm("consumer", consumer);
    }
    setOpenConsumer(false);
  };

  async function updateForm(field, value) {
    if (field === "modalConsumer") {
      setOpenConsumer(true);
      return;
    }
    if (value === undefined) return;
    let toStore = { ...store };
    let toRecord = { ...record };
    if (!toRecord.invoice_type) {
      const [_store, _record] = await funcs.getDefaults(0, ctxView);
      toStore = { ..._store, ...toStore, invoice_type: "1" };
      toRecord = { ..._record, ...record, invoice_type: "1" };
    }
    if (!startTimer) {
      toStore["created"] = dates.getNow();
      setStartTimer(toStore["created"]);
    }
    if (field === "shop" && !record.party) {
      toStore["party"] = value["party."];
      toRecord["party"] = value["party."];
    }
    toStore[field] = value;
    toRecord[field] = value;
    updateRecord(toRecord);
    updateStore(toStore);
  }

  function getSteps() {
    const steps = Object.keys(STEPS).map((step_) => {
      const Component = STEPS[step_];
      return (
        <Component
          key={step_}
          onClick={updateForm}
          record={record[step_]}
          section={step_}
        />
      );
    });
    return steps;
  }

  return (
    <div className="mt-3">
      {getSteps()}

      <hr className="h-px m-2 bg-gray-200 border-1 dark:bg-gray-700" />
      <QuickForm ctxView={ctxView} level="main" />
      <Consumer
        open={openConsumer}
        consumerId={record?.consumer?.id ?? null}
        closeConsumer={closeConsumer}
      />
      <Timer
        start={startTimer}
        stop={stopTimer}
        handleTimer={() => setStartTimer(null)}
      />
      <ScrollToTop />
    </div>
  );
}

export default CreateOrder;
