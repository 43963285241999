import React, { useState, useEffect } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { classNames } from "tools/ui";
import funcs from "tools/functions";
import { useWizardStore } from "store/wizardStore";

function Cart(props) {
  const { record: storeCart, updateWizard } = useWizardStore();

  const handleDelete = (event, id) => {
    event.preventDefault();
    if (storeCart.lines.get(id)) {
      storeCart.lines.delete(id);
      updateWizard(storeCart);
    }
  };

  function getProducts() {
    const lines = storeCart?.lines;
    if (!lines) return null;

    let rows = [];
    let total = 0;
    const styleCell = "!font-mono py-5 text-xl pl-4 break-words";
    const styleTotal =
      "font-mono py-6 text-4xl pl-4 break-words text-right font-bold";
    for (const [id, val] of lines.entries()) {
      total += val.unit_price_w_tax;
      rows.push(
        <tr key={id} className="border-collapse border-dashed border-b-2">
          <td className={classNames(styleCell)}>{val.product.name}</td>
          <td className={classNames(styleCell, "text-right")}>
            {funcs.fmtMoney(val.unit_price_w_tax)}
          </td>
          <td className={classNames(styleCell)}>
            {!props.readOnly && (
              <button
                name={val.id}
                className="bg-rose-400 rounded-lg p-2 mx-auto"
                onClick={(e) => handleDelete(e, id)}
              >
                <XMarkIcon
                  name="delete"
                  className="h-6 w-6 font-bold text-white stroke-[3]"
                />
              </button>
            )}
          </td>
        </tr>,
      );
    }
    rows.push(
      <tr key="cart-total">
        <td className={classNames(styleTotal)}>TOTAL</td>
        <td className={classNames(styleTotal)}>{funcs.fmtMoney(total)}</td>
      </tr>,
    );
    return rows;
  }

  const tdStyle = "!font-mono pl-4 font-bold text-2xl h-16 text-slate-700";
  return (
    <div className="grid">
      <h2 className="text-4xl font-bold bg-slate-700 py-10 !font-mono text-amber-300 text-center inline">
        TU ORDEN
      </h2>
      <p className="!font-mono text-2xl py-3 text-cyan-700 text-center inline">
        Puedes pedir máximo (3) tres productos.
      </p>
      <table className="w-full mt-4">
        <thead className="bg-slate-200">
          <tr className="">
            <th className={tdStyle}>PRODUCTO</th>
            <th className={tdStyle}>PRECIO</th>
            <th className={tdStyle}></th>
          </tr>
        </thead>
        <tbody>{getProducts()}</tbody>
      </table>
    </div>
  );
}

export default Cart;
