import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import React, { Fragment, useRef } from "react";

const Banner = ({ children, colors }) => {
  const divFilterRef = useRef(null); // Referencia al elemento con id="div-filter"

  const handleScrollToFilter = () => {
    if (divFilterRef.current) {
      divFilterRef.current.scrollIntoView({
        behavior: "smooth", // Scroll suave
        block: "start", // Comienza desde arriba del elemento
      });
    }
  };

  return (
    <Fragment>
      <div
        ref={divFilterRef}
        className={`${
          colors ? colors.colorPrimary : "bg-blue-presik"
        } w-screen   md:-top-16  -mb-16   md:relative -top-32 z-30 top-0 relative `}
        id="div-filter"
      >
        <div className="container mx-auto">{children}</div>
      </div>
      <CalendarDaysIcon
        className={`w-10 ${colors?.colorPrimary || "bg-blue-presik"} ${
          colors?.colorTextPrimary
        } border border-white rounded-full p-2  hover:scale-105 cursor-pointer transform transition-all duration-150 z-10  z-30 fixed right-2 bottom-2 md:hidden`}
        onClick={handleScrollToFilter} // Llama a la función al hacer clic
      />
    </Fragment>
  );
};

export default Banner;
