import React from "react";
import { FormattedMessage as FM } from "react-intl";

/**
 *
 * @param {*} props -
 * @param {Boolean} readOnly -
 * @param {String} type -
 * @param {String} name -
 * @param {String} placeholder -
 * @param {String} value -
 * @param {Function} onChange -
 * @param {Boolean} translate -
 * @returns Component TextField
 */

function SelectionCell({ value, field }) {
  let _value;
  if (typeof value === "string") {
    _value = value;
  } else {
    _value = value ? value.id : null;
  }

  for (const fi of field.options) {
    if (fi.id === _value) {
      _value = fi.name;
      break;
    }
  }

  if (field.translate && _value && _value !== "") {
    _value = <FM id={_value} key={_value} />;
  }

  function onChange(event) {
    console.log("Not implemented update in SelectionCell!");
  }

  return _value;
}

export default SelectionCell;
