import React, { Fragment } from "react";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/20/solid";
import { classNames, colors } from "tools/ui";
import { FormattedMessage as FM } from "react-intl";

const base =
  "rounded-none w-full border-t text-center border-b border-slate-300 py-2 px-3 text-gray-700 focus:outline-none focus:border focus:border-cyan-500 ";
const iconStyle = "w-5 h-5 text-stone-700";
const stBtn =
  "border-slate-300 border-[1px] px-3 py-1 bg-zinc-100 active:bg-zinc-200 focus:outline-none";
const leftBtn = "rounded-l-md";
const rightBtn = "rounded-r-md";

function IntegerField(props) {
  const { field, data, attrs, onChange, max } = props;
  const { name, readOnly, label } = field;
  const { level } = attrs;

  let _color = "bg-white";
  if (field.color === "gray") {
    _color = "bg-gray-100";
  }
  if (readOnly) {
    _color = colors.readOnly;
  }

  const changeValue = (e, sign) => {
    e.preventDefault();
    if (readOnly) return;
    let newValue = data.value;
    if (sign === "+") {
      newValue += 1;
    } else {
      if (newValue > 0) {
        newValue -= 1;
      }
    }
    onChange(name, newValue, max);
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    if (value === "") {
      onChange(name, 0, max);
    } else {
      value = parseInt(value, 10);
      if (!isNaN(value)) {
        onChange(name, value, max);
      }
    }
  };

  return (
    <Fragment>
      <div>
        <span className="font-semibold">
          <FM id={label} />
        </span>
        <div className="flex items-stretch w-full h-10">
          <button
            className={classNames(stBtn, leftBtn)}
            onClick={(e) => changeValue(e, "-")}
          >
            <MinusCircleIcon className={iconStyle} />
          </button>
          <input
            id={name}
            name={name}
            type="text"
            value={data.value}
            className={classNames(base, _color)}
            onChange={handleInputChange}
            readOnly={readOnly}
          />
          <button
            className={classNames(stBtn, rightBtn)}
            onClick={(e) => changeValue(e, "+")}
          >
            <PlusCircleIcon className={iconStyle} />
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default IntegerField;
