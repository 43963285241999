import React from "react";
import { FormattedMessage as FM } from "react-intl";
import ChangeLang from "components/Tools/ChangeLang";

const Header = ({ data = false }) => {
  return (
    <header
      className={`${
        data.value?.colorPrimary ? data.value?.colorPrimary : "bg-blue-presik"
      } w-screen relative top-1 md:top-0 z-50 py-3`}
    >
      <div className="grid grid-cols-2 justify-between px-4 md:px-14 mx-auto">
        <div>
          <a href={data.value?.url || "#"} target="__blank">
            <img
              src={data.value?.["company."].logo_link || ""}
              className={` h-10 ${
                data.value?.grayScale ? "grayscale" : ""
              } ${data.value?.sizeLogo ? data.value?.sizeLogo : "md:h-16"}`}
            />
          </a>
        </div>
        <div className="flex justify-end items-center  align-middle divide-x divide-gray-500 space-x-3">
          <ChangeLang colors={data.value?.colorPrimary} />
          {data.value?.privacyPolicies && (
            <a
              className="text-white hover:text-yellow-500 hidden md:block transition-all duration-150 pl-3"
              href={data.value?.privacyPolicies}
              target="_blank"
              rel="noreferrer"
            >
              <FM id="app.booking.term_cond" />
            </a>
          )}
          {data.value?.dataProtection && (
            <a
              className="text-white hover:text-yellow-500 hidden md:block transition-all duration-150 pl-3"
              href={data.value?.dataProtection}
            >
              <FM id="app.booking.privacy" />
            </a>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
