import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import proxy from "api/proxy";
import { classNames } from "tools/ui";

function LogoCompany(props) {
  const [logoCompany, setLogoCompany] = useState(null);
  const { session } = useOutletContext();

  async function getLogo() {
    const fieldsNames = ["logo_company_link"];
    const { data } = await proxy.search(
      "dash.configuration",
      [],
      fieldsNames,
      1,
      null,
      null,
      session.db,
    );
    if (data) {
      setLogoCompany(data[0]?.logo_company_link ?? null);
    }
  }

  useEffect(() => {
    getLogo();
  }, []);

  return (
    logoCompany && (
      <img
        className={classNames("pt-4 mx-auto", props.size)}
        src={logoCompany}
        alt="logo-company"
      />
    )
  );
}

export default LogoCompany;
