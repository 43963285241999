import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";

import ChartCard from "components/Chart/ChartCard";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title);

var options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
};

function LineChart({ name, data, getReport }) {
  const dataObj = {
    labels: data.labels,
    datasets: [
      {
        label: data.label_item,
        tension: 0.1,
        data: data.values,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        pointRadius: 4,
        pointHoverRadius: 6,
        borderWidth: 2,
      },
    ],
  };

  if (data.max_value) {
    options.scales = {
      y: {
        min: data.min_value,
        max: data.max_value,
      },
    };
  }
  return (
    <ChartCard
      header={name}
      header_meta={data.header_meta}
      desc={data.desc}
      desc_meta={data.desc_meta}
      getReport={getReport}
    >
      <div className="">
        <Line data={dataObj} height={350} options={options} />
      </div>
    </ChartCard>
  );
}

export default LineChart;
