import React, { useEffect, useState } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "chartjs-plugin-annotation";

import ChartCard from "components/Chart/ChartCard";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: "Plazo de pago",
      font: {
        size: 24,
      },
    },
  },
};

function DoughnutChart({ data, name, getReport }) {
  const dataObj = {
    labels: data.labels,
    datasets: [
      {
        label: "Valor",
        data: data.values,
        backgroundColor: ["#d9f99d", "#bae6fd"],
        borderColor: ["#84cc16", "rgba(150, 150, 150, 1)"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <ChartCard
      header={name}
      header_meta={data.header_meta}
      desc={data.desc}
      desc_meta={data.desc_meta}
      style="pb-8"
    >
      <div className="chart-segment-container">
        <Doughnut data={dataObj} height={285} options={options} />
      </div>
      <div className="text-center text-3xl text-cyan-600">
        {data.center_label}
      </div>
    </ChartCard>
  );
}

function ChartPaymentTerm(props) {
  const [data, setData] = useState({});

  function getReport() {}

  function getData() {
    if (props.data) {
      const labels = props.data.map((item) => item.payment_term);
      const values = props.data.map((item) => item.untaxed_amount);
      const data_ = {
        labels: labels,
        values: values,
      };
      setData(data_);
    }
  }

  useEffect(() => {
    getData();
  }, [props.data]);

  if (!props.data) return;
  return <DoughnutChart data={data} />;
}
export default ChartPaymentTerm;
