import React from "react";

import LoadingIcon from "components/Tools/LoadingIcon.js";

function Loading() {
  return (
    <div className="flex flex-col items-center justify-center w-full mx-auto h-16 py-24 ">
      <span>
        <LoadingIcon />
      </span>
      <p className="mx-10 my-4 text-gray-500 text-xl text-center">
        Loading . . .
      </p>
    </div>
  );
}

export default Loading;
