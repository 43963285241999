import React, { useEffect, useState } from "react";
import { ArrowPathIcon, ShoppingCartIcon } from "@heroicons/react/24/outline";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import DropdownField from "components/MeForm/DropdownField";
import Divider from "components/MeForm/Divider";
import FileField from "components/MeForm/FileField";
import StdButton from "components/Buttons/StdButton";
import QuickTable from "components/Meta/QuickTable";
import TableData from "./TableData";
import purchaseRequisition from "./ModelPurchaseRequisition";
import proxy from "api/proxy";
import func from "tools/functions";
import { signal } from "@preact/signals-react";
import { useWizardStore } from "store/wizardStore";

const customQuery = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function PurchaseRequisition(props) {
  const { config } = props;
  const { record, reset } = useWizardStore();
  const [type, setType] = useState("requisition");
  const [viewTable, setViewTable] = useState(false);
  const [viewTable2, setViewTable2] = useState(false);
  const [domain, setDomain] = useState([]);
  const [ctxView, setCtxView] = useState(purchaseRequisition(config));

  const warehouse = signal(record.warehouse);
  const supplier = signal(record.supplier);
  const brand = signal(record.brand);
  const file = signal(record.file);
  const [dataRows, setDataRows] = useState([]);
  const [header, setHeader] = useState([]);

  function handleButtonType(e, name) {
    setType(name);
    resetForm();
  }

  // function handleField(name, rec) {
  //   let dataForm_ = { ...dataForm };
  //   dataForm_[name] = rec;
  //   setDataForm(dataForm_);
  // }

  function resetForm() {
    setDomain([]);
    setViewTable(false);
    reset();
  }

  async function handleValidate() {
    const args = func.recToTryton(record);
    let viewTable2 = false;
    let domain = [];
    if (record.file_upload) {
      const response = await proxy.method({
        model: "dash.app.purchase_requisition",
        method: "process_file",
        args: args,
      });
      if (response.status === "success") {
        const request_ids = response.requests;
        const not_request = response.not_request;
        domain = [
          ["state", "=", "draft"],
          ["id", "in", request_ids],
        ];
        viewTable2 = true;
        setDataRows(not_request.data);
        setHeader(not_request.columns);
        // handleField("requests", request_ids);
      }
    } else {
      if (!record.supplier || !record.warehouse) return;
      domain = [
        ["state", "=", "draft"],
        ["party", "=", record.supplier.id],
        ["warehouse", "=", record.warehouse.id],
      ];
      if (record.brand) {
        domain.push(["product.brand", "=", record.brand.id]);
      }
    }
    if (domain) {
      let ctxView_ = { ...ctxView };
      ctxView_["pagination"] = domain;
      setCtxView(ctxView_);
      setDomain(domain);
      setViewTable(true);
      setViewTable2(viewTable2);
    }
  }

  async function handlePurchase() {
    const args = func.recToTryton(record);
    if (!args.supplier) {
      return;
    }
    const { data } = await proxy.methodCall({
      model: "dash.app.purchase_requisition",
      method: "create_purchase",
      args: args,
    });
    console.log(data, "validate response");
    // setOpenModalMsg(true);
    // setStatus(response.status);
    // setModalMsg(response.msg);
  }

  function searchParty(value) {
    let domParty = [];
    if (value) {
      domParty = [["name", "ilike", `%${value}%`]];
    }
    return domParty;
  }

  // function handleClose() {
  //   setOpen(false);
  // }

  // function onClickCell(field, storeRec) {
  //   let Component = null;
  //   let openModal = false;
  //   if (["current_stock", "sale_history", "purchases"].includes(field)) {
  //     Component = (
  //       <History
  //         open={open}
  //         field={field}
  //         record={storeRec}
  //         onClose={handleClose}
  //       />
  //     );
  //     openModal = true;
  //   }
  //   setModal(Component);
  //   setOpen(openModal);
  // }

  function searchLocation(value) {
    let domLocation = [["type", "=", "warehouse"]];
    if (value) {
      domLocation.push(["name", "ilike", `%${value}%`]);
    }
    return domLocation;
  }

  function updateRecords(field, value, recordId) {
    let value_ = value;
    let args = { id: recordId };
    if (field === "party") {
      value_ = value_.id;
    }
    args[field] = value_;
    proxy.saveQuery({ model: ctxView.model, storeRec: args });
  }

  // function onClickRow(rec) {
  //   let selectedRows_ = new Map(selectedRows);

  //   if (selectedRows_.get(rec.id)) {
  //     selectedRows_.delete(rec.id);
  //   } else {
  //     selectedRows_.set(rec.id, rec);
  //   }
  //   setSelectedRows(selectedRows_);
  // }
  console.log("RENDER THIS VIEW");
  return (
    <QueryClientProvider client={customQuery}>
      <div className=" w-full m-4 md:m-6 p-4 relative">
        <div className="flex flex-col lg:flex-row justify-between flex-wrap gap-4">
          <div className="flex justify-start gap-4">
            <StdButton
              name={"requisition"}
              color="indigo"
              size={"w-80"}
              onClick={handleButtonType}
              content="purchase_requisition.requisition"
            />
            <StdButton
              name={"suggested"}
              color="green"
              size={"w-80"}
              onClick={handleButtonType}
              content="purchase_requisition.suggested"
            />
          </div>
          <div className="flex justify-start lg:justify-end gap-4">
            <StdButton
              name={"reload"}
              color="slate"
              size={"w-80"}
              onClick={handleValidate}
              content="purchase_requisition.reload"
            >
              <ArrowPathIcon className="h-5 w-5" />
            </StdButton>

            <StdButton
              style={"float-right"}
              name={"create_purchase"}
              color="blue"
              size={"w-80"}
              onClick={handlePurchase}
              content="purchase_requisition.create"
            >
              <ShoppingCartIcon className="h-5 w-5" />
            </StdButton>
          </div>
        </div>
        {type === "requisition" && (
          <div className="flex flex-col sm:flex-row justify-between gap-4">
            <DropdownField
              field={{
                readonly: false,
                name: "warehouse",
                label: "purchase_requisition.warehouse",
                model: "stock.location",
                required: false,
                recSearch: searchLocation,
              }}
              attrs={{
                level: "wizard",
              }}
              data={warehouse}
            />
            <DropdownField
              field={{
                readonly: false,
                name: "supplier",
                label: "purchase_requisition.supplier",
                model: "party.party",
                required: false,
                recSearch: searchParty,
              }}
              attrs={{
                level: "wizard",
              }}
              data={supplier}
            />
            <DropdownField
              field={{
                readonly: false,
                name: "brand",
                label: "purchase_requisition.brand",
                model: "product.brand",
                required: false,
                recSearch: searchParty,
              }}
              attrs={{
                level: "wizard",
              }}
              data={brand}
            />
          </div>
        )}
        {type === "suggested" && (
          <div className="flex flex-col md:flex-row gap-4">
            <FileField
              field={{
                label: "purchase_requisition.file",
                name: "file_upload",
                required: true,
                accept: ".xls, .xlsx",
              }}
              attrs={{
                level: "wizard",
              }}
              data={file}
            />
            <DropdownField
              field={{
                readonly: false,
                name: "supplier",
                label: "purchase_requisition.supplier",
                model: "party.party",
                required: false,
                recSearch: searchParty,
              }}
              attrs={{
                level: "wizard",
              }}
              data={supplier}
            />
          </div>
        )}
        {viewTable && (
          <QuickTable
            ctxView={ctxView}
            domain={domain}
            updateRecords={updateRecords}
            level={"main"}
          />
        )}
        {viewTable2 && (
          <div className="pt-4">
            <Divider text="purchase_requisition.productsNotFound" />
            <TableData header={header} rows={dataRows} />
          </div>
        )}
        {/* {open ? modal : null}
      {openModalMsg && (
        <MeModal
          open={openModalMsg}
          type={status}
          buttons={["close"]}
          msg={modalMsg}
          onClose={closeModalMsg}
        />
      )} */}
      </div>
    </QueryClientProvider>
  );
}

export default PurchaseRequisition;
