import React from "react";

const Banner = ({ category }) => {
  if (category.images.length < 1) return;
  const image = category.images[0];

  return (
    <div
      className="flex items-center justify-between h-80  relative goder shadow-md overflow-hidden mb-8 bg-cover bg-bottom bg-no-repeat"
      // style={{
      //   backgroundImage:
      //     "url(https://res.cloudinary.com/dl4sdiukx/image/upload/v1695823361/t19gjhgmk3anynjbpjiz.jpg)",
      // }}
    >
      <div className="w-1/2 text-white z-10 px-16 p-y-8 space-y-5">
        <h2 className="text-4xl font-bold uppercase">
          {category?.rec_name || "Texto por actualizar"}
        </h2>
        <p className="text-lg w-96">
          {category?.description || "Texto por actualizar"}
        </p>
      </div>
      <div className="w-1/2 flex justify-end ">
        {/* esta imagen debe estar sin fondo y es png  700 px de ancho  400px alto */}
        <img src={image} alt="Imagen de ejemplo" className="h-[330px] z-10 " />
      </div>
      <div className="absolute top-0 left-0 w-full bg-gradient-to-r   from-blue-presik  via-blue-presik/90 to-blue-presik/30 min-h-full p-4 z-0 "></div>
    </div>
  );
};

export default Banner;
