import React from "react";
import MenuItem from "./MenuItem";

function Menu({ dashMenu, open, handleSidebar, widthScreen }) {
  return (
    <div className="flex flex-1 flex-col overflow-y-auto md:overflow-y-visible pt-5 pb-4 justify-center align-middle items-center z-10">
      <nav className="grid grid-cols-2 sm:flex-1 space-y-1 px-2 sm:grid-cols-none gap-5 sm:gap-0">
        {dashMenu.map((item) => (
          <MenuItem
            item={item}
            key={item.name}
            open={open}
            handleSidebar={handleSidebar}
            widthScreen={widthScreen}
          />
        ))}
      </nav>
    </div>
  );
}

export default Menu;
