import React from "react";
import { FormattedMessage as FM } from "react-intl";

const Footer = ({ colors }) => {
  const bgColor = colors?.colorPrimary || "bg-blue-presik";
  return (
    <footer
      className={`md:px-10 px-4 py-5  text-white w-screen text-center absolute z-20 bottom-0 ${bgColor}`}
    >
      <FM id="app.booking.right_reserved" />{" "}
      <b>
        <a href="https://presik.com" target="_blank" rel="noreferrer">
          <FM id="app.booking.copyright" />
        </a>
      </b>
    </footer>
  );
};

export default Footer;
