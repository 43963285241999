import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import QuickForm from "components/Meta/QuickForm";
import ModalMsg from "components/Modals/ModalMsg";
import ModelGuest from "./ModelGuest";
import mainStore from "store/mainStore";
import proxy from "api/proxy";

function FormGuest(props) {
  // window.scrollTo(0, 0); FIXME
  const ctxView = ModelGuest.ctxView();
  // let [btnDisabled, setBtnDisabled] = useState(true);
  // let [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(null);
  const { store } = mainStore();
  const navigate = useNavigate();

  async function addGuest() {
    // setIsLoading(true);
    store.country = store.nationality;
    await proxy.saveQuery({
      model: ctxView.model,
      storeRec: store,
    });
    setOpenModal({
      msg: "Huesped agregado exitosamente!",
    });
    props.onAdd();
    // setIsLoading(false);
  }

  function onCloseReturn() {
    setOpenModal(null);
    navigate(-1);
  }

  // async function handelCancel() {
  //   setIsLoading(false);
  //   navigate(-1);
  // }

  ctxView.webfields.add_guest.method = addGuest;
  ctxView.webfields.cancel.method = onCloseReturn;

  return (
    <div>
      <QuickForm ctxView={ctxView} level="main" onChangeView={onCloseReturn} />
      {openModal && (
        <ModalMsg
          open={true}
          onClose={onCloseReturn}
          buttons={["close"]}
          type="success"
          msg={openModal.msg}
        />
      )}
    </div>
  );
}

export default FormGuest;
