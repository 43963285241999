import React from "react";
import { useNavigate } from "react-router-dom";

import Footer from "pages/Footer";
import presikLogo from "assets/img/logo_original.png";
import imgHome from "assets/img/home.png";

function HomePage() {
  const navigate = useNavigate();

  function handleLogin() {
    navigate("/login");
  }

  return (
    <div className="h-full px-6 pt-4 md:pt-30 md:px-20 xl:px-20 lg:px-20 bg-cover">
      <div className="block sm:grid sm:grid-cols-2 h-5/6 xl:px-32 xl:py-20 w-full  items-center align-middle">
        <div className="w-full lg:w-3/4 xl:w-3/4 px-4 md:py-10 my-12 ">
          <img src={presikLogo} className="w-52 mx-auto sm:ml-auto" />
          <div className="backdrop-blur-lg bg-white/10 opacity-80 rounded-2xl">
            <h1 className="text-5xl lg:text-7xl xl:text-7xl text-stone-800 font-bold mt-12 sm:mt-16 md:mt-20">
              Bienvenido!
            </h1>
            <p className="text-stone-700 text-2xl lg:text-3xl xl:text-3xl py-10">
              Desde aquí tendrás acceso a nuestras Apps...
            </p>
            <button
              className="w-40 bg-yellow-400 hover:bg-yellow-300 text-black rounded-xl py-3 mt-8 text-xl shadow-xl"
              onClick={handleLogin}
            >
              Login
            </button>
          </div>
        </div>
        <img className="w-48 mx-auto sm:w-10/12" src={imgHome} />
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
