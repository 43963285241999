import React, { useState } from "react";

import BasicModal from "components/Modals/BasicModal";
import QuickTable from "components/Meta/QuickTable";
import AttachmentModel from "./AttachmentModel";
import AllPage from "./AllPage";

function Attachment(props) {
  const { ctxView, activeRecord, onClose } = props;
  const resource = ctxView.model + "," + activeRecord.id;
  const domain = [["resource", "in", [resource]]];
  const [viewAttach, setViewAttach] = useState(false);
  const [open, setOpen] = useState(true);
  const [data, setData] = useState(false);
  const [fileName, setFileName] = useState(false);

  function onClickCell(field, rec) {
    if (rec.data) {
      setViewAttach(true);
      setData(rec.data);
      setFileName(rec.name);
    }
  }

  function handleClose() {
    setViewAttach(false);
  }

  return (
    <>
      {viewAttach && (
        <AllPage
          pdf={data}
          onClose={handleClose}
          open={viewAttach}
          fileName={fileName}
        />
      )}

      <BasicModal
        // onOpen={(value) => setOpen(value)}
        onClose={() => {
          onClose();
          setOpen(false);
        }}
        open={open}
        title={"ir.attachment"}
        width={"w-5/6"}
      >
        <div className="mb-4">
          <QuickTable
            model={ctxView.model}
            onClickCell={onClickCell}
            domain={domain}
            ctxView={AttachmentModel}
          />
        </div>
      </BasicModal>
    </>
  );
}

export default Attachment;
