import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { XMarkIcon } from "@heroicons/react/24/solid";

import { classNames } from "tools/ui";

function FormMessage({ message, handleCloseMessage }) {
  if (!message) return;
  const { type, msg } = message;
  let style = "bg-sky-100 text-sky-700";
  if (type === "error") {
    style = "bg-rose-100 text-red-700";
  }
  return (
    <div
      className={classNames(
        "mx-5 my-2 py-3 px-6 text-sm flex justify-between",
        style,
      )}
    >
      <FM id={msg} key={msg} />
      <XMarkIcon
        className="h-5 w-5 cursor-pointer"
        onClick={handleCloseMessage}
      />
    </div>
  );
}

export default FormMessage;
