import store from "store";

import proxy from "api/proxy";
import fetchLocalAPI from "printing/printing";

async function wizardPrint(rec) {
  const print = async () => {
    const ctxSession = store.get("ctxSession");
    let obj = {};
    let contextArgs = {
      model: "sale.sale",
      method: "get_printing_context",
      args: [{ user_id: ctxSession.user }],
    };
    const { data } = await proxy.methodCall(contextArgs);
    obj["context"] = data;
    const apiPrint = obj["context"].local_printer;
    const saleArgs = {
      model: "sale.sale",
      method: "get_order2print",
      args: [
        {
          sale_id: rec.id,
          repeat: false,
        },
      ],
    };

    const { data: order2print } = await proxy.methodCall(saleArgs);
    console.log();
    obj["orders"] = order2print[0];

    console.log(" contextPrint >>> ", data);
    // let opts = {
    //   method: "POST",
    //   uri: "/print_order",
    //   body: data,
    // };

    const printer = new fetchLocalAPI(apiPrint);
    const { data: result } = await printer.post("/print_order", obj);
    console.log("result Print...", result);

    if (result && result.msg === "ok") {
      const _args = {
        model: "sale.sale",
        method: "mark_commanded",
        args: [
          {
            sale_id: rec.id,
          },
        ],
      };
      obj["result"] = await proxy.method(_args);
    } else {
      window.alert("LA IMPRESION DE COMANDA FALLÓ!");
    }
  };

  await print();
}

export default wizardPrint;
