import React, { useEffect, useState } from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { useDropzone } from "react-dropzone";

import FormField from "./FormField";
import StdButton from "components/Buttons/StdButton";
import proxy from "api/proxy";

const sizeStyle = {
  width: 200,
  height: 200,
};

function CloudImage(props) {
  const [active, setActive] = useState(true);
  const [photo, setPhoto] = useState(null);
  const [upload, setUpload] = useState({});
  const session = proxy.getSession();
  const [cloudConf, setCloudConf] = useState({});
  const getConf = async () => {
    const fields = [
      "cloudinary_api_key",
      "cloudinary_api_secret",
      "cloudinary_upload_preset",
      "cloudinary_upload_url",
    ];
    const cloud_conf = await proxy.search(
      "company.company",
      [["id", "=", session.company]],
      fields,
    );

    setCloudConf(cloud_conf[0]);
  };

  useEffect(() => {
    async function getConfig() {
      await getConf();
    }
    getConfig();
    if (props.value) {
      setActive(false);
      setPhoto(props.value);
    }
  }, [props.value]);

  async function onRemove() {
    const value = null;
    props.onChange(props.name, value);
    setActive(true);
    setPhoto(null);
    setUpload({});
  }

  async function onImageDrop(acceptedFiles) {
    var formData = new FormData();
    if (acceptedFiles.lenght === 0) {
      return;
    }
    const file_ = acceptedFiles[0];
    formData.append("file", file_);
    formData.append("upload_preset", cloudConf.cloudinary_upload_preset);
    const config = {
      method: "POST",
      body: formData,
    };
    const response = await fetch(
      cloudConf.cloudinary_upload_url + "upload",
      config,
    );
    const res = await response.json();
    setUpload(res);
    setActive(false);
    setPhoto(res.secure_url);
    props.onChange(props.name, res.secure_url);
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onImageDrop,
    accept: "image/jpg,image/png,image/jpeg,application/pdf",
    multiple: false,
  });

  return (
    <FormField {...field}>
      {active ? (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div
            height={sizeStyle.height}
            width={sizeStyle.width}
            style={styles.content}
          >
            {props.help && <em style={{ color: "grey" }}>{props.help}</em>}
          </div>
        </div>
      ) : (
        <div className="flex-col">
          {/* {upload?.format === "pdf" ? (
          ) : (
            upload && <image key={props.name} src={upload} width="150" height="150" />
          )} */}
          <StdButton
            onClick={onRemove}
            size="w-full"
            color="blue"
            // content="crm.customer_service.search"
            style="mb-3 my-6 sm:mt-auto md:mt-auto lg:mt-auto"
          >
            {upload && <p> {upload.original_filename}</p>}
            <XCircleIcon className="h-5 w-5" aria-hidden="true" />
          </StdButton>
        </div>
      )}
    </FormField>
  );
}

const styles = {
  photo: {
    marginTop: 10,
  },
  button: {
    marginTop: 5,
  },
  content: {
    textAlign: "center",
    margin: "auto",
    marginTop: 10,
    marginBottom: 10,
    display: "grid",
    alignItems: "center",
    padding: "1rem",
    borderStyle: "solid",
    borderWidth: 2,
    fontSize: 16,
  },
};

export default CloudImage;
