import React, { useState } from "react";
import mainStore from "store/mainStore";

import CreateSale from "./CreateSale";
import ListSale from "./ListSales";
import ModelSale from "./ModelSale";
import Button from "components/Buttons/StdButton";
// import TimeLine from "./TimeLine";

const SaleCallCenter = () => {
  const [viewType, setViewType] = useState("");
  const [title, setTitle] = useState("Crear Orden");
  const { reset, newRecord } = mainStore();
  const ctxViewSale = ModelSale.ctxView();

  function onClickAction(event, name) {
    let name_ = name;
    let title = "Registro de pedidos";
    if (name_ === "cancel") {
      name_ = "";
    } else if (name_ === "list") {
      title = "Ordenes";
    }
    setViewType(name_);
    setTitle(title);
    reset(true);
    newRecord(ctxViewSale);
  }

  return (
    <div className="container mx-auto my-4 p-3" role="main">
      <h3 className="text-center leading-normal font-semibold text-4xl mt-0 mb-3 text-gray-800">
        {title}
      </h3>
      {/* <TimeLine /> */}
      <div className="grid md:grid-cols-3 grid-cols-1 gap-5">
        <Button
          style={"  "}
          onClick={onClickAction}
          name={"create"}
          disabled={viewType === "create"}
          color={"green"}
          size={"w-70 h-16"}
          content={"sale.callcenter.create"}
        />
        <Button
          style={"  "}
          onClick={onClickAction}
          name={"list"}
          disabled={viewType === "create"}
          color={"blue"}
          size={"w-70 h-16"}
          content={"sale.callcenter.view"}
        />
        <Button
          style={"  "}
          onClick={onClickAction}
          name={"cancel"}
          disabled={viewType !== "create"}
          color={"rose"}
          size={"w-70 h-16"}
          content={"sale.callcenter.cancel"}
        />
      </div>
      {viewType === "create" ? (
        <CreateSale ctxView={ctxViewSale} />
      ) : viewType === "list" ? (
        <ListSale />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SaleCallCenter;
