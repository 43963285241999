// Account Statement Report

const getView = () => {
  let ctxView = {
    model: "account_statement_co.advanced.start",
    reportName: "account_statement_co.advanced_report",
    webfields: {
      operation_center: {
        type: "many2one",
        model: "company.operation_center",
        searchable: true,
        required: true,
      },
      date: {
        type: "date",
        required: true,
      },
      // Add in future for many2many
      // shops: {
      //   type: "many2many",
      //   model: "sale.shop",
      //   searchable: true,
      // },
    },
    webtree: [],
    webform: [{ name: "operation_center" }, { name: "date" }],
  };
  return ctxView;
};

export default { ctxView: getView };
