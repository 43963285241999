import React from "react";
import CardItemProductCategory from "../Components/CardItemProductCategory";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CardproductCategory = ({ data, currentCategory, handleCategory }) => {
  // Assuming your data is an array of categories
  const itemsPerSlide = 5;

  const renderCategoryGrid = () => {
    const categoryGrid = [];

    for (let i = 0; i < data.length; i += itemsPerSlide) {
      const categorySlice = data.slice(i, i + itemsPerSlide);

      categoryGrid.push(
        <div key={i} className="grid grid-cols-5 gap-5">
          {categorySlice.map((item, key) => (
            <CardItemProductCategory
              key={key}
              data={item}
              currentCategory={currentCategory}
              handleCategory={handleCategory}
            />
          ))}
        </div>,
      );
    }

    return categoryGrid;
  };

  return (
    <div className="carousel-wrapper m-3 lg:mx-4">
      {data && data.length > 0 ? (
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          emulateTouch={true}
          showArrows={false}
          renderIndicator={(clickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  className="custom-dot active"
                  aria-label={`Slide ${label} - Current Slide`}
                />
              );
            }
            return (
              <li
                className="custom-dot"
                onClick={clickHandler}
                onKeyDown={clickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label}`}
                aria-label={`Slide ${label}`}
              />
            );
          }}
        >
          {renderCategoryGrid()}
        </Carousel>
      ) : (
        <p>No categories available</p>
      )}
    </div>
  );
};

export default CardproductCategory;
