import React, { useState, Fragment } from "react";

import BasicModal from "components/Modals/BasicModal";
import TablesModal from "./TablesModal";

function TableDisplay({ attrs, field, record }) {
  const [openModal, setOpenModal] = useState(false);

  function onClose() {
    setOpenModal(false);
  }

  function onClick() {
    setOpenModal(true);
  }

  const name = record.table_assigned?.rec_name || "Mesa";

  return (
    <Fragment>
      <div
        className="flex mx-4 my-5 h-10 bg-orange-100 outline outline-amber-400 outline-2 outline-offset-2 rounded-full text-stone-600 active:scale-95 cursor-pointer shadow-md"
        onClick={() => onClick()}
      >
        <div className="mx-auto my-auto text-stone-600 text-md font-bold">
          {name}
        </div>
      </div>
      <TablesModal open={openModal} onClose={onClose} />
    </Fragment>
  );
}

export default TableDisplay;
