import React from "react";

import version from "version";

function Footer() {
  return (
    <footer className="mx-auto py-5 bottom-0">
      <div className="mx-auto">
        <p className="text-center text-gray-400">
          Copyright© 2024 All rights reserved.
        </p>
        <p className="text-center text-gray-400">Version {version}</p>
        <a
          className="block text-center text-gray-600 hover:text-yellow-400"
          href="http://www.presik.com"
        >
          PRESIK SAS
        </a>
      </div>
    </footer>
  );
}

export default Footer;
