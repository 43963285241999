import React, { useState } from "react";
import ModelRental from "./ModelRental"; // Ensure this import is correct
import Board from "components/Meta/Board"; // Ensure this import is correct
import Screen from "components/Meta/Screen"; // Ensure this import is correct
import PanelRight from "components/Panel/PanelRight"; // Ensure this import is correct
import date from "date-and-time";
import DateRangeFilter from "components/Tools/DateRangeFilter"; // Ensure this import is correct

const ScreenRental = (props) => {
  const ctxView = ModelRental.ctxView(props.config); // Ensure rental.ctxView is a valid function or property

  const [filterDay, setFilterDay] = useState(
    date.format(new Date(), "YYYY-MM-DD"),
  );
  const [statusSection, setStatusSection] = useState(true);

  const handleChangeDate = (value) => {
    let daySelected = date.format(new Date(value), "YYYY-MM-DD");
    setFilterDay(daySelected);
    console.log(filterDay);
  };

  return (
    <div>
      <DateRangeFilter handleChangeDate={handleChangeDate} />

      {statusSection && (
        <div
          className={`flex ${
            statusSection ? "flex-row justify-between" : "flex-col"
          }`}
        >
          <div
            className={
              statusSection
                ? "md:w-[70vw] xl:w-[90vw]"
                : "md:w-12/12 relative pr-10"
            }
          >
            <Board ctxView={ctxView} />
          </div>
          {/* <div className="w-[22vw]">
            <PanelRight
              position={"right"}
              widgets={["status", "numPeople"]}
              title="INFO RENTAL"
              bgColor={statusSection ? "bg-gray-100" : "bg-blue-presik"}
              status={statusSection}
              handleChangeStatus={setStatusSection}
              displayOpen={true}
              style="bg-gray-100 px-2 custom-transition duration-500 sticky w-full"
            ></PanelRight>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default ScreenRental;
