// Folio Charge model

const getView = () => {
  let DictCtxView = {
    model: "hotel.folio.charge",
    form_action: [], // options: ['save', 'delete']
    table_action: [], // options: ['open', 'delete', 'edit', 'add']
    domain: [], // Options: null or valid domain
    orderBy: [["sale_date", "ASC"]],
    limit: 100,
    selectable: null, // Options for table rows: null, multi, one
    webfields: {
      sale_date: { type: "date", readOnly: true },
      quantity: {
        type: "integer",
        readOnly: true,
      },
      amount: { type: "numeric", readOnly: true },
      status: { type: "char", readOnly: true },
      product: {
        type: "many2one",
        model: "product.product",
        readOnly: true,
      },
      unit_price_w_tax: {
        type: "numeric",
        readOnly: true,
      },
    },
    webtree: [
      { name: "sale_date", width: "10%" },
      { name: "product", width: "25%" },
      { name: "quantity", width: "20%" },
      { name: "unit_price_w_tax", width: "20%" },
      { name: "amount", width: "25%" },
      { name: "status", width: "25%" },
    ],
    webform: [
      { name: "product" },
      { name: "sale_date" },
      { name: "quantity" },
      { name: "unit_price_w_tax" },
      { name: "amount" },
      { name: "status" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
