import proxy from "api/proxy";

async function fetchToAPI({ report, moment }) {
  const kwargs = {
    moment: moment, // current or previous
  };
  const obj = {
    model: report.model,
    method: report.method,
    args: [kwargs],
  };
  const { data, error } = await proxy.methodCall(obj);
  return data;
}

export default fetchToAPI;
