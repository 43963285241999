// Party model

const getView = () => {
  let DictCtxView = {
    model: "party.party",
    form_action: [],
    table_action: [],
    webfields: {
      name: { type: "char", searchable: true },
      id_number: { type: "char", searchable: true },
    },
    webtree: [
      { name: "name", width: "30%" },
      { name: "id_number", width: "10%" },
    ],
    webform: [{ name: "name" }, { name: "id_number" }],
  };

  return DictCtxView;
};

export default { ctxView: getView };
