import React from "react";

import Divider from "components/MeForm/Divider";
import { useQuery } from "@tanstack/react-query";
import proxy from "api/proxy";

function Sources({ section, record, onClick }) {
  const sources = useQuery(["sources"], async () => {
    const { data } = await proxy.search(
      "sale.source",
      [["active", "=", true]],
      ["name"],
    );
    return data;
  });
  let selected = record?.id ?? "";

  function handleClick(value) {
    selected = value.id;
    onClick(section, value);
  }
  return (
    <div className="mt-3" id={section}>
      <Divider text={`sale_call_center.${section}`} key={section} />
      <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mb-4">
        {sources.data?.map((source) => {
          return (
            <div
              className={
                "rounded-md cursor-pointer shadow-sm hover:opacity-50 text-center py-8 " +
                (source.id !== selected ? "bg-orange-100" : "bg-orange-300")
              }
              key={source.id}
              onClick={() => handleClick(source)}
            >
              <p key={source.id} className="pointer-events-none">
                {source.name}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default Sources;
