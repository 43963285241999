import React from "react";
import { FormattedMessage as FM } from "react-intl";

import { useWizardStore } from "store/wizardStore";

const CardTotal = () => {
  const { record } = useWizardStore();
  return (
    <div className="mx-12 w-1/3 px-8 pt-4 text-center text-2xl text-white bg-sky-900 h-36 rounded-xl">
      <p className="mx-auto font-bold">
        <FM id="sale_pos.money_count.total" />
      </p>
      <p className="mt-8 mx-auto">{record.total || 0}</p>
    </div>
  );
};

export default CardTotal;
