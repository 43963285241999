import React from "react";

import CardSidePanel from "components/Panel/CardSidePanel";
import { classNames } from "tools/ui";

const base =
  "bg-white border-gray-100 shadow-sm p-3 rounded-lg overflow-hidden grid justify-between gap-3";

function SectionSidePanel({ data }) {
  const cols = `grid-cols-${data.length}`;
  return (
    <div key="section-side-panel" className={classNames(base, cols)}>
      {data.map((card) => (
        <CardSidePanel key={card.name} {...card} />
      ))}
    </div>
  );
}

export default SectionSidePanel;
