import React from "react";

import FormField from "components/MeForm/FormField";

/**
 * Component Text Field
 *
 * @param {string} name property define name field
 * @param {string} value property value of field optional
 * @param {string} accept property accept of field optional ex: ".xls,.xlsx"
 * @param {function} callback property callback(nameField, valueField, record, callback)
 * @returns {component} return a component of type text field
 */

function FileField(props) {
  const { field, attrs, data } = props;
  const { name, readOnly, onChange } = field;
  // let [editedValue, setValue] = useState(props.value || {});

  // useEffect(() => {
  //   setValue(props.value || {});
  // }, [props.value]);

  function handleFile(event) {
    const selectedFiles = event.target.files;
    const reader = new FileReader();

    reader.onload = () => {
      const value = {
        filename: selectedFiles[0].name,
        data: btoa(reader.result),
      };
      setValue(value);
      props.onChange(props.name, value, null, null);
    };

    if (selectedFiles && selectedFiles.length > 0) {
      reader.readAsDataURL(selectedFiles[0]);
    }
  }

  return (
    <FormField {...field}>
      <input
        className={
          "relative m-0 block w-full min-w-0 bg-white flex-auto rounded-md border border-solid border-slate-300 bg-clip-padding px-3 py-[0.40rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.40rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
        }
        disabled={readOnly}
        id={name}
        key={name}
        name={name}
        type={"file"}
        accept={props.accept ?? "*"}
        onChange={(event, data) => handleFile(event, data)}
      />
    </FormField>
  );
}

export default FileField;
