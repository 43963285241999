const TYPE_DOC = [
  { id: "passport", name: "Passport" },
  { id: "driver_license", name: "Driver License" },
  { id: "medicare_card", name: "Medicare Card" },
];
const SUBDIVISION = [
  { id: 1, cod: "ACT", name: "Australian Capital Territory" },
  { id: 2, cod: "NSW", name: "New South Wales" },
  { id: 3, cod: "NT", name: "Northern Territory" },
  { id: 4, cod: "QLD", name: "Queensland" },
  { id: 5, cod: "SA", name: "South Australia" },
  { id: 6, cod: "TAS", name: "Tasmania" },
  { id: 7, cod: "VIC", name: "Victoria" },
  { id: 8, cod: "WA", name: "Western Australia" },
];

export { TYPE_DOC, SUBDIVISION };
