import React, { useEffect } from "react";
import store from "store";

import StdButton from "components/Buttons/StdButton";
import Footer from "pages/Footer";
import { useNavigate } from "react-router-dom";
import notFoundImg from "assets/img/404.png";

const NotFound = () => {
  const navigate = useNavigate();

  function handleClick() {
    navigate("/home");
  }

  useEffect(() => {
    const session = store.get("ctxSession", null);
    if (session && session.session) {
      navigate("/dash/", { replace: true });
    }
  }, []);

  return (
    <div className="pt-24 text-center px-24 h-full">
      <img className="mx-auto" alt="not_found" width={100} src={notFoundImg} />
      <h1 className="mx-auto pt-10 text-6xl text-gray-600 font-black">
        Page not found
      </h1>
      <h2 className="mx-auto py-5 text-xl text-gray-600">
        Uuups!... Sorry not found! please check your url in the address bar and
        try again
      </h2>
      <StdButton style="mx-auto" size="w-60" onClick={handleClick}>
        <p>Go back home</p>
      </StdButton>
      <div className="pt-32 h-2/5" />
      <Footer />
    </div>
  );
};

export default NotFound;
