import React from "react";
import ItemRestaurant from "./ItemRestaurant";
import TitleSection from "../../ui/TitleSection";

const SectionRestaurant = ({ data = [], baseUrl }) => {
  return (
    <section className="container mx-auto mb-20">
      <TitleSection text="TIENDAS" />
      <div className="grid md:grid-cols-4 gap-10 px-3">
        {data &&
          data.map((item, key) => (
            <ItemRestaurant key={key} data={item} baseUrl={baseUrl} />
          ))}
      </div>
    </section>
  );
};

export default SectionRestaurant;
