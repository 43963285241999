import React, { useState } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

const ProductAttributeFilter = ({
  attributes,
  selectedAttributes,
  onSelectAttribute,
}) => {
  const [openDisclosure, setOpenDisclosure] = useState(null);
  const [attrs, setAttrs] = useState([]);

  const handleAttribute = (key, code, value) => {
    const attrs_ = [...attrs];
    let selectedAttributes_ = { ...selectedAttributes };
    const index = attrs_.indexOf(key);
    if (index !== -1) {
      attrs_.splice(index, 1);
      let attribute = selectedAttributes_[code];
      const index_ = attribute.indexOf(value);
      attribute.splice(index_, 1);
    } else {
      attrs_.push(key);
      if (Object.hasOwn(selectedAttributes_, code)) {
        selectedAttributes_[code].push(value);
      } else {
        selectedAttributes_[code] = [value];
      }
    }
    setAttrs(attrs_);
    onSelectAttribute(selectedAttributes_);
  };

  return (
    <div className=" bg-white p-3 rounded-md shadow">
      <h2 className="text-xl font-semibold mb-2">Filtrar por Atributos</h2>
      <div className="space-y-2">
        {attributes?.map((attribute, index) => (
          <Disclosure key={attribute.id}>
            {() => (
              <>
                <Disclosure.Button
                  className="flex w-full justify-between rounded-md bg-slate-100 px-4 py-2 text-left text-sm font-medium text-slate-900 hover:bg-slate-200 focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75"
                  onClick={() => setOpenDisclosure(index)}
                >
                  <span>{attribute.frontend_label}</span>
                  <ChevronUpIcon
                    className={`${
                      index == openDisclosure ? "rotate-180 transform" : ""
                    } h-5 w-5 text-slate-500`}
                  />
                </Disclosure.Button>
                <Transition
                  show={index == openDisclosure}
                  enter="transition-opacity duration-200"
                  enterFrom="opacity-50"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  {(ref) => (
                    <Disclosure.Panel ref={ref}>
                      <ul>
                        {attribute.options.map((option) => {
                          const key =
                            attribute.attribute_code + "_" + option.value;
                          const code = attribute.attribute_code;
                          const value = option.name;
                          return (
                            <li
                              key={option.value}
                              className="flex items-center p-2 space-x-2"
                            >
                              <input
                                name={option.name}
                                id={key}
                                type="checkbox"
                                onChange={() =>
                                  handleAttribute(key, code, value)
                                }
                                checked={attrs.includes(key)}
                                className="w-5 h-5 bg-slate-400"
                              ></input>
                              <label htmlFor={key}>{option.label}</label>
                            </li>
                          );
                        })}
                      </ul>
                    </Disclosure.Panel>
                  )}
                </Transition>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
};

export default ProductAttributeFilter;
