import React, { useEffect, useState } from "react";
import dates from "tools/dates";

function Timer({ start, stop, handleTimer }) {
  const [time, setTime] = useState(0);

  useEffect(() => {
    let intervalId;

    if (start) {
      intervalId = setInterval(() => {
        setTime(dates.getNow() - start);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }
    if (stop) {
      handleTimer();
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [start, stop]);

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 1000 / 60);
    const remainingSeconds = Math.floor((seconds / 1000) % 60);

    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  return (
    <div className="fixed top-32 right-2 transform -translate-y-1/2">
      <div className="bg-gray-800 bg-opacity-50 text-white px-4 py-1 rounded-full">
        <div className="text-lg">{formatTime(time)}</div>
      </div>
    </div>
  );
}

export default Timer;
