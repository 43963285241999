import React from "react";

import Table from "components/MeTable/Table";
import HeaderCell from "components/MeTable/HeaderCell";
import BodyTable from "components/MeTable/BodyTable";
import Divider from "components/MeForm/Divider";

const ctxViewTaxes = {
  model: "sale.taxes",
  webfields: {
    description: {
      type: "char",
      readOnly: true,
    },
    base: { type: "numeric", readOnly: true },
    percent: {
      type: "numeric",
      readOnly: true,
      decimalPlaces: 2,
      widget: "percent",
    },
    value_tax: { type: "numeric", readOnly: true },
  },
  webtree: [{ name: "description" }, { name: "base" }, { name: "value_tax" }],
};

function Taxes({ field, record }) {
  const sale_taxes = record?.sale_taxes;

  let taxes = [];
  if (sale_taxes && Array.isArray(sale_taxes)) {
    taxes = record?.sale_taxes?.filter((item) => {
      if (!item.not_printable) return item;
    });
  }

  return (
    <div
      id="col-form"
      className="mt-1 mb-2 w-full md:col-span-2 lg:col-span-2 xl:col-span-2"
    >
      <Divider field={{ text: "sale.taxes" }} />
      <Table>
        <thead className="sticky top-0 leading-6 ">
          <tr className="bg-amber-100">
            {ctxViewTaxes.webtree.map((field, index) => (
              <HeaderCell
                key={index}
                index={index}
                field={field}
                ctxView={ctxViewTaxes}
              />
            ))}
          </tr>
        </thead>
        <BodyTable
          ctxView={ctxViewTaxes}
          records={taxes ?? []}
          fieldName={field.name}
        />
      </Table>
    </div>
  );
}
export default Taxes;
