import React from "react";
import { useQuery } from "@tanstack/react-query";
import { BuildingStorefrontIcon } from "@heroicons/react/24/outline";

import Divider from "components/MeForm/Divider";
import proxy from "api/proxy";

function Shops({ section, record, onClick }) {
  const shops = useQuery(["shops"], async () => {
    const { data } = await proxy.search(
      "sale.shop",
      [],
      [
        "name",
        "address.street",
        "product_categories",
        "product_categories.childs",
        "party.name",
      ],
    );
    return data ?? [];
  });

  let selected = record?.id ?? "";

  function handleClick(value) {
    selected = value.id;
    onClick(section, value);
  }

  return (
    <div className="mt-3" id={section}>
      <Divider text={`sale_call_center.${section}`} key={section} />
      <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mb-4">
        {shops.data?.map((shop) => {
          return (
            <div
              className={
                "flex cursor-pointer shadow-sm justify-center items-center hover:opacity-50 py-8 rounded-md " +
                (shop.id !== selected ? "bg-green-100" : "bg-green-300")
              }
              key={shop.id}
              onClick={() => handleClick(shop)}
            >
              <BuildingStorefrontIcon className="w-6 h-6 mx-4 " color="green" />
              <div>
                <p className="pointer-events-none text-gray-900 text-lg">
                  {shop.name}
                </p>
                <p className="pointer-events-none text-gray-600 text-sm">
                  {shop["address."]?.street}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default Shops;
