import React from "react";

function Table({ children }) {
  return (
    <table className="table-fixed border-red-500 min-w-full divide-y-2 divide-amber-200 mb-6 overflow-scroll">
      {children}
    </table>
  );
}

export default Table;
