import React, { Fragment } from "react";
import { signal } from "@preact/signals-react";
import { Transition } from "@headlessui/react";
// import imgDefault from "../../../../../assets/img/default-image.jpg";
import PureModal from "components/Modals/PureModal";
import ModalProduct from "./ModalProduct";
import { DEFAULT_TEXT } from "ext-apps/WebMelhous/fakeData";
import tools from "tools/functions";
const imgDefaultCat =
  "https://cocinemosjuntos.com.co/media/mageplaza/blog/post/a/l/almuerzos-caseros-ricos-y-facil.jpg";

const imgDefault =
  "https://magefesa.com.co/wp-content/uploads/2021/04/huevos.jpg";

const openModal = signal(false);
const selectedProduct = signal();
const formatter = Intl.NumberFormat("en-US");

const MenuItem = ({ data }) => {
  const handlerClickProduct = (product) => {
    openModal.value = true;
    selectedProduct.value = product;
  };

  return (
    <Fragment>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 mb-9">
        {data &&
          data.map((category, index) => (
            <Transition
              key={index}
              as={React.Fragment}
              show={true}
              enter="transition ease-in-out duration-300 transform opacity-0"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in-out duration-300 transform opacity-0"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              {(show) => (
                <div
                  className={` px-3 py-4 rounded-lg md:w-full bg-[#f1eae2]  ${
                    show ? "opacity-100" : "opacity-0"
                  }`}
                  key={index}
                  id={`category-${index}`}
                >
                  <div className=" border border-yellow-500 h-full rounded-md ">
                    <img
                      src={imgDefaultCat}
                      className="w-full h-56 object-cover"
                    />
                    <div className="p-5">
                      <h3 className="md:text-2xl relative -top-11 w-fit  bg-black  py-2 text-yellow-500 melhous-menu-title rounded-full uppercase px-10">
                        {category?.name}
                      </h3>
                      <p className="text-gray-900 melhous-menu-description relative -top-6 ">
                        {DEFAULT_TEXT.description_cat}
                      </p>
                      <div className="">
                        {category["products."].map((product, productIndex) => (
                          <div
                            key={productIndex}
                            className="flex items-end rounded-lg overflow-hidden active:scale-95 transition duration-100 cursor-pointer relative"
                            onClick={() => handlerClickProduct(product)}
                          >
                            <div className=" to-transparent  pb-3 pt-5 w-full text-gray-900 flex flex-col md:flex-row space-x-5 items-center">
                              <div className="w-full md:w-auto">
                                <img
                                  src={
                                    product["images."][0]
                                      ? product["images."][0].image_url
                                      : imgDefault
                                  }
                                  className="md:w-[200px]  w-full h-28 object-cover rounded-md max-w-none"
                                />
                              </div>
                              <div className="w-full text-white mt-4 md:mt-0">
                                <div className=" md:flex md:space-x-5 items-center">
                                  <h3 className="text-sm font-semibold md:min-w-max text-gray-700 melhous-menu-title uppercase  ">
                                    {product.name}
                                  </h3>
                                  <div className="w-full border-b border-dashed border-gray-800 hidden md:block"></div>
                                  <div className=" md:relative  top-0 right-0  p-0 md:p-0 rounded-full">
                                    <span className="font-medium text-xl text-black melhous-menu-title space-x-1 md:space-x-2">
                                      <span className="md:text-xs text-gray-700 relative md:-top-2 ">
                                        $
                                      </span>
                                      <span>
                                        {tools.fmtMoneyLocal(
                                          product.list_price,
                                          0,
                                        )}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <p className="text-gray-600 text-[15px] melhous-menu-description line-clamp-2">
                                  {product.description ||
                                    DEFAULT_TEXT.description}
                                  estoy en una descripcion corta de el problema
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Transition>
          ))}
      </div>
      <PureModal
        open={openModal.value}
        onClose={() => {
          openModal.value = false;
        }}
        backdrop={true}
        width="md:max-w-5xl"
      >
        <ModalProduct product={selectedProduct.value} imgDefault={imgDefault} />
      </PureModal>
    </Fragment>
  );
};

export default MenuItem;
