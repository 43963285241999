import React, { useRef, useState } from "react";
import date from "date-and-time";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import { CalendarDaysIcon } from "@heroicons/react/20/solid";

import { classNames, colors } from "tools/ui";
import upStore from "store/upStore";

const fmt = "YYYY-MM-DD";
const style = "flatpickr  outline-none w-full py-2 px-2 rounded-md wrap";
const styleIcon = "text-start mr-2 h-6 w-6 my-auto text-zinc-600";
const styleDiv =
  "flex outline-none border border-slate-300 rounded-md focus:border-sky-400 h-10";

function DateCell(props) {
  const { field, attrs, data } = props;
  if (!field.editable) return data;

  const { name, readOnly } = field;
  const { level } = attrs;

  const fp = useRef(null);
  const [state, setState] = useState(null);
  let initialDate = "";
  if (data.value) {
    if (typeof data.value.getMonth === "function") {
      initialDate = date.format(data.value, fmt);
    } else {
      initialDate = data.value;
    }
  }
  let dateRef = useRef(null);
  dateRef.current = initialDate;

  function openCalendar() {
    if (state) {
      fp.current.flatpickr.close();
      setState(false);
    } else {
      setState(true);
      fp.current.flatpickr.open();
    }
  }

  function handleClose() {
    setState(false);
  }

  function handleDate(rangeDates) {
    if (rangeDates.length === 0) return;
    const _value = rangeDates[0];
    const value = date.format(_value, fmt);
    upStore(level, {
      fieldName: name,
      value: value,
      field: field,
    });
    if (field.onChange) {
      field.onChange(props.name, value);
    }
  }

  let color = "bg-white";
  if (fp.current) {
    fp.current.flatpickr.input.disabled = readOnly;
    if (state === null) {
      fp.current.flatpickr.close();
    }
  }
  if (readOnly) {
    color = colors.readOnly;
  }

  return (
    <div id="div-flatpickr" className={classNames(styleDiv, color)}>
      <Flatpickr
        ref={fp}
        className={classNames(style, color)}
        value={dateRef.current}
        onChange={handleDate}
        onClick={openCalendar}
        onClose={handleClose}
      />
      <CalendarDaysIcon
        onClick={openCalendar}
        className={classNames(styleIcon, color)}
        aria-hidden="true"
      />
    </div>
  );
}

export default DateCell;
