import { HomeIcon } from "@heroicons/react/20/solid";
import React from "react";

const Breadcrumb = ({ categories, category, handleCategory }) => {
  let breadcrumbTrail = [];

  const buildBreadcrumbTrail = (categories) => {
    let categories_ = categories;
    if (category) {
      const parentIds = category.parent_ids;
      if (parentIds.length > 0 && categories_) {
        parentIds.map((parent) => {
          const item = categories_.find((item) => item.id === parent);
          breadcrumbTrail.push(item);
          categories_ = item.children;
        });
      }
      breadcrumbTrail.push(category);
    }
  };

  buildBreadcrumbTrail(categories);

  return (
    <div className="flex my-4" aria-label="Breadcrumb">
      <div className="flex overflow-hidden rounded-lg border border-gray-200 text-gray-600 items-center pl-2">
        {category && (
          <HomeIcon
            className="w-8 h-8"
            stroke="currentColor"
            strokeWidth={1.5}
            fill="none"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            onClick={() => handleCategory()}
          />
        )}

        {breadcrumbTrail.map((crumb, index) =>
          index % 2 === 0 ? (
            <span
              key={crumb.id}
              className="relative flex h-10 items-center gap-1.5 bg-gray-100 px-4 transition hover:text-gray-900"
            >
              {index > 0 && (
                <span className="absolute inset-y-0 -start-px h-10 w-4 bg-gray-300 [clip-path:_polygon(0_0,_0%_100%,_100%_50%)] rtl:rotate-180"></span>
              )}
              <button
                className="bg-gray-100 px-2"
                onClick={() => handleCategory(crumb)}
              >
                {crumb.name}
              </button>
            </span>
          ) : (
            <span
              key={crumb.id}
              className="relative flex h-10 items-center gap-1.5 bg-gray-300 px-4 transition hover:text-gray-900"
            >
              <button
                className="bg-gray-300 px-2"
                onClick={() => handleCategory(crumb)}
              >
                {crumb.name}
              </button>
              <span className="absolute inset-y-0 -start-px h-10 w-4 bg-gray-100 [clip-path:_polygon(0_0,_0%_100%,_100%_50%)] rtl:rotate-180"></span>
            </span>
          ),
        )}
      </div>
    </div>
  );
};

export default Breadcrumb;
