import React, { Fragment } from "react";
import { useRecords } from "hooks/records";
import funcs from "tools/functions";
import FullTable from "components/MeTable/FullTable";

const ctxViewMove = {
  model: "stock.move",
  row_selectable: false,
  target: "shipment",
  form_action: [],
  table_action: [],
  webfields: {
    product: {
      type: "many2one",
      model: "product.product",
      attrs: ["id", "default_uom.name", "name"],
      required: true,
    },
    quantity: { type: "float", decimalPlaces: 2, required: true },
    uom: { type: "many2one", model: "product.uom", readOnly: true },
    from_location: {
      type: "many2one",
      model: "stock.location",
    },
    to_location: {
      type: "many2one",
      model: "stock.location",
    },
    state: {
      type: "char",
      translate: true,
      readOnly: true,
    },
  },
  webtree: [
    { name: "product", width: "60%" },
    { name: "uom", width: "10%" },
    { name: "quantity", width: "20%" },
    { name: "state", width: "20%" },
  ],
  webform: [{ name: "product" }, { name: "uom" }, { name: "quantity" }],
};

const ctxView = {
  model: "stock.shipment.out",
  selectable: false, // options: multi - one - false
  limit: 50,
  form_action: ["open"],
  table_action: ["open"],
  webfields: {
    number: { type: "char", readOnly: true },
    reference: { type: "char", readOnly: true },
    effective_date: {
      type: "date",
      readOnly: true,
    },
    planned_date: {
      type: "date",
      readOnly: true,
    },
    state: {
      type: "char",
      readOnly: true,
      translate: true,
    },
    invoice_state: {
      type: "char",
      readOnly: true,
      translate: true,
    },
    warehouse_storage: {
      type: "many2one",
      model: "stock.location",
      readOnly: true,
    },
    moves: {
      type: "one2many",
      model: "sale.line",
      ctxView: ctxViewMove,
      required: true,
      readOnly: { state: ["quotation"] },
    },
  },
  webform: [
    {
      id: "infoShipment",
      grid: [
        { name: "number" },
        { name: "reference" },
        { name: "effective_date" },
        { name: "planned_date" },
      ],
      size: [1, 4],
      span: "col-span-2",
    },
    // { name: "number" },
    // { name: "reference" },
    // { name: "effective_date" },
    // { name: "planned_date" },
    { name: "state" },
    { name: "warehouse_storage" },
    { name: "moves" },
  ],
  webtree: [
    { name: "number", width: "20%" },
    { name: "reference", width: "20%" },
    { name: "effective_date", width: "20%" },
    { name: "planned_date", width: "20%" },
    { name: "state", width: "20%" },
    { name: "warehouse_storage", width: "20%" },
  ],
};

function Shipment({ record, name }) {
  const domain = [["id", "in", record[name] ?? []]];
  const model =
    name === "shipments" ? "stock.shipment.out" : "stock.shipment.out.return";
  const fields_names = funcs.getViewFields(ctxView, "list");
  let { data, isLoading } = useRecords(model, domain, fields_names);

  const ctxViewShipment = { ...ctxView, domain, model };
  return (
    <Fragment>
      <FullTable
        records={data ? data : []}
        model={model}
        ctxView={ctxViewShipment}
      />
    </Fragment>
  );
}

export default Shipment;
