let apiHost = process.env.REACT_APP_TRYTON_API_HOST;
let apiPort = process.env.REACT_APP_TRYTON_API_PORT;

const api_key = process.env.REACT_APP_TRYTON_API_KEY;
const api_openpay = process.env.OPENPAY_API_KEY;

const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
const CLOUDINARY_UPLOAD_URL = process.env.REACT_APP_CLOUDINARY_UPLOAD_URL;
const CLOUDINARY_UPLOAD_URL2 = process.env.REACT_APP_CLOUDINARY_UPLOAD_URL2;
const CLOUDINARY_API_KEY = process.env.REACT_APP_CLOUDINARY_API_KEY;
const CLOUDINARY_API_SECRET = process.env.REACT_APP_CLOUDINARY_API_SECRET;
const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

let http = "http";

console.log("running on :", process.env.REACT_APP_ENV);
if (process.env.REACT_APP_ENV === "production") {
  http = "https";
}
console.log("Api connection :", apiHost, apiPort);
// let api = `${http}://${apiHost}:${apiPort}/`;
let api = `${http}://${apiHost}:${apiPort}/`;

module.exports = {
  api,
  api_key,
  api_openpay,
  CLOUDINARY_UPLOAD_PRESET,
  CLOUDINARY_UPLOAD_URL,
  CLOUDINARY_UPLOAD_URL2,
  CLOUDINARY_API_SECRET,
  CLOUDINARY_API_KEY,
  GOOGLE_MAP_API_KEY,
};
