import React from "react";

import ModelMaintenance from "./ModelMaintenance";
import Board from "components/Meta/Board";

const ScreenMaintenance = (props) => {
  const ctxView = ModelMaintenance.ctxView(props.config);
  return <Board ctxView={ctxView} />;
};

export default ScreenMaintenance;
