import React from "react";
import { FormattedMessage as FM } from "react-intl";

function Paragraph(props) {
  const { field, data } = props;
  let _value;
  if (field.translate) {
    _value = <FM id={`model.${field.model}.${field.name}`} />;
  } else {
    _value = data.value;
  }

  return (
    <p key={_value} className="mt-2 text-zinc-500 text-xl">
      {_value}
    </p>
  );
}

export default Paragraph;
