function checkIncluded(r, store) {
  return store.includes(r);
}

const validateForm = (action, storeData, required) => {
  let result = true;
  let msg = "board.missing_required_fields";
  let storeKeys = [];
  Object.entries(storeData).forEach(([k, v]) => {
    if (v && v !== "") {
      storeKeys.push(k);
    }
  });
  if (action === "create") {
    if (required) {
      result = required.every((r) => checkIncluded(r, storeKeys));
    } else {
      msg = "ok";
    }
  } else {
    for (const fieldReq of required) {
      const value = storeData[fieldReq];
      if (!value || value === "") {
        console.log("Missing this required field: ", fieldReq);
        result = false;
        break;
      }
    }
  }
  if (result) {
    msg = "ok";
  }
  return msg;
};

const checkRequiredForm = (storeData, storeRequired) => {
  // This method to check the form and return true if all required
  // fields are filled, otherwise return false
  let result = false;
  let storeKeys = [];
  Object.entries(storeData).forEach(([k, v]) => {
    if (v instanceof Map) {
      if (v.size > 0) {
        storeKeys.push(k);
      }
    } else if (v && v !== "") {
      storeKeys.push(k);
    }
  });
  if (storeRequired) {
    result = storeRequired.every((r) => storeKeys.includes(r));
  }
  return result;
};

export { validateForm, checkRequiredForm };
