import React, { useState, useEffect } from "react";
import proxy from "api/proxy-old";
import ModalMsg from "components/Modals/ModalMsg";
import StdButton from "components/Buttons/StdButton";
import { MapPinIcon } from "@heroicons/react/20/solid";
import { FormattedMessage as FM } from "react-intl";
import { Link } from "react-router-dom";

const classLabel =
  "text-lg mr-5 text-gray-900 uppercase rounded-full font-bold";

const BookingConfirmation = ({ id }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  const handleCreateBooking = async () => {
    try {
      const _store = {};
      const fields = [
        "first_name",
        "mobile",
        "last_name",
        "doc_number",
        "email",
        "type_document",
        "state",
        "booking_date",
        "address",
        "start_date",
        "end_date",
      ];

      const fieldsNames = id;
      _store.state = "booking";
      _store.id = id;
      let searchId = await await proxy.browse(
        "rental.booking",
        [fieldsNames],
        fields,
      );
      let data = { ...searchId[0] };
      const searchId_ = { ...searchId[0] };
      searchId_.fullName = searchId[0].first_name + " " + searchId[0].last_name;
      delete searchId_.id;
      delete searchId_.first_name;
      delete searchId_.last_name;
      // searchId_["start_date_"] = new Date(searchId_.start_date);
      // searchId_["end_date_"] = new Date(searchId_.end_date);
      const updatedSearchId = {
        fullName: searchId.fullName,
        ...searchId_,
      };

      setData(updatedSearchId);

      if (searchId) {
        // let res = await proxy.saveQuery({
        //   model: "rental.booking",
        //   storeRec: _store,
        // });
        const args = {
          model: "rental.booking",
          method: "booking",
          ids: [data.id],
          // args: data,
        };

        const { data: res, error } = await proxy.button_method(args);
        if (res) {
          setOpen(true);
        } else {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onCloseModal = () => {
    setOpen(false);
  };
  // Utiliza useEffect para ejecutar handleCreateBooking al montar el componente
  useEffect(() => {
    handleCreateBooking();
  }, []); // El segundo argumento es un array vacío para indicar que solo se debe ejecutar una vez al montar

  return (
    <div className="w-full">
      <div className="w-full mt-20 md:mt-5 mb-10 grid grid-cols-2 gap-5 p-3 bg-green-300 rounded-md justify-items-center">
        <p className="w-full text-xs col-span-2 text-center text-green-700">
          Your email has been successfully verified, now to continue with the
          rental process you must go to one of our offices.
        </p>
      </div>
      <h1 className="font-medium text-3xl mb-7">Validate your data</h1>

      <div className="grid grid-cols-1 space-y-5">
        {Object.entries(data).map(([key, value]) => (
          <div className="" key={key}>
            <span className={classLabel}>
              <FM id={`rental.booking.${key}`} name={`rental.booking.${key}`} />
              :
            </span>{" "}
            <span className="uppercase">{value}</span>
          </div>
        ))}
      </div>
      <div className="w-full text-xs mt-10 grid grid-cols-2 gap-5 p-3 bg-gray-100 rounded-md justify-items-center">
        <p className="w-full col-span-2 text-center text-gray-500">
          You created a reservation request for the following dates
        </p>
        <div className="flex flex-col text-gray-500 items-center">
          <span className="font-bold text-sm">Start</span>
          <span>{data.start_date}</span>
        </div>
        <div className="flex flex-col text-gray-500 items-center">
          <span className="font-bold text-sm">End</span>
          <span>{data.end_date}</span>
        </div>
      </div>

      {/* Comentario: Puedes eliminar el botón si solo deseas crear la reserva una vez al montar */}
      {/* <button onClick={handleCreateBooking}>Create Booking</button> */}
      {open && (
        <ModalMsg
          open={open}
          onClose={onCloseModal}
          buttons={["close"]}
          type="success"
          msg={"Your email has already been validated."}
        />
        // <BasicModal
        //   open={open}
        //   title="HOla mundo"
        //   onClose={onCloseModal}
        //   width="w-2/5"
        // >
        //   <p>sadasdasdasd</p>
        // </BasicModal>
      )}
    </div>
  );
};

export default BookingConfirmation;
