import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function MenuItem({ item, open, handleSidebar, widthScreen }) {
  const intl = useIntl();
  const item_name = intl.formatMessage({ id: `menu.${item.name}` });
  const handleClick = () => {
    if (widthScreen <= 649) {
      handleSidebar(false);
    }
  };

  return (
    <Link
      key={item.name}
      to={item.path}
      className={classNames(
        item.current
          ? "bg-stone-800 text-white hover:text-yellow-300"
          : "text-gray-100 hover:text-yellow-200  hover:bg-gray-700 bg-gray-700 sm:bg-transparent py-6 px-3 md:py-0 md:px-0",
        "relative group text-[11px] z-10 md:text-sm text-center block items-center font-medium rounded-md py-6 px-3 md:py-2 md:px-2 active:scale-95 active:text-yellow-200 transform duration-200",
      )}
      onClick={handleClick}
      // onTouchStart={() => handleSidebar}
    >
      {open && (
        <div className="absolute bg-gray-700 left-9  hidden group-hover:block z-30 w-max rounded-r-md top-0 py-6 px-3 md:py-2.5 md:px-3 transform duration-200">
          {item_name}
        </div>
      )}

      <item.icon
        className={classNames(
          item.current
            ? "text-yellow-300"
            : "text-gray-200 group-hover:text-yellow-300",
          "mx-auto  block h-6 w-6 ",
          !open ? "mb-2" : "mb-0",
        )}
        aria-hidden="true"
      />
      {!open && item_name}
    </Link>
  );
}

export default MenuItem;
