import React from "react";

import FormField from "./FormField";
import { classNames, colors } from "tools/ui";
import upStore from "store/upStore";

const style =
  "h-10 rounded-md shadow-sm w-full text-right py-2 px-3 text-gray-700 border border-slate-300 focus:outline-none focus:border focus:border-cyan-500";

const PATTERN = /^-?\d*\.?\d{0,6}$/;
const formatter = Intl.NumberFormat("en-US");

function FloatField(props) {
  const { field, attrs, data } = props;
  const { name, readOnly, placeholder, icon, format } = field;
  const { level } = attrs;

  let percent;
  if (format && format === "percent") {
    percent = true;
  }
  let initValue = data.value;
  if (initValue) {
    if (percent) initValue *= 100;
    else if (initValue instanceof String && !initValue.endsWith("."))
      initValue = formatter.format(data.value);
  }

  function onChange(event) {
    let _value = event.target.value;
    _value = _value.replaceAll(",", "");
    const validNumber = PATTERN.test(_value);
    if (!validNumber) {
      return;
    }
    data.value = _value;
  }

  const onBlur = () => {
    let _value = data.value;
    if (_value !== "" && _value instanceof String) {
      _value = _value.replaceAll(",", "");
      let valueFloat = parseFloat(_value).toFixed(field.decimalPlaces || 0);
      if (percent) {
        _value = _value / 100;
      }
      upStore(level, {
        fieldName: name,
        value: Number(valueFloat),
        field: field,
      });
    }
    upStore(level, {
      fieldName: name,
      value: Number(_value),
      field: props.field,
    });
  };

  let _color = readOnly ? colors.readOnly : field.color ?? "bg-white";

  let moreTools;
  if (field.format && field.format === "percent") {
    moreTools = <p className="pl-2 my-auto text-stone-700 text-sm">%</p>;
  }

  return (
    <FormField {...field}>
      <div className="flex">
        <input
          id={name}
          name={name}
          className={classNames(style, _color)}
          type="text"
          value={initValue}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
          placeholder={placeholder ?? ""}
        />
        {icon || null}
        {moreTools}
      </div>
    </FormField>
  );
}

export default FloatField;
