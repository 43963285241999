// Sale model line
import tools from "tools/common";
import date from "date-and-time";
const today = new Date();

function get_time_commanded(rec) {
  let commanded =
    tools.getTrytonDateTime2Js(rec["order_status_time."]?.commanded, true) ??
    null;
  if (!commanded) {
    return;
  }

  return date.format(commanded, "HH:mm:ss");
}

function get_time_requested(rec) {
  let requested =
    tools.getTrytonDateTime2Js(rec["order_status_time."]?.requested, true) ??
    today;

  return date.format(requested, "HH:mm:ss");
}

const CtxViewLine = {
  model: "sale.line",
  row_selectable: false,
  table_action: [],
  form_action: [],
  webfields: {
    product: {
      type: "many2one",
      model: "product.product",
      required: true,
    },
    unit: {
      type: "many2one",
      model: "product.uom",
      readOnly: true,
    },
    quantity: {
      type: "numeric",
      required: true,
      default: 1,
    },
    note: { type: "text" },
    unit_price: { type: "numeric", readOnly: true },
    amount: { type: "numeric", readOnly: true },
    amount_w_tax: { type: "numeric", readOnly: true },
  },
  webtree: [
    { name: "product", width: "30%" },
    { name: "quantity", width: "10%" },
    { name: "amount", width: "15%" },
    { name: "amount_w_tax", width: "15%" },
    { name: "note", width: "30%" },
  ],
  webform: [
    { name: "product" },
    { name: "quantity" },
    { name: "amount" },
    { name: "amount_w_tax" },
    // { name: "comment" },
  ],
};

const stateColors = {
  requested: "slate",
  commanded: "lime",
  dispatched: "sky",
};

const ctxViewSale = {
  row_selectable: false,
  tags: {
    order_status: stateColors,
  },
  limit: 30,
  model: "sale.sale",
  form_action: ["save"],
  table_action: ["edit"],
  webfields: {
    number: { type: "char", readOnly: true },
    delivery: { type: "money", readOnly: true },
    party: {
      type: "many2one",
      model: "party.party",
      readOnly: { state: ["quotation"] },
    },
    source: {
      type: "many2one",
      model: "sale.source",
      readOnly: true,
    },
    consumer: {
      type: "many2one",
      model: "party.consumer",
      required: true,
    },
    lines: {
      type: "one2many",
      model: "sale.line",
      ctxView: CtxViewLine,
      readOnly: true,
    },
    sale_date: {
      type: "date",
      readOnly: true,
    },
    state: {
      type: "char",
      readOnly: true,
      translate: true,
      default: "draft",
    },
    order_status: {
      type: "char",
      readOnly: true,
      translate: true,
      default: "draft",
      // attrs: ["order_status_time.requested", "order_status_time.commanded"],
    },
    shop: {
      type: "many2one",
      model: "sale.shop",
      readOnly: true,
    },
    total_amount: { type: "numeric", readOnly: true },
    description: { type: "char", readOnly: true },
    kind: { type: "char", readOnly: true, translate: true },
    comment: { type: "text", readOnly: true },
    "order_status_time.requested": {
      type: "function",
      search: true,
      function: get_time_requested,
    },
    "order_status_time.commanded": {
      type: "function",
      search: true,
      function: get_time_commanded,
    },
  },
  webtree: [
    { name: "number", width: "20%" },
    { name: "sale_date", width: "25%" },
    { name: "shop", width: "25%" },
    { name: "party", width: "25%" },
    { name: "consumer", width: "25%" },
    {
      name: "order_status",
      width: "30%",
      widget: "circle",
    },
    { name: "total_amount", width: "25%" },
    { name: "order_status_time.requested", width: "15" },
  ],
  webform: [
    { name: "source" },
    { name: "shop" },
    { name: "kind" },
    { name: "consumer" },
    { name: "party" },
    { name: "sale_date" },
    { name: "delivery" },
    { name: "lines", component: "modal" },
    { name: "total_amount" },
    { name: "order_status" },
    { name: "comment" },
    //aca debe ir el compomente que muestre estados

    { component: "timeLine" },
  ],
};
export default ctxViewSale;
