import React, { Fragment } from "react";
import { signal } from "@preact/signals-react";

import pool from "components/MeForm/pool";
import tools from "components/MeForm/toolsField";

function Form(props) {
  const { ctxView, webform, level, handleButton, record, parentRec } = props;
  const { webfields, model } = ctxView;
  let form = [];
  let _webform = webform;
  if (!webform) {
    _webform = ctxView.webform;
  }

  let formObject = {};
  for (const _field of _webform) {
    const field = { ..._field, ...webfields[_field.name] };

    const isVisible = tools.getVisible(field, record);
    if (!isVisible) {
      continue;
    }

    const keyName = field.name ?? field.id;
    const value = tools.getValue(field, record);
    const error = Boolean(null && Boolean(!value));
    let parentRec_ = parentRec;
    if (field.ctxView) {
      parentRec_ = {
        target: field.ctxView.target,
        record: record,
        model: model,
        ctxView: ctxView,
        fieldName: field.name,
      };
    }
    field.label = tools.getLabel(field, record, model);
    field.readOnly = tools.getReadOnly(field, record, parentRec_);
    field.required = tools.getRequired(field, record, parentRec_);
    const data = signal(value);
    const attrs = {
      level,
      error,
      record,
      ctxView,
      handleButton,
      parentRec: parentRec_,
    };

    let _type = field.widget ?? field.type;
    if (!_type) {
      if (field.grid) {
        _type = "grid";
      } else if (field.tabs) {
        _type = "tabs";
      }
    }

    formObject[keyName] = {
      _type: _type,
      field: field,
      attrs: attrs,
      data: data,
    };
  }

  for (const [keyName, vals] of Object.entries(formObject)) {
    const { _type, field, attrs, data } = vals;
    const Component = pool[_type];
    if (!Component) {
      console.log("This type of Component there is not exist!!!....", _type);
      return null;
    }

    const component = (
      <Component
        key={keyName}
        field={field}
        attrs={attrs}
        data={data}
        record={record}
      />
    );
    form.push(component);
  }
  return <Fragment>{form}</Fragment>;
}

export default Form;
