import React, { useState, Fragment } from "react";
import { FormattedMessage as FM } from "react-intl";
import { Menu, Transition } from "@headlessui/react";
import { FunnelIcon } from "@heroicons/react/24/outline";

import { classNames } from "tools/ui";

function FilterTable({ ctxView, onClickFilter, session }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const filterTags = ctxView.filters(session) ?? null;
  if (!filterTags) return null;

  function onClick(name, valueDom) {
    setSelectedOption(name);
    onClickFilter(valueDom);
  }

  let items = [];
  for (let [k, v] of Object.entries(filterTags)) {
    items.push(
      <Menu.Item key={k} onClick={() => onClick(k, v)}>
        {({ active }) => (
          <a
            key={v.id}
            className={classNames(
              active ? "bg-gray-100 text-gray-900" : "text-gray-700",
              "block px-4 py-2 text-sm",
            )}
          >
            <FM id={`${ctxView.model}.${k}`} />
          </a>
        )}
      </Menu.Item>,
    );
  }

  return (
    <Menu as="div" className="relative text-left h-10 my-auto w-5/6">
      <div className="flex my-auto h-full">
        <Menu.Button className="inline-flex h-full w-full justify-between rounded-md bg-white px-3 py-2 text-sm text-gray-600 border border-slate-400 ring-inset ring-gray-300 hover:bg-sky-50">
          {selectedOption ? (
            <p className="text-sm">
              <FM id={`${ctxView.model}.${selectedOption}`} />
            </p>
          ) : (
            <p className="text-sm">
              <FM id="board.filter" />
            </p>
          )}
          <FunnelIcon
            className="-mr-1 h-5 w-5 text-stone-500"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">{items}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default FilterTable;
