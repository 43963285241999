import React, { Fragment, useState } from "react";
import { useQuery } from "@tanstack/react-query";
// import mainStore from "store/mainStore";
// import { useWizardStore } from "store/wizardStore";
import proxy from "api/proxy";
import StdButton from "components/Buttons/StdButton";
import useMainStore from "store/mainStore";

function ProductDiscount({ onClose }) {
  const { record, reload } = useMainStore();
  const [selected, setSelected] = useState([]);
  const [checked, setChecked] = useState(false);
  const discountIds = selected.map((item) => item.id);
  const discount = selected.reduce((sum, item) => sum + item.discount, 0);
  // const { upActiveWizard, upStoreWizard } = useWizardStore();
  const shopId = record?.shop?.id;
  const queryProductDiscount = useQuery(
    ["productDiscount", shopId],
    async () => {
      let discounts = [];
      if (shopId) {
        const { data: shops, error } = await proxy.search(
          "sale.shop",
          [
            ["id", "=", shopId],
            ["discounts.active", "=", true],
          ],
          ["discounts.name", "discounts.type_discount", "discounts.discount"],
        );
        if (shops) {
          discounts = shops[0]["discounts."];
        }
      }

      return discounts;
    },
  );

  const handleSelect = (index) => {
    let selected_ = [...selected];
    if (selected_.includes(index)) {
      const idx = selected_.findIndex((t) => index == t);
      selected_.splice(idx, 1);
    } else {
      selected_.push(index);
    }
    setSelected(selected_);
  };

  const onAcept = async () => {
    const lines = record.lines;
    if (checked) {
      const args = {
        line_ids: Array.from(lines.keys()),
        value: 0,
        type: "percentage",
      };
      await proxy.methodCall({
        model: "sale.line",
        method: "faster_set_discount",
        args: [args],
      });
    } else {
      const { data: lines_ } = await proxy.browse(
        "sale.line",
        Array.from(lines.keys()),
        ["unit_price"],
      );
      for (const item of lines_) {
        const new_unit_price =
          item.unit_price - (item.unit_price * discount) / 100;
        await proxy.saveQuery({
          model: "sale.line",
          storeRec: { unit_price: new_unit_price, id: item.id },
        });
      }

      // for (const item of Array.from(new_discounts.values())) {
      //   const args = {
      //     line_ids: item.lines,
      //     value: item.discount * 100,
      //     type: "percentage",
      //   };
      //   await proxy.methodCall({
      //     model: "sale.line",
      //     method: "faster_set_discount",
      //     args: [args],
      //   });
      // }
    }
    reload();
    onClose(true, false);
    // return;
  };

  return (
    <Fragment>
      <div className="px-4 sm:px-6 lg:px-8 py-2 md:col-span-2 bg-blue">
        <div className="flex justify-between space-x-3">
          <span>TOTAL DESCUENTO: {discount}%</span>
          <div>
            <span>Resetear Descuentos </span>
            <input type="checkbox" onClick={() => setChecked(!checked)} />
          </div>
          <StdButton
            color={"bluePresik"}
            onClick={onAcept}
            name={"done"}
            content={"modal_form.button_accept"}
          />
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 pt-2">
          {queryProductDiscount.data?.map((discount, index) => {
            const active = discountIds.includes(discount.id);
            return (
              <div
                className={`group  rounded-md cursor-pointer text-center  px-2 py-2 relative overflow-hidden ${
                  active ? "bg-yellow-500" : "bg-blue-presik"
                } `}
                key={discount.id}
                onClick={() => {
                  handleSelect(discount);
                }}
                name={index}
              >
                <div className="absolute hidden group-hover:block transition h-full w-full backdrop-blur-sm left-0 top-0" />
                <div
                  key={discount.id}
                  className="pointer-events-none flex-wrap p-2 border h-full border-white py-8 border-dashed text-white"
                >
                  <h3 className="font-semibold text-[17px]">{discount.name}</h3>
                  <span className="text-gray-500 text-[30px]">
                    {discount.discount}{" "}
                    {discount.type_discount == "percentage" ? "%" : ""}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
}

export default ProductDiscount;
