import React from "react";

import { classNames } from "tools/ui";

/**
 * Define cell for row
 * @param {Function} onClick - callback function on click cell
 * @param {String} align - optional align text for cell [left, right, center] default center
 * @param {String} color - define color for cell
 * @param {Boolean} selectable?? - optional selectable
 * @param {Boolean} editable?? - optional default true
 */

const style = "py-2 px-2 text-sm sm:pl-2 break-words";
const text_color = "text-slate-700";

function TableCell(props) {
  const align = props.align ?? "text-left";
  const color = props.color ?? text_color;

  function handleClick() {
    if (props.onClick) {
      props.onClick(props.field, props.record);
    }
  }

  return (
    <td onClick={handleClick} className={classNames(style, align, color)}>
      {props.children}
    </td>
  );
}

export default TableCell;
