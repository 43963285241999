// Cash Up Report

const getView = () => {
  let ctxView = {
    model: "account_statement_co.cash_up.start",
    reportName: "account_statement_co.cash_up_report",
    webfields: {
      operation_center: {
        type: "many2one",
        model: "company.operation_center",
        searchable: true,
        required: true,
      },
      shop: {
        type: "many2one",
        model: "sale.shop",
        searchable: true,
        required: true,
      },
      user: {
        type: "many2one",
        model: "res.user",
        searchable: true,
        required: true,
      },
      date: {
        type: "date",
        required: true,
      },
    },
    webtree: [],
    webform: [
      { name: "operation_center" },
      { name: "shop" },
      { name: "date" },
      { name: "user" },
    ],
  };
  return ctxView;
};

export default { ctxView: getView };
