import React from "react";

import StdButton from "components/Buttons/StdButton";

function FooterForm({ onSave, ctxView, mode }) {
  const { form_action } = ctxView;
  let buttonsActive = [];

  // ?????
  // const btnCancel = (
  //   <StdButton
  //     key="cancel"
  //     color="rose"
  //     size="w-full sm:w-1/2 md:w-1/3"
  //     onClick={onClose}
  //     content="basic_modal.button_cancel"
  //   />
  // );

  const btnAccept = (
    <StdButton
      key="accept"
      color="blue"
      size="w-full sm:w-1/2 md:w-1/3"
      onClick={() => onSave("accept")}
      content="basic_modal.button_ok"
    />
  );

  const btnCreate = (
    <StdButton
      key="create"
      color="blue"
      size="w-full sm:w-1/2 md:w-1/3"
      onClick={() => onSave("create")}
      content="basic_modal.button_create"
    />
  );

  const btnAdd = (
    <StdButton
      key="add"
      color="blue"
      size="w-full sm:w-1/2 md:w-1/3"
      onClick={() => onSave("add")}
      content="basic_modal.button_add"
    />
  );

  if (form_action) {
    const editAction = form_action.includes("edit");
    const addAction = form_action.includes("add");
    const createAction = form_action.includes("create");
    if (editAction && mode === "edit") {
      buttonsActive.push(btnAccept);
    } else if (addAction && mode === "add") {
      buttonsActive.push(btnAdd);
    } else if (createAction && mode === "create") {
      buttonsActive.push(btnCreate);
    }
  }

  return (
    <div id="bottom-form" className="flex justify-end px-3 py-4">
      {buttonsActive}
    </div>
  );
}

export default FooterForm;
