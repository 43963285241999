import React, { Fragment, useState } from "react";
import { FormattedMessage as FM } from "react-intl";

import BasicModal from "components/Modals/BasicModal";
import { BUTTON_COLORS } from "components/Constants/constants";
import { classNames } from "tools/ui";
import { useWizardStore } from "store/wizardStore";
import mainStore from "store/mainStore";

const style =
  "my-4 mx-auto h-12 text-center rounded-2xl active:scale-95 active:shadow-md";

function ButtonCustomModal({ field, attrs }) {
  const { Component, label, icon, color, button_ok, onAccept, size } = field;
  // const { onAccept } = attrs;
  const [open, setOpen] = useState(false);
  const {
    record: activeWizard,
    store: storeWizard,
    reset: resetWizard,
  } = useWizardStore();
  const { record, store, updateStore, updateRecord } = mainStore();
  const _color = color ?? "blue";
  const size_ = size ?? "w-full";
  const [bgColor, txtColor, border] = BUTTON_COLORS[_color];

  function onClose() {
    resetWizard();
    setOpen(false);
  }

  function handleAccept() {
    if (onAccept) {
      onAccept(storeWizard, activeWizard);
      updateRecord(record);
      updateStore(store);
    } else {
      if (Object.keys(storeWizard).length > 0) {
        const _store = { ...store, ...storeWizard };
        updateStore(_store);
        const _record = { ...record, ...activeWizard };
        updateRecord(_record);
      }
    }
    onClose();
  }

  let buttons = [];
  if (button_ok !== false) {
    buttons = ["ok"];
  }

  return (
    <Fragment>
      <div>
        <button
          onClick={() => setOpen(true)}
          className={classNames(style, bgColor, txtColor, border, size_)}
        >
          <span className={classNames("w-42 ml-auto  my-auto")}>
            <FM id={label} />
          </span>
          {icon && (
            <i
              className={classNames(
                "mr-auto my-auto ml-6 text-xl",
                icon,
                txtColor,
              )}
            />
          )}
        </button>
      </div>
      <BasicModal
        title={label}
        open={open}
        onClose={onClose}
        buttons={buttons}
        onAccept={handleAccept}
        titleSize="text-2xl"
        width="w-full sm:w-5/6 md:w-1/2"
      >
        <Component record={record} onClose={onClose} />
      </BasicModal>
    </Fragment>
  );
}

export default ButtonCustomModal;
