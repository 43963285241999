// Purchase model line

const getView = () => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    // table_action_update: true,
    model: "purchase.line",
    form_action: [],
    table_action: [],
    row_selectable: false,
    validate_state: true,
    target: "purchase",
    webfields: {
      product: {
        type: "many2one",
        model: "product.product",
        readOnly: true,
      },
      quantity: { type: "integer", readOnly: true },
      unit: { type: "numeric", readOnly: true },
      unit_price: { type: "numeric", readOnly: true },
      description: { type: "char", readOnly: true },
      amount: { type: "numeric", readOnly: true },
    },
    webtree: [
      { name: "product", width: "30%" },
      { name: "quantity", width: "10%" },
      { name: "unit_price", width: "15%" },
      { name: "amount", width: "15%" },
      { name: "description", width: "30%" },
    ],
    webform: [
      { name: "product" },
      { name: "quantity" },
      { name: "unit_price" },
      { name: "amount" },
      { name: "description" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
