function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const colors = {
  readOnly: "bg-gray-200",
};

const fmtCurrency = (value) => {
  return value.toLocaleString("es", { useGrouping: true });
};

export { classNames, colors, fmtCurrency };
