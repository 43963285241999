import React from "react";

import Board from "components/Meta/Board";
import sale from "./ModelSale";

const SaleAudit = ({ config }) => {
  return <Board ctxView={sale.ctxView(config)} />;
};

export default SaleAudit;
