// Purchase model line
import proxy from "api/proxy";
import mainStore from "store/mainStore";

const getProduct = (value) => {
  const search = [
    "OR",
    [
      ["template.name", "ilike", `%${value}%`],
      ["template.purchasable", "=", true],
    ],
    [
      ["code", "ilike", `${value}%`],
      ["template.purchasable", "=", true],
    ],
  ];
  return search;
};

const onChangeProduct = async (record) => {
  if (record?.product) {
    const taxes = await getTaxes(record.product.id);
    record.description = record.product.name;
    record.taxes = taxes;
  }
};

const onChangeQty = (activeRecord) => {
  if (activeRecord.unit_price) {
    let price_w_tax = activeRecord.unit_price;
    if (activeRecord.taxes) {
      if (activeRecord.taxes.percentage > 0) {
        price_w_tax *= 1 + activeRecord.taxes.percentage;
      }
      if (activeRecord.taxes.fixed > 0) {
        price_w_tax += activeRecord.taxes.fixed;
      }
    }
    const amount = price_w_tax * activeRecord.quantity;
    activeRecord.amount = amount;
  }
};

const getTaxes = async (product) => {
  const { data } = await proxy.methodCall({
    model: "purchase.purchase",
    method: "dash_get_taxes",
    args: [{ id: product }],
  });
  return data;
};

const onChangeUnitPrice = (activeRecord) => {
  const { quantity, unit_price, taxes } = activeRecord;
  if (quantity) {
    let price_w_tax = Number(unit_price);
    if (taxes.percentage > 0) {
      price_w_tax *= 1 + taxes.percentage;
    }
    if (taxes.fixed > 0) {
      price_w_tax += taxes.fixed;
    }
    const amount = price_w_tax * Number(quantity);
    activeRecord.amount = amount;
  }
};

const getStatusLine = () => {
  const { record: recordParent } = mainStore.getState();
  const statusVisible =
    recordParent.state == "done" ||
    recordParent.state == "processing" ||
    recordParent.state == "quotation"
      ? true
      : false;
  return statusVisible;
};

const getView = () => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    form_action_add: "modal",
    model: "purchase.line",
    form_action: ["delete", "add", "edit"],
    table_action: ["edit", "add"],
    row_selectable: false,
    validate_state: true,
    target: "purchase",
    webfields: {
      product: {
        type: "many2one",
        model: "product.product",
        recSearch: getProduct,
        withChange: onChangeProduct,
        attrs: ["id", "name"],
        required: true,
        readOnly: ["quotation"],
        readOnly: getStatusLine,
      },
      quantity: {
        type: "numeric",
        withChange: onChangeQty,
        required: true,
        decimalPlaces: 2,
        readOnly: getStatusLine,
      },
      unit: { type: "numeric", readOnly: ["quotation"] },
      unit_price: {
        type: "numeric",
        withChange: onChangeUnitPrice,
        required: true,
        decimalPlaces: 4,
        readOnly: getStatusLine,
      },
      state: { type: "char" },
      description: { type: "char", readOnly: getStatusLine },
      amount: { type: "numeric", readOnly: true, decimalPlaces: 2 },
    },
    webtree: [
      { name: "product", width: "30%" },
      { name: "quantity", width: "10%" },
      { name: "unit_price", width: "15%" },
      { name: "amount", width: "15%" },
      { name: "description", width: "30%" },
    ],
    webform: [
      { name: "product" },
      { name: "quantity", component: "increment" },
      { name: "unit_price" },
      { name: "amount" },
      { name: "description" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
