import React from "react";
import { FormattedMessage as FM } from "react-intl";

import { classNames } from "tools/ui";

export default function Tab({ item, model, onClick }) {
  function handleClick(item) {
    onClick(item);
  }

  // item.current
  // ? "bg-gray-900 text-white"
  return (
    <div key={item} className="flex">
      <button
        key={item}
        onClick={() => handleClick(item)}
        className={classNames(
          "text-gray-300 hover:bg-gray-700 hover:text-white",
          "rounded-md px-3 py-2 text-md font-medium",
        )}
      >
        <FM id={`${model}.${item}`} />
      </button>
      <div className="h-full w-0 border-r border-gray-700 mx-3" />
    </div>
  );
}
