import React, { useState, useEffect } from "react";

const ProgressBar = ({ progress, maxValue }) => {
  const [style, setStyle] = useState({});
  const percentage = Math.round((progress / maxValue) * 100);

  useEffect(() => {
    const newStyle = {
      width: `${percentage}%`,
      transition: "width 0.5s ease-out",
      backgroundColor: "blue",
      position: "relative",
    };
    setStyle(newStyle);
  }, [progress]);

  return (
    <div>
      <p>
        {progress}/{maxValue} ... {isNaN(percentage) ? 0 : percentage}%
      </p>
      <div className="w-full h-3 bg-gray-300 my-4 rounded-lg">
        <div
          className="h-full rounded-lg text-sm font-semibold"
          style={style}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
