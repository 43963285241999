import React from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import imgDefault from "../../../assets/img/default-image.jpg";

import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";

// import {}

const CardItemProductCategory = ({ data, handleCategory }) => {
  const image = data.images[0];
  return (
    <article
      className="group overflow-hidden h-fit transform rounded border border-border-200 bg-light shadow-lg transition-all duration-200 hover:-translate-y-0.5 hover:shadow bg-white cursor-pointer"
      onClick={() => handleCategory(data)}
    >
      <div className="flex  justify-center items-center align-middle h-full px-6  py-2">
        <img
          className="h-28 object-contain grayscale-1 "
          src={image ?? imgDefault}
        />
      </div>
      <div className="w-full  bg-blue-presik py-3 px-3 text-white font-semibold text-lg flex justify-between">
        <div className="divide-x divide-gray-50 space-x-3">
          <span>{data.name}</span>
          <span className="pl-3">{data.children.length}</span>
        </div>
        <ArrowRightCircleIcon className="w-7" />
      </div>
      <div className="hidden text-white group-hover:block absolute w-full h-full bg-blue-presik/80  justify-center items-center">
        {data.name}
      </div>
    </article>
  );
};

export default CardItemProductCategory;
