const TYPE_DOC = [
  { id: "13", name: "cedula_de_ciudadania" },
  { id: "41", name: "pasaporte" },
  { id: "12", name: "tarjeta_de_identidad" },
  { id: "21", name: "tarjeta_de_extranjeria" },
  { id: "22", name: "cedula_de_extranjeria" },
];

const TYPE_GUEST = [
  { id: "adult", name: "adult" },
  { id: "child", name: "child" },
];

const SEX = [
  { id: "male", name: "male" },
  { id: "female", name: "female" },
];

export { TYPE_DOC, TYPE_GUEST, SEX };
