// Spanish translation

const es = {
  "account.invoice": "Factura",
  "account.invoice.invoice_date": "Fecha",
  "account.invoice.total_amount": "Total",
  "account.invoice.number": "Número",
  "account.invoice.reference": "Referencia",
  "account.invoice.party": "Cliente",
  "account.invoice.invoice_type": "Tipo",
  "account.invoice.payment_term": "Plazo de Pago",
  "account.invoice.untaxed_amount": "Base",
  "account.invoice.tax_amount": "Impuestos",
  "account.invoice.line.unit": "Und",
  "account.invoice.line.product": "Producto",
  "account.invoice.line.unit_price": "Precio Unitario",
  "account.invoice.line.quantity": "Cantidad",
  "account.invoice.line.description": "Descripcion",
  "account.invoice.line.amount": "Importe",
};

export default es;
