import React, { Fragment } from "react";

import Circle from "components/Tools/Circle";
import Badge from "components/Tools/Badge";
import IconSource from "components/Icons/IconSource";
import ButtonCustomModal from "components/MeTable/ButtonCustomModal";
import CallAction from "components/MeTable/CallAction";

function WidgetCell({ field, attrs, data }) {
  const { model, key, record } = attrs;
  let value = data.value;
  let color;
  if (field.widget === "circle") {
    color = "white";
    if (field.tags) {
      color = field.tags[value];
    } else if (field.color) {
      color = field.color;
    }
    value = <Circle color={color} />;
  } else if (field.widget === "badge") {
    color = "white";
    if (field.tags) {
      color = field.tags[value];
    } else if (field.color) {
      color = field.color;
    }
    value = <Badge color={color} value={value} model={model} />;
  } else if (field.widget === "button-custom-modal") {
    value = (
      <ButtonCustomModal
        key={key}
        {...field}
        label={value}
        record={record}
        model={model}
      />
    );
  } else if (field.widget === "call-action") {
    value = <CallAction key={key} value={value} />;
  } else if (field.widget.type === "sourceImg") {
    if (field.widget.data) {
      value = <IconSource data={field.widget.data[value ? value : 0]} />;
    }
  }

  return <Fragment>{value}</Fragment>;
}

export default WidgetCell;
