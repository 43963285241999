import React from "react";
import { Outlet } from "react-router-dom";

function ExtApps({ session }) {
  return (
    <div className="w-full h-full">
      <Outlet context={{ session }} />
    </div>
  );
}

export default ExtApps;
