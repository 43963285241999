import React, { Fragment, useState } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { FormattedMessage as FM } from "react-intl";
import L from "leaflet";

import "leaflet/dist/leaflet.css";

const TextFieldGeocoding = (props) => {
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState(null);
  // const [error, setError] = useState(null);
  const [zipCode, setZipCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const customIcon = new L.Icon({
    iconUrl: require("../../assets/apps/marker.png"),
    iconSize: [40],
    iconAnchor: [16, 32],
  });

  const handlePlaceSearch = async () => {
    if (address.trim() === "") {
      setCoordinates(null);
      setZipCode("");
      // setError(null);
      return;
    }

    setIsLoading(true);

    try {
      const url = `https://api.geoapify.com/v1/geocode/search?text=${encodeURIComponent(
        address,
      )}&apiKey=${props.apiGeocoding}&country=${encodeURIComponent(
        props.country || "Australia",
      )}&state=${encodeURIComponent(props.stateAcronym || "")}`;

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Error al buscar la dirección");
      }

      const data = await response.json();

      if (data.features && data.features.length > 0) {
        const { lat, lon } = data.features[0].properties;
        let subdivision = {
          post_code: data?.query.parsed.postcode,
          city: data?.query.parsed.city,
          suburb: data?.query.parsed.suburb,
          address: address,
        };

        props.onChange(props.name, subdivision);
        setCoordinates({ lat, lng: lon });
        setZipCode(data?.features[0].properties.postcode);
        // setError(null); // Limpiar errores anteriores
      } else {
        // setError(
        //   "No se encontraron resultados para la dirección proporcionada.",
        // );
      }
    } catch (error) {
      console.error("Error al buscar la dirección:", error);
      // setError("Hubo un error al buscar la dirección.");
    } finally {
      // Oculta el spinner de carga cuando la búsqueda se completa
      setIsLoading(false);
    }
  };

  const handleButtonClick = async (e) => {
    // e.preventDefault();
    setIsLoading(true);
    await handlePlaceSearch();
  };

  return (
    <div className="w-full z-0">
      <label
        className="flex text-sm mt-3 mb-0.5 ml-1 font-bold text-zinc-600 max-h-5"
        htmlFor={props.name}
      >
        <FM id={props.label} key={props.label} />
        {props.country && <span className="ml-2">(País: {props.country})</span>}
      </label>
      <div className="flex relative mb-4 focus:outline-none focus:border focus:border-cyan-500 rounded-md shadow-md overflow-hidden">
        <input
          type="text"
          placeholder="Ingresa una dirección"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          onBlur={() => handleButtonClick()}
          className="bg-white  border border-slate-300 h-10  rounded-md w-full py-2 px-2 text-gray-700  relative"
          disabled={props.stateAcronym ? false : true}
        />

        {/* <MapPinIcon
          className="w-10 h-full absolute text-white right-0 bg-blue-presik px-2 cursor-pointer"
          onClick={handleButtonClick}
        /> */}
      </div>

      {!isLoading && coordinates && (
        <Fragment>
          <MapContainer
            center={[coordinates.lat, coordinates.lng]}
            zoom={15}
            style={{
              height: "200px",
              width: "100%",
              position: "absolute",
              left: "0px",
              zIndex: "0",
            }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker
              position={[coordinates.lat, coordinates.lng]}
              // icon={IconMarket}
              icon={customIcon}
            />
          </MapContainer>
          <hr className="mb-44" />
        </Fragment>
      )}
    </div>
  );
};

export default TextFieldGeocoding;
