import React from "react";

import Spinner from "components/Tools/Spinner.js";

function SegLoader() {
  return (
    <div className="flex p-6 shadow-lg rounded-md bg-gray-900 my-4 h-48">
      <Spinner />
    </div>
  );
}

export default SegLoader;
