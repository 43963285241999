// Room Housekeeping model

import WidgetGuest from "./WidgetGuest";

const stateColors = {
  clean: "sky",
  dirty: "rose",
  not_authorized: "lime",
  maintenance: "black",
};

const getFilters = () => {
  return {
    clean: [["state", "=", "clean"]],
    dirty: [["state", "=", "dirty"]],
    check_out_today: [["check_out_today", "!=", null]],
    all: [[]],
  };
};

function getCheckinWidget({ record }) {
  console.log("record.........", record);
  return WidgetGuest("check_in", record);
}

function getCheckoutWidget({ record }) {
  return WidgetGuest("check_out", record);
}

function getCleanVisible(nameField, record) {
  return record?.state === "dirty";
}

const getView = () => {
  let DictCtxView = {
    model: "hotel.room",
    table_action: ["open"], // options: ['open', 'delete', 'edit', 'add']
    form_action: ["edit"], // options: ['open', 'delete', 'edit', 'add']
    card_action: ["open"], // options: ['open', 'delete', 'edit', 'add']
    defaultView: "cards",
    filters: getFilters,
    activeSearch: true,
    domain: [], // Options: null or valid domain
    orderBy: [["code", "ASC"]],
    selectable: null, // Options for table rows: null, multi, one
    title: { field: "rec_name" },
    tags: {
      state: stateColors,
    },
    webfields: {
      name: {
        type: "heading",
        readOnly: true,
        searchable: true,
      },
      notes: { type: "text" },
      // paragraph: {
      //   type: 'paragraph',
      //
      // },
      type_: {
        type: "many2one",
        model: "hotel.room.type",
        readOnly: true,
      },
      cleaning_type: {
        type: "many2one",
        model: "hotel.room.cleaning_type",
        readOnly: true,
      },
      location: {
        type: "many2one",
        model: "hotel.location",
        readOnly: true,
      },
      housekeeping: {
        type: "many2one",
        model: "company.employee",
        readOnly: true,
      },
      state: {
        type: "char",
        label: false,
        readOnly: true,
        translate: true,
        tags: stateColors,
      },
      last_check_in: {
        type: "datetime",
        readOnly: true,
      },
      last_check_out: {
        type: "datetime",
        readOnly: true,
      },
      last_clean: {
        type: "datetime",
        readOnly: true,
      },
      check_out_widget: {
        type: "custom",
        component: getCheckoutWidget,
        depends: ["check_out_today", "departure_today", "occupied_today"],
      },
      check_in_widget: {
        type: "custom",
        component: getCheckinWidget,
        depends: ["check_in_today", "arrival_today", "occupied_today"],
      },
      check_in_today: {
        type: "time",
      },
      check_out_today: {
        type: "time",
      },
      arrival_today: {
        type: "boolean",
      },
      departure_today: {
        type: "boolean",
      },
      occupied_today: {
        type: "boolean",
      },
      clean: {
        type: "button",
        button_method: "clean",
        visible: getCleanVisible,
        onSuccessMsg: "AHORA LA HABITACION ESTA LIMPIA!",
        color: "blue",
      },
      not_authorized: {
        type: "button",
        button_method: "not_authorized",
        visible: getCleanVisible,
        onSuccessMsg: "SE MARCO QUE LA HAB. NO AUTORIZA LIMPIEZA!",
        color: "green",
      },
    },
    webtree: [
      { name: "name", width: "15%" },
      {
        name: "state",
        width: "10%",
        widget: "circle",
      },
      { name: "cleaning_type", width: "15%" },
      { name: "clean", width: "25%" },
      { name: "not_authorized", width: "25%" },
    ],
    webform: [
      { name: "name", colspan: true },
      { name: "type_" },
      { name: "location" },
      {
        id: "check_out_ctx",
        grid: [{ name: "check_out_widget" }],
        size: [1, 1],
        span: "col-span-1",
      },
      {
        id: "check_in_ctx",
        grid: [{ name: "check_in_widget" }],
        size: [1, 1],
        span: "col-span-1",
      },
      { name: "cleaning_type" },
      { name: "last_clean" },
      { name: "housekeeping" },
      { name: "state" },
      { name: "clean" },
      { name: "not_authorized" },
      { name: "notes" },
    ],
    webcards: {
      head: [
        {
          col: [{ name: "name", style: "font-bold text-lg" }],
          width: "w-11/12",
        },
        {
          col: [{ name: "state", widget: "circle" }],
        },
      ],
      content: [
        { row: [{ name: "type_" }] },
        { row: [{ name: "location" }] },
        {
          row: [
            { name: "cleaning_type", width: "w-3/5" },
            { name: "check_out_today", width: "w-2/5" },
          ],
        },
      ],
    },
  };

  return DictCtxView;
};

export default { ctxView: getView };
