import React from "react";
import { EXPERIENCES } from "../fakeData";
import TitleSection from "../components/ui/TitleSection";
const PageExperience = () => {
  return (
    <div className="">
      <TitleSection text="EXPERIENCIAS" />
      <div className="grid grid-cols-1  md:grid-cols-5 gap-3  md:w-[100%] rounded-md overflow-hidden my-10">
        {EXPERIENCES.map((expererience, index) => (
          <article
            key={index}
            className="h-[400px]  bg-cover flex items-end rounded-lg overflow-hidden shadow-xl relative cursor-pointer active:scale-95 "
            style={{ backgroundImage: `url(${expererience.image})` }}
            title={expererience.name}
          >
            <div className=" bg-gradient-to-t from-black via-black/90 to-transparent px-5  pb-3 pt-10  w-full text-white">
              <h2 className="text-xs truncate w-full text-left font-semibold  melhous-menu-title uppercase">
                {expererience.name}
                <hr className="border-[0,5] border-gray-700 mb-2 mt-1 w-[70%]" />
              </h2>

              <div className="flex  justify-between">
                <div className="flex space-x-1 text-left line-clamp-2 melhous-menu-description">
                  <span>{expererience.description}</span>
                </div>
              </div>
            </div>
            {expererience.price && (
              <div className="text-[13px] text-yellow-500 font-medium text-left text-base absolute top-2 right-2 bg-black px-3 py-1 rounded-full">
                $ {expererience.price}
              </div>
            )}
          </article>
        ))}
      </div>
    </div>
  );
};

export default PageExperience;
