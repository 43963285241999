import React from "react";

import { classNames } from "tools/ui";
import Form from "components/MeForm/Form";

const base = "grid gap-x-2 mb-auto rounded-lg h-full";

function GridFields(props) {
  const { field, attrs } = props;
  const { level, record, ctxView, parentRec, handleButton } = attrs;
  let style = "";
  let cols = "";
  let rows = "";
  let border = "";
  if (field.border === "visible") {
    border = "border border-dashed border-stone-400 px-2";
  }
  if (field.size) {
    rows = field.size[0];
    cols = `md:grid-cols-${field.size[1]}`;
  }
  let span = "";
  if (field.span) {
    span = field.span;
  }
  return (
    <div
      key={field.id}
      className={classNames(base, span, cols, rows, style, border)}
    >
      {Form({
        keyName: field.name,
        ctxView: ctxView,
        webform: field.grid,
        handleButton: handleButton,
        record: record,
        parentRec: parentRec,
        level: level,
      })}
    </div>
  );
}

export default GridFields;
