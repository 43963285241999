import React, { Fragment } from "react";
const STYLE_BUTTON = "border border-yellow-500 rounded-full p-5 bg-gray-100";
export const NavCategories = ({ mode = "center", categories }) => {
  const baseUrl = new URL(window.location.href);
  const handleAnchorClick = (index) => {
    const categoryAnchor = `#category-${index}`;
    window.location.hash = categoryAnchor;
  };
  return (
    <Fragment>
      <div className="relative mt-16 hidden md:block">
        <div className="border-b border-yellow-500  absolute top-5 w-[60%]  right-0"></div>
        <div className="w-6 h-6 flex justify-center  items-center rounded-full border  border-yellow-500 bg-white  absolute text-yellow-500 top-[8px] right-[60%]">
          <div className=" bg-yellow-500  w-3 h-3 animate-ping rounded-full"></div>
        </div>
        <div
          className={`flex space-x-8 relative ${
            mode == "left" ? "justify-end pr-8" : "justify-center"
          }`}
        >
          {categories &&
            categories.map((item, index) => (
              <a
                onClick={() => handleAnchorClick(index)}
                className="border cursor-pointer border-yellow-500 rounded-full py-2 px-3 bg-gray-100 "
              >
                <span>{item.name}</span>
              </a>
            ))}
        </div>
      </div>
    </Fragment>
  );
};
