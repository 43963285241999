import React from "react";

import { classNames } from "tools/ui";
import imgPizza from "assets/apps/pizza.png";
import imgBurger from "assets/apps/burger.png";
import imgSoda from "assets/apps/soda.png";
import imgCalzone from "assets/apps/calzone.png";
import imgLunch from "assets/apps/lunch.png";
import imgNachos from "assets/apps/nachos.png";
import imgSpaghetti from "assets/apps/spaghetti.png";
import imgSushi from "assets/apps/sushi.png";
import imgBeer from "assets/apps/beer.png";
import imgDessert from "assets/apps/dessert.png";
import imgFrenchFries from "assets/apps/french-fries.png";
import imgBreakfast from "assets/apps/breakfast.png";
import imgDonut from "assets/apps/donut.png";
import imgCoffee from "assets/apps/coffee.png";
import imgChampagne from "assets/apps/champagne.png";
import imgJuice from "assets/apps/juice.png";
import imgRice from "assets/apps/rice.png";
import imgSausage from "assets/apps/sausage.png";
import imgFork from "assets/apps/fork.png";
// import imgMilkshake from "assets/apps/milkshake.png";
// import imgIcecream from "assets/apps/ice-cream.png";

const ICONS = {
  pizza: { img: imgPizza, color: "bg-stone-100" },
  burger: { img: imgBurger, color: "bg-stone-100" },
  soda: { img: imgSoda, color: "bg-stone-100" },
  calzone: { img: imgCalzone, color: "bg-stone-100" },
  lunch: { img: imgLunch, color: "bg-stone-100" },
  nachos: { img: imgNachos, color: "bg-stone-100" },
  spaghetti: { img: imgSpaghetti, color: "bg-stone-100" },
  sushi: { img: imgSushi, color: "bg-stone-100" },
  beer: { img: imgBeer, color: "bg-stone-100" },
  french_fries: { img: imgFrenchFries, color: "bg-stone-100" },
  breakfast: { img: imgBreakfast, color: "bg-stone-100" },
  donut: { img: imgDonut, color: "bg-stone-100" },
  champagne: { img: imgChampagne, color: "bg-stone-100" },
  coffee: { img: imgCoffee, color: "bg-stone-100" },
  dessert: { img: imgDessert, color: "bg-stone-100" },
  juice: { img: imgJuice, color: "bg-stone-100" },
  rice: { img: imgRice, color: "bg-stone-100" },
  sausage: { img: imgSausage, color: "bg-stone-100" },
};

const base =
  "text-center mx-2 my-3 rounded-2xl p-3 active:scale-95 active:bg-lime-100";

const baseText =
  "text-sm md:text-base text-gray-600 font-semibold mx-auto my-auto";
const shadow = "shadow-[0_8px_30px_rgb(0,0,0,0.12)]";

function RestCardCategory({ record, onChange }) {
  let iconImg = imgFork;
  let iconBg = "bg-stone-100";
  if (record && record.name_icon) {
    let icon = ICONS[record.name_icon];
    if (icon) {
      iconImg = icon.img;
      iconBg = icon.color;
    }
  }

  return (
    <div
      className={classNames(base, iconBg, shadow)}
      onClick={() => onChange("enter", record)}
    >
      <img src={iconImg} className="w-16 h-16 mx-auto p-2" alt="category" />
      <p className={classNames(baseText)}>{record.name}</p>
    </div>
  );
}

export default RestCardCategory;
