// Sale model
import store from "store";
import date from "date-and-time";

import saleLine from "./ModelSaleLine";
import tools from "tools/common";
import wizardPrint from "./wizardPrint";

const today = new Date();
const raw_date = tools.subtractTimeFromDate(today, 12);
const target_date = date.format(raw_date, "YYYY-MM-DD HH:mm:ss");

const searchParty = (value) => {
  const search = [
    "OR",
    [("name", "ilike", value)],
    [("id_number", "ilike", value)],
  ];
  return search;
};

const searchTable = () => {
  const session = store.get("ctxSession");
  const search = [
    ["state", "=", "available"],
    ["shop", "=", session.shop],
  ];
  return search;
};

// const getAddress = (value, record) => {
//   if (record && record.hasOwnProperty("party.")) {
//     const party_id = record["party."].id;
//     const dom = `[
//       ('party', '=', ${party_id}),
//     ]`;
//     return dom;
//   }
// };

// const onChangeLines = (activeRecord, fieldValue) => {
//   if (activeRecord.lines) {
//     let total_amount = 0;
//     for (let l of fieldValue.values()) {
//       total_amount += l.amount;
//     }
//     activeRecord["total_amount"] = total_amount;
//   }
//   return activeRecord;
// };

const getFilters = () => {
  const session = store.get("ctxSession");
  return {
    today: [
      ["create_date", ">=", `${target_date}`],
      ["create_uid", "=", session.user],
      ["order_status", "=", "commanded"],
    ],
  };
};

const getView = (config) => {
  let DictCtxView = {
    row_selectable: false,
    // form_action_add: true,
    // table_action_update: true,
    activeSearch: true,
    filters: getFilters,
    model: "sale.sale",
    form_action: ["add"],
    table_action: ["edit"],
    webfields: {
      number: { type: "char", readOnly: true },
      table_assigned: {
        type: "many2one",
        model: "sale.shop.table",
        recSearch: searchTable,
        required: true,
        readOnly: { state: ["quotation"] },
      },
      party: {
        type: "many2one",
        model: "party.party",
        recSearch: searchParty,
        readOnly: { state: ["quotation"] },
      },
      sale_date: {
        type: "date",
        readOnly: { state: ["quotation"] },
      },
      state: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "draft",
      },
      order_status: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "draft",
      },
      lines: {
        type: "one2many",
        model: "sale.line",
        ctxView: saleLine.ctxView(config),
        required: true,
      },
      total_amount: { type: "numeric", readOnly: true },
      description: { type: "char", readOnly: { state: ["quotation"] } },
      quote: {
        type: "button",
        method: "create_sale",
        visible: ["draft"],
      },
      command: {
        type: "button",
        method: "command",
        wizard: wizardPrint,
        onSuccessMsg: "PEDIDO GENERADO!",
      },
    },
    webtree: [
      { name: "number", width: "20%" },
      { name: "table_assigned", width: "25%" },
      { name: "total_amount", width: "25%" },
      { name: "order_status", width: "30%" },
    ],
    webform: [
      { name: "table_assigned" },
      { name: "number" },
      { name: "lines", component: "modal" },
      { name: "total_amount" },
      { name: "order_status" },
      { name: "command" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
