import React, { Fragment } from "react";
import { Transition, Popover } from "@headlessui/react";

function Popup(props) {
  function handleMouseOut() {
    // setOpen(false);
    props.handleMouseOut(false);
  }

  return (
    <div className="" onMouseLeave={handleMouseOut}>
      <Popover className="relative">
        <Fragment>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
            show={props.open}
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-96 transform px-4 sm:px-0 lg:max-w-3xl">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                {props.header && (
                  <div className="bg-slate-200 px-4 py-2">{props.header}</div>
                )}
                <div className="relative grid gap-8 bg-white px-5 pt-2 pb-4">
                  {props.children}
                </div>
                {props.footer && (
                  <div className="bg-gray-50 p-4">{props.footer}</div>
                )}
              </div>
            </Popover.Panel>
          </Transition>
        </Fragment>
      </Popover>
    </div>
  );
}

export default Popup;
