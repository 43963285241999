import React, { Fragment } from "react";

import StdButton from "components/Buttons/StdButton";
import useMainStore from "store/mainStore";
import { useWizardStore } from "store/wizardStore";
import proxy from "api/proxy";
import Form from "components/MeForm/Form";

const getLineProduct = (value) => {
  const { record } = useMainStore.getState();
  let dom = [["inventory", "=", record.id]];
  if (value) {
    const target = [
      "OR",
      ["product.code", "ilike", `%${value}%`],
      ["product.name", "ilike", `%${value}%`],
      ["product.barcode", "=", value],
    ];
    dom.push(target);
  }
  return dom;
};

const onChangeLine = (record) => {
  console.log("validate", record);
  record.current_quantity = record.product?.quantity;
};

const ctxView = {
  model: "stock.inventory",
  webfields: {
    product: {
      type: "many2one",
      model: "stock.inventory.line",
      recSearch: getLineProduct,
      withChange: onChangeLine,
      attrs: ["quantity"],
    },
    current_quantity: { type: "numeric", decimalPlaces: 2, readonly: true },
    add_quantity: { type: "numeric", decimalPlaces: 2 },
    substract_quantity: { type: "numeric", decimalPlaces: 2 },
  },
  webform: [
    { name: "product" },
    { name: "current_quantity" },
    { name: "add_quantity" },
    { name: "substract_quantity" },
  ],
};

const InventoryUpdate = () => {
  const { record, updateRecord } = useMainStore();
  const { record: activeWizard, resetWizard } = useWizardStore();

  const handleClick = async (e, name) => {
    const { product, add_quantity, substract_quantity } = activeWizard;
    if (name !== "clean") {
      const quantity =
        (product.quantity ?? 0) +
        Number(add_quantity ?? 0) -
        Number(substract_quantity ?? 0);
      const { data, error } = await proxy.saveQuery({
        model: "stock.inventory.line",
        storeRec: { id: product.id, quantity: quantity },
      });
      let line_ = record.lines.get(product.id);
      line_.quantity = quantity;
      updateRecord(record);
      console.log(data, error);
    }
    reset();
  };

  const reset = () => {
    resetWizard();
  };

  return (
    <Fragment>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <Form
          ctxView={ctxView}
          webform={ctxView.webform}
          level="wizard"
          kind={"modal"}
          record={activeWizard}
        />
        <StdButton
          content={"stock.inventory.confirm_quantity"}
          name={"confirm"}
          onClick={handleClick}
        />
        <StdButton
          content={"stock.inventory.reset"}
          name={"clean"}
          onClick={handleClick}
          color={"slate"}
        />
      </div>
    </Fragment>
  );
};

export default InventoryUpdate;
