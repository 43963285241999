import PritingAPI from "printing/printing";

const URI = "http://localhost:7001";
const pritingAPI = new PritingAPI(URI);
// import store from "store";

async function printOrder(props) {
  let { data_order, data_invoice, ctx } = props;
  await pritingAPI.post("/print_invoice", {
    context: ctx,
    record: data_invoice,
  });
  await pritingAPI.post("/print_order", { context: ctx, orders: data_order });
}

export default printOrder;
