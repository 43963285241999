import React from "react";
import { useQuery } from "@tanstack/react-query";
import { FormattedMessage as FM } from "react-intl";
import date from "date-and-time";
import proxy from "api/proxy";

const WidgetSideStatusActivity = ({ day }) => {
  const ticketsDay = useQuery(["ticketsDay", day.value], getShedule);

  async function getShedule() {
    const filterDayStart = date.format(day.value, "YYYY-MM-DD");
    const dom = [
      ["schedule.date_activity", "=", filterDayStart],
      ["state", "=", "scheduled"],
    ];
    const fields = ["state", "available", "quota", "schedule.date_activity"];
    const { data: res, error } = await proxy.search(
      "sale_activity.activity",
      dom,
      fields,
    );
    if (error) {
      return [];
    }
    if (res) return res;
  }
  // let dayCurrent = new Date(day);
  // dayCurrent.setDate(dayCurrent.getDate() + 1);
  // dayCurrent.setHours(23, 59, 59);
  // let valueQuota = 0;
  // let valueAvalible = 0;
  let percentageSold = 0;

  if (ticketsDay.data?.length > 0) {
    const valueQuota = ticketsDay.data.reduce((accumulator, ticket) => {
      return accumulator + ticket.quota;
    }, 0);

    const valueAvalible = ticketsDay.data.reduce((accumulator, ticket) => {
      return accumulator + ticket.available;
    }, 0);

    percentageSold = ((valueQuota - valueAvalible) / valueQuota) * 100;
  }
  // let totalSale = (valueAvalible * 100) / valueQuota;
  // let totalSale_ = totalSale.toFixed(0);
  // totalSale_ = 100 - totalSale_;

  return (
    <div className=" space-y-3 rounded-lg">
      <div className="flex flex-col space-y-2 ">
        <div className="space-x-3 flex justify-around text-center">
          <span className="text-1xl font-semibold">
            <FM id="activity.widget_side_status.revenue_today" />
          </span>{" "}
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="space-x-3 flex justify-around pb-2">
          <span className="text-5xl font-semibold">
            {percentageSold?.toFixed(2)}%
          </span>{" "}
        </div>
        <hr className="mt-5" />
      </div>
    </div>
  );
};

export default WidgetSideStatusActivity;
