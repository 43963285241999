import React from "react";

import Board from "components/Meta/Board";
import requestView from "./ModelStockRequest";

const StockRequest = () => {
  return <Board ctxView={requestView} />;
};

export default StockRequest;
