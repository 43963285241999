import React from "react";
import { useWizardStore } from "store/wizardStore";

function CardTotal() {
  const selectedRows = useWizardStore((state) => state.record.selectedRows);
  let total_amount = 0;
  let invoices = 0;
  if (selectedRows) {
    total_amount = Array.from(selectedRows.values()).reduceRight(
      (acc, cur) => acc + cur.total_amount,
      0,
    );
    invoices = selectedRows.size;
  }
  return (
    <div className="bg-white rounded-md shadow-lg p-4 flex items-center justify-between">
      <div className="text-gray-500 font-medium">
        <p className="text-lg text-slate-800">Valor Facturas</p>
        <p className="text-lg">
          {Number(total_amount).toLocaleString("es", {
            useGrouping: true,
          })}
        </p>
      </div>
      <div className="text-gray-500 font-medium">
        <p className="text-lg text-slate-800">Número de Facturas</p>
        <p className="text-lg">{invoices}</p>
      </div>
      <div className="bg-gray-300 rounded-full h-10 w-10 flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="h-6 w-6 text-gray-500"
        >
          <path
            fillRule="evenodd"
            d="M10 2a8 8 0 100 16 8 8 0 000-16zM8.25 10a1.75 1.75 0 113.5 0 1.75 1.75 0 01-3.5 0z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
}

export default CardTotal;
