import React from "react";

import Board from "components/Meta/Board";
import modelRoom from "./ModelRoom";

function Housekeeping(props) {
  const ctxView = modelRoom.ctxView(props.config);

  return <Board ctxView={ctxView} />;
}

export default Housekeeping;
