const getOptions = () => {
  const options = [
    // { id: "", name: "Seleccionar" },

    { id: "11", name: "Registro Civil de Nacimiento" },
    { id: "12", name: "Tarjeta de Identidad" },
    { id: "13", name: "Cedula de Ciudadania" },
    { id: "21", name: "Tarjeta de Extranjeria" },
    { id: "22", name: "Cedula de Extranjeria" },
    { id: "31", name: "NIT" },
    { id: "41", name: "Pasaporte" },
    { id: "42", name: "Tipo de Documento Extranjero" },
    { id: "47", name: "PEP" },
    { id: "50", name: "NIT de otro pais" },
    { id: "91", name: "NUIP" },
    { id: "", name: "" },
  ];

  return options;
};

const getOptionsSex = () => {
  const options = [
    // { id: "", name: "Seleccionar" },

    { id: "male", name: "Masculino" },
    { id: "female", name: "Femenino" },
    { id: "", name: "" },
  ];

  return options;
};

const getView = () => {
  let ctxView = {
    model: "party.party",
    selectable: "one",
    activeSearch: true,
    filters: null,
    form_action: ["save", "add"],
    table_action: ["add"],

    // selectable: "one",
    webfields: {
      name: { type: "char", searchable: true },
      id_number: { type: "char", searchable: true },
      account_payable: { type: "boolean" },
      type_document: {
        type: "selection",
        options: getOptions(),
        // translate: true,
        default: "",
      },
      sex: {
        type: "selection",
        options: getOptionsSex(),
        // translate: true,
        default: "",
      },
      check_digit: {
        type: "integer",
      },
      email: {
        type: "char",
      },
      mobile: {
        type: "char",
        // searchable: true,
      },
      phone: {
        type: "char",
      },
      birthday: {
        type: "date",
      },
      address: {
        type: "char",
      },
      country: {
        type: "many2one",
        model: "party.country_code",
        recSearch: () => [],
      },
      nationality: {
        type: "many2one",
        model: "country.country",
        recSearch: () => [],
      },
      account_receivable: {
        type: "many2one",
        model: "account.account",
        readOnly: true,
      },
    },
    webtree: [
      { name: "name", width: "30%" },
      { name: "id_number", width: "10%" },
      { name: "account_receivable", width: "15%" },
      // { name: "amount", width: "15%" },
      // { name: "description", width: "30%" },
    ],
    webform: [
      { name: "name", uppercase: true, specialCharacters: true },
      { name: "type_document" },
      { name: "id_number" },
      { name: "address" },
      { name: "country" },
      { name: "email" },
      { name: "mobile" },
      { name: "nationality" },
      { name: "sex" },
      { name: "phone" },
      { name: "birthday" },
      { name: "addres" },
      { name: "account_receivable" },
    ],
  };

  return ctxView;
};

export default { ctxView: getView };
