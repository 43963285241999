// Sale model line

const getProduct = (value) => {
  const search = [
    "OR",
    [
      ["template.name", "ilike", `%${value}%`],
      ["template.salable", "=", true],
    ],
    [
      ["code", "ilike", `${value}%`],
      ["template.salable", "=", true],
    ],
  ];
  return search;
};

// const onChangeProduct = async (activeRecord, fieldValue, parentCtx) => {
//   if (fieldValue.list_price) {
//     if (parentCtx && parentCtx.price_list) {
//       const line = {
//         product: activeRecord.product,
//         price_list: parentCtx.price_list,
//       };
//       const res = await proxy.method("sale.sale", "dash_get_line", line);
//       activeRecord.unit_price = res.unit_price;
//       activeRecord.unit_price_w_tax = res.unit_price_w_tax;
//       // activeRecord.commission_amount = res.commission_amount;
//     } else {
//       activeRecord.unit_price_w_tax = fieldValue.list_price;
//       activeRecord.unit_price = fieldValue.list_price;
//     }
//   }
//   return activeRecord;
// };

// const onChangeQty = (activeRecord, qty) => {
//   if (activeRecord.unit_price_w_tax) {
//     if (activeRecord.discount) {
//       const { unit_price_w_tax, discount } = activeRecord;
//       const amount =
//         unit_price_w_tax * qty - (unit_price_w_tax * qty * discount) / 100;
//       activeRecord.amount = amount;
//     } else {
//       activeRecord.amount = activeRecord.unit_price_w_tax * qty;
//     }
//   }
//   return activeRecord;
// };

const getView = () => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    row_selectable: false,
    form_action_add: "modal",
    // table_action_update: true,
    model: "sale.line",
    form_action: ["add"],
    table_action: ["edit"],
    form_action_delete: [{ state: "draft" }],
    webfields: {
      product: {
        type: "many2one",
        model: "product.product",
        recSearch: getProduct,
        attrs: ["id", "list_price", "name"],
        required: true,
      },
      quantity: { type: "integer", required: true },
      amount: { type: "numeric", readOnly: true },
      note: { type: "text" },
      order_sended: { type: "boolean", readOnly: true, translate: true },
    },
    webtree: [
      { name: "product", width: "30%" },
      { name: "quantity", width: "10%" },
      { name: "note", width: "25%" },
      { name: "order_sended", width: "10%" },
    ],
    webform: [
      { name: "product" },
      { name: "quantity" },
      { name: "note" },
      { name: "order_sended" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
