import React from "react";
import TitleSection from "../../ui/TitleSection";
import { NavCategories } from "../../NavCategories";
import MenuItem from "./MenuItem";
import Button from "../../ui/Button";

// const arrayProducts = signal([]);

const Menu = ({ data: dataRes, baseUrl, shop }) => {
  const { name, id, ticket_footer } = shop;
  const linkShop = `/${baseUrl}/shops`;
  return (
    <section className="container mx-auto mb-20 px-2 md:px-0">
      <Button className={"mt-5"} text="volver" url={linkShop} />
      <div className="space-y-10">
        <TitleSection text={name}>{ticket_footer}</TitleSection>
        <NavCategories mode="left" categories={dataRes} />
        <MenuItem data={dataRes} />
      </div>
    </section>
  );
};

export default Menu;
