const ctxViewCard = {
  model: "web.user",
  form_action: [],
  table_action: ["delete"],
  webfields: {
    card_number: {
      type: "char",
      required: true,
    },
    name: {
      type: "char",
      required: true,
    },
    expiration_year: {
      type: "integer",
      required: true,
    },
    expiration_month: {
      type: "integer",
      required: true,
    },
    address: {
      type: "char",
      required: true,
    },
    creation_date: {
      type: "date-time",
    },
    // brand: {
    //   type: "char",
    //   required: true,
    // },
    points_card: {
      type: "integer",
      required: true,
    },
    state: { type: "char", readOnly: true },
    active: {
      type: "button",
      method: "active_card",
      color: "green",
      visible: ["deactive"],
    },
    deactive: {
      type: "button",
      method: "deactive_card",
      color: "rose",
      visible: ["active"],
    },
  },
  webtree: [
    { name: "card_number", width: "40%" },
    { name: "name", width: "40%" },
    // { name: "brand", width: "40%" },
    { name: "state", width: "40%" },
    { name: "active", width: "40%" },
    { name: "deactive", width: "40%" },
    // { name: "expiration_year", width: "40%" },
    // { name: "expiration_month", width: "40%" },
    // { name: "address", width: "40%" },
    // { name: "creation_date", width: "40%" },
    // { name: "points_card", width: "40%" },
  ],
};

export default ctxViewCard;
