// Guest model
import mainStore from "store/mainStore";
import grandStore from "store/grandStore";
import proxy from "api/proxy";

const GUEST = [
  { id: "adult", name: "adult" },
  { id: "child", name: "child" },
];

const SEX = [
  { id: "female", name: "female" },
  { id: "male", name: "male" },
];

const TYPES = [
  { id: "13", name: "cedula_ciudadania" },
  { id: "41", name: "pasaporte" },
  { id: "12", name: "tarjeta_identidad" },
  { id: "11", name: "registro_civil" },
  { id: "21", name: "tarjeta_extranjeria" },
  { id: "22", name: "cedula_extranjeria" },
  { id: "42", name: "tipo_documento_extranjero" },
  { id: "47", name: "pep" },
  { id: "91", name: "nuip" },
];

const model = "party.party";
const fields = [
  "name",
  "id_number",
  "type_document",
  "sex",
  "main_mobile",
  "main_email",
  "street",
  "birthday",
  "nationality.rec_name",
  "country.rec_name",
];

function getRequiredByMainGuest(record) {
  const res = record ? record.main_guest : false;
  return res;
}

function getCities(data, record) {
  let dom = null;
  if (record && record.subdivision) {
    dom = [["department", "=", record.subdivision.id]];
  }
  return dom;
}

function reqByCountry(record) {
  let res = false;
  if (record.country && record.country.rec_name === "COLOMBIA") {
    res = true;
  }
  return res;
}

async function onAutoCreateGuest() {
  const { record: mainRecord } = mainStore.getState();
  const { record, store, updateStore, updateRecord } = grandStore.getState();

  if (mainRecord.party) {
    const dom = [
      ["id", "=", mainRecord.party.id],
      ["type_document", "!=", "31"],
    ];
    const { data } = await proxy.search(model, dom, fields);

    if (data) {
      const rec = { ...data[0] };
      console.log("rec.....", rec);
      let recordGuest = {
        main_guest: true,
        name: rec.name,
        id_number: rec.id_number,
        address: rec.street,
        mobile: rec.main_mobile,
        email: rec.main_email,
        birthday: rec.birthday,
      };
      let storeGuest = { ...recordGuest };
      if (rec.type_document) {
        for (const _type of TYPES) {
          if (_type.id === rec.type_document) {
            recordGuest.type_document = _type;
            storeGuest.type_document = _type.id;
          }
        }
      }

      if (rec["country."]) {
        recordGuest.country = rec["country."];
        storeGuest.country = rec["country."].id;
      }
      if (rec["nationality."]) {
        recordGuest.nationality = rec["nationality."];
        storeGuest.nationality = rec["nationality."].id;
      }

      if (rec.sex) {
        for (const _sex of SEX) {
          if (_sex.id === rec.sex) {
            recordGuest.sex = _sex;
            storeGuest.sex = _sex.id;
          }
        }
      }
      const recordGrand = { ...record, ...recordGuest };
      const storeGrand = { ...store, ...storeGuest };
      updateRecord(recordGrand);
      updateStore(storeGrand);
      return {};
    }
  }

  return {
    msg: "hotel.guest.you_can_not_clone_holder",
  };
}

function visibleCreateGuest() {
  return true;
}

const getView = () => {
  let DictCtxView = {
    model: "hotel.folio.guest",
    form_action: ["add", "delete", "edit"],
    table_action: ["add", "edit"],
    orderBy: [["name", "ASC"]],
    selectable: null, // Options for table rows: null, multi, one
    target: "folio",
    autoSave: true,
    webfields: {
      name: { type: "char", required: true },
      type_guest: {
        type: "selection",
        options: GUEST,
        required: true,
        translate: true,
        default: { id: "adult", name: "adult" },
      },
      type_document: {
        type: "selection",
        options: TYPES,
        required: true,
        translate: true,
        default: { id: "13", name: "cedula_ciudadania" },
      },
      sex: {
        type: "selection",
        options: SEX,
        required: true,
        translate: true,
        default: { id: "male", name: "male" },
      },
      id_number: { type: "char", required: true },
      mobile: { type: "char", required: true },
      email: { type: "char", required: true },
      address: { type: "char", required: getRequiredByMainGuest },
      birthday: { type: "date" },
      visa_date: { type: "date" },
      visa_number: { type: "char" },
      profession: { type: "char" },
      notes: { type: "text" },
      main_guest: {
        type: "boolean",
        translate: true,
      },
      country: {
        type: "many2one",
        model: "country.country",
      },
      subdivision: {
        type: "many2one",
        model: "country.subdivision",
        required: reqByCountry,
        visible: reqByCountry,
      },
      city: {
        type: "many2one",
        model: "country.city_co",
        required: reqByCountry,
        visible: reqByCountry,
        recSearch: getCities,
      },
      origin_country: {
        type: "many2one",
        model: "country.country",
      },
      target_country: {
        type: "many2one",
        model: "country.country",
      },
      nationality: {
        type: "many2one",
        model: "country.country",
        required: true,
      },
      clone_holder: {
        type: "button",
        visible: visibleCreateGuest,
        color: "amber",
        method: onAutoCreateGuest,
        iconLeft: "fi fi-rr-users",
      },
    },
    webtree: [
      { name: "name", width: "25%" },
      { name: "type_guest", width: "15%" },
      { name: "type_document", width: "10%" },
      { name: "id_number", width: "10%" },
      { name: "mobile", width: "10%" },
      { name: "main_guest", width: "5%" },
    ],
    webform: [
      { name: "clone_holder" },
      { name: "name" },
      {
        id: "info1",
        grid: [{ name: "type_guest" }, { name: "main_guest" }],
        size: [1, 2],
        span: "col-span-1",
      },
      { name: "type_document" },
      { name: "id_number" },
      {
        id: "info2",
        grid: [{ name: "mobile" }, { name: "sex" }],
        size: [1, 2],
        span: "col-span-1",
      },
      { name: "email" },
      { name: "country" },
      { name: "address" },
      { name: "subdivision" },
      { name: "city" },
      { name: "nationality" },
      {
        id: "info_country",
        grid: [{ name: "origin_country" }, { name: "target_country" }],
        size: [1, 2],
        span: "col-span-1",
      },
      { name: "birthday" },
      { name: "profession" },
      {
        id: "info4",
        grid: [{ name: "visa_date" }, { name: "visa_number" }],
        size: [1, 2],
        span: "col-span-1",
      },
      { name: "notes" },
      { name: "clone_holder" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
