import React, { useEffect, useState } from "react";
// import { useQuery } from "@tanstack/react-query";
import { CheckIcon } from "@heroicons/react/20/solid";

import proxy from "api/proxy";
import BasicModal from "components/Modals/BasicModal";
import Button from "components/Buttons/StdButton";
import { useWizardStore } from "store/wizardStore";

function ModalConsumer({ consumerId, closeConsumer, open }) {
  const { record, updateWizard, reset } = useWizardStore();
  const [idConsumer, setIdConsumer] = useState(null);
  const [checkVisible, setCheckVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [msg, setMsg] = useState(null);

  let data = record;
  console.log(consumerId, idConsumer, data, open);
  // const queryConsumer = useQuery(["consumer", idConsumer], async () => {
  //   if (idConsumer) {
  //     const { data } = await proxy.search(
  //       "party.consumer",
  //       [["id", "=", idConsumer]],
  //       ["phone", "address", "id_number", "name", "notes", "delivery"],
  //     );
  //     if (data) updateWizard(data[0]);
  //     return data[0] ?? null;
  //   } else {
  //     return null;
  //   }
  // });

  async function getConsumer() {
    if (open) {
      const { data } = await proxy.search(
        "party.consumer",
        [["id", "=", consumerId]],
        ["phone", "address", "id_number", "name", "notes", "delivery"],
      );
      if (data?.length > 0) {
        updateWizard(data[0]);
      }
    }
  }

  useEffect(() => {
    getConsumer();
    return () => {
      reset();
      setIdConsumer(null);
    };
  }, [consumerId, open]);

  const handlePhone = async (event) => {
    const phone = event.target.value;
    setIsChecked(false);
    if (phone) {
      const { data: consumer_ } = await proxy.search(
        "party.consumer",
        [["phone", "=", phone]],
        ["phone", "address", "id_number", "name", "notes", "delivery"],
      );
      setCheckVisible(false);

      if (consumer_ && consumer_.length > 0) {
        event.target.form.reset();
        setIdConsumer(consumer_[0].id);
        updateWizard(consumer_[0]);
        setCheckVisible(true);
      } else {
        event.target.form.reset();
        reset();
        updateWizard({ phone });
        setIdConsumer(null);
        setCheckVisible(false);
      }
    }
  };

  const handleField = (event) => {
    const target = event.target;
    let storeRec_ = { ...record };
    if (target.name === "newPhone") {
      storeRec_["phone"] = target.value;
    } else {
      storeRec_[target.name] = target.value;
    }
    updateWizard(storeRec_);
  };

  async function acceptConsumer(event) {
    event.stopPropagation();
    event.preventDefault();
    let consumer_ = { ...record, ...{ id: idConsumer } };
    if (!consumer_.address || !consumer_.phone || !consumer_.name) {
      setMsg("*Faltan campos requeridos");
      return;
    }
    const { data: res } = await proxy.saveQuery({
      model: "party.consumer",
      storeRec: consumer_,
    });
    if (idConsumer <= 0 && res) {
      consumer_["id"] = res[0];
    }
    const { data: consumer } = await proxy.browse(
      "party.consumer",
      [consumer_.id],
      ["rec_name"],
    );
    closeConsumer(consumer[0]);
  }

  const closeConsumer_ = () => {
    closeConsumer(null);
  };

  const required = <span className="text-rose-400"> *</span>;

  return (
    <BasicModal
      title={"sale.consumer.title"}
      open={open}
      width={"sm:w-2/6"}
      onClose={closeConsumer_}
    >
      <form className={"pb-4 space-y-4"}>
        <div>
          <label htmlFor="phone" className="block text-gray-700 font-bold mb-2">
            TELEFONO {required}
          </label>
          <div className="relative">
            <input
              className="shadow border appearance-none py-2 px-3 w-full"
              type={"text"}
              name="phone"
              value={data?.phone}
              onBlur={handlePhone}
              onChange={handleField}
            />
            {checkVisible && (
              <CheckIcon
                className="h-[30px] w-[30px] py-1 px-1 absolute right-0 top-1"
                color="#4cc9f0"
              />
            )}
          </div>
        </div>
        {idConsumer && idConsumer !== -1 && (
          <div>
            <label
              htmlFor="name"
              className="block text-gray-700 font-bold mb-2"
            >
              ACTUALIZAR A NUEVO TELEFONO
              <input
                type="checkbox"
                className="ml-3"
                onChange={() => {
                  setIsChecked(!isChecked);
                }}
              />
            </label>
          </div>
        )}
        {isChecked && (
          <div>
            <label
              htmlFor="name"
              className="block text-gray-700 font-bold mb-2"
            >
              NUEVO TELEFONO
            </label>
            <input
              className="shadow border appearance-none py-2 px-3 w-full"
              type={"text"}
              name="newPhone"
              value={data?.newPhone}
              onChange={handleField}
            />
          </div>
        )}
        <div>
          <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
            NOMBRE {required}
          </label>
          <input
            className="shadow border appearance-none py-2 px-3 w-full"
            type={"text"}
            name="name"
            value={data?.name}
            onChange={handleField}
          />
        </div>
        <div>
          <label
            htmlFor="address"
            className="block text-gray-700 font-bold mb-2"
          >
            DIRECCION {required}
          </label>
          <input
            className="shadow border appearance-none py-2 px-3 w-full"
            type={"text"}
            name="address"
            value={data?.address}
            onChange={handleField}
          />
        </div>
        <div>
          <label
            htmlFor="id_number"
            className="block text-gray-700 font-bold mb-2"
          >
            NUMERO DOCUMENTO
          </label>
          <input
            className="shadow border appearance-none py-2 px-3 w-full"
            type={"text"}
            name="id_number"
            value={data?.id_number}
            onChange={handleField}
          />
        </div>
        <div>
          <label
            htmlFor="delivery"
            className="block text-gray-700 font-bold mb-2"
          >
            DOMICILIO
          </label>
          <input
            className="shadow border appearance-none py-2 px-3 w-full"
            type={"number"}
            name="delivery"
            value={data?.delivery}
            onChange={handleField}
          />
        </div>
        <div>
          <label htmlFor="note" className="block text-gray-700 font-bold mb-2">
            NOTAS
          </label>
          <textarea
            className="shadow border appearance-none py-2 px-3 w-full"
            name="notes"
            value={data?.notes}
            onChange={handleField}
          />
        </div>
        {msg && <span className="text-rose-400">{msg}</span>}
        <div className="mt-4 grid grid-cols-2 gap-4 sticky bg-gray-50 bottom-0 w-[106%] p-3 -ml-[16px]">
          <Button
            onClick={closeConsumer_}
            key="cancel"
            color="rose"
            size="w-full"
            content="basic_modal.button_cancel"
          />
          <button
            className="flex my-auto h-12 from-blue-400 to-cyan-500 text-white text-base font-bold hover:shadow-md bg-gradient-to-r rounded-lg py-2 px-3 items-center text-center hover:opacity-90 disabled:opacity-50 justify-center gap-2"
            onClick={acceptConsumer}
            key="ok"
          >
            OK
          </button>
        </div>
      </form>
    </BasicModal>
  );
}

export default ModalConsumer;
